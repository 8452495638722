import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CceAdmin from "../cce/CceAdmin";
import PasswordManagement from "../cce/PasswordManagement";
import Ccesetup from "../cce/Init";
import { Panel } from "primereact/panel";

export class Hradmin extends Component {
  render() {
    return (
      <div className="layout-main">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-no-topborder">
              {/* <div className="box-header with-border">
                <div className="box-title"> {this.props.menuProps.label}</div>
              </div> */}

              <Panel header={this.props.menuProps.label}>
                {/* given access to sling */}
                {window.location.host.toLowerCase().includes("sling1") ? (
                  <CceAdmin type="hr" typelogin="hr"></CceAdmin>
                ) : (
                  <TabView>
                    <TabPanel header="Employee Question Status">
                      <CceAdmin
                        type="hr"
                        typelogin="hr"
                        menuProps={{ label: "Questions & Answers Status" }}
                      ></CceAdmin>
                    </TabPanel>
                    <TabPanel header="Password Reset">
                      <PasswordManagement
                        menuProps={{ label: "Password Management" }}
                        typelogin="hr"
                      ></PasswordManagement>
                    </TabPanel>
                    <TabPanel header="Employee Configuration">
                      <Ccesetup
                        menuProps={{ label: "Setup" }}
                        typelogin="hr"
                      ></Ccesetup>
                    </TabPanel>
                  </TabView>
                )}
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hradmin;
