import React, { Component } from "react";
import api from "../../api";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { TreeTable } from "primereact/treetable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import _ from "lodash";

class DimEmpScore extends Component {
  state = {
    dim_Emp: [],
    text: null,
    text2: "",
    text3: "",
    dim_Emp_Score: null,
    exp: {},
    exp1: false,
    dim: {},
    globalFilter: null,
    loading: true
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation)) this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true, text2: "" });
    const { organisation } = this.props;
    api
      .post("/api/cce/dim_emp_score", { organisation })
      .then(res => {
        this.setState({ dim_Emp: res.data.res, loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }

  filterMsg = event => {
    setTimeout(() => {
      var results = this.state.dim_Emp.filter(country => {
        return country.emp_name
          .toLowerCase()
          .startsWith(event.query.toLowerCase());
      });

      this.setState({ text: results });
    }, 250);
  };
  empSearch = () => {
    if (this.state.text2 === "") {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Select an Employee"
      });
    } else {
      const { organisation } = this.props;
      const fData = { emp_no: this.state.text2, organisation: organisation };
      api
        .post("/api/cce/dim_emp_score_post", JSON.stringify(fData))
        .then(res => {
          this.setState({
            dim_Emp_Score: res.data.dim_emp_score,
            exp: res.data.expmsg,
            loading: false
          });
        })
        .catch(error => console.error("Error:", error));
    }
  };
  toggleApplications = () => {
    let dim = { ...this.state.dim };
    if (this.state.exp1 === true) {
      dim = {};
      this.setState({ exp1: false });
    } else {
      dim = this.state.exp;
      this.setState({ exp1: true });
    }
    this.setState({ dim: dim });
  };

  render() {
    let header = (
      <div className="p-grid">
        <div className="p-col-6" style={{ textAlign: "left" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={e => this.setState({ globalFilter: e.target.value })}
            placeholder="Search"
            size="50"
          />
        </div>
        <div className="p-col-3"></div>
        <div className="p-col-2">
          <Button
            onClick={this.toggleApplications}
            label="Expand/Collapse All"
          />
        </div>
      </div>
    );
    const {
      dim_Emp,
      text2,
      dim_Emp_Score,
      dim,
      globalFilter,
      loading
    } = this.state;
    return (
      <div className="layout-main">
        <div className="p-grid">
          <div className="p-col-12">
            {loading ? (
              <div className="loading-spinner-container">
                <ProgressSpinner></ProgressSpinner>
              </div>
            ) : (
                <Panel header={this.props.menuProps.label}>
                  <Growl
                    ref={el => (this.growl = el)}
                    style={{ marginTop: 20 }}
                    position="bottomright"
                  />
                  <div className="p-grid">
                    <div className="p-col-2">
                      <label>Select Employee: </label>
                    </div>
                    <Dropdown
                      style={{ width: "19em", marginLeft: "-3em" }}
                      size={30}
                      value={text2}
                      options={dim_Emp}
                      placeholder={
                        dim_Emp.length === 0
                          ? "Employees not found"
                          : "Click here..."
                      }
                      onChange={e => this.setState({ text2: e.value })}
                      filter={true}
                      filterPlaceholder="Select Employee"
                      filterBy="label,value"
                    />

                    <Button
                      style={{ marginLeft: "5em" }}
                      onClick={
                        (() => this.setState({ loading: true }),
                          () => this.empSearch())
                      }
                      label="Search"
                    />
                    <br />
                    <div>
                      <br />
                      {dim_Emp_Score === null ? (
                        <p></p>
                      ) : loading ? (
                        <div className="loading-spinner-container">
                          <ProgressSpinner></ProgressSpinner>
                        </div>
                      ) : text2 !== "" ? (
                        <TreeTable
                          value={dim_Emp_Score}
                          expandedKeys={dim}
                          globalFilter={globalFilter}
                          header={header}
                          onToggle={e => this.setState({ dim: e.value })}
                          style={{ marginTop: ".5em" }}
                          emptyMessage={
                            dim_Emp_Score.length === 0 && !loading
                              ? "No data"
                              : "Loading..."
                          }
                        >
                          <Column
                            field="title"
                            header="Dimension Name"
                            expander
                          ></Column>
                          <Column field="score" header="Dimension Id"></Column>
                        </TreeTable>
                      ) : null}
                    </div>
                  </div>
                </Panel>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organisation: state.util.organisation
  };
};

export default connect(mapStateToProps, null)(DimEmpScore);
