import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CceAdmin from "../cce/CceAdmin";
import PasswordManagement from "../cce/PasswordManagement";
import Ccesetup from "../cce/Init";
import { Panel } from "primereact/panel";

export class Statistics extends Component {
  render() {
    return (
      <CceAdmin
        type={this.props.type}
        user={this.props.type}
        menuProps={{ label: "Questions & Answers Statistics" }}
      ></CceAdmin>
    );
  }
}

export default Statistics;
