import React, { Component } from "react";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
// import { trainexcelexport } from "../../common/Helper";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { InputSwitch } from "primereact/inputswitch";
import XLSX from "xlsx";

let key = 0;
export class Training extends Component {
  state = {
    dims: [],
    levels: [],
    data: [],
    activeIndex: 0,
    index1: 0,
    index2: 0,
    departments: [],
    seldep: [],
    location: [],
    selloc: [],
    dimensions: [],
    parentdimensions: [],
    selparentdim: [],
    groupnames: [],
    selgroup: [],
    load_flag: "True",
    visible: false,
    popupmsg: "",
    dimname: "",
    loading_handle: true,
    date: null,
    report: null,
    dataprogress: false,
    loading_flag: true,
    empcount: 0,
    filename_dialog: false,
    filename: "",
    train_yellow_text: "",
    train_red_text: "",
    enablebutton: true,
    first: 0,
    display_tarining: false,
    training_data: [],
    training_ids: [],
    sel_train_id: [],
  };
  componentDidMount() {
    const data = { user: this.props.user };
    api
      .post("/api/assessments/traindata", data)
      .then((res) => {
        if (res.data.results === true) {
          this.setState({
            load_flag: "False",
            dims: res.data.dims,
            levels: res.data.levels,
            data: res.data.data,
            training_data: res.data.training_data,
            masterdata: res.data.data,
            mastertraining_data: res.data.training_data,
            training_ids: res.data.training_ids,
            location: res.data.locations,
            departments: res.data.departments,
            parentdimensions: res.data.parentdimensions,
            groupnames: res.data.groupnames,
            index1: res.data.index1,
            index2: res.data.index2,
            loading_handle: false,
            dataprogress: true,
            loading_flag: false,
            empcount: res.data.empcount,
            train_red_text: res.data.train_red_text,
            train_yellow_text: res.data.train_yellow_text,
          });
        } else {
          this.setState({
            loading_handle: false,
            loading_flag: false,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  dimsel = (num) => {
    const { dims } = this.state;
    api
      .post("/api/assessments/traindata", {
        id: dims[num][1],
        user: this.props.user,
      })
      .then((res) => {
        this.setState({
          levels: res.data.levels,
          data: res.data.data,
          training_data: res.data.training_data,
          training_ids: res.data.training_ids,
          masterdata: res.data.data,
          mastertraining_data: res.data.training_data,
        });
      })
      .catch((err) => console.log(err));
  };
  disblebtn = () => {
    if (this.state.seldep.length === 0 && this.state.selloc.length === 0 && this.state.selparentdim.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  filters = () => {
    if (
      this.state.seldep.length === 0 &&
      this.state.selloc.length === 0 &&
      this.state.selgroup.length === 0 &&
      this.state.sel_train_id.length === 0
      // this.state.selparentdim.length === 0 &&
    ) {
      {
        this.growl.show({
          severity: "error",

          detail: "No Filter Selected",
        });
      }
    } else {
      this.setState({ loading_handle: true });
      api
        .post("/api/assessments/trainingfilters", {
          // selecteddata: this.state.selectedemps,
          selectedloc: this.state.selloc,
          selecteddep: this.state.seldep,
          selparentdim: this.state.selparentdim,
          selgroup: this.state.selgroup,
          sel_train_id: this.state.sel_train_id,
          user: this.props.user,
        })
        .then((res) => {
          if (res.data.results === true) {
            this.setState({
              dims: res.data.dims,
              levels: res.data.levels,
              data: res.data.data,
              training_data: res.data.training_data,
              location: res.data.locations,
              departments: res.data.departments,
              index1: res.data.index1,
              index2: res.data.index2,
              parentdimensions: res.data.parentdimensions,
              load_flag: "False",
              loading_handle: false,
              empcount: res.data.empcount,
            });
          } else {
            this.setState({
              dims: res.data.dims,
              levels: res.data.levels,
              data: res.data.data,
              training_data: res.data.training_data,
              location: res.data.locations,
              departments: res.data.departments,
              index1: res.data.index1,
              index2: res.data.index2,
              parentdimensions: res.data.parentdimensions,
              load_flag: "False",
              loading_handle: false,
              empcount: res.data.empcount,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  getfilename = () => {
    console.log(this.state.selectedemps);
    if (this.state.selectedemps) {
      this.setState({ filename_dialog: true });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Please select atleast one Dimension..",
      });
    }
  };
  getemployees = (filename) => {
    const fname = filename;

    if (fname) {
      console.log(fname.length);
      if (fname.length > 0) {
        api
          .post("/api/assessments/trainingemployees", {
            selecteddata: this.state.selectedemps,
            selectedloc: this.state.selloc,
            selecteddep: this.state.seldep,
            display_tarining: this.state.display_tarining,
            user: this.props.user,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.results) {
              if (res.data.emps) {
                this.setState({
                  emps: res.data.emps,
                  dimensions: res.data.dimensions,
                  date: res.data.currdate,
                  report: res.data.report,
                });
                this.fonHide();
                this.trainexcelexport(fname);
              } else {
                this.growl.show({
                  severity: "info",
                  summary: "No employees matching the filter",
                });
              }
            } else {
              this.growl.show({
                severity: "error",
                summary: "Please select atleast one Dimension..",
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.growl.show({
          severity: "error",
          summary: "Please enter the filename.",
        });
      }
    }
  };
  excelexport = ({ fname, expfname, headers, emps, dims, currdate, reportname }) => {
    const { display_tarining, training_data } = this.state;
    const wb = XLSX.utils.book_new();
    dims.map((dim) => {
      var data = this.state.emps[dim].map((emp) => [emp.emp_no, emp.emp_name, emp.email, emp.mgr, emp.dept, emp.sec, emp.role, emp.designation]);
      const ws = XLSX.utils.aoa_to_sheet([headers, ...data], { origin: "A6" });

      XLSX.utils.sheet_add_aoa(ws, [["Date:"]], {
        origin: "A1",
      });
      XLSX.utils.sheet_add_aoa(ws, [[currdate]], {
        origin: "B1",
      });
      XLSX.utils.sheet_add_aoa(ws, [["Report Type:"]], {
        origin: "A2",
      });
      XLSX.utils.sheet_add_aoa(ws, [[reportname]], {
        origin: "B2",
      });
      if (display_tarining) {
        XLSX.utils.sheet_add_aoa(ws, [["Training Id:"]], {
          origin: "A3",
        });
        XLSX.utils.sheet_add_aoa(ws, [["Training Name:"]], {
          origin: "A4",
        });
        XLSX.utils.sheet_add_aoa(ws, [[dim]], {
          origin: "B3",
        });
        const training = training_data.filter((data) => data.training_id === dim);
        const training_name = training ? training[0] : { module_name: "" };
        XLSX.utils.sheet_add_aoa(ws, [[training_name["module_name"]]], {
          origin: "B4",
        });
      } else {
        XLSX.utils.sheet_add_aoa(ws, [["KPT:"]], {
          origin: "A3",
        });
        XLSX.utils.sheet_add_aoa(ws, [[dim]], { origin: "B3" });
      }
      XLSX.utils.book_append_sheet(wb, ws, dim.substring(0, 30));
    });
    XLSX.writeFile(wb, expfname);
  };
  reset = () => {
    this.setState({ loading_handle: true });
    const data = { user: this.props.user };
    api
      .post("/api/assessments/traindata", data)
      .then((res) => {
        key++;
        if (res.data.results === true) {
          this.setState({
            load_flag: "False",
            dims: res.data.dims,
            levels: res.data.levels,
            data: res.data.data,
            training_data: res.data.training_data,
            masterdata: res.data.data,
            mastertraining_data: res.data.training_data,
            location: res.data.locations,
            departments: res.data.departments,
            parentdimensions: res.data.parentdimensions,
            index1: res.data.index1,
            index2: res.data.index2,
            loading_handle: false,
            empcount: res.data.empcount,
            train_red_text: res.data.train_red_text,
            train_yellow_text: res.data.train_yellow_text,
          });
        } else {
          this.setState({
            dims: res.data.dims,
            levels: res.data.levels,
            data: res.data.data,
            training_data: res.data.training_data,
            masterdata: res.data.data,
            mastertraining_data: res.data.training_data,
            parentdimensions: res.data.parentdimensions,
            location: res.data.locations,
            departments: res.data.departments,
            index1: res.data.index1,
            index2: res.data.index2,
            empcount: res.data.empcount,
            train_red_text: res.data.train_red_text,
            train_yellow_text: res.data.train_yellow_text,
          });
        }
      })
      .catch((err) => console.log(err));
    this.setState({
      selparentdim: [],
      seldep: [],
      selloc: [],
      selgroup: [],
      sel_train_id: [],
      selgroup: [],
      globalFilter: null,
    });
  };
  trainexcelexport = (filename) => {
    const fname = filename;
    const expfname = filename + ".xls";
    const headers = ["Employee Number", "Employee Name", "Email", "Manager Number", "Department", "Section", "Role", "Designation"];
    const dims = this.state.dimensions;
    const currdate = this.state.date;
    const reportname = this.state.report;
    const emps = this.state.emps;
    /* convert from workbook to array of arrays */
    this.excelexport({
      fname,
      expfname,
      headers,
      emps,
      dims,
      currdate,
      reportname,
    });
    this.setState({
      selectedemps: [],
      enablebutton: true,
      // selloc: [],
      // seldep: [],
    });
  };
  actionTemplate = (rowData) => {
    const { display_tarining } = this.state;
    return (
      <Button
        className="clickbtn p-button-raised p-button-secondary p-button-rounded"
        label={display_tarining ? (rowData["module_name"] ? rowData["module_name"] : "Null") : rowData["dim"]}
        onClick={() => this.popup(rowData)}
      />
    );
  };
  popup = (rowData) => {
    const { display_tarining } = this.state;
    this.setState({
      popupmsg: display_tarining ? rowData["syllabus"] : rowData["diminf"],
      visible: true,
      dimname: display_tarining ? rowData["module_name"] : rowData["dim"],
    });
  };
  onHide = () => {
    this.setState({
      visible: false,
      popupmsg: "",
      dimname: "",
    });
  };
  // rowClassName = rowData => {
  //   if (rowData.index <= this.state.index1) {
  //     return { "p-highlight-red": true };
  //   } else if (
  //     rowData.index > this.state.index1 &&
  //     rowData.index <= this.state.index2
  //   ) {
  //     return { "p-highlight-yellow": true };
  //   }
  // };
  rowClassName = (rowData) => {
    if (rowData["color"] === "red") {
      return { "p-highlight-red": true };
    } else if (rowData["color"] === "yellow") {
      return { "p-highlight-yellow": true };
    } else {
      return { "p-highlight-red": true };
    }
  };

  fonHide = () => {
    this.setState({
      filename_dialog: false,
      filename: null,
    });
  };

  exportenable = () => {
    if (this.state.selectedemps.length > 0) {
      this.setState({ enablebutton: false });
    } else {
      this.setState({ enablebutton: true });
    }
  };

  render() {
    const { location, departments, parentdimensions, load_flag, groupnames, data, display_tarining } = this.state;
    const { enableExport } = this.props.menuProps;
    var header = (
      <div className="p-grid">
        <div className="p-col-12">
          <div className="row">
            <div className="p-col-6">
              <InputText
                type="search"
                style={{ marginRight: "50px" }}
                onInput={(e) => this.setState({ globalFilter: e.target.value, first: 0 })}
                placeholder="Search..."
                size="50"
              />
            </div>
            {/* enableExport */}
            <div className="p-col-4">
              {/* {enableExport ? ( */}
              <Button
                className="button-excel"
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="Export"
                disabled={this.state.enablebutton}
                onClick={this.getfilename}
              />
              {/* ) : null} */}
            </div>
            <div className="p-col-2">
              <div className="total-employees-container">N - {this.state.empcount}</div>
            </div>
          </div>

          {/* <div className="row">
            <div
              className="p-col-1"
              style={{
                backgroundColor: "#f8dada",
                marginTop: "10px",
                marginRight: "0px",
                marginLeft: "20px",
                width: "2px",
                height: "15px"
              }}
            ></div>
            <div
              className="p-col-10"
              style={{ textAlign: "left", fontFamily: "Times New Roman" }}
            >
              {this.state.train_red_text}
            </div>
          </div>
          <div className="row" style={{ marginTop: "-10px" }}>
            <div
              className="p-col-1"
              style={{
                backgroundColor: "#fafa79",
                marginTop: "10px",
                marginRight: "0px",
                marginLeft: "20px",
                width: "2px",
                height: "15px"
              }}
            ></div>
            <div
              className="p-col-10"
              style={{ textAlign: "left", fontFamily: "Times New Roman" }}
            >
              {" "}
              {this.state.train_yellow_text}
            </div>
          </div> */}
        </div>
      </div>
    );
    const levels = this.state.levels.map((level, index) => <Column key={index} field={level} header={level} sortable={true} />);

    return (
      <div className="layout-main">
        {this.state.filename_dialog ? (
          <Dialog
            header="Please Provide the filename by which you want to save the file."
            visible={this.state.filename_dialog}
            style={{ width: "50vw" }}
            onHide={() => this.fonHide()}
          >
            <InputText style={{ marginRight: "15px" }} value={this.state.filename} onChange={(e) => this.setState({ filename: e.target.value })} />
            <Button label="Save" icon="pi pi-check" onClick={() => this.getemployees(this.state.filename)} />
          </Dialog>
        ) : null}
        <Dialog
          style={{ width: "40vw" }}
          className=""
          appendTo={document.body}
          header={this.state.dimname}
          visible={this.state.visible}
          onHide={this.onHide}
        >
          <span style={{ lineHeight: "2" }}>
            <ul>{this.state.popupmsg.split("::").map((learn) => learn && <li>{learn}</li>)}</ul>
          </span>
        </Dialog>

        <Panel header={<div style={{ fontSize: "18px" }}>{this.props.menuProps.label}</div>}>
          {/* <div className="box-header with-border">
<div className="box-title">Training Needs</div>
</div> */}

          {this.state.loading_flag ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : this.state.dataprogress ? (
            <div>
              <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Select: </span>
                Behavioral-wise
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.display_tarining}
                  onChange={(e) => {
                    // this.reset();
                    this.setState((prevState) => ({
                      display_tarining: e.value,
                      selectedemps: [],
                      enablebutton: true,
                      sel_train_id: [],
                      seldep: [],
                      selgroup: [],
                      selloc: [],
                      selparentdim: [],
                      data: prevState.masterdata,
                      training_data: prevState.mastertraining_data,
                    }));
                  }}
                />
                Training topic-wise
              </div>
              <Panel header="Filters">
                {/* <div className="box-header with-border">
<div className="box-title">Filters</div>
</div> */}
                <div className="box-body box-body-cust-250">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <MultiSelect
                          value={this.state.selloc}
                          options={location}
                          onChange={(e) => this.setState({ selloc: e.value })}
                          filter={true}
                          placeholder="Location..."
                        />
                      </div>
                      <div className="col-md-3">
                        <MultiSelect
                          value={this.state.seldep}
                          options={departments}
                          onChange={(e) => this.setState({ seldep: e.value })}
                          style={{ marginLeft: "15px" }}
                          filter={true}
                          placeholder="Department..."
                          scrollHeight="200px"
                        />
                      </div>
                      <div className="col-md-3">
                        <MultiSelect
                          value={display_tarining ? this.state.sel_train_id : this.state.selgroup}
                          options={display_tarining ? this.state.training_ids : groupnames}
                          onChange={(e) => {
                            display_tarining ? this.setState({ sel_train_id: e.value }) : this.setState({ selgroup: e.value });
                          }}
                          style={{ marginLeft: "15px" }}
                          filter={true}
                          placeholder={display_tarining ? "Training ids..." : "Groups..."}
                          scrollHeight="200px"
                        />
                      </div>
                      <div className="col-md-1">
                        <Button
                          type="button"
                          label="Apply"
                          onClick={this.filters}
                          style={{ marginRight: "15px" }}
                          // disabled = {false}
                        />
                      </div>
                      <div className="col-md-1">
                        <Button type="button" label="Reset" onClick={this.reset} style={{ marginRight: "15px" }} />
                      </div>

                      {/* <MultiSelect
                        value={this.state.selparentdim}
                        options={parentdimensions}
                        onChange={e => this.setState({ selparentdim: e.value })}
                        style={{ marginLeft: "15px", width: "30%" }}
                        filter={true}
                        placeholder="Category..."
                        scrollHeight="110px"
                      /> */}
                    </div>
                  </div>
                </div>
              </Panel>
              <Growl ref={(el) => (this.growl = el)} />
              <div className="row" style={{ float: "right" }}></div>
              {this.state.data === null ? null : this.state.loading_handle === true ? (
                <>
                  <div className="loading-spinner-container">
                    <ProgressSpinner></ProgressSpinner>
                  </div>
                </>
              ) : (
                <div style={{ marginTop: 20 }} className="clk-table-btn">
                  <DataTable
                    style={{ textAlign: "center" }}
                    key={key}
                    className="oranget"
                    value={display_tarining ? this.state.training_data : this.state.data}
                    selection={this.state.selectedemps}
                    onSelectionChange={(e) => this.setState({ selectedemps: e.value }, this.exportenable)}
                    sortField="colorid"
                    sortOrder={1}
                    header={header}
                    first={this.state.first}
                    onPage={(e) => this.setState({ first: e.first })}
                    globalFilter={this.state.globalFilter}
                    paginator={true}
                    rows={20}
                    rowsPerPageOptions={[10, 20, 50]}
                    responsive={true}
                    rowClassName={this.rowClassName}
                    resizableColumns={true}
                    emptyMessage={
                      "No Data"
                      // (data.length > 0 || this.state.training_data > 0) && load_flag == "False"
                      //   ? "Loading data"
                      //   : (data.length == 0 && this.state.training_data == 0) && load_flag == "True"
                      //   ? "Loading Data"
                      //   : "No data"
                    }
                  >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column
                      field="sno"
                      header="S.No"
                      style={{ width: "5em" }}
                      sortable={true}
                      // body={this.bgcolorTemplate}
                    />
                    <Column
                      field={display_tarining ? "training_id" : "category"}
                      header={display_tarining ? "Training code" : "Group"}
                      style={{ width: display_tarining ? "10em" : "15em" }}
                      sortable={true}
                      // body={this.bgcolorTemplate}
                    />
                    <Column
                      field={display_tarining ? "module_name" : "dim"}
                      header={display_tarining ? "Training topic name" : "Key success behaviours"}
                      style={{ width: display_tarining ? "20em" : "15em" }}
                      sortable={true}
                      body={this.actionTemplate}
                    />
                    <Column field={display_tarining ? "train_emp" : "lowscore"} header="Employee Count" sortable={true} />
                    <Column field={display_tarining ? "emp_per" : "lhc"} header="Employee %" sortable={true} />
                    <Column field={display_tarining ? "total_emp" : "totalemps"} header="Applicable Employees" sortable={true} />
                    {levels}
                  </DataTable>
                </div>
              )}
            </div>
          ) : (
            <div className="p-col-12">
              <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                <div
                  className="medialog"
                  style={{
                    fontSize: "20px",
                    fontWeight: "800px",
                    textAlign: "center",
                  }}
                >
                  Data Collection is in Progress
                </div>
              </div>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

export default Training;
