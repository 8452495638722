import axios from "axios";
import * as actionTypes from "./actionTypes";
import nav from "../../utils/Utils";
import api from "../../api";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const update_language = (language) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE,
    language: language,
  };
};

export const authSuccess = (token, user_details) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user_details,
  };
};

export const setMenu = (menu) => {
  return {
    type: actionTypes.AUTH_SIDEMENU,
    menu: menu,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  var keys = Object.keys(localStorage),
    i = keys.length;
  while (i--) {
    localStorage.removeItem(keys[i]);
  }
  localStorage.clear();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const setOrgSettings = (orgSettings) => {
  return {
    type: actionTypes.ORG_SETTINGS,
    orgSettings,
    error: null,
  };
};

export const setOrgSettingsFail = (err) => {
  return {
    type: actionTypes.ORG_SETTINGS_FAIL,
    error: err,
  };
};

export const setMarkReadHelp = (read) => {
  return {
    type: actionTypes.MARK_READ_HELP,
    read,
  };
};
export const setShowDetails = (first_name, last_name, email) => {
  return {
    type: actionTypes.SHOW_DETAILS,
    first_name,
    last_name,
    email,
  };
};

export const markPaid = () => {
  return {
    type: actionTypes.MARK_PAID,
  };
};

export const authLogin = (username, password) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        "/auth/login",
        {
          username: username,
          password: password,
        },
        axiosConfig
      )
      .then((res) => {
        const { token, user_details = {} } = res.data;
        const { sideMenu } = user_details;
        if (token) {
          api.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
          user_details["sideMenu"] = setMenuDetails(sideMenu);
          localStorage.setItem("user_details", JSON.stringify(user_details));
          dispatch(authSuccess(token, user_details));
        } else localStorage.removeItem("token");
        nav("/");
      })
      .catch((error) => {
        dispatch(authFail(error.response["non_field_errors"] || "Invalid credentials"));
      });
  };
};

export const keyLogin = (token, user_details) => {
  return (dispatch) => {
    const { sideMenu } = user_details;
    if (token) {
      api.defaults.headers.common["Authorization"] = "Token " + token;
      localStorage.setItem("token", token);
      user_details["sideMenu"] = setMenuDetails(sideMenu);
      localStorage.setItem("user_details", JSON.stringify(user_details));
      dispatch(authSuccess(token, user_details));
    } else localStorage.removeItem("token");
    nav("/");
  };
};

export const orgSettings = (company) => {
  let axiosConfig = {
    baseURL: process.env.REACT_APP_BASE_URL,
    target: process.env.REACT_APP_SERVER_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        "api/organisation/organisation-settings",
        {
          organisation: company,
        },
        axiosConfig
      )
      .then((res) => {
        const { organisationSettings = {}, success, err } = res.data;
        if (success) dispatch(setOrgSettings(organisationSettings));
        else dispatch(setOrgSettingsFail(err));
      })
      .catch((err) => {
        dispatch(setOrgSettings({}));
      });
  };
};

export const setMenuDetails = (sideMenu) => {
  const menu = sideMenu.map((s) => {
    const eachMenu = {
      label: s.name,
      link: s.link,
      icon: s.icon,
      cpcl_img: s.cpcl_img,
    };
    const childMenu = s.child.map((c) => {
      return {
        label: c.name,
        link: c.link,
        icon: c.icon,
        enableExport: c.enable_export,
        showEmpDetails: c.show_emp_details,
        cpcl_img: c.cpcl_img,
      };
    });
    if (childMenu.length > 0) eachMenu["items"] = childMenu;
    return eachMenu;
  });
  localStorage.setItem("menu", JSON.stringify(menu));
  return menu;
};

export const authLogout = () => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
    },
  };

  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/auth/logout", {}, axiosConfig)
      .then((res) => {
        const isCce = window.location.href.includes("/cce/");
        dispatch(logout());
        if (isCce) nav("/login/admin");
        else nav("/login");
      })
      .catch((err) => {
        dispatch(logout());
        nav("/login");
      });
  };
};

export const authSignup = (username, email, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/rest-auth/registration/", {
        username: username,
        email: email,
        password1: password1,
        password2: password2,
      })
      .then((res) => {
        const token = res.data.key;
        localStorage.setItem("token", token);
        dispatch(authSuccess(token));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const user_details = localStorage.getItem("user_details");
    if (token === undefined) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token, JSON.parse(user_details)));
      api.defaults.headers.common["Authorization"] = "Token " + token;
    }
  };
};

export const markReadHelp = () => {
  return (dispatch) => {
    api
      .post("api/me/mark-read-help")
      .then((res) => {
        dispatch(setMarkReadHelp(!res.data.success));
      })
      .catch((error) => console.log(error));
  };
};

export const isPaymentSuccess = () => {
  return (dispatch) => {
    api.get("/api/payments/fetch-payment-details").then((res) => {
      if (res.data.success) dispatch(markPaid());
    });
  };
};
