import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import api from "../../api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { excelexport } from "../../utils/Utils";
import _ from "lodash";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";

export class EmployeeManagement extends Component {
  state = {
    loading: true,
    visible: false,
    department: [],
    grade: [],
    location: [],
    section: [],
    gender: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      { label: "Others", value: "Others" },
    ],
    emps: [],
    sel_emp: "",
    sel_emp_details: [],
    user_emp_data: {},
    emp_data: {},
    emp_data_val: {},
    s_emp_data: {},
    s_emp_data_val: {},
    action: "",
    req_fields: [],
    save_dis: true,
    load: false,
    dialog: false,
    status: "",
  };

  componentDidMount() {
    if (
      !_.isEmpty(this.props.organisation) ||
      !this.props.user_details.isSuperUser
    )
      this.fetchData();
  }
  fetchData = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
            type: this.props.user_details.organisation["org_ver_type"],
          });
    }
    this.setState({ loading: true });
    // const { organisation } = this.props;
    api
      .post("api/cce/get_dcode", { organisation })
      .then((res) => {
        if (res.data.results) {
          const {
            location,
            department,
            section,
            grade,
            emps,
            emp_data,
            emp_data_val,
            mnghr,
            req_fields,
          } = res.data;
          this.setState({
            loading: false,
            location: location,
            department: department,
            section: section,
            grade: grade,
            emps: emps,
            emp_data: { ...emp_data },
            emp_data_val: { ...emp_data_val },
            s_emp_data: { ...emp_data },
            s_emp_data_val: { ...emp_data_val },
            managerEmployeeNumber: mnghr,
            hrEmployeeNumber: mnghr,
            req_fields: req_fields,
          });
        }
        this.setState({ loading: false });
      })

      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };
  fetchData2 = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
            type: this.props.user_details.organisation["org_ver_type"],
          });
    }
    this.setState({ loading: true });
    // const { organisation } = this.props;
    api
      .post("api/cce/get_dcode", { organisation })
      .then((res) => {
        if (res.data.results) {
          const {
            location,
            department,
            section,
            grade,
            emps,
            emp_data,
            emp_data_val,
            mnghr,
            req_fields,
          } = res.data;
          this.setState({
            loading: false,
            location: location,
            department: department,
            section: section,
            grade: grade,
            emps: emps,
            managerEmployeeNumber: mnghr,
            hrEmployeeNumber: mnghr,
            req_fields: req_fields,
          });
        }
        this.setState({ loading: false });
      })

      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }

  getdetails = (e) => {
    this.setState({ sel_emp: e.value });

    this.setState({
      sel_emp_details: this.state.emps.filter((m) => {
        return m.value === e.value;
      }),
    });
  };
  onHide = () => {
    this.setState({
      visible: false,
    });
  };

  text_template = (key, label, formlabel, disabled) => {
    console.log("--->", key, label, formlabel, disabled);
    return (
      <div style={{ margin: 9 }}>
        <div className={formlabel ? "formlabel" : ""}>{label}</div>
        <InputText
          disabled={disabled}
          className={"input-text"}
          value={this.state.emp_data[key] || ""}
          onChange={(e) => this.inputchange(e, key)}
          placeholder={label}
        />
        {!this.state.emp_data_val[key + "_val"] ? null : (
          <div className="formlabel" style={{ color: "red", fontSize: 10 }}>
            not valid
          </div>
        )}
      </div>
    );
  };

  calender_template = (key, label, formlabel) => {
    return (
      <div style={{ margin: 9 }}>
        <div className={formlabel ? "formlabel" : ""}>{label}</div>
        <InputMask
          className={"input-text"}
          mask="99-99-9999"
          placeholder="dd-mm-yyyy"
          slotChar="dd-mm-yyyy"
          value={this.state.emp_data[key] || ""}
          onChange={(e) => this.inputchange(e, key)}
        ></InputMask>
        {!this.state.emp_data_val[key + "_val"] ? null : (
          <div className="formlabel" style={{ color: "red", fontSize: 10 }}>
            not valid
          </div>
        )}
      </div>
    );
  };

  drop_template = (key, label, formlabel, editable) => {
    return (
      <div style={{ margin: 9 }}>
        <div className={formlabel ? "formlabel" : ""}>{label}</div>
        <Dropdown
          value={this.state.emp_data[key] || { key }}
          options={this.state[key]}
          onChange={(e) => this.inputchange(e, key)}
          editable={editable}
          filter={true}
          filterPlaceholder="Search..."
          filterBy="label"
        />
        {!this.state.emp_data_val[key + "_val"] ? null : (
          <div className="formlabel" style={{ color: "red", fontSize: 10 }}>
            not valid
          </div>
        )}
      </div>
    );
  };

  drophrmng_template = (key, label, formlabel, editable) => {
    return (
      <div style={{ margin: 9 }}>
        <div className={formlabel ? "formlabel" : ""}>{label}</div>
        <Dropdown
          value={this.state.emp_data[key] || ""}
          options={this.state[key]}
          onChange={(e) => this.hrmnginputchange(e, key)}
          editable={editable}
          filter={true}
          filterPlaceholder="Search..."
          filterBy="label"
        />
        {!this.state.emp_data_val[key + "_val"] ? null : (
          <div className="formlabel" style={{ color: "red", fontSize: 10 }}>
            not valid
          </div>
        )}
      </div>
    );
  };

  inputchange = (e, key) => {
    let emp_data = this.state.emp_data;
    let emp_data_val = this.state.emp_data_val;
    emp_data[key] = e.target.value;
    emp_data_val[key + "_val"] = false;

    this.setState({ emp_data, emp_data_val, save_dis: false });
  };

  hrmnginputchange = (e, key) => {
    let emp_data = this.state.emp_data;
    let emp_data_val = this.state.emp_data_val;
    let data = this.state[key];
    let val = data.filter((v) => (v.value = e.target.value));
    if (val.length > 0) {
      emp_data[key] = val[0].value;
      emp_data_val[key + "_val"] = false;

      this.setState({ emp_data, emp_data_val, save_dis: false });
    }
  };

  update = () => {
    if (this.state.sel_emp.toString().length > 0) {
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
              type: this.props.user_details.org_ver_type,
            });
      }
      const data = { emp: this.state.sel_emp, org: organisation };
      this.setState({ load: true });
      api
        .post("api/cce/get_empupdate", data)
        .then((res) => {
          if (res.data.results) {
            const { user_emp_data, emp_data, emp_data_val, status } = res.data;
            this.setState({
              loading: false,
              user_emp_data: user_emp_data,
              emp_data: emp_data,
              emp_data_val: emp_data_val,
              visible: true,
              action: "U",
              load: false,
              status: status,
            });
          }
          this.setState({ loading: false });
        })

        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      this.growl.show({
        sticky: true,
        severity: "error",
        summary: "Select user",
      });
    }
  };

  create = () => {
    this.setState({
      loading: false,
      emp_data: this.state.s_emp_data,
      emp_data_val: this.state.s_emp_data_val,
      visible: true,
      action: "A",
      sel_emp: "",
      sel_emp_details: [],
    });
  };
  errorfind = () => {
    let errors = [];
    let emp_data_val = { ...this.state.emp_data_val };
    this.state.req_fields.map((req) => {
      if (!this.state.emp_data[req].length > 0) {
        errors.push(req);
        emp_data_val[req + "_val"] = true;
      }
    });
    if (this.state.action === "A") {
      if (!this.state.emp_data.userId.length > 0) {
        errors.push("userId");
        emp_data_val["userId_val"] = true;
      }
      if (!this.state.emp_data.password.length > 0) {
        errors.push("password");
        emp_data_val["password_val"] = true;
      }
    }
    this.setState({ emp_data_val });
    return errors;
  };
  save = () => {
    let errors = this.errorfind();
    if (errors.length === 0) {
      this.setState({ load: true });
      let emp_data = [this.state.emp_data];
      emp_data[0]["action"] = this.state.action;
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
              type: this.props.user_details.org_ver_type,
            });
      }
      const data = { data: emp_data, org: organisation["name"] };
      api.post("api/organisation/updateEmployee", data).then((res) => {
        this.setState({ load: false });
        if (!res.data.errors) {
          this.setState({
            action: "U",
          });
          this.growl.show({
            sticky: true,
            severity: "success",
            summary: res.data.result,
          });
          this.fetchData2();
        } else {
          this.growl.show({
            sticky: true,
            severity: "error",
            summary: res.data.result,
          });
        }
      });
    }
  };

  pre_del = () => {
    if (this.state.sel_emp.toString().length > 0) {
      this.setState({ dialog: true });
    } else {
      this.growl.show({
        sticky: true,
        severity: "error",
        summary: "Select user",
      });
    }
  };
  delete = () => {
    if (this.state.sel_emp.toString().length > 0) {
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
              type: this.props.user_details.organisation["org_ver_type"],
            });
      }
      const data = {
        emp: this.state.sel_emp,
        org: organisation,
        status: this.state.status,
      };
      const stat = this.state.status;
      api
        .post("api/cce/emp_delete", data)
        .then((res) => {
          if (res.data.results) {
            this.setState({
              loading: false,
              visible: false,
              action: "",
              sel_emp: "",
              sel_emp_details: [],
              load: false,
              dialog: false,
              status: "",
            });
            this.growl.show({
              sticky: true,
              severity: "success",
              summary:
                stat === "Deactivated"
                  ? "Employee Restored"
                  : "Deactivated successfully",
            });
            this.fetchData();
          } else {
            this.growl.show({
              sticky: true,
              severity: "error",
              summary: "Something went wrong",
            });
          }
        })

        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };
  render() {
    const { visible, loading, load } = this.state;
    // const name_header = this.props.user_details.organisation["org_ver_type"] === 'RV' ? 'Candidate ' : 'Employee '

    return (
      <div className={"layout-main"}>
        <Dialog
          className=""
          appendTo={document.body}
          header={"Confirmation"}
          visible={this.state.dialog}
          onHide={() => this.setState({ dialog: false })}
          style={{ width: 500, overflow: "scroll" }}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            selected user -{" "}
            {this.state.sel_emp_details.length > 0
              ? this.state.sel_emp_details[0].label
              : null}
            <br />
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                iconPos="right"
                style={{ marginLeft: "1%" }}
                label={
                  this.state.status === "Deactivated"
                    ? "Restore User"
                    : "Deactivate User"
                }
                onClick={this.delete}
              />
            </div>
          </div>
        </Dialog>

        <div className="p-grid">
          <div className="p-col-12">
            <Growl ref={(el) => (this.growl = el)} />
            {loading ? (
              <>
                <div className="loading-spinner-container">
                  <ProgressSpinner></ProgressSpinner>
                </div>
              </>
            ) : (
              <div>
                <Panel header={this.props.menuProps.label}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: "row",
                      }}
                    >
                      <div>
                        <Dropdown
                          className="readynextdd"
                          style={{ width: "22em", marginLeft: "1em" }}
                          //size={70}
                          value={this.state.sel_emp}
                          options={this.state.emps}
                          placeholder="Click here..."
                          onChange={(e) => this.getdetails(e)}
                          filter={true}
                          filterPlaceholder="Search user"
                          filterBy="label"
                        />
                        <Button
                          label="Show"
                          onClick={this.update}
                          style={{ marginLeft: 18 }}
                        />
                      </div>
                      <div style={{ float: "right" }}>
                        {this.state.action === "A" ? null : (
                          <Button
                            label={"Add New User"}
                            onClick={this.create}
                            style={{ marginLeft: 18 }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Panel>
                {visible ? (
                  load ? (
                    <>
                      <div className="loading-spinner-container">
                        <ProgressSpinner></ProgressSpinner>
                      </div>
                    </>
                  ) : (
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: 40,
                          }}
                        >
                          <div>
                            {this.state.action === "A"
                              ? "Add User"
                              : "Update User Details"}
                          </div>
                          <div>
                            {this.state.action === "A" ? null : (
                              <Button
                                label={
                                  this.state.status === "Deactivated"
                                    ? "Restore User"
                                    : "Deactivate User"
                                }
                                onClick={this.pre_del}
                                style={{ marginLeft: 18 }}
                              />
                            )}
                          </div>
                        </div>
                      }
                    >
                      <div className="p-grid">
                        {/* {this.state.user_emp_data.map((field) => (
                          <div
                              key={field}
                              className="p-col-4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                          console.log("aaa")
                        )}
                            */}
                      </div>

                      <div>
                        <div className="row">
                          {this.state.action === "A" ? (
                            <div className="col-md-4 col-sm-6">
                              {this.text_template(
                                "userId",
                                "User Id",
                                true,
                                false
                              )}
                            </div>
                          ) : (
                            <div className="col-md-4 col-sm-6">
                              {this.text_template(
                                "userId",
                                "User Id",
                                true,
                                true
                              )}
                            </div>
                          )}
                          <div className="col-md-4 col-sm-6">
                            {this.text_template("email", "Email", true, false)}
                          </div>

                          <div className="col-md-4 col-sm-6">
                            {this.text_template(
                              "employeeNumber",
                              "Roll No.",
                              true,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.text_template("title", "Title", false, false)}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.text_template(
                              "firstName",
                              "First Name",
                              true,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.text_template(
                              "lastName",
                              "Last Name",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.text_template(
                              "phone",
                              "Mobile",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.drop_template(
                              "grade",
                              "Grade / Level",
                              true,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.drop_template(
                              "gender",
                              "Gender",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.calender_template(
                              "dateOfBirth",
                              "Date of birth",
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.drop_template(
                              "location",
                              "Location",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.drop_template(
                              "department",
                              "Department",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.drop_template(
                              "section",
                              "Section",
                              false,
                              false
                            )}
                          </div>
                          <div className="col-md-4 col-sm-6">
                            {this.text_template(
                              "designation",
                              "Designation",
                              false,
                              false
                            )}
                          </div>
                          {this.props.user_details.organisation[
                            "org_ver_type"
                          ] === "RV" ? (
                            <div className="col-md-4 col-sm-6">
                              {this.text_template(
                                "hiringManagerName",
                                "Hiring Manager",
                                false,
                                false
                              )}
                            </div>
                          ) : (
                            <>
                              <div className="col-md-4 col-sm-6">
                                {this.drop_template(
                                  "managerEmployeeNumber",
                                  "Manager Roll No.",
                                  false,
                                  false
                                )}
                              </div>
                              <div className="col-md-4 col-sm-6">
                                {this.drop_template(
                                  "hrEmployeeNumber",
                                  "Hr Roll No.",
                                  false,
                                  false
                                )}
                              </div>
                              <div className="col-md-4 col-sm-6">
                                {this.calender_template(
                                  "dateOfJoining",
                                  "Date of Joining",
                                  false
                                )}
                              </div>
                              <div className="col-md-4 col-sm-6">
                                {this.calender_template(
                                  "dateOfLeaving",
                                  "Date of Leaving",
                                  false
                                )}
                              </div>
                            </>
                          )}
                          {this.state.action === "A" ? (
                            <div className="col-md-4 col-sm-6">
                              {this.text_template(
                                "password",
                                "Password",
                                true,
                                false
                              )}
                            </div>
                          ) : null}
                          {this.state.action === "A" ? null : (
                            <div className="col-md-4 col-sm-6">
                              {this.text_template(
                                "created_on",
                                "Created On",
                                false,
                                true
                              )}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Button
                              style={{ float: "right", marginRight: 50 }}
                              onClick={this.save}
                              label="Save"
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                  )
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(EmployeeManagement);
