import React, { Component } from "react";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import ScoreDistribution from "./DimInfLayout";

class EngagementIndex extends Component {
  state = {
    data: [],
    role: [],
    result: [],
    engresult: [],
    seldep: [],
    selloc: [],
    dataprogress: false,
    load: true,
    location: [],
    departments: [],
    empcount: 0,
    no_data: false,
    dep: [],
    loc: [],
    secwin: false,
    load2: false,
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    distdata: [],
    rowData: {},
    totalempcount: "",
    oempcount: "",
    openDialog: false,
  };

  componentDidMount() {
    const data = { user: this.props.user };
    api
      .post("/api/assessments/engagementdash", data)
      .then((res) => {
        this.setState({ load: false });
        let engresultGroupNames = [];
        let engresult = res.data.engresult;
        engresult.forEach((element) => {
          if (!engresultGroupNames.includes(element.groupName))
            engresultGroupNames.push(element.groupName);
        });
        let resultGroupNames = [];
        let result = res.data.result;
        result.forEach((element) => {
          if (!resultGroupNames.includes(element.groupName))
            resultGroupNames.push(element.groupName);
        });
        if (res.data.results == true) {
          this.setState({
            data: res.data,
            role: res.data.role,
            result,
            resultGroupNames,
            engresult,
            engresultGroupNames,
            dataprogress: true,
            location: res.data.locations,
            departments: res.data.departments,
            empcount: res.data.empcount,
          });
        } else {
          console.log(res.data);
          this.setState({ no_data: true, empcount: res.data.empcount });
        }
      })
      .catch((err) => console.log(err));
  }
  levelTemplate = (rowData, target) => {
    return rowData[target] ? <span>{rowData[target]}</span> : <span>-</span>;
  };

  reset = () => {
    this.setState({ loc: [], dep: [], secwin: false });
    const data = { user: this.props.user };
    api
      .post("/api/assessments/engagementdash", data)
      .then((res) => {
        this.setState({ load: false });
        let engresultGroupNames = [];
        let engresult = res.data.engresult;
        engresult.forEach((element) => {
          if (!engresultGroupNames.includes(element.groupName))
            engresultGroupNames.push(element.groupName);
        });
        if (res.data.results) {
          this.setState({
            data: res.data,
            role: res.data.role,
            result: res.data.result,
            engresult,
            engresultGroupNames,
            dataprogress: true,
            location: res.data.locations,
            departments: res.data.departments,
            empcount: res.data.empcount,
            seldep: [],
            selloc: [],
            no_data: false,
          });
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  levelTemplate = (rowData, target) => {
    return rowData[target] ? <span>{rowData[target]}</span> : <span>-</span>;
  };

  actionTemplate = (rowData, borderColor) => {
    return (
      <Button
        className="clickbtn p-button-raised p-button-secondary p-button-rounded"
        label={rowData["dimname"]}
        onClick={() =>
          this.setState({ openDialog: true, borderColor }, () =>
            this.post_data(rowData)
          )
        }
      />
    );
  };

  post_data = (rowData) => {
    const { dep, loc } = this.state;
    this.setState({ load2: true, secwin: true, rowData: rowData });
    const data = {
      dim_id: rowData["dim_id"],
      dep: dep,
      loc: loc,
      eng: rowData["eng"],
      user: this.props.user,
    };
    api
      .post("/api/assessments/engagementdash_post", data)
      .then((res) => {
        const { demograph } = res.data;
        this.setState({
          load2: false,
          distdata: demograph,
          oempcount: demograph.overallCount,
          totalempcount: demograph.totalempcount,
          leveldata: demograph.levelWise.map((grade) => ({
            name: grade.role,
            value: grade.percentage,
          })),
          deptdata: demograph.deptWise.map((grade) => ({
            name: grade.name,
            value: grade.percentage,
          })),
          agedata: demograph.ageWise.map((grade) => ({
            name: grade.range,
            value: grade.percentage,
          })),
          workexpdata: demograph.workexpWise.map((grade) => ({
            name: grade.range,
            value: grade.percentage,
          })),
          empc: demograph.overall,
        });
      })
      .catch((err) => console.log(err));
  };

  popup = (rowData) => {
    this.setState({
      visible: true,
    });
  };
  onHide = () => {
    this.setState({
      visible: false,
    });
  };
  filters = () => {
    if (this.state.seldep.length === 0 && this.state.selloc.length === 0) {
      {
        this.growl.show({
          severity: "error",
          detail: "No Filter Selected",
        });
      }
    } else {
      this.setState({
        load: true,
        loc: this.state.selloc,
        dep: this.state.seldep,
        secwin: false,
      });
      api
        .post("/api/assessments/engagementfilters", {
          selectedloc: this.state.selloc,
          selecteddep: this.state.seldep,
          user: this.props.user,
        })
        .then((res) => {
          this.setState({ load: false });
          console.log("a", res.data);
          if (res.data.results == true) {
            this.setState({
              data: res.data,
              role: res.data.role,
              result: res.data.result,
              engresult: res.data.engresult,
              dataprogress: true,
              location: res.data.locations,
              departments: res.data.departments,
              empcount: res.data.empcount,
              no_data: false,
            });
            console.log(res.data);
          } else {
            this.setState({ no_data: true, empcount: res.data.empcount });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  render() {
    const {
      location,
      departments,
      engresultGroupNames,
      resultGroupNames,
      borderColor,
    } = this.state;
    var header = (
      <div className="col-md-12">
        <div className="row" style={{ height: "40px" }}>
          <label
            className="selected-dimension-heading"
            style={{ float: "left" }}
          >
            {" "}
            Disengagement Factors
          </label>
          {/* <div className="col-md-6">
            <InputText
              type="search"
              style={{ marginRight: "50px" }}
              onInput={e => this.setState({ globalFilter: e.target.value })}
              placeholder="Search..."
              size="50"
            />
          </div> */}
        </div>
      </div>
    );
    var engheader = (
      <div className="col-md-12">
        <div className="row" style={{ height: "40px" }}>
          <label
            className="selected-dimension-heading"
            style={{ float: "left" }}
          >
            {" "}
            Engagement Factors
          </label>
          {/* <div className="col-md-6">
            <InputText
              type="search"
              style={{ marginRight: "50px" }}
              onInput={e => this.setState({ eglobalFilter: e.target.value })}
              placeholder="Search..."
              size="50"
            />
          </div> */}
        </div>
      </div>
    );
    const columnsdt = this.state.role.map((level, index) => (
      <Column
        key={index}
        style={{ backgroundColor: "white" }}
        body={(rowData) => this.levelTemplate(rowData, level)}
        header={level}
      />
    ));
    return (
      <div className="layout-main">
        <Dialog
          style={{ width: "40vw" }}
          //className="medialog"
          appendTo={document.body}
          header={this.state.rowData["dimname"]}
          visible={this.state.visible}
          onHide={this.onHide}
        >
          <span style={{ lineHeight: "2" }}>
            {this.state.rowData["inference"]}
          </span>
        </Dialog>
        <Growl
          ref={(el) => (this.growl = el)}
          style={{ marginTop: 20 }}
          position="bottomright"
        />
        <Panel
          header={
            <div style={{ marginBottom: 15 }}>
              <span style={{ fontSize: "18px" }}>
                {this.props.menuProps.label}
              </span>
              <span style={{ float: "right" }}>
                <div className="total-employees-container">
                  N - {this.state.empcount}
                </div>
              </span>
            </div>
          }
        >
          <>
            <Panel header="Filters">
              <div className="box-body box-body-cust-250">
                <div className="row">
                  <div className="col-md-7">
                    <MultiSelect
                      value={this.state.selloc}
                      scrollHeight={"250px"}
                      options={location}
                      onChange={(e) => this.setState({ selloc: e.value })}
                      filter={true}
                      placeholder="Location..."
                      style={{ marginLeft: "15px", width: "40%" }}
                      scrollHeight="200px"
                    />
                    <MultiSelect
                      value={this.state.seldep}
                      options={departments}
                      onChange={(e) => this.setState({ seldep: e.value })}
                      style={{ marginLeft: "15px", width: "40%" }}
                      filter={true}
                      placeholder="Department..."
                      scrollHeight="200px"
                    />
                    {/* <MultiSelect
                  value={this.state.selparentdim}
                  options={parentdimensions}
                  onChange={e => this.setState({ selparentdim: e.value })}
                  style={{ marginLeft: "15px", width: "30%" }}
                  filter={true}
                  placeholder="Factors..."
                  scrollHeight="110px"
                /> */}
                  </div>
                  <div className="col-md-5">
                    <Button
                      type="button"
                      label="Apply"
                      onClick={this.filters}
                      style={{ marginRight: "15px" }}
                      // disabled = {false}
                    />
                    <Button
                      type="button"
                      label="Reset"
                      onClick={this.reset}
                      style={{ marginRight: "15px" }}
                    />
                  </div>
                </div>
              </div>
            </Panel>

            {this.state.load ? (
              <div className="loading-spinner-container">
                <ProgressSpinner></ProgressSpinner>
              </div>
            ) : this.state.dataprogress ? (
              this.state.no_data ? (
                <div className="p-col-12" style={{ textAlign: "center" }}>
                  No data
                </div>
              ) : (
                <div className="engagement-index-container">
                  <Panel className="border-red" header={header}>
                    <div className="box-body box-body-cust-250">
                      {resultGroupNames.map((resGroup) => (
                        <DataTable
                          className="oranget-engIndex dis-engagement-tables engtab metable-me-dashboard"
                          sortField="total"
                          sortOrder={-1}
                          globalFilter={this.state.globalFilter}
                          responsive={true}
                          style={{ textAlign: "center", marginTop: "10px" }}
                          resizableColumns={true}
                          value={this.state.result.filter(
                            (res) => res.groupName === resGroup
                          )}
                        >
                          <Column
                            style={{ width: 250, backgroundColor: "white" }}
                            body={(rowData) =>
                              this.actionTemplate(rowData, "red")
                            }
                            header={resGroup}
                          />
                          <Column
                            style={{ backgroundColor: "white" }}
                            field="total"
                            header="No. of employees"
                          />
                          <Column
                            style={{ backgroundColor: "white" }}
                            body={(rowData) => Math.round(rowData.lpc) + "%"}
                            header="Employee percentage"
                          />
                          {columnsdt}
                        </DataTable>
                      ))}
                    </div>
                  </Panel>
                  <Panel header={engheader}>
                    <div className="box-body box-body-cust-250">
                      {engresultGroupNames.map((engGroup) => (
                        <DataTable
                          className="oranget-engIndex engagement-tables enggtab metable-me-dashboard"
                          sortField="total"
                          sortOrder={-1}
                          globalFilter={this.state.eglobalFilter}
                          responsive={true}
                          style={{ textAlign: "center", marginTop: "10px" }}
                          resizableColumns={true}
                          value={this.state.engresult.filter(
                            (engFil) => engFil.groupName === engGroup
                          )}
                        >
                          <Column
                            style={{ width: 250, backgroundColor: "white" }}
                            body={(rowData) =>
                              this.actionTemplate(rowData, "green")
                            }
                            header={engGroup}
                          />
                          <Column
                            style={{ backgroundColor: "white" }}
                            field="total"
                            header="No. of employees"
                          />
                          <Column
                            style={{ backgroundColor: "white" }}
                            body={(rowData) => Math.round(rowData.lpc) + "%"}
                            header="Employee percentage"
                          />
                          {columnsdt}
                        </DataTable>
                      ))}
                    </div>
                  </Panel>
                  <Dialog
                    header={
                      <span>
                        <span>{this.state.rowData["dimname"]} </span>
                        <span>
                          <i
                            onClick={() => this.popup()}
                            style={{
                              cursor: "pointer",
                              color: "#00a3b4",
                              fontSize: "14px",
                            }}
                            className="far fa-question-circle"
                          ></i>
                        </span>
                      </span>
                    }
                    className={
                      "dashboard-dialog " + borderColor + "-border-dialog"
                    }
                    appendTo={document.body}
                    visible={this.state.openDialog}
                    onHide={() => this.setState({ openDialog: false })}
                  >
                    {this.state.secwin ? (
                      this.state.load2 ? (
                        <div className="loading-spinner-container">
                          <ProgressSpinner></ProgressSpinner>
                        </div>
                      ) : (
                        <ScoreDistribution
                          nCount={this.state.oempcount}
                          menuProps={this.props.menuProps}
                          data={this.state.distdata}
                          potential={[]}
                          leveldata={this.state.leveldata}
                          deptdata={this.state.deptdata}
                          agedata={this.state.agedata}
                          workexpdata={this.state.workexpdata}
                          height={"380px"}
                          dheight={"440px"}
                          total={true}
                        />
                      )
                    ) : null}
                  </Dialog>
                </div>
              )
            ) : this.state.no_data ? (
              <div className="p-col-12" style={{ textAlign: "center" }}>
                No data
              </div>
            ) : (
              <div className="p-col-12">
                <div
                  style={{ color: "#6a6b6cba" }}
                  className=" box-body box-body-cust-250"
                >
                  <div
                    className="medialog"
                    style={{
                      fontSize: "20px",
                      fontWeight: "800px",
                      textAlign: "center",
                    }}
                  >
                    Data Collection is in Progress
                  </div>
                </div>
              </div>
            )}
          </>
        </Panel>
      </div>
    );
  }
}

export default EngagementIndex;
