import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import api from "../../api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { excelexport } from "../../utils/Utils";
import _ from "lodash";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import { Paginator } from "primereact/paginator";

export class PasswordManagement extends Component {
  state = {
    emps: [],
    selectedemps: [],
    empStat: [],
    cpaswd: "",
    paswd: "",
    showing: false,
    hidden: true,
    loading: true,
    rresults: [],
    pwdresetbtn: false,
    rawresultbtn: false,
    rawdatabtn: false,
    resultcalbtn: false,
    newresultcalbtn: false,
    hrdashbtn: false,
    medashbtn: false,
    empcount: null,
    auto_answer: false,
    del_answer: false,
    auto_ans_dialog: false,
    del_state: false,
    auto_state: false,
    awakecalbtn: false,
    first: 0,
    rows: 10,
  };
  componentDidMount() {
    if (
      !_.isEmpty(this.props.organisation) ||
      !this.props.user_details.isSuperUser
    )
      this.fetchData();
  }
  fetchData = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }

    this.setState({ loading: true });
    // const { organisation } = this.props;
    api
      .post("api/cce/pwdreset", { organisation })
      .then((res) => {
        this.setState({
          loading: false,
          emps: res.data.results,
          first: 0,
          rows: 10,
        });
        let empStat = [...res.data.results].filter(
          (emp) => emp.status === "Active"
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  onClick = () => {
    this.setState({ visible: true });
  };
  reset = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    // const isnum = /^\d+$/;

    if (this.state.cpaswd === this.state.paswd) {
      // if (this.state.paswd.toString().length < 8) {
      //   this.growl.show({
      //     severity: "error",
      //     summary:
      //       "This password is too short. It must contain at least 8 characters",
      //   });
      // } else if (isnum.test(this.state.paswd)) {
      //   this.growl.show({
      //     severity: "error",
      //     summary: "This password is entirely numeric",
      //   });
      // } else {
      this.setState({ pwdresetbtn: true });
      api
        .post("api/cce/pwd_reset_sel", {
          pwd: this.state.paswd,
          checkedRows: this.state.selectedemps,
          organisation: organisation,
        })
        .then((res) => {
          if (res.data.results === true) {
            this.setState(
              {
                selectedemps: [],
                cpaswd: "",
                paswd: "",
                resp: res.data.results,
                pwdresetbtn: false,
              },
              () =>
                this.growl.show({
                  severity: "success",
                  summary: "Password reset successful",
                })
            );
          }
        });

      this.setState({ visible: false });
      // }
    } else {
      this.setState({ showing: true });
    }
  };

  onHide = () => {
    this.setState({
      visible: false,
      cpaswd: "",
      paswd: "",
      showing: false,
      hidden: true,
    });
  };
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  getrawresult = () => {
    this.setState({ rawresultbtn: true });
    const { organisation } = this.props;
    api
      .post("api/cce/getrawresult", {
        organisation: organisation,
        checkedRows: this.state.selectedemps,
      })
      .then((res) => {
        this.setState({ rresults: res.data.results, rawresultbtn: false });
        this.exportFile();
      });
  };

  getrawdata = () => {
    this.setState({ rawdatabtn: true });
    const { organisation } = this.props;
    api
      .post("api/cce/getrawdata", {
        organisation: organisation,
        checkedRows: this.state.selectedemps,
      })
      .then((res) => {
        this.setState({ rawdatabtn: false });
        if (res.data.results) {
          this.filedownload(res.data.data);
        }
      })
      .catch((err) => {
        this.setState({ rawdatabtn: false });
      });
  };

  filedownload = (url) => {
    // console.log(url);
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getitems = () => {
    this.setState({ rawresultbtn: true });
    api.post("api/cce/getitems").then((res) => {
      this.setState({ rresults: res.data.results, rawresultbtn: false });
      // this.exportFile();
    });
  };

  genhrdashboard = () => {
    const { organisation } = this.props;
    console.log(organisation);
    this.setState({ hrdashbtn: true });
    api.post("api/cce/loaddetails", organisation).then((res) => {
      this.setState({ hrdashbtn: false });
      console.log(res.data);
      if (res.data.results === true) {
        this.growl.show({
          severity: "info",
          summary: "Message",
          detail: "calculated successfully",
          life: 10000,
        });
      } else {
        this.growl.show({
          severity: "info",
          summary: "info Message",
          detail: "calculation failed",
          life: 10000,
        });
      }
    });
  };

  genmedashboard = () => {
    const { organisation } = this.props;
    console.log(organisation);
    this.setState({ medashbtn: true });
    api.post("api/cce/medashboard", organisation).then((res) => {
      this.setState({ medashbtn: false });
      console.log(res.data);
      if (res.data.results === true) {
        this.growl.show({
          severity: "info",
          summary: "Message",
          // detail: "calculated successfully",
          detail: res.data.msg,
          life: 10000,
        });
      } else {
        this.growl.show({
          severity: "warning",
          summary: "Warning Message",
          // detail: "calculation failed",
          detail: res.data.msg,
          life: 10000,
        });
      }
    });
  };

  empcalculate = () => {
    this.setState({ tablekey: this.state.tablekey + 1, newresultcalbtn: true });
    const token = localStorage.getItem("token");
    const data = { count: this.state.empcount, org: this.props.organisation };
    api.post("api/cce/result_gen_empcount", data).then((res) => {
      this.setState({ newresultcalbtn: false });
      console.log("result_gen_empcount:", res);
      if (res.data.results["status"] === true) {
        this.growl.show(
          {
            severity: "info",
            summary: "Message",
            detail:
              "Result calculated successfully for " +
              this.state.empcount +
              " employees",
            life: 10000,
          },
          this.setState({ empcount: "" })
        );
      } else {
        this.setState({ empcount: "" });
        this.growl.show({
          severity: "info",
          summary: "Info Message",
          detail: res.data.exception || res.data.status,
        });
      }
    });
  };

  calculate = () => {
    this.setState({ tablekey: this.state.tablekey + 1, resultcalbtn: true });
    const token = localStorage.getItem("token");
    const data = {
      emps: this.state.selectedemps,
      org: this.props.organisation,
    };
    api.post("api/cce/result_gen", data).then((res) => {
      this.setState({ resultcalbtn: false });
      console.log(res.data.exception);
      if (res.data.results["status"] === true) {
        this.growl.show({
          severity: "info",
          summary: "Message",
          detail: "Result calculated successfully",
          life: 10000,
        });
      } else {
        this.growl.show({
          severity: "info",
          summary: "Info Message",
          detail: res.data.exception || res.data.status,
          life: 10000,
        });
      }
    });
  };

  pre_auto = () => {
    if (this.state.selectedemps.length === 1) {
      this.setState({ auto_ans_dialog: true, auto_state: true });
    } else {
      this.growl.show({
        severity: "info",
        summary: "Info Message",
        detail: "Select one user",
      });
    }
  };

  atmascore = () => {
    if (this.state.selectedemps.length === 1) {
      this.setState({ tablekey: this.state.tablekey + 1, awakecalbtn: true });
      const token = localStorage.getItem("token");
      const data = {
        emps: this.state.selectedemps,
        org: this.props.organisation,
      };
      api.post("api/cce/atmascores_cce", data).then((res) => {
        this.setState({ awakecalbtn: false });
        if (res.data.results === true) {
          this.growl.show({
            severity: "info",
            summary: "Message",
            detail: res.data.results,
            life: 10000,
          });
        } else {
          const list = res.data.exception.map((m) => m + " ");
          this.growl.show({
            severity: "info",
            summary: "Info Message",
            detail: "Failed at " + list,
            life: 10000,
          });
        }
      });
    } else {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Select one user",
      });
    }
  };

  pre_del = () => {
    if (this.state.selectedemps.length === 1) {
      this.setState({ auto_ans_dialog: true, del_state: true });
    } else {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Select one user",
      });
    }
  };

  auto_answer = () => {
    this.setState({ tablekey: this.state.tablekey + 1, auto_answer: true });

    const data = {
      emps: this.state.selectedemps,
      org: this.props.organisation,
    };
    api.post("api/cce/auto_answer", data).then((res) => {
      this.setState({
        auto_answer: false,
        auto_ans_dialog: false,
        auto_state: false,
      });
      if (res.data.results) {
        this.growl.show({
          severity: "Info",
          summary: "Message",
          detail: "Success:" + res.data.status,
          life: 10000,
        });
      } else {
        this.growl.show({
          severity: "info",
          summary: "Info Message",
          detail: "Failed:" + res.data.status,
          life: 10000,
        });
      }
    });
  };
  del_answer = () => {
    this.setState({ tablekey: this.state.tablekey + 1, del_answer: true });

    const data = {
      emps: this.state.selectedemps,
      org: this.props.organisation,
    };
    api.post("api/cce/del_answer", data).then((res) => {
      this.setState({
        del_answer: false,
        auto_ans_dialog: false,
        del_state: false,
      });
      if (res.data.results) {
        this.growl.show({
          severity: "info",
          summary: "Message",
          detail: res.data.status,
          life: 10000,
        });
      } else {
        this.growl.show({
          severity: "info",
          summary: "Fail Message",
          detail: "Failed:" + res.data.status,
          life: 10000,
        });
      }
    });
  };

  exportFile = () => {
    var fname = "Rawresults";
    var expfname = "Rawresults.xls";
    var headers = [
      "Roll No.",
      "Question_ID",
      "Version",
      "Updated",
      "Tool",
      "Score",
      "Question",
      "Answer",
      "Answer_ID",
    ];
    /* convert from workbook to array of arrays */
    var data = this.state.rresults.map((emp) => [
      emp.emp_no,
      emp.q_ID,
      emp.Version,
      emp.Updated,
      emp.Tool,
      emp.Score,
      emp.Question,
      emp.Answer,
      emp.A_ID,
    ]);
    excelexport({ fname, expfname, headers, data });
    this.setState({
      selectedemps: [],
    });
  };

  onHide_D = () => {
    this.setState({
      auto_ans_dialog: false,
      auto_state: false,
      del_state: false,
      auto_answer: false,
      del_answer: false,
    });
  };

  activeDelete = (value) => {
    const { selectedemps, empStat } = this.state;
    if (selectedemps.length > 0) {
      let emps = [];
      selectedemps.map((se) => {
        emps.push(se.empIdNo);
      });
      api
        .post("/api/cce/activeDelete", {
          emps: emps,
          value: value,
        })
        .then((res) => {
          if (res.data.result) {
            empStat.map((emp) => {
              if (emps.includes(emp.empIdNo)) {
                emp.status = value ? "Deactivated" : "Active";
              }
            });
            this.setState({ empStat });
          }
        })
        .catch(() => {
          this.growl.show({
            severity: "error",
            detail: "Something went wrong",
            life: 10000,
          });
        });
    } else {
      this.growl.show({
        severity: "info",
        detail: "Select at least one user",
        life: 10000,
      });
    }
  };

  deleteUsers = () => {
    const { selectedemps, emps } = this.state;
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }

    const data = { org: organisation, checkedRows: selectedemps };
    api
      .post("api/cce/delete_users", data)
      .then((res) => {
        if (res.data.results) {
          this.growl.show({
            severity: "success",
            summary: res.data.msg,
          });
          let selArray = [];
          selectedemps.map((selVal) => selArray.push(selVal.EmpId));
          let empslist = emps.filter((val) => !selArray.includes(val.EmpId));
          this.setState({ emps: empslist, selectedemps: [] });
        } else {
          this.growl.show({
            severity: "error",
            summary: res.data.msg,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  render() {
    const {
      visible,
      hidden,
      cpaswd,
      paswd,
      selectedemps,
      emps,
      globalFilter,
      loading,
      showing,
    } = this.state;
    var header = (
      <div className="col-md-12">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <InputText
              type="search"
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
              placeholder="Search..."
              size="30"
            />
          </div>
          <div className="col-md-3">
            <Button
              icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
              iconPos="left"
              label="Reset Password"
              style={{ marginLeft: "5%" }}
              onClick={this.onClick}
              disabled={this.state.selectedemps.length === 0}
            />
          </div>
        </div>
        {this.props.user_details.isSuperUser ? (
          <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-md-3">
              <Button
                icon={this.state.rawresultbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                label="Get Rawresult"
                style={{ margin: "5%" }}
                onClick={this.getrawresult}
                disabled={this.state.selectedemps.length === 0}
              />
              <Button
                icon={this.state.rawdatabtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                label="Get Raw Data"
                style={{ margin: "5%" }}
                onClick={this.getrawdata}
                disabled={this.state.selectedemps.length !== 1}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.resultcalbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="Calculate Result"
                onClick={this.calculate}
              />
            </div>

            <div className="col-md-3">
              <Button
                icon={this.state.hrdashbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="Hr Dashboard"
                onClick={this.genhrdashboard}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.medashbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="Me Dashboard"
                onClick={this.genmedashboard}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.auto_answer ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="Auto answer"
                onClick={this.pre_auto}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.del_answer ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="Delete Quest & Answers"
                onClick={this.pre_del}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.awakecalbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ marginLeft: "1%" }}
                label="Job family Score"
                onClick={this.atmascore}
              />
            </div>
            {/* <div className="col-md-2">
            <Button
              icon={this.state.medashbtn ? "pi pi-spin pi-spinner" : ""}
              iconPos="right"
              style={{ margin: "5%" }}
              label="Items Results"
              onClick={this.getitems}
            />
          </div> */}
            <div className="col-md-3">
              <InputText
                value={this.state.empcount || ""}
                onChange={(e) => this.setState({ empcount: e.target.value })}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.newresultcalbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="right"
                style={{ margin: "5%" }}
                label="New Result"
                onClick={this.empcalculate}
              />
            </div>
            <div className="col-md-3">
              <Button
                icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
                iconPos="left"
                label="Delete Users"
                style={{ margin: 9 }}
                onClick={this.deleteUsers}
                disabled={this.state.selectedemps.length === 0}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
    const footer = (
      <div>
        <Button label="Reset" icon="pi pi-check" onClick={this.reset} />
      </div>
    );

    // const name_header = this.props.user_details.organisation["org_ver_type"] === 'RV' ? 'Candidate ' : 'Employee '

    return (
      <div className={this.props.typelogin ? "" : "layout-main"}>
        <Dialog
          className=""
          appendTo={document.body}
          header={
            this.state.auto_state
              ? "Confirmation for auto answer"
              : this.state.del_state
              ? "Confirmation for delete answer"
              : "Confirmation"
          }
          visible={this.state.auto_ans_dialog}
          onHide={this.onHide_D}
          style={{ width: 500, overflow: "scroll" }}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            selected user id -{" "}
            {this.state.selectedemps.length > 0
              ? this.state.selectedemps[0].UserId
              : null}
            <br />
            <br />
            selected name -{" "}
            {this.state.selectedemps.length > 0
              ? this.state.selectedemps[0].Name
              : null}
            <br />
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                icon={
                  this.state.auto_answer || this.state.del_answer
                    ? "pi pi-spin pi-spinner"
                    : ""
                }
                iconPos="right"
                style={{ marginLeft: "1%" }}
                label="Confirm"
                onClick={
                  this.state.auto_state
                    ? this.auto_answer
                    : this.state.del_state
                    ? this.del_answer
                    : null
                }
              />
            </div>
          </div>
        </Dialog>
        <div className="p-grid">
          <div className="p-col-12">
            <Growl ref={(el) => (this.growl = el)} />
            {loading ? (
              <>
                <div className="loading-spinner-container">
                  <ProgressSpinner></ProgressSpinner>
                </div>
              </>
            ) : (
              <Panel header={this.props.menuProps.label}>
                <Dialog
                  appendTo={document.body}
                  modal={true}
                  header="Password-Reset"
                  visible={visible}
                  style={{ width: "50vw" }}
                  //footer={footer}
                  onHide={this.onHide}
                  maximizable
                >
                  <div>
                    <span>Password best practices</span>
                    <ul>
                      <li>Your password must contain at least 8 characters.</li>
                      <li>
                        Your password can't be too similar to your other
                        personal information.
                      </li>
                      <li>Your password can't be a commonly used password.</li>
                      <li>Your password can't be entirely numeric.</li>
                    </ul>
                    <div>
                      <div>Password :</div>

                      <InputText
                        style={{ width: "100%" }}
                        type={hidden ? "password" : "text"}
                        className="p-inputtext"
                        value={paswd}
                        onChange={(e) =>
                          this.setState({ paswd: e.target.value })
                        }
                      />

                      <div style={{ marginTop: "10px" }}>
                        Confirm Password :
                      </div>
                      <InputText
                        style={{ width: "100%" }}
                        type={hidden ? "password" : "text"}
                        onChange={(e) =>
                          this.setState({ cpaswd: e.target.value })
                        }
                        value={cpaswd}
                        className="p-inputtext"
                      />

                      <div className="p-grid">
                        <div className="p-col-12">
                          <a
                            style={{ float: "right" }}
                            onClick={this.toggleShow}
                            className="showpwd"
                          >
                            <p
                              style={{ cursor: "pointer", color: "#00a3b4" }}
                              className="showpwd1"
                            >
                              <u>Show/Hide Password</u>
                            </p>
                          </a>

                          {showing ? (
                            <div style={{ color: "red" }}>
                              Password doesnt match
                            </div>
                          ) : null}
                        </div>
                        <div className="p-col-12">
                          <div style={{ float: "right" }}>
                            <Button
                              label="Reset"
                              icon="pi pi-check"
                              onClick={this.reset}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
                <DataTable
                  // scrollable
                  selection={selectedemps}
                  onSelectionChange={(e) =>
                    this.setState({ selectedemps: e.value })
                  }
                  value={emps}
                  paginator={true}
                  header={header}
                  globalFilter={globalFilter}
                  emptyMessage={
                    emps.length === 0 && !loading ? "No data" : "Loading..."
                  }
                  first={this.state.first}
                  onPage={(e) =>
                    this.setState({ first: e.first, rows: e.rows })
                  }
                  rows={this.state.rows}
                  responsive={true}
                  resizableColumns={true}
                  rowsPerPageOptions={[10, 20, 50, emps ? emps.length : ""]}
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                  className="scrolltable"
                >
                  <Column selectionMode="multiple" style={{ width: "4em" }} />
                  <Column field="Sno" header="Sl. No." sortable={true} />
                  <Column
                    field="employee_number"
                    header={"Roll No."}
                    sortable={true}
                  />
                  <Column field="Name" header={"Name"} sortable={true} />
                  <Column field="UserId" header={"User Id"} sortable={true} />
                  <Column field="email" header="Email Id" sortable={true} />
                  <Column
                    field="grade_desc"
                    header="Grade / Level"
                    sortable={true}
                  />
                  <Column
                    field="designation"
                    header="Designation"
                    sortable={true}
                  />
                  <Column
                    field="department"
                    header="Department"
                    sortable={true}
                  />
                  {this.props.user_details.organisation["org_ver_type"] ===
                  "RV" ? (
                    <Column
                      field="Created_On"
                      header="Created On"
                      sortable={true}
                    />
                  ) : null}

                  {this.props.user_details.organisation["org_ver_type"] ===
                  "RV" ? (
                    <Column
                      field="hiring_manager_name"
                      header="Hiring Manager"
                      sortable={true}
                    />
                  ) : (
                    <Column field="Mng" header="Manager" sortable={true} />
                  )}
                  {this.props.user_details.isSuperUser ? (
                    <Column
                      field="CompPerc"
                      header="% of Completion"
                      sortable={true}
                    />
                  ) : null}
                  <Column field="status" header="Status" sortable={true} />
                </DataTable>
              </Panel>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(PasswordManagement);
