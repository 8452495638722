import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import moment from "moment";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import AwakeConfig from "./AwakeConfig";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import ManageUserData from "./ManageUserData";

export const filetypes = [
  "Organisation",
  "Yeta Tools Update",
  "Yeta Items",
  "Dashboard parameters",
  "Emotional Report",
  "Recruitment Pdf configuration",
  "Organisation Picture",
  "Training Syllabus",
  "Hover Text",
  "Customer Configuration",
];

export const mandatoryFields = {
  // "HR Master Update": ["employeeNumber", "userId"],
  "HR Master Update": [],
};
class Init extends Component {
  state = {
    radioValue: "",
    cols: [],
    rows: [],
    errors: {},
    file: null,
    progress: false,
    result: [],
    sel_orgnames: [],
    loading: false,
    resultgenbtn: false,
    jobProfileDetails: false,
    msgDialog: false,
    fileLink: "",
    loader: false,
    selDecision: [],
    selStatus: [],
    first: 0,
    rows: 10,
  };

  handleFile = () => {
    let { radioValue, file } = this.state;
    // console.log("handlefile:", radioValue, file);
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    // console.log("radio:",radioValue, "file:", file, "org:", organisation);
    if (radioValue === "") {
      this.growl.show({
        severity: "error",
        detail: "Select atleast one option",
      });
    } else {
      const files = file;
      if (!files) {
        this.growl.show({
          severity: "error",
          detail: "Choose File",
        });
        return;
      }
      console.log(radioValue);
      if (radioValue === "Organisation Picture") {
        this.photoUpload();
      } else if (radioValue === "Job family Configuration") {
        this.jobFamilyConfig(files);
      } else if (radioValue === "Customer Configuration") {
        this.custConfig(files);
      } else {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          let cols = [];
          let rows = [];
          let emptyRowCount = 0;
          data.some((r, index) => {
            if (index == 0) {
              r.forEach((r1) => {
                cols.push({
                  field: r1,
                });
              });
            }
            if (index > 0) {
              let obj = {};
              r.some((r1, index2) => {
                obj[cols[index2].field] = r1;
                return emptyRowCount === 10;
              });
              if (isEmptyRow(obj, mandatoryFields[radioValue])) emptyRowCount += 1;
              else rows.push(obj);
            }
            return emptyRowCount === 10;
          });
          this.setState({ rows, progress: false });
          let datasending = { data: rows, name: file["name"] };
          // console.log(datasending)

          datasending = {
            data: rows,
            org: [organisation.name],
            name: file["name"],
          };

          this.setState({ loading: true, loader: true });
          // console.log("ds", datasending);
          api.post(this.urlMap(), datasending).then((res) => {
            this.setState({ loading: false, loader: false });
            if (radioValue === "HR Master Update") {
              console.log("return data:", res.data.result, res.data.msg);
              if (res.data.result) {
                this.growl.show({
                  severity: "success",
                  detail: res.data.msg,
                  life: 10000,
                });
                this.filedownload(res.data.data);
                //this.setState({ msgDialog: true, fileLink: res.data.data, radioValue: "", file: null, loading: false });
              } else {
                this.growl.show({
                  severity: "error",
                  detail: res.data.msg,
                  life: 10000,
                });

                this.setState({
                  // radioValue: "",
                  result: [res.data.msg],
                  // file: null,
                  loading: false,
                  loader: false,
                });
              }
            } else if (
              radioValue === "HR Master Update" ||
              radioValue === "Dashboard parameters" ||
              radioValue === "Emotional Report" ||
              radioValue === "Employee Rating" ||
              radioValue === "Yeta Items" ||
              radioValue === "Yeta Tools Update" ||
              radioValue === "Recruitment Pdf configuration" ||
              radioValue === "Organisation Picture" ||
              radioValue === "Organisation" ||
              radioValue === "Training Syllabus" ||
              radioValue === "Hover Text"
            ) {
              if (res.data.result) {
                this.fetchData();
                this.setState({
                  radioValue: "",
                  result: ["Successfully uploaded!"],
                  file: null,
                  loading: false,
                  loader: false,
                });
              } else {
                if (res.data.data) {
                  this.filedownload(res.data.data);
                  this.setState({
                    // radioValue: "",
                    result: ["Check Error File"],
                    // file: null,
                    loading: false,
                    loader: false,
                  });
                } else {
                  this.setState({
                    // radioValue: "",
                    result: [res.data.msg],
                    // file: null,
                    loading: false,
                    loader: false,
                  });
                }
              }
            } else {
              this.setState({
                // radioValue: "",
                // file: null,
                result: res.data.result,
                loading: false,
                loader: false,
              });
            }
          });
        };
        if (rABS) reader.readAsBinaryString(files);
        else reader.readAsArrayBuffer(files);
      }
    }
  };

  custConfig = (files) => {
    let { radioValue, file } = this.state;
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      let cols = [];
      let rows = [];
      let cols1 = [];
      let rows1 = [];
      for (var i = 0; i <= wb["Workbook"]["Sheets"].length; i++) {
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });

        let emptyRowCount = 0;
        data.some((r, index) => {
          if (i === 0 && index == 2) {
            r.forEach((r1) => {
              cols.push({
                field: r1,
              });
            });
          }
          if (i === 1 && index == 0) {
            r.forEach((r1) => {
              cols1.push({
                field: r1,
              });
            });
          }
          if (i === 0 && index > 2) {
            let obj = {};
            r.some((r1, index2) => {
              obj[cols[index2].field] = r1;
              return emptyRowCount === 10;
            });
            if (isEmptyRow(obj, mandatoryFields[radioValue])) emptyRowCount += 1;
            else if (i === 0) {
              rows.push(obj);
            } else if (i === 1) {
              rows1.push(obj);
            }
          }
          if (i === 1 && index > 0) {
            let obj = {};
            r.some((r1, index2) => {
              obj[cols1[index2].field] = r1;
              return emptyRowCount === 10;
            });
            if (isEmptyRow(obj, mandatoryFields[radioValue])) emptyRowCount += 1;
            else if (i === 0) {
              rows.push(obj);
            } else if (i === 1) {
              rows1.push(obj);
            }
          }
          return emptyRowCount === 10;
        });
      }
      this.setState({ rows, progress: false });

      rows[0]["lang_status"] = JSON.parse(rows[0]["lang_status"].replaceAll("'", '"').replaceAll("False", "false").replaceAll("True", "true"));
      rows[0]["welcome_text"] = JSON.parse(rows[0]["welcome_text"]);
      const datasending = {
        data: rows,
        user_config: rows1,
        org: [organisation.name],
        name: file["name"],
      };
      this.setState({ loading: true, loader: true });
      // console.log("ds", datasending);
      api.post(this.urlMap(), datasending).then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.fetchData();
          this.setState({
            radioValue: "",
            result: ["Successfully uploaded!"],
            file: null,
            loading: false,
            loader: false,
          });
        } else {
          if (res.data.data) {
            this.filedownload(res.data.data);
            this.setState({
              // radioValue: "",
              result: ["Check Error File"],
              // file: null,
              loading: false,
              loader: false,
            });
          } else {
            this.setState({
              // radioValue: "",
              result: [res.data.msg],
              // file: null,
              loading: false,
              loader: false,
            });
          }
        }
      });
    };
    if (rABS) reader.readAsBinaryString(files);
    else reader.readAsArrayBuffer(files);
  };

  jobFamilyConfig = (files) => {
    let { radioValue, file } = this.state;
    const { organisation } = this.props;
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      for (var i = 0; i <= wb["Workbook"]["Sheets"].length; i++) {
        console.log(wb["Workbook"]["Sheets"]);
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });
        let cols = [];
        let rows = [];
        let emptyRowCount = 0;
        data.some((r, index) => {
          if (index == 0) {
            r.forEach((r1) => {
              cols.push({
                field: r1,
              });
            });
          }
          if (index > 0) {
            let obj = {};
            r.some((r1, index2) => {
              obj[cols[index2].field] = r1;
              return emptyRowCount === 10;
            });
            rows.push(obj);
          }
          return emptyRowCount === 10;
        });
        this.setState({ rows, progress: false });
        let datasending = { data: rows, name: file["name"] };
        this.setState({ loading: true, loader: true });
        // console.log("ds", datasending)

        if (wb["Workbook"]["Sheets"][i]) {
          if (wb["Workbook"]["Sheets"][i]["name"] == "Courses") {
            this.setState({ radioValue: "Courses" });

            api.post("api/cce/courses", datasending).then((res) => {
              this.setState({
                radioValue: "",
                result: [...this.state.result].concat(res.data.result),
                loading: false,
                loader: false,
              });
            });
          } else if (wb["Workbook"]["Sheets"][i]["name"] == "JobFamily") {
            this.setState({ radioValue: "JobProfiles" });
            setTimeout(() => {
              api.post("api/cce/JobProfiles", datasending).then((res) => {
                this.setState({
                  radioValue: "",
                  result: [...this.state.result].concat(res.data.result),
                  loading: false,
                  loader: false,
                });
              });
            }, 2000);
          } else if (wb["Workbook"]["Sheets"][i]["name"] == "CourseToolMapping") {
            this.setState({ radioValue: "CourseToolMapping" });
            setTimeout(() => {
              api.post("api/cce/CourseToolMapping", datasending).then((res) => {
                this.setState({
                  radioValue: "",
                  result: [...this.state.result].concat(res.data.result),
                  loading: false,
                  loader: false,
                });
              });
            }, 2000);
          } else if (wb["Workbook"]["Sheets"][i]["name"] == "CourseJFMapping") {
            this.setState({ radioValue: "CourseJPMapping" });
            setTimeout(() => {
              api.post("api/cce/CourseJPMapping", datasending).then((res) => {
                this.setState({
                  radioValue: "",
                  result: [...this.state.result].concat(res.data.result),
                  loading: false,
                  loader: false,
                });
              });
            }, 3000);
          } else if (wb["Workbook"]["Sheets"][i]["name"] == "JFToolMapping") {
            this.setState({ radioValue: "JpToolMapping" });
            setTimeout(() => {
              api.post("api/cce/JobDimensionMapping", datasending).then((res) => {
                this.setState({
                  radioValue: "",
                  result: [...this.state.result].concat(res.data.result),
                  loading: false,
                  loader: false,
                });
              });
            }, 4000);
          }
          console.log("wb", wb["Workbook"]["Sheets"][i]["name"], radioValue);
        }
      }
    };

    if (rABS) reader.readAsBinaryString(files);
    else reader.readAsArrayBuffer(files);
  };
  filedownload = (url) => {
    // console.log(url);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  updatedim = () => {
    api.post("api/assessments/newdimensions").then((res) => {
      this.setState({
        result: res.data.result,
      });
    });
  };

  updateitem = () => {
    api.post("api/assessments/newitem").then((res) => {
      this.setState({
        result: res.data.result,
      });
    });
  };

  rawresultGen = () => {
    const { organisation } = this.props;
    this.setState({ resultgenbtn: true });
    api.post("api/cce/rawresultgen", { org: [organisation.name] }).then((res) => {
      this.setState({
        result: res.data.result,
      });
      if (res.data.status === true) {
        this.growl.show(
          {
            severity: "success",
            summary: "Success",
            detail: "Raw Result generated successfully ",
          },
          this.setState({ resultgenbtn: false })
        );
      } else {
        this.growl.show(
          {
            severity: "error",
            summary: "Error Message",
            detail: res.data.error,
          },
          this.setState({ resultgenbtn: false })
        );
      }
    });
  };

  urlMap = () => {
    let url = "";
    this.state.radioValue === "Yeta Tools Update"
      ? (url = "api/assessments/dimensions")
      : this.state.radioValue === "Yeta Items"
      ? (url = "api/assessments/items")
      : this.state.radioValue === "HR Master Update"
      ? (url = "api/organisation/employees")
      : this.state.radioValue === "Organisation"
      ? (url = "api/organisation/organisation")
      : this.state.radioValue === "Dashboard parameters"
      ? (url = "api/cce/hrdashboard")
      : this.state.radioValue === "Emotional Report"
      ? (url = "api/cce/emotionaldashboard")
      : this.state.radioValue === "Recruitment Pdf configuration"
      ? (url = "api/cce/softskillconfig")
      : this.state.radioValue === "Organisation Picture"
      ? (url = "api/cce/orgpic")
      : this.state.radioValue === "Employee Rating"
      ? (url = "api/cce/employeerating")
      : this.state.radioValue === "Delete Tools"
      ? (url = "api/assessments/deletedimension")
      : this.state.radioValue === "New Tools"
      ? (url = "api/assessments/updatedimension")
      : this.state.radioValue === "Delete Items"
      ? (url = "api/assessments/deleteitems")
      : this.state.radioValue === "New Items"
      ? (url = "api/assessments/updateitems")
      : this.state.radioValue === "Raw Results"
      ? (url = "api/cce/uploadrawresults")
      : this.state.radioValue === "Upload item range"
      ? (url = "api/cce/uploaditemrange")
      : this.state.radioValue === "Map employee"
      ? (url = "api/cce/mapemployeerange")
      : this.state.radioValue === "Training Syllabus"
      ? (url = "api/assessments/training_syllabus")
      : this.state.radioValue === "Hover Text"
      ? (url = "api/cce/hover_text")
      : this.state.radioValue === "Customer Configuration"
      ? (url = "api/cce/cust_config_import")
      : (url = "");
    return url;
  };

  Radiobtn = (event) => {
    this.setState({ radioValue: event.value });
    this.setState({ sel_orgnames: [] });
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation) || !this.props.user_details.isSuperUser) this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  fetchData() {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { org: organisation };
    api
      .post("api/cce/get_org_data", data)
      .then((res) => {
        this.setState({
          org: res.data.org,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  photoUpload() {
    let organisation = {};
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const formData = new FormData();
    console.log(this.state.file, "asdg");
    formData.append("file", this.state.file);
    formData.append("type", this.state.file["type"]);
    formData.append("size", this.state.file["size"]);
    formData.append("name", this.state.file["name"]);
    formData.append("org", organisation["id"]);

    api
      .post("api/cce/orgpic", formData)
      .then((res) => {
        if (res.data.result) {
          this.setState({
            radioValue: "",
            result: ["Success!"],
            file: null,
            loading: false,
          });
        } else {
          this.setState({
            radioValue: "",
            result: ["Failed!"],
            file: null,
            loading: false,
          });
        }
      })
      .catch((error) =>
        this.setState({
          radioValue: "",
          result: ["Failed!"],
          file: null,
          loading: false,
        })
      );
  }

  getFullEmpData = (selDecision, selStatus, isTemplate = false) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const datasending = {
      org: [organisation.name],
      selDecision,
      selStatus,
      isTemplate,
    };
    this.setState({ loading: true, loader: true });
    api
      .post("api/organisation/getHREmployeesData", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.msg,
            life: 10000,
          });
          this.filedownload(res.data.data);
        } else {
          this.growl.show({
            severity: "warning",
            // summary: "Error Message",
            detail: res.data.msg,
          });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  getUploadData = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const datasending = {
      org: [organisation.name],
    };
    this.setState({ loading: true });
    api
      .post("api/organisation/getUploadData", datasending)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.msg,
            life: 10000,
          });

          this.filedownload(res.data.data);
        } else {
          this.growl.show({
            severity: "warning",
            // summary: "Error Message",
            detail: res.data.msg,
            life: 10000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  jsonCheck = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  render() {
    const { result, radioValue, loading, org, jobProfileDetails } = this.state;
    const userStatus = [
      { label: "ALL", value: "ALL" },
      { label: "ACTIVE", value: "ACTIVE" },
      { label: "DEACTIVATED", value: "DEACTIVATED" },
    ];
    const hiringDecision = [
      { label: "ALL", value: "ALL" },
      { label: "SELECTED", value: "SELECTED" },
      { label: "NOT SELECTED", value: "NOT SELECTED" },
    ];
    return (
      <div className={this.props.typelogin ? "" : "layout-main"}>
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll" }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>File Details</span>}
          visible={this.state.msgDialog}
          onHide={() => this.setState({ msgDialog: false })}
        >
          <div>
            <div style={{ padding: 18 }}>
              File Upload successful
              <button
                style={{ fontSize: 12, padding: 2, borderRadius: 20, borderWidth: 1, margin: 5 }}
                onClick={() => this.filedownload(this.state.fileLink)}
              >
                Click Here
              </button>
              to download results
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll" }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>Status</span>}
          visible={this.state.loader}
          onHide={() => this.setState({ loader: false })}
          closable={false}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner></ProgressSpinner>
            </div>
          </div>
        </Dialog>

        <div className="p-grid">
          <ManageUserData getFullEmpData={(selDecision, selStatus, isTemplate) => this.getFullEmpData(selDecision, selStatus, isTemplate)} />
          <div className="p-col-12">
            {/* <Panel header={"Job family Configuration"}>
              <AwakeConfig result={(result) => this.setState({ result: [...this.state.result].concat(result) })} />
            </Panel> */}
            {this.props.user_details.isSuperUser ? (
              <div>
                <Panel header={this.props.menuProps.label}>
                  <div className="p-grid">
                    <div className="p-col-12">
                      {this.props.user_details.isSuperUser ? (
                        <>
                          <div className="p-grid">
                            {filetypes.map((filename) => (
                              <div
                                key={filename}
                                className="p-col-4"
                                style={{
                                  display: "flex",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <RadioButton
                                  name="filename_type"
                                  value={filename}
                                  onChange={(event) => this.Radiobtn(event)}
                                  checked={radioValue === filename}
                                >
                                  {filename}
                                </RadioButton>
                                <label htmlFor={filename} className="p-radiobutton-label">
                                  {filename}
                                  <p
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      marginBottom: 0,
                                    }}
                                  >
                                    {org
                                      ? filename === "Yeta Tools Update"
                                        ? org["tools_upload_fname"] && org["tools_upload_fname"]
                                        : filename === "Yeta Items"
                                        ? org["items_upload_fname"] && org["items_upload_fname"]
                                        : filename === "Organisation"
                                        ? org["org_upload_fname"] && org["org_upload_fname"]
                                        : filename === "Dashboard parameters"
                                        ? org["hr_report_upload_fname"] && org["hr_report_upload_fname"]
                                        : filename === "Emotional Report"
                                        ? org["emo_report_upload_fname"] && org["emo_report_upload_fname"]
                                        : filename === "Recruitment Pdf configuration"
                                        ? org["bio_config_upload_fname"] && org["bio_config_upload_fname"]
                                        : filename === "Organisation Picture"
                                        ? org["org_pic_upload_fname"] && org["org_pic_upload_fname"]
                                        : filename === "Training Syllabus"
                                        ? org["training_syllabus_fname"] && org["training_syllabus_fname"]
                                        : filename === "Hover Text"
                                        ? org["hover_text_description_fname"] && org["hover_text_description_fname"]
                                        : null
                                      : null}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      marginBottom: 0,
                                    }}
                                  >
                                    {org
                                      ? filename === "Yeta Tools Update"
                                        ? org["tools_upload_date"] && moment(org["tools_upload_date"]).format("lll")
                                        : filename === "Yeta Items"
                                        ? org["items_upload_date"] && moment(org["items_upload_date"]).format("lll")
                                        : filename === "Organisation"
                                        ? org["org_upload_date"] && moment(org["org_upload_date"]).format("lll")
                                        : filename === "Dashboard parameters"
                                        ? org["hr_report_upload_date"] && moment(org["hr_report_upload_date"]).format("lll")
                                        : filename === "Emotional Report"
                                        ? org["emo_report_upload_date"] && moment(org["emo_report_upload_date"]).format("lll")
                                        : filename === "Recruitment Pdf configuration"
                                        ? org["bio_config_upload_date"] && moment(org["bio_config_upload_date"]).format("lll")
                                        : filename === "Organisation Picture"
                                        ? org["org_pic_upload_date"] && moment(org["org_pic_upload_date"]).format("lll")
                                        : filename === "Training Syllabus"
                                        ? org["training_syllabus_date"] && moment(org["training_syllabus_date"]).format("lll")
                                        : filename === "Hover Text"
                                        ? org["hover_text_description_date"] && moment(org["hover_text_description_date"]).format("lll")
                                        : null
                                      : null}
                                  </p>
                                </label>
                              </div>
                            ))}

                            <div className="p-col-4">
                              <RadioButton
                                name="filename_type"
                                value="Employee Rating"
                                onChange={(event) => this.setState({ radioValue: event.value })}
                                checked={this.state.radioValue === "Employee Rating"}
                              >
                                Employee Rating
                              </RadioButton>
                              <label htmlFor="Employee Rating" className="p-radiobutton-label">
                                Employee Rating
                                <p
                                  style={{
                                    fontSize: 10,
                                    color: "#808080",
                                    marginBottom: 0,
                                  }}
                                >
                                  {org && org["emp_rating_upload_fname"] && org["emp_rating_upload_fname"]}
                                </p>
                                <p
                                  style={{
                                    fontSize: 10,
                                    color: "#808080",
                                    marginBottom: 0,
                                  }}
                                >
                                  {org && org["emp_rating_upload_date"] && moment(org["emp_rating_upload_date"]).format("lll")}
                                </p>
                              </label>
                            </div>
                            <div className="p-col-4">
                              <RadioButton
                                name="filename_type"
                                value="Raw Results"
                                onChange={(event) => this.setState({ radioValue: event.value })}
                                checked={this.state.radioValue === "Raw Results"}
                              >
                                Raw Results
                              </RadioButton>
                              <label htmlFor="Raw Results" className="p-radiobutton-label">
                                Raw Results
                                <p
                                  style={{
                                    fontSize: 10,
                                    color: "#808080",
                                    marginBottom: 0,
                                  }}
                                >
                                  {org && org["raw_results_upload_fname"] && org["raw_results_upload_fname"]}
                                </p>
                                <p
                                  style={{
                                    fontSize: 10,
                                    color: "#808080",
                                    marginBottom: 0,
                                  }}
                                >
                                  {org && org["raw_results_upload_fname"] && moment(org["raw_results_upload_date"]).format("lll")}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div className="p-grid">
                            <div className="p-col-6" style={{ paddingBottom: 0 }}>
                              <RadioButton
                                name="filename_type"
                                value="Job family Configuration"
                                onChange={(event) => this.setState({ radioValue: event.value })}
                                checked={this.state.radioValue === "Job family Configuration"}
                              >
                                Job family Configuration
                              </RadioButton>
                              <label htmlFor="Job family Configuration" className="p-radiobutton-label">
                                Job family Configuration
                                <div>
                                  <button
                                    style={{ fontSize: 9, padding: 2, borderRadius: 20, borderWidth: 1 }}
                                    onClick={() => this.setState((prevState) => ({ jobProfileDetails: !prevState.jobProfileDetails }))}
                                  >
                                    {jobProfileDetails ? "Hide details" : "Show details"}
                                  </button>
                                </div>
                              </label>
                            </div>
                            {jobProfileDetails && org && org["jobProfileConfig"] && (
                              <div className="p-col-12" style={{ display: "flex", justifyContent: "start", alignItems: "center", flexWrap: "wrap" }}>
                                {org["jobProfileConfig"]["courses"] && (
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      margin: 9,
                                    }}
                                  >
                                    <div>Courses:</div>
                                    <div>{org["jobProfileConfig"]["courses"]["name"]}</div>
                                    <div>{moment(org["jobProfileConfig"]["courses"]["date"]).format("lll")}</div>
                                  </div>
                                )}
                                {org["jobProfileConfig"]["jobFamily"] && (
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      margin: 9,
                                    }}
                                  >
                                    <div>JobFamily:</div>
                                    <div>{org["jobProfileConfig"]["jobFamily"]["name"]}</div>
                                    <div>{moment(org["jobProfileConfig"]["jobFamily"]["date"]).format("lll")}</div>
                                  </div>
                                )}
                                {org["jobProfileConfig"]["courseJF"] && (
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      margin: 9,
                                    }}
                                  >
                                    <div>CourseJF:</div>
                                    <div>{org["jobProfileConfig"]["courseJF"]["name"]}</div>
                                    <div>{moment(org["jobProfileConfig"]["courseJF"]["date"]).format("lll")}</div>
                                  </div>
                                )}
                                {org["jobProfileConfig"]["courseTool"] && (
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      margin: 9,
                                    }}
                                  >
                                    <div>CourseTool:</div>
                                    <div>{org["jobProfileConfig"]["courseTool"]["name"]}</div>
                                    <div>{moment(org["jobProfileConfig"]["courseTool"]["date"]).format("lll")}</div>
                                  </div>
                                )}
                                {org["jobProfileConfig"]["toolJF"] && (
                                  <div
                                    style={{
                                      fontSize: 10,
                                      color: "#808080",
                                      margin: 9,
                                    }}
                                  >
                                    <div>JFTool:</div>
                                    <div>{org["jobProfileConfig"]["toolJF"]["name"]}</div>
                                    <div>{moment(org["jobProfileConfig"]["toolJF"]["date"]).format("lll")}</div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : null}

                      <div className="p-grid">
                        <div className="p-col-4">
                          <RadioButton
                            name="filename_type"
                            value="HR Master Update"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "HR Master Update"}
                          >
                            HR Master Update
                          </RadioButton>
                          <label htmlFor="HR Master Update" className="p-radiobutton-label">
                            HR Master Update
                            <p
                              style={{
                                fontSize: 10,
                                color: "#808080",
                                marginBottom: 0,
                              }}
                            >
                              {org && org["emp_upload_fname"] && this.jsonCheck(org["emp_upload_fname"])
                                ? JSON.parse(org["emp_upload_fname"]).fname
                                : org && org["emp_upload_fname"]}
                            </p>
                            <p
                              style={{
                                fontSize: 10,
                                color: "#808080",
                                marginBottom: 0,
                              }}
                            >
                              {org && org["emp_upload_date"] && moment(org["emp_upload_date"]).format("lll")}
                            </p>
                            {org && org["emp_upload_fname"] && this.jsonCheck(org["emp_upload_fname"]) ? (
                              <button
                                style={{ fontSize: 9, padding: 5, borderRadius: 20, borderWidth: 1, marginLeft: 0 }}
                                onClick={() => this.filedownload(JSON.parse(org["emp_upload_fname"]).result_url)}
                              >
                                Last Processed Result
                              </button>
                            ) : null}
                            <p>
                              <button style={{ fontSize: 9, padding: 5, borderRadius: 20, borderWidth: 1 }} onClick={this.getFullEmpData}>
                                Full Employees Data
                              </button>
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="p-grid container-upload">
                      <div className="p-col-7">
                        <input
                          type="file"
                          accept=".xlsx,image/*"
                          onChange={(event) => {
                            console.log("this is file selected:", event.target.files[0]);
                            this.setState({
                              file: event.target.files[0],
                              rows: [],
                              cols: [],
                            });
                          }}
                          style={{ marginLeft: "20px" }}
                        />
                        <label>(The file format should be of type .xls or .xlsx or image format)</label>
                      </div>
                      <div className="p-col">
                        <Button type="submit" onClick={this.handleFile} className="btn btn-primary" label="Upload"></Button>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header={"Updation Tools and Items"}>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <div className="p-grid">
                        <div className="p-col-4">
                          <RadioButton
                            name="filename_type"
                            value="Delete Tools"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "Delete Tools"}
                          >
                            Employee Rating
                          </RadioButton>
                          <label htmlFor="Delete Tools" className="p-radiobutton-label">
                            Delete Tools
                          </label>
                        </div>
                        <div className="p-col-2">
                          <RadioButton
                            name="filename_type"
                            value="New Tools"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "New Tools"}
                          >
                            New Tools
                          </RadioButton>
                          <label htmlFor="New Tools" className="p-radiobutton-label">
                            New Tools
                          </label>
                        </div>
                        <div className="p-col-2">
                          <RadioButton
                            name="filename_type"
                            value="Delete Items"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "Delete Items"}
                          >
                            Delete Items
                          </RadioButton>
                          <label htmlFor="Delete Items" className="p-radiobutton-label">
                            Delete Items
                          </label>
                        </div>
                        <div className="p-col-2">
                          <RadioButton
                            name="filename_type"
                            value="New Items"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "New Items"}
                          >
                            New Items
                          </RadioButton>
                          <label htmlFor="New Items" className="p-radiobutton-label">
                            New Items
                          </label>
                        </div>
                        <div className="p-col-2">
                          <Button type="Update Tools" onClick={this.updatedim} className="btn btn-primary" label="Update Tools"></Button>
                        </div>
                        <div className="p-col-2">
                          <Button type="Update Items" onClick={this.updateitem} className="btn btn-primary" label="Update Items"></Button>
                        </div>
                        <div className="p-grid container-upload">
                          <div className="p-col">
                            <input
                              type="file"
                              accept=".xlsx"
                              onChange={(event) => {
                                this.setState({
                                  file: event.target.files[0],
                                  rows: [],
                                  cols: [],
                                });
                              }}
                              style={{ marginLeft: "20px" }}
                            />
                            <label>(The file format should be of type .xls or .xlsx)</label>
                          </div>
                          <div className="p-col">
                            <Button type="submit" onClick={this.handleFile} className="btn btn-primary" label="Upload"></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header={"Data Generation"}>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <div className="p-grid">
                        <div className="p-col-2">
                          <RadioButton
                            name="filename_type"
                            value="Upload item range"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "Upload item range"}
                          >
                            Upload item range
                          </RadioButton>
                          <label htmlFor="Upload item range" className="p-radiobutton-label">
                            Upload item range
                          </label>
                        </div>
                        <div className="p-col-2">
                          <RadioButton
                            name="filename_type"
                            value="Map employee"
                            onChange={(event) => this.setState({ radioValue: event.value })}
                            checked={this.state.radioValue === "Map employee"}
                          >
                            Map employee
                          </RadioButton>
                          <label htmlFor="New Tools" className="p-radiobutton-label">
                            Map employee to range
                          </label>
                        </div>
                        <div className="p-col-2">
                          <Button
                            icon={this.state.resultgenbtn ? "pi pi-spin pi-spinner" : ""}
                            iconPos="right"
                            type="Update Tools"
                            onClick={this.rawresultGen}
                            className="btn btn-primary"
                            label="Generate Raw Result"
                            disabled={this.state.resultgenbtn}
                          ></Button>
                        </div>
                        <div className="p-grid container-upload">
                          <div className="p-col">
                            <input
                              type="file"
                              accept=".xlsx"
                              onChange={(event) => {
                                this.setState({
                                  file: event.target.files[0],
                                  rows: [],
                                  cols: [],
                                });
                              }}
                              style={{ marginLeft: "20px" }}
                            />
                            <label>(The file format should be of type .xls or .xlsx)</label>
                          </div>
                          <div className="p-col">
                            <Button type="submit" onClick={this.handleFile} className="btn btn-primary" label="Upload"></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            ) : null}
          </div>
          <div className="p-col-12">
            {result === null ? null : loading ? (
              <>
                <div className="loading-spinner-container">
                  <ProgressSpinner></ProgressSpinner>
                </div>
              </>
            ) : (
              <Panel header="Status">
                <div>
                  {result.map((res, index) => (
                    <div key={index}>{res}</div>
                  ))}
                </div>
              </Panel>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(Init);
