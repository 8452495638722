import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user_details: action.user_details,
    error: null,
    loading: false,
    orgSettings: {},
  });
};

const setMenu = (state, action) => {
  return updateObject(state, {
    menu: action.menu,
  });
};

const orgSettings = (state, action) => {
  return updateObject(state, {
    orgSettings: action.orgSettings,
    loading: false,
  });
};

const orgSettingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const update_language = (state, action) => {
  const user_details = state.user_details;
  user_details["language_preference"] = action.language;
  return updateObject(state, {
    user_details: user_details,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    loading: false,
  });
};

const mark_read_help = (state, action) => {
  const user_details = { ...state.user_details, show_help: action.read };
  localStorage.setItem("user_details", JSON.stringify(user_details));
  return updateObject(state, {
    user_details,
  });
};
const setShowDetails = (state, action) => {
  const user_details = {
    ...state.user_details,
    first_name: action.first_name,
    last_name: action.last_name,
    email: action.email,
  };
  localStorage.setItem("user_details", JSON.stringify(user_details));
  return updateObject(state, {
    user_details,
  });
};

const mark_paid = (state) => {
  const user_details = { ...state.user_details, is_paid: true };
  localStorage.setItem("user_details", JSON.stringify(user_details));
  return updateObject(state, {
    user_details,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_SIDEMENU:
      return setMenu(state, action);
    case actionTypes.UPDATE_LANGUAGE:
      return update_language(state, action);
    case actionTypes.ORG_SETTINGS:
      return orgSettings(state, action);
    case actionTypes.ORG_SETTINGS_FAIL:
      return orgSettingsFail(state, action);
    case actionTypes.MARK_READ_HELP:
      return mark_read_help(state, action);
    case actionTypes.SHOW_DETAILS:
      return setShowDetails(state, action);
    case actionTypes.MARK_PAID:
      return mark_paid(state, action);
    default:
      return state;
  }
};

export default reducer;
