import React from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import AppTopbar from "./AppTopbar";
import AppMenu from "./AppMenu";
import nav from "../utils/Utils";
import AdminTopbar from "./AdminTopbar";
import _ from "lodash";

class CustomLayout extends React.Component {
  state = {
    layoutMode: "static",
    layoutColorMode: "dark",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
  };
  isDesktop() {
    return window.innerWidth > 1024;
  }
  onWrapperClick = (event) => {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  };
  onSidebarClick = (event) => {
    this.menuClick = true;
  };
  onToggleMenu = (event) => {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  };
  onMenuItemClick = (event) => {
    this.menuClick = true;
    const { item } = event;
    if (!item.items) {
      this.setState(
        {
          overlayMenuActive: false,
          mobileMenuActive: false,
        },
        () => {
          if (item.link) nav("/" + item.link);
        }
      );
    }
  };
  render() {
    const { authenticated = false, user_details = { organisation: {} } } = this.props;
    const { layoutMode, mobileMenuActive, staticMenuInactive, overlayMenuActive } = this.state;
    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": layoutMode === "overlay",
      "layout-static": layoutMode === "static",
      "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
      "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
      "layout-mobile-sidebar-active": mobileMenuActive,
    });
    let org_logo_bg = "white";
    if (user_details.organisation && user_details.organisation.logo_bg) org_logo_bg = "#" + user_details.organisation.logo_bg;
    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {authenticated ? (
          <>
            {!user_details.isSuperUser ? (
              <AppTopbar onToggleMenu={(e) => this.onToggleMenu(e)} user_details={user_details} />
            ) : (
              <AdminTopbar onToggleMenu={(e) => this.onToggleMenu(e)} user_details={user_details} />
            )}
            <div ref={(el) => (this.sidebar = el)} className="layout-sidebar layout-sidebar-light" onClick={() => this.onSidebarClick}>
              <div style={{ backgroundColor: org_logo_bg }} className="layout-logo">
                <img
                  alt="Logo"
                  style={{
                    width: "250px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                  src={
                    !_.isEmpty(user_details.logo_key_url)
                      ? user_details.logo_key_url
                      : "https://res.cloudinary.com/yeta/image/upload/c_scale,h_50,w_105/v1534747791/yeta_logo.png"
                  }
                />
              </div>
              <AppMenu key={authenticated} onMenuItemClick={(e) => this.onMenuItemClick(e)} />
            </div>
          </>
        ) : null}

        {this.props.children}

        <div className="layout-mask"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.token !== null,
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout));
