import React from "react";
import Modal from "react-bootstrap/Modal";
import history from "./history";
import { Button } from "primereact/button";
import store from "../store/store";
import { useState } from "react";
import XLSX from "xlsx";
import ReactPlayer from "react-player";

export const excelexport = ({ fname, expfname, headers, data }) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fname);
  XLSX.writeFile(wb, expfname);
};

export default function nav(loc) {
  history.push(loc);
}

export function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-description" id="contained-modal-title-vcenter">
          <div className="inlineContainer">
            <span>
              <img
                src="https://res.cloudinary.com/yeta/image/upload/c_scale,h_40,w_85/v1534747791/yeta_logo.png"
                alt="Yeta - Enabling people"
                className="img-responsive atma-badge-image-logo pull-left"
              />
            </span>
            <span className="modal-title">ATMA is your trusted coach and companion</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>ATMA helps employees in organisations to</h4>
        <ul type="disc">
          <li>measure their capabilities and realise their true potential</li>
          <li>understand their strength, weakness and behavioural patterns and alter them to maximise their outcome</li>
          <li>become aware of their stressors and motivators and work up on them</li>
          <li>develop wholistic life aspirations to create a path to success</li>
        </ul>
        <p>
          While helping employees individually, it also helps managers to have scientific and objective management of their teams to scale the team
          performance and happy engagement.
        </p>
        <p>
          ATMA is based on dual psychometric framework, ‘MindFrame Analytics’ &amp; ‘Potential Development Framework’ which identify your capabilities
          and potential thru well proven questionnaire based techniques. As a coach, ATMA needs to know your true strengths and weaknesses to provide
          you the right guidance. It can be possible, only with your help by responding to the questions in an open and transparent manner.
        </p>
        <p>
          ATMA assures you that your personal data is never shared with the organisation. Every effort has been taken in the design to ensure privacy
          of the individuals. The organisation can only see aggregated data for managing the business, but not individual data.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export function HelpModal(props) {
  const instructions = props.user_details.organisation_settings.val;
  const { pageId, show, onNextClick, onHide, language } = props;
  let selectedLanguage = language;
  if (selectedLanguage === null || selectedLanguage.trim() === "") {
    selectedLanguage = "english";
  }
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span>{instructions[pageId]["head"] || "Welcome to ATMA assessment!"}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {instructions[pageId]["desc"] === "video" ? (
          <div className={"dialog-p-container-no-bottom"} style={{ height: 400 }}>
            <ReactPlayer
              loop={true}
              //muted={true}
              playing={true}
              controls={true}
              width="100%"
              height="100%"
              url={instructions[pageId]["url"]}
            />
          </div>
        ) : (
          instructions[pageId][selectedLanguage]
            .split(";")
            .filter((f) => f.trim() !== "")
            .map((i, index) =>
              i.trim() !== "" ? (
                <div
                  key={i}
                  className={
                    "dialog-p-container" +
                    (instructions[pageId][selectedLanguage].split(";").filter((f) => f.trim() !== "").length === index + 1 ? "-no-bottom" : "")
                  }
                >
                  <p>{i.trim()}</p>
                </div>
              ) : null
            )
        )}
        <div className="dialog-action-button">
          <Button className="p-button-rounded p-button-warning" icon="pi pi-check" label="Next" iconPos="right" onClick={() => onNextClick()} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function getToken() {
  return store.getState().auth.token;
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function isEmptyRow(obj, mandatoryFields = []) {
  let mandatoryCount = 0;
  if (mandatoryFields.length === 0) mandatoryFields = Object.keys(obj);
  mandatoryFields.forEach((val) => {
    if (!obj[val] || (obj[val] && obj[val].toString().trim() === "")) mandatoryCount += 1;
  });
  if (mandatoryFields.length === 0 || mandatoryCount !== mandatoryFields.length) return false;
  if (mandatoryCount === mandatoryFields.length) return true;
}

export function renderPanelHeader(props) {
  const { heading, count } = props;
  return (
    <div className="row" style={{ paddingBottom: 10 }}>
      <div style={{ fontSize: "15px" }} className={count !== undefined ? "col-md-8" : "col-md-12"}>
        {heading}
      </div>
      {count !== undefined ? (
        <div className="col-md-4">
          <div className="total-employees-container">N - {count}</div>
        </div>
      ) : null}
    </div>
  );
}
