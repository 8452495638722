import React, { Component } from "react";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { array_move } from "../../utils/Utils";
import { connect } from "react-redux";
import { getquestion } from "../../store/actions/question";
import { orgSettings } from "../../store/actions/auth";

class Ranking extends Component {
  state = {
    ranking_question: this.props.rankingquestion,
    item: this.props.rankingquestion.item,
    initial: this.props.rankingquestion.item.options,
    rank_options: this.props.rankingquestion.item.options,
    options_translations: this.props.rankingquestion.item.options_translations,
    nextRanking: -1,
    allselectedranking: false,
  };
  componentDidUpdate() {
    if (this.state.ranking_question !== this.props.rankingquestion) {
      this.refs.refhere.scrollIntoView({ behavior: "smooth", block: "start" });
      this.setState({
        ranking_question: this.props.rankingquestion,
        item: this.props.rankingquestion.item,
        nextRanking: -1,
        initial: this.props.rankingquestion.item.options,
      });
    }
  }
  componentDidMount() {
    this.refs.refhere.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  leadqeustion = (language_preference, enableLanguage) => {
    const lead = this.state.ranking_question.item.item.split(";");
    const lead_lang =
      language_preference &&
      this.state.ranking_question.item["languages"]["item"] &&
      this.state.ranking_question.item["languages"]["item"][language_preference]
        ? this.state.ranking_question.item["languages"]["item"][language_preference].split(";")
        : null;

    return this.state.ranking_question.item.show_questions ? (
      <label>
        {lead.map((i, index) => (
          <p key={index}>{i}</p>
        ))}
        {lead_lang && enableLanguage ? (
          <label>
            {lead_lang.map((i, index) => (
              <p key={index}>{i}</p>
            ))}
          </label>
        ) : null}
        {this.state.ranking_question.item.guid}
      </label>
    ) : (
      <label>
        {lead.map((i, index) => (
          <p key={index}>{i}</p>
        ))}
        {lead_lang ? (
          <label>
            {lead_lang.map((i, index) => (
              <p key={index}>{i}</p>
            ))}
          </label>
        ) : null}
      </label>
    );
  };

  resetOptions = () => {
    let { item, initial, ranking_question, rank_options } = this.state;
    item["options"] = initial;
    ranking_question.item["options"] = initial;
    rank_options = initial;
    this.setState({
      item,
      ranking_question,
      nextRanking: -1,
      allselectedranking: false,
      rank_options,
    });
    // console.log(item, initial);
  };
  loadQuestion = (isResponse) => {
    this.setState({ loading: true, allselectedranking: false });
    let data = {
      response_type: this.state.item.response_type,
      version: this.state.ranking_question.currentUserItem.version,
      options: this.state.rank_options,
      itemid: this.state.item.guid,
    };
    this.props.getquestion(data, 1);
  };
  onSortEnd = (oldIndex) => {
    let { item, nextRanking, rank_options } = this.state;
    let options = rank_options;
    if (!options[oldIndex]["selected"]) {
      nextRanking = nextRanking + 1;
      // console.log("opt", options);
      options = options.map((o, index) => {
        if (oldIndex === index) {
          return { ...o, selected: true };
        } else {
          return o;
        }
      });
      options = array_move(options, oldIndex, nextRanking);
      // console.log("a", options);
      this.setState({ item, nextRanking, rank_options: options }, () => this.refreshOptions());
    }
  };
  refreshOptions = () => {
    const { rank_options } = this.state;
    let options = rank_options;
    // console.log(options);
    let selectedCount = 0;
    options.forEach((o) => {
      if (o.selected) {
        selectedCount++;
      }
    });
    if (selectedCount + 1 === options.length || selectedCount === options.length) {
      options = options.map((o) => {
        return { ...o, selected: true };
      });
      this.setState({ rank_options: options, allselectedranking: true });
    }
    // console.log("items", item, selectedCount);
  };

  rankoptions = () => {
    const { options_translations } = this.state;
    const { user_details } = this.props;
    const enableLanguage = user_details && user_details.organisation && user_details.organisation.enableLanguage;
    const language_preference = user_details && user_details.language_preference;
    return this.state.rank_options.map((ro, index) => {
      let lang_opt;
      let opt_key = options_translations?.filter((opttrans) => ro.key in opttrans);
      if (opt_key) {
        const opt_l = opt_key[0] && opt_key[0][ro.key]?.filter((opt) => language_preference in opt);
        if (opt_l) {
          lang_opt = opt_l[0] && opt_l[0][language_preference];
        }
      }

      return (
        <div className="p-grid cursor-pointer que-tile" key={ro.key} onClick={() => this.onSortEnd(index)}>
          <div className="p-col-11" style={{ backgroundColor: ro.color, color: "#ffffff" }}>
            <label htmlFor={ro.value} className="p-radiobutton-label" style={{ paddingTop: "2px", fontSize: "16px" }}>
              {ro.value}
              <div>{lang_opt && enableLanguage ? lang_opt : null}</div>
            </label>
          </div>
          <div className="p-col-1" style={ro.selected ? { backgroundColor: "#00a3b4", textAlign: "center" } : {}}>
            {/* <h4 style={ro.selected ?{ marginBottom: 0, backgroundColor:'#39cccc', color:'#00000', textAlign: 'center'}:{ marginBottom: 0 }}>{ro.selected ? index + 1 : "#"}</h4> */}
            {ro.selected ? <h4 className="rankchip">{index + 1}</h4> : <h4 style={{ marginBottom: 0, textAlign: "center" }}>"#"</h4>}
          </div>
        </div>
      );
    });
  };

  render() {
    // console.log("rq", this.state.item, this.state.initial);
    const { item, initial, rank_options } = this.state;
    let options = [];
    if (item.options) {
      options = item.options;
    }
    const { user_details } = this.props;
    const enableLanguage = user_details && user_details.organisation && user_details.organisation.enableLanguage;
    const language_preference = user_details && user_details.language_preference;
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-3 p-lg-3"></div>
        <div className="p-col-12 p-md-6 p-lg-6 relative no-padding" ref="refhere">
          <Panel
            header={
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: 50 }}>
                <div>ATMA - Questionnaire</div>
                <div style={{ border: "6px solid #e78923", borderStyle: "double", padding: 5, color: "#e78923" }}>RANK</div>
              </div>
            }
          >
            <div className="dialog-p-container text-question-bold">
              {this.state.ranking_question ? this.leadqeustion(language_preference, enableLanguage) : ""}
            </div>
            <div className="dialog-p-container">
              {this.state.ranking_question ? this.state.ranking_question.item.item_response_lead : ""}
              {this.rankoptions()}
            </div>
            <div className="p-grid">
              <div className="dialog-action-button-reset p-col-6">
                <Button
                  className="p-button-rounded p-button-secondary"
                  label="Reset"
                  icon="pi pi-refresh"
                  iconPos="right"
                  disabled={rank_options === initial ? true : false}
                  onClick={() => this.resetOptions()}
                />
              </div>
              <div className="dialog-action-button p-col-6" style={{ float: "right" }}>
                {this.props.user_details.organisation.org_ver_type === "EV" && (
                  <Button
                    style={{ marginRight: 9 }}
                    className="p-button-rounded p-button-warning purplebtn"
                    label="Take a Break"
                    // disabled={this.props.progress < 30}
                    // icon="pi pi-arrow-right"
                    // iconPos="right"
                    onClick={() => this.props.onBreak()}
                  />
                )}
                <Button
                  className="p-button-rounded p-button-warning"
                  label="Next"
                  disabled={item.response_type === "Ranking" ? !this.state.allselectedranking : null}
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => this.loadQuestion(true)}
                />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getquestion: (data, return_item) => dispatch(getquestion(data, return_item)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);
