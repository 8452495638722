import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";

function UserLogin(props) {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    const { token = "" } = props.match.params;
    const data = {
      token,
    };
    axios.post("/api/organisation/user-check/", data).then((res) => {
      if (res.data.result) {
        const username = res.data.username;
        props.login(username, username);
      } else {
        setLoad(false);
      }
    });
  });
  return (
    <div style={{ minHeight: 500, marginTop: 100 }} className="layout-main not-found">
      {load ? <ProgressSpinner></ProgressSpinner> : <h1>Page not found</h1>}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
  };
};

export default connect(null, mapDispatchToProps)(UserLogin);
