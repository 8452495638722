import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  organisation: {},
  notificationCount: 0,
  profile_pic_loading: true
};

const setOrg = (state, action) => {
  return updateObject(state, {
    organisation: action.org
  });
};

const updateNotificationCount = (state, action) => {
  return updateObject(state, {
    notificationCount: action.count
  });
}

const setProfilePic = (state, action) => {
  return updateObject(state, {
    profile_pic_url: action.profile_pic_url,
    profile_pic_loading: false
  });
}

const profile_pic_loading = (state, action) => {
  return updateObject(state, {
    profile_pic_loading: action.profile_pic_loading
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UTIL_SET_ORG:
      return setOrg(state, action);
    case actionTypes.NOTIFICATION_COUNT:
      return updateNotificationCount(state, action)
    case actionTypes.SETPROFILEPIC:
      return setProfilePic(state, action)
    case actionTypes.PROFILEPICLOADING:
      return profile_pic_loading(state, action)
    default:
      return state;
  }
};

export default reducer;
