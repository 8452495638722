import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EChartsRadar from "../../common/ECharts/EChartsRadar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import ScoreDistribution from "../hr/DimInfLayout";
import { Button } from "primereact/button";
import TrainingNeedsComp from "../../common/TrainingNeedsComp";
import { Dropdown } from "primereact/dropdown";

class MyTeam extends Component {
  state = {
    loading: true,
    loadingDetails: true,
    visible: false,
    popupmsg: "",
    dimname: "",
    potentialindexinf: {},
    scoredescription: [],
    color: "black",
    competenciesfun: [],
    dimensionwiseHeadingfun: "",
    dimensionwiseHeadingid: "",
    containerKeyfun: 1,
    is_negativefun: null,
    dimensionwisefun: [],
    dimensionwisefunlow: [],
    pot_index: "",
    emps_len: "",
    managers: [],
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    distdata: [],
  };

  componentDidMount() {
    const { dashboardData, potentialindex, scoredescription, dashboardDatafun, pot_index, emps_len, demograph } = this.props.groupData;
    if (demograph && demograph.overallCount && demograph.overallCount > 0) {
      this.setState({
        distdata: demograph,
        leveldata: demograph.levelWise.map((grade) => ({
          name: grade.role,
          value: grade.percentage,
        })),
        deptdata: demograph.deptWise.map((grade) => ({
          name: grade.name,
          value: grade.percentage,
        })),
        agedata: demograph.ageWise.map((grade) => ({
          name: grade.range,
          value: grade.percentage,
        })),
        workexpdata: demograph.workexpWise.map((grade) => ({
          name: grade.range,
          value: grade.percentage,
        })),
        emps_len: demograph.overallCount,
        competencies: dashboardData,
        competenciesfun: dashboardDatafun,

        potentialindexinf: potentialindex,
        scoredescription: scoredescription,
        pot_index: pot_index,
      });
    }
    this.setState({ loading: false });
  }

  dimensionNameTemplatefun = (rowData, column) => {
    return (
      <Button
        className="clickbtn p-button-raised p-button-secondary p-button-rounded"
        label={rowData.dimension_name}
        onClick={() => this.popupfun(rowData)}
      />
    );
  };
  popupfun = (rowData) => {
    let color = "";
    this.state.competenciesfun.map((c) => {
      if (c.dimensionName + " " === this.state.dimensionwiseHeadingfun) {
        color = c.color;
      }
    });
    const dinf = rowData.diminf.split("::");
    let regex = /\/\*(.*)\*\//;
    let diminf = dinf[0]?.replace(regex, rowData.dimension_name);
    const dinference = (
      <div>
        {diminf.split(":").map((inf, index) => (
          <div key={index}>{inf}</div>
        ))}
      </div>
    );
    console.log(dinf);
    this.setState({
      color: color,
      popupmsg: dinference,
      visible: true,
      dimname: rowData.dimension_name,
    });
  };
  onHide = () => {
    this.setState({
      visible: false,
      popupmsg: "",
      dimname: "",
    });
  };

  onDimensionClickfun = (param) => {
    const dimensionSelected = param.name;
    const { competenciesfun, containerKeyfun } = this.state;
    const selectedCompetenciesfun = competenciesfun.filter(
      // c => c.dimensionName === dimensionSelected
      (c) => c.dimensionName.replace(" ", "\n") === dimensionSelected
    )[0];
    this.setState({
      containerKeyfun: containerKeyfun + 1,
      dimensionwisefun: selectedCompetenciesfun.child1,
      dimensionwisefunlow: selectedCompetenciesfun.child2,
      dimensionwiseHeadingfun: selectedCompetenciesfun.dimensionName + " ",
      dimensionwiseHeadingid: selectedCompetenciesfun.dimension_id,
      is_negativefun: selectedCompetenciesfun.is_negative,
    });
  };

  forcolorfun = () => {
    let color = "";
    this.state.competenciesfun.map((c) => {
      if (c.dimensionName + " " === this.state.dimensionwiseHeadingfun) {
        //color = c.color + "60";
        color = c.color;
      }
    });
    return color;
  };
  dialoghead = () => {
    return <span style={{ color: this.state.color }}>{this.state.dimname}</span>;
  };

  potentialindexinf = () => {
    const header = (
      <>
        <div>{this.state.potentialindexinf["header"]}</div>
        <ul>
          {this.state.potentialindexinf["data"].map((e, index) => {
            return <li key={index}>{e}</li>;
          })}
        </ul>
      </>
    );
    this.setState({
      color: "black",
      popupmsg: header,
      visible: true,
      dimname: "Potential Index",
    });
  };

  render() {
    const {
      competenciesfun,
      dimensionwisefun,
      dimensionwisefunlow,
      loading,
      loadingDetails,
      containerKeyfun,
      dimensionwiseHeadingfun,
      dimensionwiseHeadingid,
      distdata,
      leveldata,
      workexpdata,
      agedata,
      deptdata,
      emps_len,
    } = this.state;

    const chartDatafun = {
      type: "radarMe",
      trigger: true,
      title: "",
      data: competenciesfun.map((c) => {
        return {
          name: c.dimensionName,
          value: c.score,
          color: c.color,
          id: c.dimension_id,
        };
      }),
    };

    const { user_details, userId, managerName, menuProps } = this.props;
    const displayName = managerName.length === 0 ? "" : managerName[0]["label"] + "'s group";
    const comp = user_details.organisation.configuration.competencies;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        <Dialog
          style={{ width: "40vw" }}
          className=""
          appendTo={document.body}
          header={this.dialoghead()}
          visible={this.state.visible}
          onHide={this.onHide}
        >
          <span style={{ lineHeight: "2" }}>{this.state.popupmsg}</span>
        </Dialog>
        {loading === true ? (
          <div className="row">
            <div className="loading-spinner col-md-3">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <>
            {!(user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV") && (
              <Panel
                header={
                  <div className="row" style={{ paddingBottom: 10 }}>
                    <div className="col-md-8" style={{ fontSize: "18px" }}>
                      {userId.toString() === user_details.id.toString() ? this.props.type : displayName} Potential Index
                    </div>
                    <div className="col-md-4">
                      <div className="total-employees-container">N - {this.state.emps_len}</div>
                    </div>
                  </div>
                }
              >
                <div>
                  {competenciesfun.length > 0 ? (
                    <div>
                      <div>
                        <ul>
                          <li>The chart describes the behavioural competencies of the team including the manager.</li>
                          <li>Clicking on individual arms, collective team strengths and areas to focus will be displayed.</li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className={"col-md-6"}>
                          <span className="potentail-value-span-team">
                            <span className="potentail-value-team">{this.state.pot_index}</span>
                          </span>
                          <div className="row">
                            <EChartsRadar callBack={this.onDimensionClickfun} chartData={chartDatafun} />
                          </div>
                        </div>
                        {dimensionwisefun.length > 0 ? (
                          <div className="col-md-6">
                            <Panel
                              style={{
                                borderTop: "5px solid",
                                borderTopColor: this.forcolorfun(),
                              }}
                              header={dimensionwiseHeadingfun}
                            >
                              <div>
                                <div className="row" key={containerKeyfun}>
                                  {dimensionwiseHeadingid.toString() === comp["mental_wellbeing"] ||
                                  dimensionwiseHeadingid.toString() === comp["emotional_competence"] ? (
                                    <div className="col-md-2"></div>
                                  ) : (
                                    <div className="clk-table-btn col-md-6">
                                      <DataTable
                                        className="team_green"
                                        style={{
                                          textAlign: "center",
                                        }}
                                        resizableColumns={true}
                                        responsive={true}
                                        value={dimensionwisefun}
                                        emptyMessage={dimensionwisefun.length === 0 && !loadingDetails ? "No data" : "Loading..."}
                                        resizableColumns={true}
                                      >
                                        <Column
                                          header={"Core team strengths"}
                                          body={this.dimensionNameTemplatefun}
                                          headerStyle={{
                                            color: "#31c736",
                                            backgroundColor: "white",
                                            fontSize: "17px",
                                          }}
                                          style={{
                                            width: "20%",
                                            backgroundColor: "white",
                                          }}
                                        />
                                      </DataTable>
                                    </div>
                                  )}
                                  <div
                                    className={
                                      dimensionwiseHeadingid.toString() === comp["mental_wellbeing"] ||
                                      dimensionwiseHeadingid.toString() === comp["emotional_competence"]
                                        ? "clk-table-btn col-md-8"
                                        : "clk-table-btn col-md-6"
                                    }
                                  >
                                    <DataTable
                                      className="team_red"
                                      style={{
                                        textAlign: "center",
                                      }}
                                      resizableColumns={true}
                                      responsive={true}
                                      value={dimensionwisefunlow}
                                      emptyMessage={dimensionwisefun.length === 0 && !loadingDetails ? "No data" : "Loading..."}
                                      resizableColumns={true}
                                    >
                                      <Column
                                        header={"Key areas to focus"}
                                        headerStyle={{
                                          color: "#f34949",
                                          backgroundColor: "white",
                                          fontSize: "17px",
                                        }}
                                        body={this.dimensionNameTemplatefun}
                                        style={{
                                          width: "20%",
                                          backgroundColor: "white",
                                        }}
                                      />
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="row">
                        {/* <ScoreDistribution
                        menuProps={menuProps}
                        data={distdata}
                        potential={[]}
                        nCount={emps_len}
                        leveldata={leveldata}
                        deptdata={deptdata}
                        agedata={agedata}
                        workexpdata={workexpdata}
                        // name={this.props.type}
                        // globalfilter={globalfilter}
                        height={"380px"}
                        dheight={"440px"}
                        total={false}
                        // type={this.props.type}
                      /> */}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12">
                      <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                        <div
                          className="medialog"
                          style={{
                            fontSize: "20px",
                            fontWeight: "800px",
                            textAlign: "center",
                          }}
                        >
                          {this.props.type === "My Team"
                            ? "Insufficient team data to display the scorecard"
                            : "Insufficient group data to display the scorecard"}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Panel>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(MyTeam);
