import React, { Component } from "react";
import EChart from "../../common/ECharts/ECharts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { renderPanelHeader } from "../../utils/Utils";

let key = 0;

class ScoreDistribution extends Component {
  state = {
    globalFilter: "",
    fontSize: "12px",
    first: 0,
  };
  render() {
    const {
      data,
      leveldata,
      deptdata,
      agedata,
      workexpdata,
      menuProps,
      nCount,
      total,
    } = this.props;

    const levelData = {
      type: "bar",
      data: leveldata,
      title: "Grade - wise",
      employeesCount: leveldata.length,
      width: "65%",
      rotateLabel: true,
    };
    const deptData = {
      type: "bar",
      data: deptdata,
      title: "Depatment - wise",
      employeesCount: leveldata.length,
      width: "60%",
      rotateLabel: true,
    };
    const ageData = {
      type: "bar",
      data: agedata,
      title: "",
      employeesCount: agedata.length,
      width: "65%",
      rotateLabel: true,
    };
    const workexpData = {
      type: "bar",
      data: workexpdata,
      title: "Tenure with company - wise",
      employeesCount: workexpdata.length,
      width: "65%",
      rotateLabel: true,
    };
    var header = (
      <InputText
        type="search"
        onInput={(e) =>
          this.setState({ globalFilter: e.target.value, first: 0 })
        }
        placeholder="Search..."
        size="50"
      />
    );

    return (
      <>
        <div key={key} className="row">
          <div className="col-md-12 flex-container">
            <div className="col-md-4 flex chart-container-dim-inf">
              <Panel
                header={renderPanelHeader({
                  heading: "Age - wise",
                  count: nCount,
                })}
              >
                <div className="row">
                  <div className="p-col-12">
                    <EChart chartData={ageData} />
                  </div>
                </div>
                <div className="row">
                  <div className="p-col-12">
                    <DataTable
                      style={{
                        fontSize: this.state.fontSize,
                        textAlign: "center",
                      }}
                      resizableColumns={true}
                      className="orangep"
                      value={data.ageWise}
                    >
                      <Column
                        field="range"
                        header="Age"
                        style={{ fontSize: this.state.fontSize }}
                      />
                      <Column
                        field="count"
                        header="Employee Count"
                        style={{ fontSize: this.state.fontSize }}
                      />
                      {total ? (
                        <Column
                          style={{ fontSize: this.state.fontSize }}
                          field="totalCount"
                          header="Total Employee Count"
                        />
                      ) : null}
                      <Column
                        style={{ fontSize: this.state.fontSize }}
                        header="Employee Percentage %"
                        body={(rowData) => rowData.percentage + "%"}
                      />
                    </DataTable>
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-md-4 flex chart-container-dim-inf">
              <Panel
                header={renderPanelHeader({
                  heading: "Tenure with company - wise",
                  count: nCount,
                })}
              >
                <div className="row">
                  <div className="p-col-12">
                    <EChart chartData={workexpData} />
                  </div>
                </div>
                <div className="row">
                  <div className="p-col-12">
                    <DataTable
                      style={{
                        fontSize: this.state.fontSize,
                        textAlign: "center",
                      }}
                      resizableColumns={true}
                      className="orangep"
                      value={data.workexpWise}
                    >
                      <Column
                        field="range"
                        header="Tenure with company"
                        style={{ fontSize: this.state.fontSize }}
                      />
                      <Column
                        style={{ fontSize: this.state.fontSize }}
                        field="count"
                        header="Employee Count"
                      />
                      {total ? (
                        <Column
                          style={{ fontSize: this.state.fontSize }}
                          field="totalCount"
                          header="Total Employee Count"
                        />
                      ) : null}
                      <Column
                        style={{ fontSize: this.state.fontSize }}
                        body={(rowData) => rowData.percentage + "%"}
                        header="Employee Percentage %"
                      />
                    </DataTable>
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-md-4 flex chart-container-dim-inf">
              <Panel
                header={renderPanelHeader({
                  heading: "Grade - wise",
                  count: nCount,
                })}
              >
                <div className="row">
                  <div className="p-col-12">
                    <EChart chartData={levelData} />
                  </div>
                </div>
                <div className="row">
                  <div className="p-col-12">
                    <DataTable
                      style={{
                        fontSize: this.state.fontSize,
                        textAlign: "center",
                      }}
                      resizableColumns={true}
                      className="orangep"
                      value={data.levelWise}
                    >
                      <Column
                        style={{ fontSize: this.state.fontSize }}
                        field="role"
                        header="Grade"
                      />
                      <Column
                        field="count"
                        header="Employee Count"
                        style={{
                          textAlign: "center",
                          fontSize: this.state.fontSize,
                        }}
                      />
                      {total ? (
                        <Column
                          field="totalCount"
                          header="Total Employee Count"
                          style={{
                            textAlign: "center",
                            fontSize: this.state.fontSize,
                          }}
                        />
                      ) : null}
                      <Column
                        body={(rowData) => rowData.percentage + "%"}
                        header="Employee Percentage %"
                        style={{
                          textAlign: "center",
                          fontSize: this.state.fontSize,
                        }}
                      />
                    </DataTable>
                  </div>
                </div>
              </Panel>
            </div>
          </div>

          <div className="col-md-12 diminf-12-container">
            <Panel
              header={renderPanelHeader({
                heading: "Department - wise",
                count: nCount,
              })}
            >
              <div className="row">
                <div className="col-md-6">
                  <EChart height="350px" chartData={deptData} />
                </div>
                <div className="col-md-6">
                  <DataTable
                    style={{
                      fontSize: this.state.fontSize,
                      textAlign: "center",
                    }}
                    resizableColumns={true}
                    className="orangep"
                    value={data.deptWise}
                  >
                    <Column
                      field="name"
                      header="Department"
                      style={{ fontSize: this.state.fontSize }}
                    />
                    <Column
                      field="count"
                      header="Employee Count"
                      style={{
                        textAlign: "center",
                        fontSize: this.state.fontSize,
                      }}
                    />
                    {total ? (
                      <Column
                        field="totalCount"
                        header="Total Employee Count"
                        style={{
                          textAlign: "center",
                          fontSize: this.state.fontSize,
                        }}
                      />
                    ) : null}
                    <Column
                      body={(rowData) => rowData.percentage + "%"}
                      header="Employee Percentage %"
                      style={{
                        textAlign: "center",
                        fontSize: this.state.fontSize,
                      }}
                    />
                  </DataTable>
                </div>
              </div>
            </Panel>
          </div>
          {menuProps.showEmpDetails ? (
            <div className="col-md-12 diminf-12-container">
              <DataTable
                className="orange"
                style={{
                  textAlign: "center",
                }}
                //header={<div>Employee Details</div>}
                value={data.employees}
                first={this.state.first}
                onPage={(e) => this.setState({ first: e.first })}
                paginator={true}
                globalFilter={this.state.globalFilter}
                rows={20}
                header={header}
                resizableColumns={true}
                rowsPerPageOptions={[
                  10,
                  20,
                  50,
                  data.employees ? data.employees.length : "",
                ]}
              >
                <Column sortable={true} field="employee_number" header="ID" />
                <Column field="name" sortable={true} header="Name" />
                <Column
                  field="department"
                  sortable={true}
                  header="Department"
                />
                <Column field="grade" header="Grade" sortable={true} />
                <Column field="section" header="Section" sortable={true} />
                <Column
                  field="designation"
                  header="Designation"
                  sortable={true}
                />
                <Column field="manager" header="Manager" sortable={true} />
              </DataTable>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default ScoreDistribution;
