import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import axios from "axios";
import atma_logo from "../assets/images/atma-brand-icon.png";

class ForgotPassword extends React.Component {
  state = {
    username: "",
    emailSent: false,
    disablebutton: false,
    usernameErr: false
  };

  inputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  showGrowl = messageArr => {
    messageArr.forEach(m => {
      setTimeout(() => {
        this.growl.show(m);
      }, 1);
    });
  };

  handleSubmit = () => {
    const { username } = this.state;
    if (username.trim().length === 0) {
      this.setState({ usernameErr: true, disablebutton: false });
      this.showGrowl([
        {
          severity: "error",
          detail: "Email is required"
        }
      ]);
    } else {
      axios
        .post("rest-auth/password/reset/", {
          email: username
        })
        .then(res => {
          if (res.status === 200) {
            this.showGrowl([
              {
                severity: "success",
                detail: "Email sent successfully",
                closable: false
              }
            ]);
            this.setState({ emailSent: true, disablebutton: false });
          }
        })
        .catch(e => {
          this.setState({ disablebutton: false });
          const { data } = e;
          console.log(data);
        });
    }
  };

  render() {
    const {
      username,
      emailSent,
      disablebutton,
      loading = false,
      usernameErr
    } = this.state;
    return (
      <div className="layout-main-public">
        <Growl
          ref={el => (this.growl = el)}
          position="bottomright"
          style={{ marginRight: 20 }}
        />
        <form
          onSubmit={e => {
            e.preventDefault();
            this.setState({ disablebutton: true }, () => this.handleSubmit());
          }}
        >
          <div className="p-grid">
            <div className="p-col-12">
              <div className="auto-center-container-login">
                <div className="login-header-message">
                  <img
                    style={{ width: "50%", objectFit: "contain" }}
                    src={atma_logo}
                  ></img>
                </div>
                {!emailSent ? (
                  <div className="login-form-center-align">
                    <div className="p-inputgroup">
                      <InputText
                        className={
                          "input-text " + (usernameErr ? "p-error" : "")
                        }
                        name="username"
                        value={username}
                        onChange={e => this.inputChange(e)}
                        type="text"
                        placeholder="Email"
                      />
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                      </span>
                    </div>
                    <div className="p-inputgroup">
                      <Button
                        type="submit"
                        disabled={loading}
                        icon={loading ? "pi pi-spin pi-spinner" : ""}
                        label={loading ? "" : "SUBMIT"}
                        className="login-button p-button-warning"
                      ></Button>
                    </div>
                  </div>
                ) : (
                  <div className="forgot-password-message">
                    We've emailed you instructions for setting your password, if
                    an account exists with the email you entered. You should
                    receive them shortly.
                    <br />
                    <br />
                    If you don't receive an email, please make sure you've
                    entered the address you registered with, and check your spam
                    folder.
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;
