import React, { Component } from "react";
import api from "../../api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import ScoreDistribution from "./DimInfLayout";
import { MultiSelect } from "primereact/multiselect";
import EChartScatter from "../../common/ECharts/EChartScatter";
import XLSX from "xlsx";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import { renderPanelHeader } from "../../utils/Utils";
import { Dialog } from "primereact/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let key = 0;

class DimInf extends Component {
  state = {
    highdata: [],
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    potential: [],
    lowdata: [],
    lowleveldata: [],
    lowdeptdata: [],
    lowagedata: [],
    lowworkexpdata: [],
    lowpotential: [],
    percentage: "",
    loading_handle: true,
    load: false,
    enableExport: true,
    filterData: {},
    seldep: [],
    selloc: [],
    sellev: [],
    selectedTab: "",
    filterApplied: false,
    dialog: false,
    dep: [],
    loc: [],
    lev: [],
    filter_load: false,
    btn_load: false,
  };

  fetchData = (filter) => {
    key = key + 1;
    let data = {};
    const {
      seldep,
      selloc,
      sellev,
      selectedTab,
      filterApplied,
      dep,
      loc,
      lev,
    } = this.state;
    const { topquartile, lead_h, leadership } = this.props.dataProps;
    if (filter) {
      this.setState({
        filter_load: true,
        dep: seldep,
        loc: selloc,
        lev: sellev,
      });
      data = {
        dimension_id: this.props.dimid,
        departments: seldep,
        locations: selloc,
        grades: sellev,
        keyVal: selectedTab,
        lead_h: false,
        user: this.props.user,
      };
    } else {
      this.setState({ btn_load: true });
      data = {
        dimension_id: this.props.dimid,
        departments: dep,
        locations: loc,
        grades: lev,
        keyVal: selectedTab,
        lead_h: false,
        user: this.props.user,
      };
    }
    if (leadership) {
      data["percentage"] = 100;
    }
    if (lead_h) {
      data["lead_h"] = lead_h;
    }
    if (topquartile) {
      data["percentage"] = 25;
    }
    // if (filterApplied) {
    //   data["percentage"] = 100;
    // }
    api
      .post("api/cce/get_dimensional_inference", JSON.stringify(data))
      .then((res) => {
        if (res.data.scoreDistribution.success) {
          this.setState({
            enableExport: false,
            selectedTab: res.data.scoreDistribution.selectedTab,
            currdate: res.data.scoreDistribution.currdate,
            highdata: res.data.scoreDistribution,
            dim_is_negative: res.data.scoreDistribution.dim_is_negative,
            cutoff_high: res.data.scoreDistribution.cutoff_high,
            cutoff_low: res.data.scoreDistribution.cutoff_low,
            highProficiencyCount:
              res.data.scoreDistribution.highProficiencyCount,
            proficiencyCount: res.data.scoreDistribution.proficiencyCount,
            inadequacyCount: res.data.scoreDistribution.inadequacyCount,
            leveldata: res.data.scoreDistribution.levelWise.map((grade) => ({
              name: grade.role,
              value: grade.percentage,
            })),
            deptdata: res.data.scoreDistribution.deptWise.map((grade) => ({
              name: grade.name,
              value: grade.percentage,
            })),
            agedata: res.data.scoreDistribution.ageWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            workexpdata: res.data.scoreDistribution.workexpWise.map(
              (grade) => ({
                name: grade.range,
                value: grade.percentage,
              })
            ),
            potential: res.data.scoreDistribution.potentialData.map(
              (grade, index) => [index + 1, grade.highpc]
            ),
            loading_handle: false,
            filter_load: false,
            btn_load: false,
            load: true,
          });
        } else {
          this.setState({
            loading_handle: false,
            filter_load: false,
            btn_load: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  exportFile = () => {
    var fname = "High Proficiency";
    var fname2 = "Inadequacy";
    var expfname = this.props.menuProps.label + " (Dimensional Inference).xls";
    var { currdate } = this.state;
    const {
      levelWise,
      workexpWise,
      employees,
      ageWise,
      deptWise,
    } = this.state.highdata;
    const levelWise2 = this.state.lowdata.levelWise;
    const workexpWise2 = this.state.lowdata.workexpWise;
    const employees2 = this.state.lowdata.employees;
    const ageWise2 = this.state.lowdata.ageWise;
    const deptWise2 = this.state.lowdata.deptWise;
    var level_headers = ["Grade-wise", "Count", "Total Count", "Percentage"];
    var workexp_headers = [
      "Tenure with company",
      "Count",
      "Total Count",
      "Percentage",
    ];
    var age_headers = ["Age-wise", "Count", "Total Count", "Percentage"];
    var dept_headers = [
      "Department-wise",
      "Count",
      "Total Count",
      "Percentage",
    ];
    var emp_headers = [
      "ID",
      "Name",
      "Department",
      "Grade",
      "Section",
      "Designation",
      "Manager",
    ];
    var level_data = levelWise.map((level) => [
      level.role,
      level.count,
      level.totalCount,
      level.percentage,
    ]);
    var workexp_data = workexpWise.map((workexp) => [
      workexp.range,
      workexp.count,
      workexp.totalCount,
      workexp.percentage,
    ]);
    var age_data = ageWise.map((age) => [
      age.range,
      age.count,
      age.totalCount,
      age.percentage,
    ]);
    var dept_data = deptWise.map((dept) => [
      dept.name,
      dept.count,
      dept.totalCount,
      dept.percentage,
    ]);
    var emp_data = employees.map((emp) => [
      emp.employee_number,
      emp.name,
      emp.department,
      emp.grade,
      emp.section,
      emp.designation,
      emp.manager,
    ]);
    var level_data2 = levelWise2.map((level) => [
      level.role,
      level.count,
      level.totalCount,
      level.percentage,
    ]);
    var workexp_data2 = workexpWise2.map((workexp) => [
      workexp.range,
      workexp.count,
      workexp.totalCount,
      workexp.percentage,
    ]);
    var age_data2 = ageWise2.map((age) => [
      age.range,
      age.count,
      age.totalCount,
      age.percentage,
    ]);
    var dept_data2 = deptWise2.map((dept) => [
      dept.name,
      dept.count,
      dept.totalCount,
      dept.percentage,
    ]);
    var emp_data2 = employees2.map((emp) => [
      emp.employee_number,
      emp.name,
      emp.department,
      emp.grade,
      emp.section,
      emp.designation,
      emp.manager,
    ]);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([level_headers, ...level_data], {
      origin: "B4",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Date:"]], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_aoa(ws, [[currdate]], {
      origin: "B1",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Report Type:"]], {
      origin: "A2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["High Proficiency"]], {
      origin: "B2",
    });
    XLSX.utils.sheet_add_aoa(ws, [age_headers, ...age_data], {
      origin: "B" + (level_data.length + 6),
    });
    XLSX.utils.sheet_add_aoa(ws, [workexp_headers, ...workexp_data], {
      origin: "G4",
    });
    XLSX.utils.sheet_add_aoa(ws, [dept_headers, ...dept_data], {
      origin: "G" + (workexp_data.length + 6),
    });
    XLSX.utils.sheet_add_aoa(ws, [emp_headers, ...emp_data], { origin: "L4" });
    XLSX.utils.book_append_sheet(wb, ws, fname);
    const ws2 = XLSX.utils.aoa_to_sheet([level_headers, ...level_data2], {
      origin: "B4",
    });
    XLSX.utils.sheet_add_aoa(ws2, [["Date:"]], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_aoa(ws2, [[currdate]], {
      origin: "B1",
    });
    XLSX.utils.sheet_add_aoa(ws2, [["Report Type:"]], {
      origin: "A2",
    });
    XLSX.utils.sheet_add_aoa(ws2, [["Inadequacy"]], {
      origin: "B2",
    });
    XLSX.utils.sheet_add_aoa(ws2, [age_headers, ...age_data2], {
      origin: "B" + (level_data2.length + 6),
    });
    XLSX.utils.sheet_add_aoa(ws2, [workexp_headers, ...workexp_data2], {
      origin: "G4",
    });
    XLSX.utils.sheet_add_aoa(ws2, [dept_headers, ...dept_data2], {
      origin: "G" + (workexp_data2.length + 6),
    });
    XLSX.utils.sheet_add_aoa(ws2, [emp_headers, ...emp_data2], {
      origin: "L4",
    });
    XLSX.utils.book_append_sheet(wb, ws2, fname2);
    XLSX.writeFile(wb, expfname);
  };

  componentDidMount() {
    api.get("api/cce/get-dashboard-filters").then((res) => {
      this.setState({ ...res.data });
      this.fetchData();
    });
  }

  tabClicked = (tab) => {
    this.refs.demo.scrollIntoView({ behavior: "smooth" });
    key = key + 1;
    if (this.state.selectedTab !== tab) {
      this.setState({ selectedTab: tab }, () => this.fetchData(false));
    }
  };

  renderSelectedTab = () => {
    const {
      highdata,
      potential,
      leveldata,
      deptdata,
      agedata,
      workexpdata,
      globalfilter,
      selectedTab,
      highProficiencyCount,
      proficiencyCount,
      inadequacyCount,
    } = this.state;
    let nCount = 0;
    if (selectedTab === "highProficiency") {
      nCount = highProficiencyCount;
    } else if (selectedTab === "proficiency") {
      nCount = proficiencyCount;
    } else if (selectedTab === "inadequacy") {
      nCount = inadequacyCount;
    }
    const { menuProps } = this.props;
    return (
      <div className={selectedTab}>
        <ScoreDistribution
          menuProps={menuProps}
          data={highdata}
          potential={potential}
          nCount={nCount}
          leveldata={leveldata}
          deptdata={deptdata}
          agedata={agedata}
          workexpdata={workexpdata}
          name={this.props.type}
          globalfilter={globalfilter}
          height={"380px"}
          dheight={"440px"}
          type={this.props.type}
          total={true}
        />
      </div>
    );
  };
  gridinf = () => {
    this.setState({ dialog: true });
  };
  onhide = () => {
    this.setState({ dialog: false });
  };
  pheader() {
    const { topq, type } = this.props.dataProps;
    return (
      <div
        style={{
          fontSize: "18px",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {topq ? (
          type
        ) : (
          <div>
            <span>{this.props.menuProps.label} </span>
            <span>
              <FontAwesomeIcon
                onClick={this.gridinf}
                style={{
                  cursor: "pointer",
                  color: "#00a3b4",
                  fontSize: "14px",
                }}
                icon={["far", "question-circle"]}
              />
            </span>
          </div>
        )}
        {this.props.menuProps.enableExport ? (
          <Button
            style={{ float: "right", marginRight: "20px" }}
            disabled={this.state.enableExport}
            onClick={this.exportFile}
            label="Export"
            icon="fas fa-download"
          />
        ) : null}
      </div>
    );
  }

  render() {
    const {
      highdata,
      potential,
      filterData,
      dim_is_negative = false,
      cutoff_high,
      cutoff_low,
      highProficiencyCount,
      proficiencyCount,
      inadequacyCount,
      selectedTab,
      filterApplied,
      seldep,
      selloc,
      sellev,
    } = this.state;
    const { menuProps, dataProps, layoutcheck, user_details } = this.props;
    const { user_type } = user_details;
    const { topq } = dataProps;
    const chartData = {
      type: "scatter",
      title: "",
      data: potential,
      lowd: cutoff_low,
      highd: cutoff_high,
      yaxis_label: "Potential",
      showVerticalLine: !filterApplied,
    };
    return (
      <div className={layoutcheck ? "" : "layout-main"}>
        <Dialog
          visible={this.state.dialog}
          header={this.props.menuProps.label}
          onHide={this.onhide}
        >
          {this.props.user_details.organisation.configuration.competence_text}
        </Dialog>
        <Panel header={this.pheader()}>
          {/* <div className="box-header with-border" style={{ fontSize: "18px" }}>
            {this.props.type}
            {menuProps.enableExport ? (
              <Button
                style={{ float: "right", marginRight: "20px" }}
                disabled={enableExport}
                onClick={this.exportFile}
                label="Export"
                icon="fas fa-download"
              />
            ) : null}
          </div> */}
          <div>
            {this.state.loading_handle === true ? (
              <>
                <div className="loading-spinner-container">
                  <ProgressSpinner></ProgressSpinner>
                </div>
              </>
            ) : this.state.load === false ? (
              <div className="p-col-12">
                <div
                  style={{ color: "#6a6b6cba" }}
                  className=" box-body box-body-cust-250"
                >
                  <div
                    className="medialog"
                    style={{
                      fontSize: "20px",
                      fontWeight: "800px",
                      textAlign: "center",
                    }}
                  >
                    Data Collection is in Progress
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!topq ? (
                  <>
                    {/* {user_type !== "FUNCTIONAL_HEAD" ? ( */}
                    <Panel header="Filters">
                      <div className="box-body box-body-cust-250">
                        <div className="row">
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.selloc}
                              options={
                                filterData.locations ? filterData.locations : []
                              }
                              onChange={(e) =>
                                this.setState({ selloc: e.value })
                              }
                              filter={true}
                              placeholder="Location..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.seldep}
                              options={
                                filterData.departments
                                  ? filterData.departments
                                  : []
                              }
                              onChange={(e) =>
                                this.setState({ seldep: e.value })
                              }
                              filter={true}
                              placeholder="Department..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.sellev}
                              options={
                                filterData.levels ? filterData.levels : []
                              }
                              onChange={(e) =>
                                this.setState({ sellev: e.value })
                              }
                              filter={true}
                              placeholder="Grade..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <Button
                              type="button"
                              label="Apply"
                              onClick={() =>
                                this.setState(
                                  {
                                    filterApplied:
                                      seldep.length === 0 &&
                                      selloc.length === 0 &&
                                      sellev.length === 0
                                        ? false
                                        : true,
                                  },
                                  () => this.fetchData(true)
                                )
                              }
                              style={{ marginRight: "15px" }}
                            />
                            <Button
                              type="button"
                              label="Reset"
                              onClick={() =>
                                this.setState(
                                  {
                                    seldep: [],
                                    selloc: [],
                                    sellev: [],
                                    dep: [],
                                    lev: [],
                                    loc: [],
                                    filterApplied: false,
                                  },
                                  () => this.fetchData(true)
                                )
                              }
                              style={{ marginRight: "15px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                    {/* ) : null} */}
                    {this.state.filter_load ? (
                      <div className="loading-spinner-container">
                        <ProgressSpinner></ProgressSpinner>
                      </div>
                    ) : (
                      <>
                        <Panel
                          header={
                            <div style={{ marginBottom: 15 }} ref="demo">
                              <span style={{ fontSize: "18px" }}>
                                {this.props.menuProps.label}
                              </span>
                              <span style={{ float: "right" }}>
                                <div className="total-employees-container">
                                  N - {highdata.potentialData.length}
                                </div>
                              </span>
                            </div>
                          }
                        >
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-9">
                                <EChartScatter chartData={chartData} />
                              </div>
                              <div className="col-md-3">
                                <div
                                  className="tab-button-dim-inf"
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="p-button-rounded p-button-secondary"
                                    style={
                                      selectedTab === "highProficiency"
                                        ? {
                                            background: "#cefad7",
                                            border: "3px solid #39cccc",
                                            color: "black",
                                          }
                                        : {
                                            background: "#cefad7",
                                            border: "3px solid white",
                                            color: "black",
                                          }
                                    }
                                    onClick={() =>
                                      this.tabClicked("highProficiency")
                                    }
                                    label={
                                      "High Proficiency - " +
                                      highProficiencyCount
                                    }
                                  />
                                </div>
                                <div
                                  className="tab-button-dim-inf"
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="p-button-rounded p-button-secondary"
                                    style={
                                      selectedTab === "proficiency"
                                        ? {
                                            background: "#feefc8",
                                            border: "3px solid #ffb800",
                                            color: "black",
                                          }
                                        : {
                                            background: "#feefc8",
                                            border: "3px solid white",
                                            color: "black",
                                          }
                                    }
                                    onClick={() =>
                                      this.tabClicked("proficiency")
                                    }
                                    label={"Proficiency - " + proficiencyCount}
                                  />
                                </div>
                                <div
                                  className="tab-button-dim-inf"
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="p-button-rounded p-button-secondary"
                                    style={
                                      selectedTab === "inadequacy"
                                        ? {
                                            background: "#ffdbdc",
                                            border: "3px solid #ff5050",
                                            color: "black",
                                          }
                                        : {
                                            background: "#ffdbdc",
                                            border: "3px solid white",
                                            color: "black",
                                          }
                                    }
                                    onClick={() =>
                                      this.tabClicked("inadequacy")
                                    }
                                    label={"Inadequacy - " + inadequacyCount}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                        {this.state.btn_load ? (
                          <div className="loading-spinner-container">
                            <ProgressSpinner></ProgressSpinner>
                          </div>
                        ) : (
                          <div key={key} className="row">
                            {this.renderSelectedTab()}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(DimInf);
