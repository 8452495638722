import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import _ from "lodash";
import { ProgressSpinner } from "primereact/progressspinner";
import { excelexport } from "../../utils/Utils";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Growl } from "primereact/growl";
import moment from "moment";
import XLSX from "xlsx";
import { Dropdown } from "primereact/dropdown";
import EmployeeManagement from "./EmployeeManagement";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { SelectButton } from "primereact/selectbutton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import copy from "copy-to-clipboard";

class CceAdmin extends Component {
  state = {
    empStat: [],
    Parent_empStat: [],
    exporttype: null,
    loading: true,
    first: 0,
    selectedemps: [],
    rows: 10,
    startdate_val: false,
    enddate_val: false,
    rec_ver: false,
    startdate: "",
    enddate: "",
    status: "Active",
    dept: null,
    loc: null,
    sec: null,
    grade: null,
    scoreLevel: "",
    link: "",
    filtersData: [],
    visiblePwdReset: false,
    cpaswd: "",
    paswd: "",
    showing: false,
    hidden: true,
    columns: [],
    headers: {},
    userConfig: {},
    selectedColumns: [],
    hover_text: {},
    statusReportBtn: false,
    statusChanged: false,
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    grade: "",
    gender: "",
    headers: "",
    department: "",
    userConfig: "",
    native_language: "",
    editUserDialog: false,
    editUserDetails: null,
    levels: [],
    departmentsList: [],
    lang_options: [],
    userIdMapping: "",
    org: {},
  };
  componentDidMount() {
    if (!_.isEmpty(this.props.organisation) || !this.props.user_details.isSuperUser) this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ loading: true });
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    api
      .post("/api/cce/rawresults_stats", {
        organisation: organisation,
        type: this.props.type,
        user: this.props.user,
      })
      .then((res) => {
        let empStat = [...res.data.results].filter((emp) => emp.status === "Active");
        this.setState({
          rec_ver: res.data.rec_ver,
          Parent_empStat: [...res.data.results],
          empStat: empStat,
          loading: false,
          selectedemps: [],
          first: 0,
          rows: 10,
          startdate: "",
          enddate: "",
          startdate_val: false,
          enddate_val: false,
          link: res.data.link,
          headers: res.data.headers,
          userConfig: res.data.userConfig,
          hover_text: res.data.hover_text,
        });
        let col = [];
        let results = res.data.results;
        let filtersData = res.data.filtersData;
        let { departments, levels, locations, sections } = filtersData;
        let dept = [];
        let lev = [];
        let loc = [];
        let sec = [];
        departments.map((department) => {
          const val = results.some((res) => res.dept_id === department.value);
          if (val) {
            dept.push(department);
          }
        });
        levels.map((level) => {
          const val = results.some((res) => res.grade_id === level.value);
          if (val) {
            lev.push(level);
          }
        });
        locations.map((location) => {
          const val = results.some((res) => res.loc_id === location.value);
          if (val) {
            loc.push(location);
          }
        });
        sections.map((section) => {
          const val = results.some((res) => res.sec_id === section.value);
          if (val) {
            sec.push(section);
          }
        });
        filtersData["departments"] = dept;
        filtersData["levels"] = lev;
        filtersData["locations"] = loc;
        filtersData["sections"] = sec;
        this.setState({ filtersData });
        let columns = [
          { field: "Completion", header: "% of Completion", enable: true },
          { field: "overallScore", header: "Overall Score", enable: true },
          { field: "report", header: "Report", enable: true },
          { field: "status", header: "Status", enable: true },
          {
            field: "role_level",
            header: res.data.headers["grade"],
            enable: true,
          },
          { field: "userId", header: res.data.headers["userId"], enable: true },
          { field: "Emailid", header: res.data.headers["email"], enable: true },
          { field: "Phone", header: res.data.headers["phone"], enable: true },
          {
            field: "dept",
            header: res.data.headers["department"],
            enable: true,
          },
          { field: "loc", header: res.data.headers["location"], enable: true },
          { field: "sec", header: res.data.headers["section"], enable: true },
          {
            field: "QuestionsAnswered",
            header: "Questions Answered",
            enable: true,
          },
          { field: "created_on", header: "Created On", enable: true },
          { field: "last_logged", header: "Last Logged in", enable: true },
          { field: "LastUpdated", header: "Last Answered on", enable: true },
          {
            field: "manager",
            header: res.data.headers["managerName"],
            enable: true,
          },
          { field: "hiring_decision", header: "Hiring Decision", enable: true },
        ];
        if (res.data.status_filters) {
          const status_filters = res.data.status_filters;
          // this.setState(
          //   {
          //     startdate: status_filters["startdate"],
          //     enddate: status_filters["enddate"],
          //     status: status_filters["status"],
          //     dept: status_filters["dept"],
          //     loc: status_filters["loc"],
          //     sec: status_filters["sec"],
          //     grade: status_filters["grade"],
          //     scoreLevel: status_filters["scoreLevel"],
          //   },
          //   () => this.filtersData(false)
          // );
          if (status_filters["columns"]) {
            columns = status_filters["columns"];
          }
        }
        if (res.data.rec_ver) {
          col = columns;
        } else {
          col = columns.filter((c) => !["Report", "Hiring Decision", "Overall Score"].includes(c.header));
        }
        let selectedColumns = col.filter((cl) => cl.enable);
        this.setState({ columns: col, selectedColumns });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    const data = { org: organisation };
    api
      .post("api/cce/get_org_data", data)
      .then((res) => {
        this.setState({
          levels: res.data.levels,
          departmentsList: res.data.department,
          lang_options: res.data.lang_options,
          userIdMapping: res.data.userIdMapping,
          org: res.data.org,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exportFile = () => {
    const { headers } = this.state;
    var fname = "Employee Status";
    var expfname = "EmployeeStatus.xls";
    var sheet_headers = [
      headers["employeeNumber"],
      "Name",
      headers["email"],
      headers["phone"],
      "Completion",
      "QuestionsAnswered",
      headers["department"],
      headers["grade"],
      headers["managerName"],
      "LastUpdated",
    ];
    /* convert from workbook to array of arrays */
    var data = this.state.empStat.map((emp) => [
      emp.EmpId,
      emp.Name,
      emp.Emailid,
      emp.Phone,
      emp.Completion,
      emp.QuestionsAnswered,
      emp.dept,
      emp.role_level,
      emp.manager,
      emp.LastUpdated,
    ]);
    excelexport({ fname, expfname, sheet_headers, data });
  };
  filedownload = (url) => {
    // console.log(url);
    // window.open(url, "_blank")
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  bio_key = (rowData) => {
    return (
      <Button
        disabled={!(rowData.bio_key && rowData.bio_key.length > 0)}
        label="Download"
        className="p-button-warning"
        onClick={() => this.filedownload(rowData.bio_key)}
      />
    );
  };
  testresult = () => {
    const { selectedemps } = this.state;
    if (selectedemps.length > 0) {
      let t = 1000;
      selectedemps.map((se) => {
        if (se.bio_key && se.bio_key.length > 0) {
          setTimeout(() => {
            this.filedownload(se.bio_key);
          }, t);
          t = t + 1000;
        } else {
          setTimeout(() => {
            this.growl.show({
              severity: "info",
              detail: "The selected user " + se.Name + " has not completed the test",
            });
          }, t);
        }
      });
    } else {
      this.growl.show({
        severity: "info",
        detail: "Select at least one user",
      });
    }
  };
  activeDelete = (value) => {
    const { selectedemps, empStat } = this.state;
    if (selectedemps.length > 0) {
      let emps = [];
      selectedemps.map((se) => {
        emps.push(se.empIdNo);
      });
      api
        .post("/api/cce/activeDelete", {
          emps: emps,
          value: value,
        })
        .then((res) => {
          if (res.data.result) {
            empStat.map((emp) => {
              if (res.data.success.includes(emp.empIdNo)) {
                emp.status = value ? "Deactivated" : "Active";
              }
            });
            if (res.data.failed.length > 0) {
              this.growl.show({
                severity: "info",
                detail: "Action not applied to user(s): " + res.data.failed,
              });
            }
            this.setState({ empStat, selectedemps: [] });
          }
        })
        .catch(() => {
          this.growl.show({
            severity: "error",
            detail: "Something went wrong",
          });
        });
    } else {
      this.growl.show({
        severity: "info",
        detail: "Select at least one user",
      });
    }
  };

  groupResult = () => {
    const { selectedemps } = this.state;
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    if (selectedemps.length > 0) {
      api
        .post("/api/cce/employeeSoftskill_group", {
          emps: selectedemps,
          org: organisation,
        })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.filedownload(res.data.data);
          } else {
            this.growl.show({
              severity: "error",
              detail: "Something went wrong",
            });
          }
        })
        .catch(() => {});
    } else {
      this.growl.show({
        severity: "info",
        detail: "Select at least one user",
      });
    }
  };

  filtersData = (apply) => {
    let { Parent_empStat, empStat, startdate, enddate, status, dept, loc, sec, grade, scoreLevel } = this.state;
    if (startdate) {
      Parent_empStat = Parent_empStat.filter((emp) => moment(startdate).startOf("day") <= moment(emp.raw_created_on).startOf("day"));
    }
    if (enddate) {
      Parent_empStat = Parent_empStat.filter((emp) => moment(emp.raw_created_on).startOf("day") <= moment(enddate).startOf("day"));
    }
    if (dept) {
      if (dept.length > 0) {
        Parent_empStat = Parent_empStat.filter((emp) => dept.includes(emp.dept_id));
      }
    }
    if (sec) {
      if (sec.length > 0) {
        Parent_empStat = Parent_empStat.filter((emp) => sec.includes(emp.sec_id));
      }
    }
    if (loc) {
      if (loc.length > 0) {
        Parent_empStat = Parent_empStat.filter((emp) => loc.includes(emp.loc_id));
      }
    }
    if (grade) {
      if (grade.length > 0) {
        Parent_empStat = Parent_empStat.filter((emp) => grade.includes(emp.grade_id));
      }
    }
    if (status) {
      if (status.length > 0) {
        if (status !== "All") {
          Parent_empStat = Parent_empStat.filter((emp) => emp.status === status);
        }
      }
    }
    if (scoreLevel) {
      if (scoreLevel.length > 0) {
        Parent_empStat = Parent_empStat.filter((emp) => emp.overallScore >= scoreLevel);
      }
    }
    this.setState({ empStat: Parent_empStat });
    // if (apply) {
    //   this.saveFilters();
    // }
  };

  saveFilters = (selectedColumns) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let { columns } = this.state;
    let selColList = [];
    selectedColumns.map((selC) => {
      selColList.push(selC.field);
    });
    columns.map((col) => (selColList.includes(col.field) ? (col["enable"] = true) : (col["enable"] = false)));
    const filters = { columns };
    const data = { organisation, filters };
    api.post("/api/cce/save_status_filters", data).then((res) => {
      if (res.data.results) {
        this.setState({ statusChanged: true });
      }
    });
  };

  onClickPwdReset = () => {
    this.setState({ visiblePwdReset: true });
  };
  onHide = () => {
    this.setState({
      visiblePwdReset: false,
      cpaswd: "",
      paswd: "",
      showing: false,
      hidden: true,
    });
  };
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  reset = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    // const isnum = /^\d+$/;

    if (this.state.cpaswd === this.state.paswd) {
      // if (this.state.paswd.toString().length < 8) {
      //   this.growl.show({
      //     severity: "error",
      //     summary:
      //       "This password is too short. It must contain at least 8 characters",
      //   });
      // } else if (isnum.test(this.state.paswd)) {
      //   this.growl.show({
      //     severity: "error",
      //     summary: "This password is entirely numeric",
      //   });
      // } else {
      api
        .post("api/cce/pwd_reset_sel", {
          pwd: this.state.paswd,
          checkedRows: this.state.selectedemps,
          organisation: organisation,
        })
        .then((res) => {
          if (res.data.results === true) {
            this.setState(
              {
                selectedemps: [],
                cpaswd: "",
                paswd: "",
              },
              () =>
                this.growl.show({
                  severity: "success",
                  summary: "Password reset successful",
                })
            );
          }
        });

      this.setState({ visiblePwdReset: false });
      // }
    } else {
      this.setState({ showing: true });
    }
  };

  // onColumnToggle = (val) => {
  //   const { columns } = this.state;
  //   let selCol = columns;
  //   if (val) {
  //     selCol.map((sCol) => (sCol.field === val.field ? (sCol.enable = !sCol.enable) : (selCol.enable = selCol.enable)));
  //   } else {
  //     selCol.map((sCol) => (sCol.enable = true));
  //   }
  //   this.setState({ columns: selCol });
  //   this.saveFilters();
  // };

  hiring_decision = (e, rowData, props) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const { empStat } = this.state;
    const data = { empIdNo: rowData.empIdNo, val: e.value, org: organisation };
    api
      .post("api/cce/hiring_decision", data)
      .then((res) => {
        if (res.data.results) {
          const index = empStat.findIndex((emp) => emp.empIdNo === rowData.empIdNo);
          empStat[index].hiring_decision = e.value;
          this.setState({ empStat });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  hiringBody = (rowData, props) => {
    return (
      <InputSwitch
        style={{ margin: 3 }}
        disabled={!rowData.overallScore}
        checked={rowData && rowData.hiring_decision}
        onChange={(e) => this.hiring_decision(e, rowData, props)}
      />
    );
  };

  onColumnToggle = (event) => {
    const { columns } = this.state;
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
    this.setState({ selectedColumns: orderedSelectedColumns });
    this.saveFilters(orderedSelectedColumns);
  };
  send_reg_mail = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let datasending = {
      emps: this.state.selectedemps,
      org: organisation,
    };
    api
      .post("api/cce/send_reg_mail_link", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.status,
            life: 10000,
          });
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.status,
            life: 10000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          detail: "Something went wrong",
          life: 10000,
        });
      });
  };

  copy_mail_link = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let datasending = {
      username: this.state.selectedemps[0].username,
      org: organisation,
    };
    api
      .post("api/cce/copy_mail_link", datasending)
      .then(async (res) => {
        let ret = false;
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          const email_link = res.data.email_link;
          if (email_link) {
            ret = copy(email_link);
            this.growl.show({
              severity: "success",
              detail: "Copying to clipboard.",
              life: 10000,
            });
          }
          // if ("clipboard" in navigator) {
          //   // ret = copyPageUrl(email_link);
          //   // ret = navigator.clipboard.writeText(email_link);
          // } else {
          //   ret = document.execCommand("copy", true, email_link);
          // }
          return email_link;
        } else {
          this.growl.show({
            severity: "error",
            detail: "Failed to copy link:" + res.data,
            life: 10000,
          });
        }
      })
      .catch((err) => {
        console.log("error:", err);
        this.growl.show({
          severity: "error",
          detail: "Failed to copy link" + err,
          life: 10000,
        });
      });
    return "";
  };

  statusReport = () => {
    if (this.state.statusChanged) {
      this.setState({ statusReportBtn: true });
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
            });
      }
      api
        .post("/api/cce/rawresults_stats", {
          organisation: organisation,
          type: this.props.type,
          user: this.props.user,
        })
        .then((res) => {
          this.setState({
            link: res.data.link,
            statusReportBtn: false,
            statusChanged: false,
          });
          this.filedownload(res.data.link);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      this.filedownload(this.state.link);
    }
  };

  handleUserUpdate = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const { first_name, last_name, mobile, email, grade, gender, headers, department, userConfig, native_language, editUserDialog, editUserDetails } =
      this.state;
    let errors = false;
    if (userConfig?.firstName?.field_include && userConfig?.firstName?.mandatory) {
      if (!first_name) {
        errors = true;
      }
    }
    if (userConfig?.lastName?.field_include && userConfig?.lastName?.mandatory) {
      if (!last_name) {
        errors = true;
      }
    }
    if (userConfig?.phone?.field_include && userConfig?.phone?.mandatory) {
      if (!mobile) {
        errors = true;
      }
    }
    if (userConfig?.email?.field_include && userConfig?.email?.mandatory) {
      if (!email) {
        errors = true;
      }
    }
    if (userConfig?.grade?.field_include && userConfig?.grade?.mandatory) {
      if (!grade) {
        errors = true;
      }
    }
    if (userConfig?.gender?.field_include && userConfig?.gender?.mandatory) {
      if (!gender) {
        errors = true;
      }
    }
    if (userConfig?.department?.field_include && userConfig?.department?.mandatory) {
      if (!department) {
        errors = true;
      }
    }
    if (userConfig?.nativeLanguage?.field_include && userConfig?.nativeLanguage?.mandatory) {
      if (!native_language) {
        errors = true;
      }
    }
    if (!errors) {
      let datasending = {
        data: [
          {
            [headers.action]: "U",
            [headers.firstName]: first_name,
            [headers.lastName]: last_name,
            [headers.email]: email,
            [headers.phone]: mobile,
            [headers.gender]: gender,
            [headers.grade]: grade,
            [headers.agencyName]: editUserDetails.agencyName,
            [headers.band]: editUserDetails.band,
            [headers.company]: organisation["account_name"],
            [headers.dateOfBirth]: editUserDetails.date_of_birth,
            [headers.dateOfJoining]: editUserDetails.date_of_joining,
            [headers.dateOfLeaving]: editUserDetails.date_of_leaving,
            [headers.department]: department,
            [headers.designation]: editUserDetails.designation,
            [headers.employeeNumber]: editUserDetails.employee_number,
            [headers.functionalHead]: editUserDetails.functional_head,
            [headers.gateCardNumber]: editUserDetails.gate_card_number,
            [headers.hrEmployeeNumber]: editUserDetails.reporting_hr_employee_number,
            [headers.hrName]: editUserDetails.hrName,
            [headers.location]: editUserDetails.location,
            [headers.managerEmployeeNumber]: editUserDetails.reportingManager_employeeNumber,
            [headers.managerName]: editUserDetails.managerName,
            [headers.maritalStatus]: editUserDetails.marital_status,
            [headers.nativeLanguage]: native_language,
            [headers.password]: "",
            [headers.poNumber]: editUserDetails.ponumber,
            [headers.section]: editUserDetails.section,
            [headers.skillTypes]: editUserDetails.skillTypes,
            [headers.subSection]: editUserDetails.subsection,
            [headers.subZone]: editUserDetails.subzone,
            [headers.title]: editUserDetails.title,
            [headers.userId]: editUserDetails.user_id,
            uiuserId: editUserDetails.user_id,
            [headers.zone]: editUserDetails.zone,
          },
        ],
        org: [organisation.name],
        name: "Single User Upload",
        editFromUI: true,
      };
      this.setState({ loading: true, loader: true });
      api.post("api/organisation/employees", datasending).then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.msg,
            life: 10000,
          });
          this.fetchData();
          this.setState({
            editUserDialog: false,
            editUserDetails: null,
          });
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.msg,
            life: 10000,
          });
        }
      });
    } else {
      this.growl.show({
        severity: "error",
        detail: "Enter all required fields",
      });
    }
  };

  handleEmployeeSearch = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    this.setState({ loading: true, loader: true });
    const datasending = { employeeNumber: this.state.selectedemps[0].EmpId, org: organisation };
    api
      .post("api/cce/employee_search", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          const employee = res.data.employee;
          this.setState({
            editUserDetails: employee,
            employeeNumberSearch: "",
            first_name: employee.first_name,
            last_name: employee.last_name,
            mobile: employee.phone_number,
            email: employee.email,
            grade: employee.grade_desc,
            department: employee.dept_id,
            native_language: employee.language_preference,
            gender: employee.gender,
            editUserDialog: true,
          });
        } else {
          this.growl.show({
            severity: "error",
            detail: "Employee not found",
            life: 10000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          detail: "Something went wrong",
          life: 10000,
        });
      });
  };

  render() {
    // console.log(this.props.menuProps.label);
    const {
      empStat,
      globalFilter,
      loading,
      visiblePwdReset,
      cpaswd,
      hover_text,
      paswd,
      showing,
      hidden,
      columns,
      headers,
      userConfig,
      first_name,
      last_name,
      mobile,
      email,
      grade,
      department,
      native_language,
      gender,
      levels,
      departmentsList,
      lang_options,
      userIdMapping,
      editUserDialog,
      editUserDetails,
      org,
    } = this.state;
    const statuses = [
      { label: "Active", value: "Active" },
      { label: "Deactived", value: "Deactivated" },
      { label: "All", value: "All" },
    ];
    var header = (
      <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <span>
              <InputText
                style={{ margin: "9px 0px" }}
                type="search"
                onInput={(e) => this.setState({ globalFilter: e.target.value, first: 0 })}
                placeholder="Search..."
                size="20"
              />
            </span>
            <div style={{ textAlign: "left" }}>
              <div style={{ textAlign: "left", fontWeight: 500 }}>select display fields:</div>
              <span>
                <MultiSelect
                  value={this.state.selectedColumns}
                  options={this.state.columns}
                  optionLabel="field"
                  onChange={this.onColumnToggle}
                  style={{ width: "200px" }}
                  placeholder="select display fields"
                />
              </span>
            </div>
          </div>
          <div>
            {this.state.rec_ver && (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.EditUser}</Tooltip>}>
                <div>
                  <Button
                    // icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
                    // iconPos="left"
                    label="Edit User"
                    style={{ margin: 9 }}
                    onClick={this.handleEmployeeSearch}
                    disabled={this.state.selectedemps.length !== 1}
                  />
                </div>
              </OverlayTrigger>
            )}
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bResPwd}</Tooltip>}>
              <div>
                <Button
                  icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
                  iconPos="left"
                  label="Reset Password"
                  style={{ margin: 9 }}
                  onClick={this.onClickPwdReset}
                  disabled={this.state.selectedemps.length !== 1}
                />
              </div>
            </OverlayTrigger>
          </div>
          <div>
            {this.state.rec_ver && (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bSendMail}</Tooltip>}>
                <div>
                  <Button
                    // icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
                    // iconPos="left"
                    label="Send Mail"
                    style={{ margin: 9 }}
                    onClick={this.send_reg_mail}
                    disabled={this.state.selectedemps.length === 0}
                  />
                </div>
              </OverlayTrigger>
            )}
            {this.state.rec_ver && (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bCpTLink}</Tooltip>}>
                <div>
                  <Button
                    // icon={this.state.pwdresetbtn ? "pi pi-spin pi-spinner" : ""}
                    // iconPos="left"
                    label="Copy Test Link"
                    style={{ margin: 9 }}
                    onClick={this.copy_mail_link}
                    disabled={this.state.selectedemps.length !== 1}
                  />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: 9,
              border: "1px solid blue",
              borderStyle: "dashed",
            }}
          >
            <div style={{ margin: 9, color: "blue" }}>Download</div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bStatRpt}</Tooltip>}>
                <span>
                  <Button
                    style={{ float: "right", margin: 9 }}
                    type="button"
                    icon={this.state.statusReportBtn ? "pi pi-spin pi-spinner" : ""}
                    iconPos="left"
                    label="Status Report"
                    onClick={() => this.statusReport()}
                  />
                </span>
              </OverlayTrigger>
              {this.state.rec_ver ? (
                <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bDetailRep}</Tooltip>}>
                  <span>
                    <Button
                      style={{ float: "right", margin: 9 }}
                      type="button"
                      icon="pi pi-external-link"
                      iconPos="left"
                      label="Test Results"
                      disabled={this.state.selectedemps.length === 0}
                      onClick={this.testresult}
                    />
                  </span>
                </OverlayTrigger>
              ) : null}
              {this.state.rec_ver ? (
                <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bGrpRes}</Tooltip>}>
                  <span>
                    <Button
                      style={{ float: "right", margin: 9 }}
                      type="button"
                      icon="pi pi-external-link"
                      iconPos="left"
                      label="Group Results"
                      disabled={this.state.selectedemps.length === 0}
                      onClick={this.groupResult}
                    />
                  </span>
                </OverlayTrigger>
              ) : null}
            </div>
          </div>
          <div>
            {this.state.rec_ver && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 9,
                  border: "1px solid blue",
                  borderStyle: "dashed",
                }}
              >
                <div style={{ margin: 9, color: "blue" }}>Status</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bActive}</Tooltip>}>
                    <span>
                      <Button
                        style={{ margin: 9 }}
                        type="button"
                        // icon="pi pi-external-link"
                        iconPos="right"
                        label="Activate"
                        disabled={
                          this.state.selectedemps.length === 0 || (this.state.selectedemps.length === 1 && this.state.selectedemps[0].is_logged_in)
                        }
                        onClick={() => this.activeDelete(false)}
                      />
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bDeactive}</Tooltip>}>
                    <span>
                      <Button
                        style={{ margin: 9 }}
                        type="button"
                        // icon="pi pi-external-link"
                        iconPos="right"
                        label="Deactivate"
                        disabled={
                          this.state.selectedemps.length === 0 || (this.state.selectedemps.length === 1 && this.state.selectedemps[0].is_logged_in)
                        }
                        onClick={() => this.activeDelete(true)}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div style={{ borderTop: "1px dashed #808080", paddingTop: 18, marginTop: 18 }}>
          <div style={{ margin: 9, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>Table Configuration</div>
            <Button
              label={"Reset"}
              // icon="pi pi-check"
              onClick={() => this.onColumnToggle()}
              className="p-button-warning"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {columns.map((col) => (
              <Button
                style={{ margin: 9 }}
                className={col.enable ? "p-button-primary" : "p-button-secondary"}
                key={col.field}
                label={col.header}
                // icon="pi pi-check"
                onClick={() => this.onColumnToggle(col)}
              />
            ))}
          </div>
        </div> */}
      </div>
    );

    const columnComponents = columns.map((col) => {
      if (col.header === "Overall Score" && col.enable) {
        if (this.state.rec_ver) {
          return (
            <Column
              key={col.field}
              field="overallScore"
              header={col.header}
              sortable={true}
              // style={{ width: "8%" }}
            />
          );
        }
        return;
      }
      if (col.field === "report" && col.enable) {
        if (this.state.rec_ver) {
          return (
            <Column
              key={col.field}
              body={this.bio_key}
              header={col.header}
              sortable={true}
              field="bio_key_status"
              // style={{ width: "8%" }}
            />
          );
        }
        return;
      }
      if (col.header === "Created On" && col.enable) {
        return (
          <Column
            key={col.field}
            field="created_on"
            header={col.header}
            sortable={true}
            // style={{ width: "8%" }}
          />
        );

        return;
      }
      if (col.field === "hiring_decision" && col.enable) {
        if (this.state.rec_ver) {
          return (
            <Column
              key={col.field}
              body={this.hiringBody}
              header={col.header}
              // sortable={true}
              // style={{ width: "8%" }}
            />
          );
        }
        return;
      }
      if (col.enable) {
        return <Column key={col.field} field={col.field} header={col.header} sortable={true} />;
      }
    });
    const genderOptions = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "female" },
      { label: "Others", value: "others" },
    ];

    return (
      <div className={this.props.typelogin ? "" : "layout-main"}>
        <Growl ref={(el) => (this.growl = el)} />
        {loading ? (
          <div className="loading-spinner-container">
            <ProgressSpinner></ProgressSpinner>
          </div>
        ) : (
          <div>
            <Dialog
              appendTo={document.body}
              modal={true}
              header="Password-Reset"
              visible={visiblePwdReset}
              style={{ width: "50vw" }}
              //footer={footer}
              onHide={this.onHide}
              maximizable
            >
              <div>
                <span>Password best practices</span>
                <ul>
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can't be too similar to your other personal information.</li>
                  <li>Your password can't be a commonly used password.</li>
                  <li>Your password can't be entirely numeric.</li>
                </ul>
                <div>
                  <div>Password :</div>

                  <InputText
                    style={{ width: "100%" }}
                    type={hidden ? "password" : "text"}
                    className="p-inputtext"
                    value={paswd}
                    onChange={(e) => this.setState({ paswd: e.target.value })}
                  />

                  <div style={{ marginTop: "10px" }}>Confirm Password :</div>
                  <InputText
                    style={{ width: "100%" }}
                    type={hidden ? "password" : "text"}
                    onChange={(e) => this.setState({ cpaswd: e.target.value })}
                    value={cpaswd}
                    className="p-inputtext"
                  />

                  <div className="p-grid">
                    <div className="p-col-12">
                      <a style={{ float: "right" }} onClick={this.toggleShow} className="showpwd">
                        <p style={{ cursor: "pointer", color: "#00a3b4" }} className="showpwd1">
                          <u>Show/Hide Password</u>
                        </p>
                      </a>

                      {showing ? <div style={{ color: "red" }}>Password doesnt match</div> : null}
                    </div>
                    <div className="p-col-12">
                      <div style={{ float: "right" }}>
                        <Button label="Reset" icon="pi pi-check" onClick={this.reset} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog
              style={{ maxHeight: "90%", overflow: "scroll", width: 300 }}
              appendTo={document.body}
              header={"Update User Details"}
              visible={this.state.editUserDialog}
              onHide={() =>
                this.setState({
                  editUserDialog: false,
                  editUserDetails: null,
                  first_name: "",
                  last_name: "",
                  mobile: "",
                  email: "",
                  grade: "",
                  gender: "",
                  department: "",
                  native_language: "",
                })
              }
            >
              <div>
                <div>
                  {userConfig?.firstName?.field_include && (
                    <div style={{ margin: "0px 9px" }}>
                      <div className={userConfig?.firstName.mandatory ? "formlabel" : ""}>{headers.firstName}</div>
                      <div>
                        <InputText
                          onChange={(e) => this.setState({ first_name: e.target.value })}
                          size="30"
                          placeholder="enter..."
                          value={first_name}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.lastName?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.lastName.mandatory ? "formlabel" : ""}>{headers.lastName}</div>
                      <div>
                        <InputText
                          onChange={(e) => this.setState({ last_name: e.target.value })}
                          size="30"
                          placeholder="enter..."
                          value={last_name}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.phone?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div>
                        <span className={userConfig?.phone.mandatory ? "formlabel" : ""}>{headers.phone}</span>{" "}
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>(only numbers)</span>
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <InputText
                          style={{ width: 300 }}
                          type="number"
                          onChange={(e) => this.setState({ mobile: e.target.value })}
                          placeholder="enter..."
                          value={mobile}
                          onKeyDown={this.changeMobile}
                          disabled={editUserDialog && userIdMapping === 4}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.email?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.email.mandatory ? "formlabel" : ""}>{headers.email}</div>
                      <div>
                        <InputText
                          disabled={editUserDialog && userIdMapping === 3}
                          onChange={(e) => this.setState({ email: e.target.value })}
                          size="30"
                          placeholder="enter..."
                          value={email}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.gender?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.gender.mandatory ? "formlabel" : ""}>{headers.gender}</div>
                      <div>
                        <Dropdown
                          value={gender}
                          options={genderOptions}
                          onChange={(e) => this.setState({ gender: e.target.value })}
                          placeholder="Select..."
                          style={{ width: 260 }}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.grade?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.grade.mandatory ? "formlabel" : ""}>{headers.grade}</div>
                      <div>
                        <Dropdown
                          value={grade}
                          options={levels}
                          onChange={(e) => this.setState({ grade: e.target.value })}
                          placeholder="Select..."
                          style={{ width: 260 }}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.department?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.department.mandatory ? "formlabel" : ""}>{headers.department}</div>
                      <div>
                        <Dropdown
                          value={department}
                          options={departmentsList}
                          onChange={(e) => this.setState({ department: e.target.value })}
                          placeholder="Select..."
                          style={{ width: 260 }}
                        />
                      </div>
                    </div>
                  )}
                  {userConfig?.nativeLanguage?.field_include && (
                    <div style={{ margin: 9 }}>
                      <div className={userConfig?.nativeLanguage.mandatory ? "formlabel" : ""}>{headers.nativeLanguage}</div>
                      <div>
                        <Dropdown
                          value={native_language}
                          options={lang_options}
                          onChange={(e) => this.setState({ native_language: e.target.value })}
                          placeholder="Select..."
                          style={{ width: 260 }}
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ margin: 9, textAlign: "center", marginTop: 60 }}>
                    <Button type="submit" onClick={this.handleUserUpdate} className="p-button-warning" label={"Update"}></Button>
                  </div>
                </div>
              </div>
            </Dialog>
            <Panel
              header={
                <div>
                  <div>
                    Filters
                    <span>
                      <Button type="button" label="Apply" onClick={() => this.filtersData(true)} style={{ margin: 9 }} />
                    </span>
                    <span>
                      <Button
                        type="button"
                        label="Reset"
                        onClick={() => {
                          this.setState(
                            {
                              empStat: this.state.Parent_empStat,
                              startdate: "",
                              enddate: "",
                              startdate_val: false,
                              enddate_val: "",
                              status: "",
                              dept: null,
                              loc: null,
                              sec: null,
                              grade: null,
                              scoreLevel: "",
                            }
                            // () => this.saveFilters()
                          );
                        }}
                        style={{ marginRight: "15px" }}
                      />
                    </span>
                  </div>
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <div className="box-body box-body-cust-250">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 9,
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 9,
                        flexWrap: "wrap",
                      }}
                    >
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.fLastAns}</Tooltip>}>
                        <div style={{ marginRight: "15px" }}>
                          <span>Created On:</span>
                        </div>
                      </OverlayTrigger>
                      <div>
                        <Calendar
                          style={{ marginRight: "15px" }}
                          value={this.state.startdate}
                          onChange={(e) => this.setState({ startdate: e.value })}
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          placeholder={"From Date"}
                        />
                      </div>
                      <div>
                        <Calendar
                          style={{ marginRight: "15px" }}
                          value={this.state.enddate}
                          onChange={(e) => this.setState({ enddate: e.value })}
                          monthNavigator
                          yearNavigator
                          yearRange="2010:2030"
                          placeholder={"To Date"}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.fUStat}</Tooltip>}>
                        <span>User Status:</span>
                      </OverlayTrigger>
                      <Dropdown
                        style={{ margin: 9 }}
                        // optionLabel="Status"
                        value={this.state.status}
                        options={statuses}
                        onChange={(event) => {
                          this.setState({ status: event.value });
                        }}
                        placeholder="Select"
                      />
                    </div>
                    {userConfig && userConfig["location"]["field_include"] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>{headers["location"]}:</span>
                        <MultiSelect
                          style={{ margin: 9 }}
                          // optionLabel="Status"
                          value={this.state.loc}
                          options={this.state.filtersData["locations"]}
                          onChange={(event) => {
                            this.setState({ loc: event.value });
                          }}
                          placeholder="Select"
                        />
                      </div>
                    )}
                    {userConfig && userConfig["department"]["field_include"] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>{headers["department"]}:</span>
                        <MultiSelect
                          style={{ margin: 9 }}
                          // optionLabel="Status"
                          value={this.state.dept}
                          options={this.state.filtersData["departments"]}
                          onChange={(event) => {
                            this.setState({ dept: event.value });
                          }}
                          placeholder="Select"
                        />
                      </div>
                    )}
                    {userConfig && userConfig["section"]["field_include"] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>{headers["section"]}:</span>
                        <MultiSelect
                          style={{ margin: 9 }}
                          // optionLabel="Status"
                          value={this.state.sec}
                          options={this.state.filtersData["sections"]}
                          onChange={(event) => {
                            this.setState({ sec: event.value });
                          }}
                          placeholder="Select"
                        />
                      </div>
                    )}
                    {userConfig && userConfig["grade"]["field_include"] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.fGrade}</Tooltip>}>
                          <span> {headers["grade"]}:</span>
                        </OverlayTrigger>
                        <MultiSelect
                          style={{ margin: 9 }}
                          // optionLabel="Status"
                          value={this.state.grade}
                          options={this.state.filtersData["levels"]}
                          onChange={(event) => {
                            this.setState({ grade: event.value });
                          }}
                          placeholder="Select"
                        />
                      </div>
                    )}
                    {this.state.rec_ver && (
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.fScore}</Tooltip>}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <span>{"ATMA Score >=:"}</span>
                          <input
                            style={{ margin: 9, padding: 6 }}
                            type="number"
                            min={0}
                            onChange={(e) => this.setState({ scoreLevel: e.target.value })}
                            placeholder="Score"
                            size="20"
                            value={this.state.scoreLevel}
                          />
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header={"Status"}>
              <DataTable
                selection={this.state.selectedemps}
                onSelectionChange={(e) => this.setState({ selectedemps: e.value })}
                // scrollable
                // style={{ width: "100%" }}
                style={{
                  marginBottom: "20px",
                  width: "100%",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
                responsive={true}
                value={empStat}
                paginator={true}
                first={this.state.first}
                onPage={(e) => this.setState({ first: e.first, rows: e.rows })}
                header={header}
                globalFilter={globalFilter}
                emptyMessage={empStat.length === 0 && !loading ? "No data" : "Loading..."}
                rows={this.state.rows}
                resizableColumns={true}
                rowsPerPageOptions={[10, 20, 50, empStat ? empStat.length : ""]}
                className="scrolltable"
                ref={(el) => (this.dt = el)}
              >
                <Column
                  // sortable={true}
                  selectionMode="multiple"
                  // style={{ width: "4em" }}
                />
                <Column
                  body={(rowData, props) => props["rowIndex"] + 1}
                  header="S.No"
                  // sortable={true}
                  // style={{ width: "10%" }}
                />
                <Column
                  field="EmpId"
                  header={headers["employeeNumber"]}
                  sortable={true}
                  // style={{ width: "10%" }}
                />
                <Column
                  field="Name"
                  header="Name"
                  sortable={true}
                  // style={{ width: "10%" }}
                />

                {columnComponents}

                {/* <Column
                  field="dept"
                  header="Department"
                  sortable={true}
                  // style={{ width: "10%" }}
                /> */}
              </DataTable>
            </Panel>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organisation: state.util.organisation,
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(CceAdmin);
