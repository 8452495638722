import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import _, { toPairsIn } from "lodash";
import { Dialog } from "primereact/dialog";
import { authLogout } from "../../store/actions/auth";
import { InputSwitch } from "primereact/inputswitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
class CustConfig extends Component {
  state = {
    me_dash: false,
    pvsp: false,
    top_pot: false,
    org_ver: false,
    softskill_pdf: false,
    share_mng: "off",
    share_hr: false,
    enableLanguage: false,
    jobProfile: false,
    paid_version: false,
    lang_status: {
      hindi: false,
      tamil: false,
      telugu: false,
      kannada: false,
      malayalam: false,
    },
    grades: [],
    student_levels: [],
    train_pos: "",
    train_neg: "",
    train_filter: "",
    train_postive: true,
    train_negative: true,
    welcome_text_btn: true,
    training_filter_percentage: true,
    grades_list: [],
    grade_value: "",
    grade_id: "",
    ngrade_value: "",
    del_grade: "",
    del_grade_id: "",
    confirm_dialog: false,
    userCreateConfig: [],
    userCreateConfigMaster: [],
    mandatoryFields: [],
    edit: false,
    show_forgot_password: true,
    emp_num_auto_gen: false,
    userIdMapping: 1,
    userIdMappingOptions: [],
    userIdMappingMaster: 1,
    emp_num_auto_gen_master: false,
    send_info_mail: false,
    welcome_text: null,
    user_feedback: false,
    info_mail_config: "",
    send_mail_to: "",
    mail_to_user_body: "",
    mail_back_to_admin_body: "",
    mail_to_user_subject: "",
    mail_back_to_admin_subject: "",
    mail_to_user_body_btn: true,
    mail_back_to_admin_body_btn: true,
    mail_to_user_subject_btn: true,
    mail_back_to_admin_subject_btn: true,
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation) || !this.props.user_details.isSuperUser) this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  fetchData() {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { org: organisation };
    api
      .post("api/cce/cust_config", data)
      .then((res) => {
        this.setState({
          loading: false,
          info_mail_config: res.data.info_mail_config,
          send_mail_to: res.data.send_mail_to,
          me_dash: res.data.me_dash,
          pvsp: res.data.pvsp,
          top_pot: res.data.top_pot,
          org_ver: res.data.org_ver,
          softskill_pdf: res.data.softskill_pdf,
          share_hr: res.data.share_hr,
          share_mng: res.data.share_mng,
          enableLanguage: res.data.enableLanguage,
          lang_status: res.data.lang_status,
          paid_version: res.data.paid_version,
          grades: res.data.grades,
          student_levels: res.data.student_levels,
          jobProfile: res.data.jobProfile,
          train_pos: res.data.train_pos,
          train_neg: res.data.train_neg,
          train_filter: res.data.train_filter,
          grades_list: res.data.grades_list,
          show_forgot_password: res.data.show_forgot_password,
          userCreateConfig: res.data.userCreateConfig,
          userCreateConfigMaster: JSON.parse(JSON.stringify(res.data.userCreateConfig)),
          mandatoryFields: res.data.mandatoryFields,
          userIdMapping: res.data.userIdMapping,
          userIdMappingOptions: res.data.userIdMappingOptions,
          userIdMappingMaster: parseInt(res.data.userIdMapping),
          emp_num_auto_gen_master: toString(res.data.emp_num_auto_gen) === "true",
          send_info_mail: res.data.send_info_mail,
          user_feedback: res.data.user_feedback,
          emp_num_auto_gen: res.data.userIdMapping === 2 ? false : res.data.emp_num_auto_gen,
          emp_num_auto_gen_disable: res.data.userIdMapping === 2 ? true : false,
          welcome_text: res.data.welcome_text ? JSON.stringify(res.data.welcome_text) : "",
          mail_to_user_body: res.data.mail_to_user_body,
          mail_back_to_admin_body: res.data.mail_back_to_admin_body,
          mail_to_user_subject: res.data.mail_to_user_subject,
          mail_back_to_admin_subject: res.data.mail_back_to_admin_subject,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  exportConfig() {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    this.setState({ loading: true });
    const data = {
      info_mail_config: this.state.info_mail_config,
      send_mail_to: this.state.send_mail_to,
      me_dash: this.state.me_dash,
      pvsp: this.state.pvsp,
      top_pot: this.state.top_pot,
      org_ver: this.state.org_ver,
      softskill_pdf: this.state.softskill_pdf,
      share_hr: this.state.share_hr,
      share_mng: this.state.share_mng,
      enableLanguage: this.state.enableLanguage,
      lang_status: this.state.lang_status,
      paid_version: this.state.paid_version,
      grades: this.state.grades,
      student_levels: this.state.student_levels,
      jobProfile: this.state.jobProfile,
      train_pos: this.state.train_pos,
      train_neg: this.state.train_neg,
      train_filter: this.state.train_filter,
      grades_list: this.state.grades_list,
      show_forgot_password: this.state.show_forgot_password,
      userCreateConfig: this.state.userCreateConfig,
      mandatoryFields: this.state.mandatoryFields,
      userIdMapping: this.state.userIdMapping,
      userIdMappingOptions: this.state.userIdMappingOptions,
      send_info_mail: this.state.send_info_mail,
      user_feedback: this.state.user_feedback,
      emp_num_auto_gen: this.state.emp_num_auto_gen,
      welcome_text: this.state.welcome_text,
      mail_to_user_body: this.state.mail_to_user_body,
      mail_back_to_admin_body: this.state.mail_back_to_admin_body,
      mail_to_user_subject: this.state.mail_to_user_subject,
      mail_back_to_admin_subject: this.state.mail_back_to_admin_subject,
    };
    api
      .post("api/cce/cust_config_export", { org: organisation, data: data })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.results) {
          this.filedownload(res.data.link);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  filedownload = (url) => {
    // console.log(url);
    // window.open(url, "_blank")
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  me_dash = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { me_dash: e.value, org: organisation };
    api
      .post("api/cce/me_dash_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            me_dash: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  pvsp = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { pvsp: e.value, org: organisation };
    api
      .post("api/cce/pvsp_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            pvsp: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  top_pot = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { top_pot: e.value, org: organisation };
    api
      .post("api/cce/top_pot_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            top_pot: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  org_ver = (e, ver) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { org_ver: ver, org: organisation };
    api
      .post("api/cce/org_ver_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            org_ver: ver,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  info_mail_config = (config) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let data = null;
    let errors = [];
    if (config === "SEND_MAIL_TO_SPECIFIC") {
      const mailregex = /^([a-zA-Z0-9\.-]+)@([a-zA-Z0-9-]+).([a-zA-Z]{2,8})(.[a-zA-Z]{2,8})?$/;
      if (this.state.send_mail_to) {
        if (!mailregex.test(this.state.send_mail_to)) {
          errors.push("err");
        }
      } else {
        errors.push("err");
      }
      data = { info_mail_config: config, org: organisation, send_mail_to: this.state.send_mail_to };
    } else {
      data = { info_mail_config: config, org: organisation };
    }
    if (errors.length === 0) {
      api
        .post("api/cce/info_mail_config", data)
        .then((res) => {
          if (res.data.results) {
            if (config !== "SEND_MAIL_TO_SPECIFIC") {
              this.setState({
                info_mail_config: config,
                send_mail_to: "",
              });
            }
          } else {
            this.growl.show({
              severity: "error",
              summary: "Something went wrong",
            });
          }
        })
        .catch((err) => {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
          console.log(err);
        });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Enter valid mail address",
      });
    }
  };

  jobProfile = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { jobProfile: e.value, org: organisation };
    api
      .post("api/cce/jobProfile_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            jobProfile: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  softskill_pdf = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { softskill_pdf: e.value, org: organisation };
    api
      .post("api/cce/softskill_pdf_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            softskill_pdf: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  share_mng = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { share_mng: e.value, org: organisation };
    api
      .post("api/cce/share_mng_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            share_mng: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  share_hr = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { share_hr: e.value, org: organisation };
    api
      .post("api/cce/share_hr_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            share_hr: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  enableLanguage = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { enableLanguage: e.value, org: organisation };
    api
      .post("api/cce/enableLanguage_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            enableLanguage: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  showforgotpassword = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { show_forgot_password: e.value, org: organisation };
    api
      .post("api/cce/show_forgot_password", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            show_forgot_password: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  sendInfoMail = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { send_info_mail: e.value, org: organisation };
    api
      .post("api/cce/send_info_mail", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            send_info_mail: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  user_feedback = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { user_feedback: e.value, org: organisation };
    api
      .post("api/cce/user_feedback", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            user_feedback: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  paid_version = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { paid_version: e.value, org: organisation };
    api
      .post("api/cce/paid_version_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            paid_version: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  selectLanguage = (e, lang) => {
    let { lang_status } = this.state;
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    lang_status[lang] = e.value;

    const data = { lang_status: lang_status, org: organisation };
    api
      .post("api/cce/selectLanguage_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            lang_status: lang_status,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  selectGrade = (e, grade) => {
    let { student_levels, grades } = this.state;
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    if (student_levels.includes(grade)) {
      student_levels = student_levels.filter((sl) => sl !== grade);
    } else {
      student_levels.push(grade);
    }

    const data = { student_levels: student_levels, org: organisation };
    api
      .post("api/cce/selectGrade_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            student_levels: student_levels,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  train = (type, value) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { value, type, org: organisation };
    api
      .post("api/cce/tainingvalues", data)
      .then((res) => {
        if (res.data.results) {
          this.growl.show({
            severity: "success",
            summary: "Saved",
          });
          this.setState({ [type]: true });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  saveGrade = (grade_id, grade_value) => {
    if (this.state.grade_value && this.state.grade_value.length > 0 && grade_value !== this.state.grade_value) {
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
            });
      }
      const data = { grade_id: grade_id, grade_value: this.state.grade_value, org: organisation };
      api
        .post("api/cce/save-grade", data)
        .then((res) => {
          if (res.data.results) {
            if (res.data.exist) {
              this.growl.show({
                severity: "error",
                summary: "Grade already exist",
              });
            } else {
              this.setState({ grades_list: res.data.grades_list, grade_id: "", grade_value: "" });
            }
          }
        })
        .catch((error) => {});
    }
  };

  toggleGrade = (grade_id, is_active) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { grade_id: grade_id, is_active: !is_active, org: organisation };
    api
      .post("api/cce/toggle-grade", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({ grades_list: res.data.grades_list, grade_id: "", grade_value: "" });
        }
      })
      .catch((error) => {});
  };

  welcomeText = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { welcome_text: this.state.welcome_text, org: organisation };
    api
      .post("api/cce/welcome_text", data)
      .then((res) => {
        if (res.data.results) {
          this.growl.show({
            severity: "success",
            summary: "Saved!",
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: res.data.msg,
          });
        }
      })
      .catch((error) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
      });
  };

  update_mail = (key) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { value: this.state[key], key: key, org: organisation };
    api
      .post("api/cce/update_mail", data)
      .then((res) => {
        if (res.data.results) {
          this.growl.show({
            severity: "success",
            summary: "Saved!",
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: res.data.msg,
          });
        }
      })
      .catch((error) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
      });
  };

  createGrade = () => {
    if (this.state.ngrade_value && this.state.ngrade_value.length > 0) {
      let { organisation } = [];
      {
        this.props.user_details.isSuperUser
          ? (organisation = this.props.organisation)
          : (organisation = {
              id: this.props.user_details.organisation_id,
              name: this.props.user_details.organisation["account_name"],
            });
      }
      const data = { grade_id: "", grade_value: this.state.ngrade_value, org: organisation };
      api
        .post("api/cce/create-grade", data)
        .then((res) => {
          if (res.data.results) {
            if (res.data.exist) {
              this.growl.show({
                severity: "error",
                summary: "Grade already exist",
              });
            } else {
              this.setState({ grades_list: res.data.grades_list, grade_id: "", grade_value: "", ngrade_value: "" });
            }
          }
        })
        .catch((error) => {});
    }
  };
  deleteGrade = (grade_id) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { grade_id: grade_id, grade_value: this.state.grade_value, org: organisation };
    api
      .post("api/cce/delete-grade", data)
      .then((res) => {
        this.setState({ confirm_dialog: false });
        if (res.data.results) {
          if (res.data.deleted) {
            this.setState({
              grades_list: res.data.grades_list,
              grade_id: "",
              grade_value: "",
              del_grade: "",
              del_grade_id: "",
              confirm_dialog: false,
            });
          } else {
            this.growl.show({
              severity: "error",
              summary: res.data.emp_count + " Users exists in the level",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ confirm_dialog: false });
      });
  };
  onEditorValueChange(props, value, parse) {
    let userCreateConfig = [...props.value];
    userCreateConfig[props.rowIndex][props.field] = parse ? value.split(",") : value;
    this.setState({ userCreateConfig });
  }
  editField(rowData, props, textbox, size, parse) {
    let value = rowData[props["field"]];
    const { mandatoryFields, edit, userIdMapping } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        {textbox ? (
          edit ? (
            <InputText
              style={{
                border: value.length === 0 && rowData["field_include"] && props["field"] !== "allowed_values" ? "1px solid red" : "1px solid #a6a6a6",
              }}
              disabled={props["field"] === "field_name" || (rowData["field_name"] === "userId" && parseInt(userIdMapping) !== 1)}
              onChange={(e) => this.onEditorValueChange(props, e.target.value, parse)}
              size={size}
              placeholder="value..."
              value={value}
            />
          ) : (
            <div>{props["field"] === "allowed_values" ? value.join(", ") : value}</div>
          )
        ) : (
          <InputSwitch
            disabled={
              edit
                ? (mandatoryFields.includes(rowData["field_name"]) && (props["field"] === "mandatory" || props["field"] === "field_include")) ||
                  (rowData["field_name"] === "employeeNumber" && parseInt(userIdMapping) === 2) ||
                  (rowData["field_name"] === "email" && parseInt(userIdMapping) === 3) ||
                  (rowData["field_name"] === "phone" && parseInt(userIdMapping) === 4)
                : true
            }
            checked={value}
            size="2"
            onChange={(e) => this.onEditorValueChange(props, e.value)}
          />
        )}
      </div>
    );
  }
  saveuserCreate = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const { userCreateConfig, userIdMapping, emp_num_auto_gen } = this.state;
    let valid = true;
    userCreateConfig.map((uc) => {
      if (uc.field_include && (!uc.display_name || !uc.max_length || !uc.min_length)) {
        valid = false;
      }
    });
    if (valid) {
      const data = { userCreateConfig, userIdMapping, emp_num_auto_gen, org: organisation };
      api
        .post("api/cce/save-userCreate", data)
        .then((res) => {
          if (res.data.results) {
            this.growl.show({
              severity: "success",
              summary: "Saved Successfully",
            });
            this.setState({
              userCreateConfig: res.data.userCreateConfig,
              userCreateConfigMaster: JSON.parse(JSON.stringify(res.data.userCreateConfig)),
              userIdMapping: res.data.userIdMapping,
              userIdMappingMaster: parseInt(res.data.userIdMapping),
              emp_num_auto_gen_master: toString(res.data.emp_num_auto_gen) === "true",
              edit: false,
              emp_num_auto_gen: res.data.userIdMapping === 2 ? false : res.data.emp_num_auto_gen,
              emp_num_auto_gen_disable: res.data.userIdMapping === 2 ? true : false,
            });
          } else {
            this.growl.show({
              severity: "error",
              summary: "Something Went Wrong",
            });
          }
        })
        .catch((error) => {
          this.growl.show({
            severity: "error",
            summary: "Something Went Wrong",
          });
        });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Fill fields in red",
      });
    }
  };

  userMapChanges = (e) => {
    const { emp_num_auto_gen_master, userCreateConfig } = this.state;
    this.setState({
      userIdMapping: e.value,
      emp_num_auto_gen: e.value === 2 ? false : emp_num_auto_gen_master,
      emp_num_auto_gen_disable: e.value === 2 ? true : false,
    });
    userCreateConfig.map((ucc) => {
      if (e.value === 2 && ucc.field_name === "employeeNumber") {
        ucc.mandatory = true;
        ucc.field_include = true;
      } else if (e.value === 3 && ucc.field_name === "email") {
        ucc.mandatory = true;
        ucc.field_include = true;
      } else if (e.value === 4 && ucc.field_name === "phone") {
        ucc.mandatory = true;
        ucc.field_include = true;
      }
    });
    this.setState({ userCreateConfig });
  };

  render() {
    const {
      train_pos,
      edit,
      train_neg,
      train_filter,
      grades_list,
      grade_value,
      grade_id,
      ngrade_value,
      userCreateConfig,
      userCreateConfigMaster,
      userIdMapping,
      userIdMappingOptions,
      userIdMappingMaster,
      emp_num_auto_gen_master,
      emp_num_auto_gen,
      emp_num_auto_gen_disable,
      welcome_text,
      mail_to_user_body,
      mail_back_to_admin_body,
      mail_to_user_subject,
      mail_back_to_admin_subject,
    } = this.state;
    let userIdMappedFilter = userIdMappingOptions.filter((um) => um.value === userIdMapping);
    let userIdMapped = userIdMappedFilter && userIdMappedFilter.length > 0 ? userIdMappedFilter[0]["label"] : "CUSTOM";
    let header = <div>User Create Configuration</div>;
    let header2 = (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {edit ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Emp Num Auto Gen: </span>
            No
            <InputSwitch
              disabled={emp_num_auto_gen_disable}
              style={{ marginLeft: 5, marginRight: 5 }}
              checked={emp_num_auto_gen}
              onChange={(e) => {
                this.setState({
                  emp_num_auto_gen: e.value,
                });
              }}
            />{" "}
            Yes
          </div>
        ) : (
          <div>Emp Num Auto Gen: {emp_num_auto_gen ? "YES" : "NO"}</div>
        )}
        {edit ? (
          <div style={{ margin: 18, display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>User Id Map:</span>
            <Dropdown value={userIdMapping} options={userIdMappingOptions} onChange={this.userMapChanges} placeholder="User ID Mapping..." />
          </div>
        ) : (
          <div>User Id Map: {userIdMapped} </div>
        )}
        <div>
          <Button
            className="button-excel"
            type="button"
            // icon="pi pi-external-link"
            iconPos="left"
            label={edit ? "Save" : "Edit"}
            onClick={
              edit
                ? this.saveuserCreate
                : () => {
                    this.setState({ edit: true });
                  }
            }
          />
          {edit && (
            <Button
              className="button-excel"
              type="button"
              // icon="pi pi-external-link"
              iconPos="left"
              label={"Cancel"}
              onClick={() =>
                this.setState({
                  edit: false,
                  userCreateConfig: JSON.parse(JSON.stringify(userCreateConfigMaster)),
                  userIdMapping: parseInt(userIdMappingMaster),
                  emp_num_auto_gen: toString(emp_num_auto_gen_master) === "true",
                })
              }
              style={{ marginLeft: 9 }}
            />
          )}
        </div>
      </div>
    );
    let panelHeader = (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 50 }}>
        <div>{this.props.menuProps.label}</div>
        <div>
          <Button
            className="button-excel"
            type="button"
            // icon="pi pi-external-link"
            iconPos="left"
            label="Export Configuration"
            onClick={() => this.exportConfig()}
          />
        </div>
      </div>
    );
    const sharemngoptions = [
      { label: "OFF", value: "off" },
      { label: "SHARE", value: "share" },
      { label: "SHARE ALL", value: "share_all" },
    ];
    return (
      <div className={this.props.typelogin ? "" : "layout-main"}>
        <Dialog
          className=""
          appendTo={document.body}
          header={"Confirmation"}
          visible={this.state.confirm_dialog}
          onHide={() => {
            this.setState({ confirm_dialog: false, del_grade: "", del_grade_id: "" });
          }}
          style={{ width: 500, overflow: "scroll" }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ paddingTop: 18 }}>Selected Grade : {this.state.del_grade}</div>
            <div>
              <Button
                style={{ margin: 18, backgroundColor: "red", border: "1px solid red" }}
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Cancel"
                onClick={() => {
                  this.setState({ confirm_dialog: false, del_grade: "", del_grade_id: "" });
                }}
              />
              <Button
                style={{ margin: 18 }}
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Delete"
                onClick={() => this.deleteGrade(this.state.del_grade_id)}
              />
            </div>
          </div>
        </Dialog>
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Panel header={panelHeader}>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Auto Calculate Me Dashboard:</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.me_dash} onChange={this.me_dash} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Display of 9 Grid Graph:</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.pvsp} onChange={this.pvsp} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Organisation Version: </span>
          </div>
          <div
            style={{
              margin: 9,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
              <span style={{ marginRight: 9, fontWeight: 700 }}>Enterprise Version:</span>
              No
              <InputSwitch
                style={{ marginLeft: 5, marginRight: 5 }}
                checked={this.state.org_ver === "EV"}
                onChange={(e) => this.org_ver(e, "EV")}
              />{" "}
              Yes
            </div>
            <div
              className="sel-lang"
              style={{ margin: 9, marginTop: 18, display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Institution Version:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.org_ver === "IV"}
                  onChange={(e) => this.org_ver(e, "IV")}
                />{" "}
                Yes
              </div>
              {this.state.org_ver === "IV" ? (
                <div style={{ display: "flex", alignItems: "center", marginTop: 9 }}>
                  <span style={{ marginRight: 9, fontWeight: 700 }}>Version Type:</span>
                  Trial
                  <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.paid_version} onChange={this.paid_version} /> Paid
                </div>
              ) : null}
            </div>
            <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
              <span style={{ marginRight: 9, fontWeight: 700 }}>Recruitment Version:</span>
              No
              <InputSwitch
                style={{ marginLeft: 5, marginRight: 5 }}
                checked={this.state.org_ver === "RV"}
                onChange={(e) => this.org_ver(e, "RV")}
              />{" "}
              Yes
            </div>
            <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
              <span style={{ marginRight: 9, fontWeight: 700 }}>Retail Version:</span>
              No
              <InputSwitch
                style={{ marginLeft: 5, marginRight: 5 }}
                checked={this.state.org_ver === "REV"}
                onChange={(e) => this.org_ver(e, "REV")}
              />{" "}
              Yes
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> jobProfile feature: </span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.jobProfile} onChange={this.jobProfile} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Enable softskill pdf feature: </span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.softskill_pdf} onChange={this.softskill_pdf} /> Yes
          </div>
          {this.state.softskill_pdf && (
            <div
              style={{
                margin: 9,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {this.state.grades.map((grade) => (
                <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                  <span style={{ marginRight: 9, fontWeight: 700 }}>{grade}:</span>
                  Professional
                  <InputSwitch
                    style={{ marginLeft: 5, marginRight: 5 }}
                    checked={this.state.student_levels.includes(grade)}
                    onChange={(e) => this.selectGrade(e, grade)}
                  />{" "}
                  Student
                </div>
              ))}
            </div>
          )}
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Enable top potential name list:</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.top_pot} onChange={this.top_pot} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Enable share:</span>
            <SelectButton
              style={{ marginLeft: 5, marginRight: 5 }}
              value={this.state.share_mng}
              options={sharemngoptions}
              onChange={this.share_mng}
            />
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Enable share with HR:</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.share_hr} onChange={this.share_hr} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Show Forgot Password</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.show_forgot_password} onChange={this.showforgotpassword} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Send Info Mail</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.send_info_mail} onChange={this.sendInfoMail} /> Yes
            {this.state.send_info_mail && (
              <div
                style={{
                  margin: 9,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                  <span style={{ marginRight: 9, fontWeight: 700 }}>Send Mail To Users:</span>
                  No
                  <InputSwitch
                    style={{ marginLeft: 5, marginRight: 5 }}
                    checked={this.state.info_mail_config && this.state.info_mail_config === "SEND_MAIL_TO_USERS"}
                    onChange={() => this.info_mail_config("SEND_MAIL_TO_USERS")}
                  />{" "}
                  Yes
                </div>
                <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18, display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 9, fontWeight: 700 }}>Send Mail To :</span>
                  No
                  <InputSwitch
                    style={{ marginLeft: 5, marginRight: 5 }}
                    checked={this.state.info_mail_config && this.state.info_mail_config === "SEND_MAIL_TO_SPECIFIC"}
                    onChange={() =>
                      this.setState({
                        info_mail_config: "SEND_MAIL_TO_SPECIFIC",
                      })
                    }
                  />
                  Yes
                  {this.state.info_mail_config === "SEND_MAIL_TO_SPECIFIC" && (
                    <InputText
                      style={{ marginLeft: "50px", marginRight: "50px" }}
                      onChange={(e) => this.setState({ send_mail_to: e.target.value })}
                      placeholder="value..."
                      size="30"
                      value={this.state.send_mail_to}
                    />
                  )}
                  {this.state.info_mail_config === "SEND_MAIL_TO_SPECIFIC" && (
                    <Button
                      className="button-excel"
                      type="button"
                      // icon="pi pi-external-link"
                      iconPos="left"
                      label="Save"
                      disabled={!this.state.send_mail_to}
                      onClick={() => this.info_mail_config("SEND_MAIL_TO_SPECIFIC")}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Enable User Feedback</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.user_feedback} onChange={this.user_feedback} /> Yes
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>Welcome Text</span>
            <InputTextarea
              rows={10}
              style={{ marginRight: "50px", width: 500 }}
              onChange={(e) => this.setState({ welcome_text: e.target.value, welcome_text_btn: false })}
              placeholder="value..."
              value={welcome_text || ""}
            />
            <div>
              <Button
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Save"
                disabled={this.state.welcome_text_btn}
                onClick={() => this.welcomeText()}
              />
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, fontWeight: 700 }}>
            Mail to User
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, marginLeft: 30 }}>
            <span style={{ marginRight: 9, fontWeight: 700, width: 100 }}>Subject</span>
            <InputTextarea
              rows={10}
              style={{ marginRight: "50px", width: 500 }}
              onChange={(e) => this.setState({ mail_to_user_subject: e.target.value, mail_to_user_subject_btn: false })}
              placeholder="value..."
              value={mail_to_user_subject || ""}
            />
            <div>
              <Button
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Save"
                disabled={this.state.mail_to_user_subject_btn}
                onClick={() => this.update_mail("mail_to_user_subject")}
              />
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, marginLeft: 30 }}>
            <span style={{ marginRight: 9, fontWeight: 700, width: 100 }}>Body</span>
            <InputTextarea
              rows={10}
              style={{ marginRight: "50px", width: 500 }}
              onChange={(e) => this.setState({ mail_to_user_body: e.target.value, mail_to_user_body_btn: false })}
              placeholder="value..."
              value={mail_to_user_body || ""}
            />
            <div>
              <Button
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Save"
                disabled={this.state.mail_to_user_body_btn}
                onClick={() => this.update_mail("mail_to_user_body")}
              />
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, fontWeight: 700 }}>
            Mail to Admin/Manager
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, marginLeft: 30 }}>
            <span style={{ marginRight: 9, fontWeight: 700, width: 100 }}>Subject</span>
            <InputTextarea
              rows={10}
              style={{ marginRight: "50px", width: 500 }}
              onChange={(e) => this.setState({ mail_back_to_admin_subject: e.target.value, mail_back_to_admin_subject_btn: false })}
              placeholder="value..."
              value={mail_back_to_admin_subject || ""}
            />
            <div>
              <Button
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Save"
                disabled={this.state.mail_back_to_admin_subject_btn}
                onClick={() => this.update_mail("mail_back_to_admin_subject")}
              />
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18, marginLeft: 30 }}>
            <span style={{ marginRight: 9, fontWeight: 700, width: 100 }}>Body</span>
            <InputTextarea
              rows={10}
              style={{ marginRight: "50px", width: 500 }}
              onChange={(e) => this.setState({ mail_back_to_admin_body: e.target.value, mail_back_to_admin_body_btn: false })}
              placeholder="value..."
              value={mail_back_to_admin_body || ""}
            />
            <div>
              <Button
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Save"
                disabled={this.state.mail_back_to_admin_body_btn}
                onClick={() => this.update_mail("mail_back_to_admin_body")}
              />
            </div>
          </div>
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Enable Language Translation:</span>
            No
            <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.enableLanguage} onChange={this.enableLanguage} /> Yes
          </div>
          {this.state.enableLanguage && (
            <div
              style={{
                margin: 9,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Hindi:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.lang_status && this.state.lang_status.hindi}
                  onChange={(e) => this.selectLanguage(e, "hindi")}
                />{" "}
                Yes
              </div>
              <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Tamil:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.lang_status && this.state.lang_status.tamil}
                  onChange={(e) => this.selectLanguage(e, "tamil")}
                />{" "}
                Yes
              </div>
              <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Telugu:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.lang_status && this.state.lang_status.telugu}
                  onChange={(e) => this.selectLanguage(e, "telugu")}
                />{" "}
                Yes
              </div>
              <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Kannada:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.lang_status && this.state.lang_status.kannada}
                  onChange={(e) => this.selectLanguage(e, "kannada")}
                />{" "}
                Yes
              </div>
              <div className="p-grid sel-lang" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Malayalam:</span>
                No
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.lang_status && this.state.lang_status.malayalam}
                  onChange={(e) => this.selectLanguage(e, "malayalam")}
                />{" "}
                Yes
              </div>
            </div>
          )}
          <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}> Training Limits:</span>
          </div>
          <div className="p-grid" style={{ margin: 18, display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>For postive dimensions:</span>
            <InputText
              type="number"
              style={{ marginRight: "50px" }}
              onChange={(e) => this.setState({ train_pos: e.target.value, train_postive: false })}
              placeholder="value..."
              size="10"
              value={train_pos}
            />
            <Button
              className="button-excel"
              type="button"
              // icon="pi pi-external-link"
              iconPos="left"
              label="Save"
              disabled={this.state.train_postive}
              onClick={() => this.train("train_postive", train_pos)}
            />
          </div>
          <div className="p-grid" style={{ margin: 18, display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>For negative dimensions:</span>
            <InputText
              type="number"
              style={{ marginRight: "50px" }}
              onChange={(e) => this.setState({ train_neg: e.target.value, train_negative: false })}
              placeholder="value..."
              size="10"
              value={train_neg}
            />
            <Button
              className="button-excel"
              type="button"
              // icon="pi pi-external-link"
              iconPos="left"
              label="Save"
              disabled={this.state.train_negative}
              onClick={() => this.train("train_negative", train_neg)}
            />
          </div>
          <div className="p-grid" style={{ margin: 18, display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 9, fontWeight: 700 }}>For team filter:</span>
            <InputText
              type="number"
              style={{ marginRight: "50px" }}
              onChange={(e) => this.setState({ train_filter: e.target.value, training_filter_percentage: false })}
              placeholder="value..."
              size="10"
              value={train_filter}
            />
            <Button
              className="button-excel"
              type="button"
              // icon="pi pi-external-link"
              iconPos="left"
              label="Save"
              disabled={this.state.training_filter_percentage}
              onClick={() => this.train("training_filter_percentage", train_filter)}
            />
          </div>
          <Panel header="Grade Configuration" style={{ maxWidth: 350 }}>
            {grades_list &&
              grades_list.map((grade, i) => (
                <div key={grade.id}>
                  {grade.id === grade_id ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: 300,
                        padding: 9,
                        margin: 9,
                        border: grade.is_active ? "2px solid green" : "2px solid red",
                      }}
                    >
                      <InputText onChange={(e) => this.setState({ grade_value: e.target.value })} placeholder="value..." value={grade_value} />
                      <div>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Save</Tooltip>}>
                          <FontAwesomeIcon
                            style={{ margin: 9, cursor: "pointer", color: "green" }}
                            className=""
                            icon={["fas", "check-square"]}
                            onClick={() => this.saveGrade(grade.id, grade.value)}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Cancel</Tooltip>}>
                          <FontAwesomeIcon
                            style={{ margin: 9, cursor: "pointer", color: "red" }}
                            className=""
                            icon={["fas", "times"]}
                            onClick={() => this.setState({ grade_id: "", grade_value: "" })}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: 300,
                        padding: 9,
                        margin: 9,
                        border: grade.is_active ? "2px solid green" : "2px solid red",
                      }}
                    >
                      <div>{i + ". " + grade.name}</div>
                      <div>
                        {grade.is_active && (
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit</Tooltip>}>
                            <FontAwesomeIcon
                              style={{ margin: 9, cursor: "pointer", color: "blue" }}
                              className=""
                              icon={["fas", "edit"]}
                              onClick={() => this.setState({ grade_value: grade.value, grade_id: grade.id })}
                            />
                          </OverlayTrigger>
                        )}
                        {grade.is_active && (
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete</Tooltip>}>
                            <FontAwesomeIcon
                              style={{ margin: 9, cursor: "pointer", color: "red" }}
                              className=""
                              icon={["fas", "trash"]}
                              onClick={() => {
                                this.setState({ confirm_dialog: true, del_grade: grade.value, del_grade_id: grade.id });
                              }}
                            />
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{grade.is_active ? "Deactivate" : "Activate"}</Tooltip>}>
                          <FontAwesomeIcon
                            style={{
                              margin: 9,
                              marginRight: grade.is_active ? 9 : 21,
                              cursor: "pointer",
                              color: grade.is_active ? "orange" : "green",
                            }}
                            className=""
                            icon={grade.is_active ? ["fas", "ban"] : ["fas", "check-square"]}
                            onClick={() => this.toggleGrade(grade.id, grade.is_active)}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: 300,
                padding: 9,
                margin: 9,
                border: "2px solid green",
              }}
            >
              <InputText
                onChange={(e) => this.setState({ ngrade_value: e.target.value })}
                // size="25"
                placeholder="create new..."
                value={ngrade_value}
              />
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Create</Tooltip>}>
                <FontAwesomeIcon
                  style={{ margin: 9, marginRight: 21, cursor: "pointer", color: "green" }}
                  className=""
                  icon={["fas", "check-square"]}
                  onClick={() => this.createGrade()}
                />
              </OverlayTrigger>
            </div>
          </Panel>

          <Panel header={header}>
            <DataTable
              value={userCreateConfig}
              className="scrolltable"
              header={header2}
              style={{ marginBottom: "20px", width: "100%", whiteSpace: "nowrap", textAlign: "center" }}
              responsive={true}
              resizableColumns={true}
              reorderableColumns={true}
              onRowReorder={(e) => this.setState({ userCreateConfig: e.value })}
            >
              {edit && <Column rowReorder={true} style={{ width: "3em" }} />}
              <Column
                body={(rowData, props) => {
                  return props["rowIndex"] + 1;
                }}
                header="S.No"
              />
              <Column field="field_name" body={(rowData, props) => this.editField(rowData, props, true, 20, false)} header="Field Name" />
              <Column field="display_name" body={(rowData, props) => this.editField(rowData, props, true, 20, false)} header="Display Name" />
              <Column field="field_include" body={(rowData, props) => this.editField(rowData, props, false, 0, false)} header="Enable" />
              <Column field="mandatory" body={(rowData, props) => this.editField(rowData, props, false, 0, false)} header="Mandatory" />
              {/* <Column field="auto_generate" body={(rowData, props) => this.editField(rowData, props, false, 0, false)} header="Auto Generate" /> */}
              <Column field="min_length" body={(rowData, props) => this.editField(rowData, props, true, 3, false)} header="Min Length" />
              <Column field="max_length" body={(rowData, props) => this.editField(rowData, props, true, 3, false)} header="Max Length" />
              <Column field="allowed_values" body={(rowData, props) => this.editField(rowData, props, true, 20, true)} header="Allowed Values" />
            </DataTable>
          </Panel>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustConfig);
