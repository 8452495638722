import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import axios from "axios";
import nav from "../utils/Utils";
import atma_logo from "../assets/images/atma-brand-icon.png";

class PasswordReset extends React.Component {
  state = {
    passwordVisible1: false,
    passwordVisible2: false,
    password1: "",
    password2: "",
    password1Err: "",
    password2Err: "",
    uid: "",
    token: "",
    resetSuccessful: false,
  };

  componentDidMount() {
    const { uid = "", token = "" } = this.props.match.params;
    this.setState({ uid, token });
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (name === "password1" && value !== "") {
      this.setState({ password1Err: false });
    }
    if (name === "password2" && value !== "") {
      this.setState({ password2Err: false });
    }
  };

  showGrowl = (messageArr) => {
    messageArr.forEach((m) => {
      setTimeout(() => {
        this.growl.show(m);
      }, 1);
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { uid, token, password1, password2 } = this.state;
    if (password1.trim().length === 0 || password2.trim().length === 0) {
      if (password1.trim().length === 0) {
        this.setState({ password1Err: true });
        this.showGrowl([
          {
            severity: "error",
            detail: "Password is required",
          },
        ]);
      }
      if (password2.trim().length === 0) {
        this.setState({ password2Err: true });
        this.showGrowl([
          {
            severity: "error",
            detail: "Confirm password is required",
          },
        ]);
      }
    } else if (password1 !== password2) {
      this.setState({ password1Err: true, password2Err: true });
      this.showGrowl([
        {
          severity: "error",
          detail: "Password and confirm password should match",
        },
      ]);
    } else {
      this.setState({ password1Err: false, password2Err: false });
      axios
        .create({
          baseURL: process.env.REACT_APP_SERVER_URL,
        })
        .post("rest-auth/password/reset/confirm/", {
          uid,
          token,
          new_password1: password1,
          new_password2: password2,
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ resetSuccessful: true });
            this.showGrowl([
              {
                severity: "success",
                detail: "Password reset successful",
                closable: false,
              },
            ]);
          } else {
            this.showGrowl([
              {
                severity: "error",
                detail: "Retry by complying with Password characters requirement",
                closable: false,
              },
            ]);
          }
        })
        .catch((e) => {
          this.showGrowl([
            {
              severity: "error",
              detail: "Retry by complying with Password characters requirement",
              closable: false,
            },
          ]);
        });
    }
  };

  render() {
    const { password1, password2, password1Err, password2Err, passwordVisible1, passwordVisible2, resetSuccessful, loading = false } = this.state;
    return (
      <div className="layout-main-public">
        <Growl ref={(el) => (this.growl = el)} position="bottomright" style={{ marginRight: 20 }} />
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="p-grid">
            <div className="p-col-12">
              <div className="auto-center-container-login">
                <div className="login-header-message">
                  <img style={{ width: "50%", objectFit: "contain" }} src={atma_logo}></img>
                </div>
                {resetSuccessful ? (
                  <div className="forgot-password-message">
                    Your password has been set. You may go ahead and{" "}
                    <span onClick={() => nav("/login")} className="clickable-link">
                      sign in
                    </span>{" "}
                    now.
                  </div>
                ) : (
                  <div className="login-form-center-align">
                    <div className="p-inputgroup">
                      <InputText
                        className={"input-text " + (password1Err ? "p-error" : "")}
                        name="password1"
                        value={password1}
                        onChange={(e) => this.onInputChange(e)}
                        type={passwordVisible1 ? "text" : "password"}
                        placeholder="New password"
                      />
                      <span className="p-inputgroup-addon">
                        <i
                          onClick={() =>
                            this.setState({
                              passwordVisible1: !passwordVisible1,
                            })
                          }
                          className={passwordVisible1 ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                        ></i>
                      </span>
                    </div>
                    <div className="p-inputgroup">
                      <InputText
                        className={"input-text " + (password2Err ? "p-error" : "")}
                        name="password2"
                        value={password2}
                        onChange={(e) => this.onInputChange(e)}
                        type={passwordVisible2 ? "text" : "password"}
                        placeholder="Confirm password"
                      />
                      <span className="p-inputgroup-addon">
                        <i
                          onClick={() =>
                            this.setState({
                              passwordVisible2: !passwordVisible2,
                            })
                          }
                          className={passwordVisible2 ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                        ></i>
                      </span>
                    </div>
                    <div className="p-inputgroup">
                      <Button
                        type="submit"
                        disabled={loading}
                        icon={loading ? "pi pi-spin pi-spinner" : ""}
                        label={loading ? "" : "SUBMIT"}
                        className="login-button p-button-warning"
                      ></Button>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        color: "#808080",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        marginTop: 18,
                        marginLeft: 33,
                      }}
                    >
                      <div>Requirements:</div>
                      <ol>
                        <li>Minimum 8 letters are required</li>
                        <li>Atleast one smallcase letter is required</li>
                        <li>Atleast one uppercase letter is required</li>
                        <li>Atleast one numeric digit is required</li>
                      </ol>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PasswordReset;
