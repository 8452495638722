import React, { Component } from "react";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { ProgressBar } from "primereact/progressbar";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { getquestion, group_answers } from "../../store/actions/question";
import { orgSettings, isPaymentSuccess, setShowDetails } from "../../store/actions/auth";
import Groupquestion from "../questions/GroupQuestions";
import Ranking from "../questions/Ranking";
import OneInMany2 from "../questions/OneInMany2";
import MeDashboard from "./MeDashboard";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Dialog } from "primereact/dialog";
import api from "../../api";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { createPayment } from "../payments/Payments";
import { Button } from "primereact/button";

const styles = {
  fadeIn: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

const animationIntervalCount = 5;

class DashboardMe extends Component {
  state = {
    data: [],
    nextRanking: 0,
    groupquestions: [],
    itemNotFound: true,
    allselectedranking: false,
    succsessmsg: true,
    medash: true,
    dialog25: true,
    dialog50: true,
    dialog75: true,
    msg25: null,
    msg50: null,
    msg75: null,
    show_details: this.props.user_details.show_details,
    show_help: this.props.user_details.show_help,
    first_name: this.props.user_details ? this.props.user_details.first_name : "",
    last_name: this.props.user_details ? this.props.user_details.last_name : "",
    email: this.props.user_details ? this.props.user_details.email : "",
    language_preference: "",
    onBreak: JSON.parse(localStorage.getItem("onBreak")),
    password: "",
    cpassword: "",
    errorMessage: "",
    passwordVisible: false,
    cpasswordVisible: false,
    feedback: "",
    show_feedback: true,
  };

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }
  onFocus = () => {
    console.log("focus");
    // this.props.isPaymentSuccess();
  };
  componentDidMount() {
    this.loadQuestion(false);
    api
      .get("api/me/get_que_comp_msg")
      .then((res) => {
        if (res.data.success) {
          this.setState({
            msg25: res.data.que_comp_msg["25%"],
            msg50: res.data.que_comp_msg["50%"],
            msg75: res.data.que_comp_msg["75%"],
            show_details: res.data.show_details,
            onBreak: JSON.parse(localStorage.getItem("onBreak")),
          });
        }
      })
      .catch((e) => console.log(e));
    window.addEventListener("focus", this.onFocus);
  }
  componentDidUpdate(prevProps) {
    if (this.props.user_details && prevProps.user_details !== this.props.user_details) {
      this.setState({
        first_name: this.props.user_details.first_name,
        last_name: this.props.user_details.last_name,
        email: this.props.user_details.email,
      });
      if (this.props.user_details && this.props.user_details.show_help !== prevProps.user_details.show_help) {
        this.setState({ show_help: this.props.user_details.show_help });
      }
    }

    if (this.props.user_details && this.state.language_preference !== this.props.user_details.language_preference) {
      // this.loadQuestion();
      this.setState({
        language_preference: this.props.user_details.language_preference,
      });
    }
  }
  loadQuestion = () => {
    let data = {};
    this.props.getquestion(data, 1);
  };
  panelhead() {
    let completed = false;
    if (this.props.data.question && this.props.data.question.data) {
      completed = this.props.data.question.data.completed;
    }
    return (
      <div style={{ marginBottom: "14px" }}>
        <span style={{ fontSize: "18px" }}>
          {this.props.data.question.data
            ? completed
              ? "My Dashboard"
              : "Response progress " + (this.props.data.question.data ? this.props.data.question.data.progress.toFixed(2) : null) + "%"
            : null}
        </span>
        {!completed ? (
          <>
            {/* <button onClick={() => createPayment()}>Pay</button> */}
            <span className="complete-questions-message">Complete the 'ATMA - Questionnaire' to see your dashboard</span>
          </>
        ) : null}
      </div>
    );
  }
  succsessmsg = (param1, param2) => {
    this.setState({ succsessmsg: param1, medash: param2 });
  };
  getAnimation = () => {
    if (this.props.question_count % animationIntervalCount === 0) {
      return styles.fadeIn;
    } else {
      return null;
    }
  };
  onHide = (val) => {
    api
      .post("api/me/que_comp_msg", val)
      .then((res) => {
        if (res.data.success) {
          if (val === 25) {
            this.setState({ dialog25: false });
          } else if (val === 50) {
            this.setState({ dialog50: false });
          } else if (val === 75) {
            this.setState({ dialog75: false });
          }
          this.setState({
            msg25: res.data.que_comp_msg["25%"],
            msg50: res.data.que_comp_msg["50%"],
            msg75: res.data.que_comp_msg["75%"],
          });
        }
      })
      .catch((e) => console.log(e));
  };
  show_details = () => {
    const { first_name, last_name, email } = this.state;
    const data = { first_name, last_name, email };
    if (first_name.length > 0 && email.length > 0) {
      api
        .post("api/me/update_show_details", data)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              show_details: false,
            });
            this.props.setShowDetails(first_name, last_name, email);
          }
        })
        .catch((e) => console.log(e));
    }
  };
  updatePassword = () => {
    const { password, cpassword } = this.state;
    const regex = /\d/;
    const data = { password, cpassword, update: true };
    if (password.length > 7) {
      if (password !== cpassword) {
        this.setState({ errorMessage: "Password does not match" });
        return;
      }
      if (password.toUpperCase() === password || password.toLowerCase() === password || !regex.test(password)) {
        this.setState({
          errorMessage: "Password does not satisfy requirements",
        });
        return;
      }
      api
        .post("api/me/update_password", data)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              show_details: false,
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({
        errorMessage: "Password should be atleast 8 characters",
      });
      return;
    }
  };
  retainPassword = () => {
    const { password, cpassword } = this.state;
    const data = { password: "", cpassword: "", update: false };

    api
      .post("api/me/update_password", data)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            show_details: false,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  submitFeedback = () => {
    let textFeedback = this.state.feedback.trim();
    if (textFeedback.length <= 0) {
      this.setState({
        feedback: textFeedback,
        errorMessage: "Please write your feedback ...",
      });
      return;
    }
    // this.props.data.question.data.showFeedback = false;
    const data = { feedback: textFeedback };
    api
      .post("api/me/submit_test_feedback", data)
      .then((res) => {
        if (res.data.result) {
          this.setState({
            show_details: false,
            show_feedback: false,
          });
          this.growl.show({
            severity: "success",
            detail: "Thank you for the feedback.",
            life: 10000,
          });
        } else {
          this.growl.show({
            severity: "error",
            detail: "Failed to sumit the feedback." + res.data.result,
            life: 10000,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.growl.show({
          severity: "error",
          detail: "Failed to sumit the feedback." + e,
          life: 10000,
        });
      });
    return;
  };

  onBreak = () => {
    this.setState((prev) => ({
      onBreak: !prev.onBreak,
    }));
    localStorage.setItem("onBreak", JSON.stringify(!this.state.onBreak));
    this.props.group_answers({});
  };
  breakScreen = () => {
    return (
      <div style={{ padding: 18, marginTop: 27 }}>
        <h5 style={{ fontWeight: 600, textAlign: "center" }}>Welcome back to resume the assessment complete quickly and</h5>
        <h5 style={{ fontWeight: 600, textAlign: "center" }}>Get your behavioural competency score card soon</h5>
        <Panel header={<div style={{ fontWeight: 600, fontSize: 18 }}>Some statistics for you</div>}>
          <h6 style={{ fontWeight: 600, fontSize: 15, marginTop: 18 }}>Response coverage:</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "flext-start",
              alignItems: "center",
              width: "100%",
              marginLeft: 36,
              flexWrap: "wrap",
            }}
          >
            Estimated time to get dashboard - {this.props.data.question.data ? this.props.data.question.data.estimatedTime.toFixed(2) : null} minutes
          </div>
          <h6 style={{ fontWeight: 600, fontSize: 15, marginTop: 18 }}>Competencies cluster coverage:</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {this.props.data.question.data.dimCompletionStatus.map((data) => {
              return (
                <div style={{ width: "45%", margin: 18 }}>
                  <div>
                    {data.dimension_name} - {data.quesions_complete_percent.toFixed() + "%"}
                  </div>
                  <ProgressBar
                    style={{ height: "1vh" }}
                    className="desktop-progressbar"
                    showValue={false}
                    value={data.quesions_complete_percent ? data.quesions_complete_percent.toFixed() : null}
                  />
                </div>
              );
            })}
          </div>
        </Panel>
        <div style={{ textAlign: "center", marginTop: 27 }}>
          <Button className="p-button-rounded p-button-warning purplebtn" label="Resume" onClick={() => this.onBreak()} />
        </div>
      </div>
    );
  };
  render() {
    const group_question = () => {
      const { data } = this.props;
      if (data.question.data) {
        const group = data.question.data.group_result;
        if (group.length > 0) {
          return group;
        }
      }
    };
    const { user_details, data } = this.props;
    const {
      first_name,
      last_name,
      email,
      onBreak,
      password,
      cpassword,
      errorMessage,
      passwordVisible,
      cpasswordVisible,
      show_help,
      feedback,
      show_feedback,
    } = this.state;
    const loading_flag = data.question.loading;
    return (
      <div className="layout-main item-wrapper">
        <Panel header={this.panelhead()}>
          <div style={{ padding: 0 }} className="p-col-12 progress-container">
            <ProgressBar
              className="desktop-progressbar"
              showValue={false}
              value={this.props.data.question.data ? this.props.data.question.data.progress.toFixed(2) : null}
            />
          </div>
          <Dialog
            appendTo={document.body}
            header={user_details.organisation.org_ver_type == "RV" ? "Confirm Your Details" : "Change your password"}
            visible={
              user_details && user_details.organisation && user_details.organisation.show_password_change && this.state.show_details && !show_help
            }
            style={{ width: "fit-content" }}
            onHide={this.retainPassword}
          >
            {user_details.organisation.org_ver_type == "RV" ? (
              <div
                style={{
                  textAlign: "center",
                  margin: 0,
                  padding: 9,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 9,
                  }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      minWidth: 100,
                      textAlign: "right",
                    }}
                  >
                    First Name:
                  </div>
                  <div>
                    <InputText
                      id="username"
                      value={first_name || ""}
                      onChange={(e) => {
                        this.setState({ first_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 9,
                  }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      minWidth: 100,
                      textAlign: "right",
                    }}
                  >
                    Last Name:
                  </div>
                  <div>
                    <InputText
                      id="username"
                      value={last_name || ""}
                      onChange={(e) => {
                        this.setState({ last_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {user_details?.userConfig["email"]["field_include"] && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 9,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 9,
                        minWidth: 100,
                        textAlign: "right",
                      }}
                    >
                      Email:
                    </div>
                    <div>
                      <InputText
                        id="username"
                        value={email || ""}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                )}
                <div style={{ fontStyle: "italic", fontSize: 12 }}>Edit the information for correctness and then click CONFIRM button</div>
                <div>
                  <Button style={{ marginTop: 9 }} label="CONFIRM" onClick={this.show_details} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  // textAlign: "center",
                  margin: 0,
                  padding: 9,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 9,
                  }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      marginLeft: 30,
                      minWidth: 100,
                      textAlign: "right",
                    }}
                  >
                    Password:
                  </div>
                  <div>
                    <InputText
                      type={passwordVisible ? "text" : "password"}
                      id="username"
                      value={password || ""}
                      onChange={(e) => {
                        this.setState({
                          password: e.target.value,
                          errorMessage: "",
                        });
                      }}
                    />{" "}
                    <span className="p-inputgroup-addon">
                      <i
                        onClick={() =>
                          this.setState({
                            passwordVisible: !passwordVisible,
                          })
                        }
                        className={passwordVisible ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 9,
                  }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      minWidth: 100,
                      textAlign: "right",
                    }}
                  >
                    Confirm Password:
                  </div>
                  <div>
                    <InputText
                      type={cpasswordVisible ? "text" : "password"}
                      id="username"
                      value={cpassword || ""}
                      onChange={(e) => {
                        this.setState({
                          cpassword: e.target.value,
                          errorMessage: "",
                        });
                      }}
                    />
                    <span className="p-inputgroup-addon" style={{ marginLeft: 5 }}>
                      <i
                        onClick={() =>
                          this.setState({
                            cpasswordVisible: !cpasswordVisible,
                          })
                        }
                        className={cpasswordVisible ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                      ></i>
                    </span>
                  </div>
                </div>
                {errorMessage.length > 0 && <div style={{ color: "red", fontSize: 12 }}>{errorMessage}</div>}
                <div
                  style={{
                    fontSize: 12,
                    color: "#808080",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    // marginTop: 18,
                    // marginLeft: 33,
                  }}
                >
                  <div>Requirements:</div>
                  <ol>
                    <li>Minimum 8 letters are required</li>
                    <li>Atleast one smallcase letter is required</li>
                    <li>Atleast one uppercase letter is required</li>
                    <li>Atleast one numeric digit is required</li>
                  </ol>
                </div>
                <div>
                  <Button style={{ margin: 9 }} label="UPDATE PASSWORD" onClick={this.updatePassword} />
                </div>
                <div>OR</div>
                <div>
                  <Button style={{ margin: 9 }} label="RETAIN PASSWORD" onClick={this.retainPassword} />
                </div>
              </div>
            )}
          </Dialog>
          {loading_flag ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : (
            <>
              <StyleRoot>
                <div style={this.getAnimation()}>
                  {!user_details?.organisation.org_ver_type == "RV" &&
                  this.props.data.question.data.progress >= 75 &&
                  this.props.data.question.data.progress < 100 &&
                  !this.state.msg75 ? (
                    <Dialog
                      appendTo={document.body}
                      header={<span style={{ color: "white" }}>Congratulations</span>}
                      visible={this.state.dialog75}
                      onHide={() => this.onHide(75)}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: 700,
                        }}
                      >
                        <div>Congratulations on reaching level 3! </div>
                        <div>Quickly complete the next level to get your </div>
                        <div> ATMA Score Board - Your window to self development</div>
                      </div>
                    </Dialog>
                  ) : null}
                  {!user_details?.organisation.org_ver_type == "RV" &&
                  this.props.data.question.data.progress >= 50 &&
                  this.props.data.question.data.progress < 75 &&
                  !this.state.msg50 ? (
                    <Dialog
                      appendTo={document.body}
                      header={<span style={{ color: "white" }}>Congratulations</span>}
                      visible={this.state.dialog50}
                      onHide={() => this.onHide(50)}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: 700,
                        }}
                      >
                        <div>Congratulations on reaching level 2! </div>
                        <div>Quickly complete the next 2 levels to get your </div>
                        <div> ATMA Score Board - Your window to self development</div>
                      </div>
                    </Dialog>
                  ) : null}
                  {!user_details?.organisation.org_ver_type == "RV" &&
                  this.props.data.question.data.progress >= 25 &&
                  this.props.data.question.data.progress < 50 &&
                  !this.state.msg25 ? (
                    <Dialog
                      appendTo={document.body}
                      header={<span style={{ color: "white" }}>Congratulations</span>}
                      visible={this.state.dialog25}
                      onHide={() => this.onHide(25)}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 25,
                          fontWeight: 700,
                        }}
                      >
                        <div>Congratulations on reaching level 1! </div>
                        <div>Quickly complete the next 3 levels to get your </div>
                        <div>ATMA Score Board - Your window to self development</div>
                      </div>
                    </Dialog>
                  ) : null}
                  {this.props.data.question.data && this.props.data.question.data.response_type === "Group Questions" ? (
                    onBreak ? (
                      this.breakScreen()
                    ) : (
                      <Groupquestion
                        key={group_question()}
                        question={group_question()}
                        progress={this.props.data.question.data.progress}
                        onBreak={this.onBreak}
                      ></Groupquestion>
                    )
                  ) : null}
                  {this.props.data.question.data && this.props.data.question.data.item ? (
                    this.props.data.question.data.item.response_type === "Ranking" ? (
                      onBreak ? (
                        this.breakScreen()
                      ) : (
                        <div className="ranking-response-container">
                          <Ranking
                            rankingquestion={this.props.data.question.data}
                            progress={this.props.data.question.data.progress}
                            onBreak={this.onBreak}
                          ></Ranking>
                        </div>
                      )
                    ) : null
                  ) : null}
                  {this.props.data.question.data && this.props.data.question.data.item ? (
                    this.props.data.question.data.item.response_type === "One in Many 2" ? (
                      onBreak ? (
                        this.breakScreen()
                      ) : (
                        <div className="ranking-response-container">
                          <OneInMany2
                            OneInMany2_question={this.props.data.question.data}
                            progress={this.props.data.question.data.progress}
                            onBreak={this.onBreak}
                          ></OneInMany2>
                        </div>
                      )
                    ) : null
                  ) : null}
                </div>
              </StyleRoot>
            </>
          )}
          {this.props.data.question.data ? (
            this.props.data.question.data.completed ? (
              <>
                <div hidden={this.state.succsessmsg} className="p-grid">
                  <div className="p-col-12 p-md-3 p-lg-3"></div>
                  <div className="p-col-12 p-md-6 p-lg-6">
                    <Panel header="Successfully completed">
                      <div>
                        <span>
                          {user_details.constants.val.completionMessage
                            .replace("#username", user_details.first_name)
                            .split(";")
                            .map((m) => (
                              <p key={m}>{m}</p>
                            ))}
                        </span>
                      </div>
                    </Panel>
                  </div>
                </div>
                <div hidden={this.state.medash}>
                  <MeDashboard user="" userb={false} callback={this.succsessmsg} />
                </div>

                {this.props.data.question.data && this.props.data.question.data.showFeedback ? (
                  <>
                    <div hidden={!show_feedback} className="p-grid">
                      <div className="p-col-12 p-md-3 p-lg-3"></div>
                      <div className="p-col-12 p-md-6 p-lg-6">
                        <Panel header="Please provide your valuable feedback:">
                          <div>
                            <InputTextarea
                              id="feedback"
                              // this.state.feedback = this.props.data.question.data.TestFeedback || "";
                              value={feedback || this.props.data.question.data.TestFeedback || ""}
                              onChange={(e) => {
                                this.setState({
                                  feedback: e.target.value,
                                  errorMessage: "",
                                });
                              }}
                              maxLength="2500"
                              rows={5}
                              style={{ width: "100%" }}
                            />
                          </div>
                          {errorMessage.length > 0 && <div style={{ color: "red", fontSize: 12 }}>{errorMessage}</div>}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              label={"SUBMIT"}
                              className="p-grid"
                              style={{ marginRight: "14px" }}
                              onClick={this.submitFeedback}
                            ></Button>
                            <Button
                              type="submit"
                              className="p-button-secondary"
                              label={"Close"}
                              // className="p-grid"
                              onClick={() => {
                                this.setState({ show_feedback: false });
                              }}
                            ></Button>
                          </div>
                        </Panel>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null
          ) : (
            !loading_flag && (
              <div style={{ marginTop: 25, fontSize: 25, textAlign: "center" }}>
                You are not configured to take the assessment. When the admin configures your user id, you will get the assessment questionnaire
                automatically. Admin may not configure your id, in case, you are not expected to take the assessment
              </div>
            )
          )}
        </Panel>
        <Growl ref={(el) => (this.growl = el)} position="topright" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
    user_details: state.auth.user_details,
    language: state.auth.user_details.language_preference,
    question_count: state.question.question_count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    group_answers: (ans_arr) => dispatch(group_answers(ans_arr)),
    getquestion: (data, return_item) => dispatch(getquestion(data, return_item)),
    setShowDetails: (first_name, last_name, email) => dispatch(setShowDetails(first_name, last_name, email)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
    isPaymentSuccess: () => dispatch(isPaymentSuccess()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMe);
