import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildOptionBarStacked } from "../Helper";

class EChart extends Component {
  onChartClick = (param) => {
    this.props.callBack(param);
  };
  render() {
    let onEvents = {
      click: this.onChartClick,
      legendselectchanged: this.onChartLegendselectchanged,
    };
    const { chartData = [] } = this.props;
    return (
      <div>
        <ReactEcharts
          onEvents={onEvents}
          style={{ height: "600px" }}
          option={BuildOptionBarStacked(chartData)}
        />
      </div>
    );
  }
}

export default EChart;
