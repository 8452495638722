import React, { Component } from "react";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="layout-main not-found">
        <h1>Page not found</h1>
      </div>
    );
  }
}

export default NotFoundPage;
