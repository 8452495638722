var optoins = {
  pie: {
    title: {
      text: "Title",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
    },
    series: [
      {
        type: "pie",
        radius: "55%",
        label: {
          formatter: (e) => e.name + "\n" + e.value.toFixed(0) + "%",
          color: "black",
        },
        labelLine: { lineStyle: { color: "black" } },
        data: [],
      },
    ],
  },
  small_pie: {
    title: {
      text: "Title",
      x: "35%",
      textStyle: {
        fontWeight: "normal",
      },
    },
    legend: {
      type: "scroll",
      align: "left",
      left: "right",
      orient: "vertical",
    },
    series: [
      {
        type: "pie",
        radius: "30%",
        center: ["60%", "25%"],
        label: {
          position: "inside",
          formatter: (e) => e.value.toFixed(0) + "%",
        },
        labelLine: { lineStyle: { color: "black" } },
        data: [],
      },
    ],
  },
  small_pie_left: {
    title: {
      text: "title",
      x: "10%",
      top: -5,
    },
    legend: {
      show: true,
      align: "left",
      orient: "vertical",
      right: "20%",
      top: 20,
    },
    series: [
      {
        type: "pie",
        radius: "30%",
        center: ["25%", "25%"],
        label: {
          position: "inside",
          formatter: (e) => e.value.toFixed(0) + "%",
        },
        data: [],
      },
    ],
  },
  bar: {
    title: {
      text: "",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
    },
    legend: {
      // type: "scroll",
      align: "left",
      // left: "600",
      right: "0",
      orient: "vertical",
      data: [],
      itemWidth: 15,
    },
    tooltip: {
      formatter: "{a}",
    },
    grid: {
      width: "",
      top: 20,
      bottom: 10,
      left: 30,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      splitLine: { show: false },
      type: "value",
      name: "",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [0, 0, 10, 0],
      },
      interval: 25,
      min: 0,
      max: 100,
    },
    series: [
      {
        name: "name",
        type: "bar",
        label: {
          normal: {
            show: true,
            position: "top",
            color: "black",
            formatter: "{c}%",
          },
        },
        data: [],
      },
    ],
  },
  bar_stacked: {
    title: {
      text: "Title",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
      left: "30%",
    },
    legend: {
      data: ["Good", "Moderate", "Area of concern"],
      right: 25,
    },
    grid: {
      left: "5%",
      right: "5%",
      top: "10%",
      bottom: "-1%",
      containLabel: true,
    },
    yAxis: {
      type: "value",
      name: "Number of employees",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [15, 0, 15, 0],
      },
      min: 0,
      max: 500,
    },
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        fontSize: 15,
        rotate: 70,
        shadowBlur: 5,
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffsetY: 2,
        color: "black",
        backgroundColor: "#ffffff",
        borderRadius: 12,
      },
      triggerEvent: true,
      data: [],
    },
    series: [],
  },
  doughnut: {
    title: {
      text: "Title",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
    },
    color: ["#34a853", "#ea4335", "#4285f4", "#fbbc05"],
    grid: {
      top: 30,
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "55%"],
        label: {
          formatter: (e) => e.name + " - " + e.value.toFixed(0) + "%",
          color: "black",
        },
        labelLine: { lineStyle: { color: "black" } },
        data: [],
      },
    ],
  },
  area: {
    title: {
      text: "Title",
      id: "",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b}:<br/> {a}:{c}, <br/>{a1}: {c1}, <br/>{a2}: {c2}",

      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: [0, 1],
        },
      ],
    },
    dataZoom: [
      {
        type: "slider",
        xAxisIndex: [0, 1],
        realtime: false,
        start: 0,
        end: 100,
        left: 90,
        right: 90,
        bottom: 150,
        height: 20,
        handleIcon:
          "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "80%",
      },
    ],
    xAxis: [
      {
        type: "category",
        data: [],
        boundaryGap: false,
        name: "Time line ",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [30, 0, 0, 0],
        },
      },
      {
        type: "category",
        gridIndex: 1,
        data: [],
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: "#777" } },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
        axisPointer: {
          type: "shadow",
          label: { show: false },
          triggerTooltip: true,
          handle: {
            show: false,
            margin: 30,
            color: "#B80C00",
          },
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        boundaryGap: false,
        min: -100,
        max: 100,
        axisTick: {
          show: true,
        },
        name: "% of Stressed employees | % of Happy employees",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [0, 0, 20, 0],
        },
      },
      {
        scale: false,
        boundaryGap: false,
        gridIndex: 1,
        splitNumber: 0,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
    ],
    grid: [
      {
        show: false,
        left: 80,
        right: 80,
        top: 60,
        height: 350,
      },
      {
        show: false,
        left: 80,
        right: 80,
        height: 80,
        bottom: 20,
      },
    ],
    series: [
      {
        name: "Employee Count",
        type: "bar",
        barWidth: 50,
        xAxisIndex: 1,
        yAxisIndex: 1,
        label: {
          position: "top",
          formatter: "{d}",
        },
        itemStyle: {
          normal: {
            color: "#7fbe9e",
          },
          emphasis: {
            color: "#140",
          },
        },
        data: [],
      },
      {
        name: "Happiness Score",
        type: "line",
        label: {
          position: "inner",
          formatter: "{d}",
        },
        smooth: true,
        symbol: "none",
        sampling: "average",
        itemStyle: {
          color: "rgb(50,205,50)",
        },
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //   {
          //     offset: 0,
          //     color: "rgb(34,139,34)"
          //   },
          //   {
          //     offset: 1,
          //     color: "rgb(255,255,255)"
          //   }
          // ])
        },
        data: [],
      },
      {
        name: "Stressfull Score",
        type: "line",
        label: {
          position: "inner",
          formatter: "{d}",
        },
        smooth: true,
        symbol: "none",
        sampling: "average",
        itemStyle: {
          color: "rgb(250,128,114)",
        },
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //   {
          //     offset: 0,
          //     color: "rgb(255,255,255)"
          //   },
          //   {
          //     offset: 1,
          //     color: "rgb(255,0,0)"
          //   }
          // ])
        },
        data: [],
      },
    ],
  },
  MindFramepie: {
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    // legend: {
    //   orient: "vertical",
    //   left: "left",
    // },
    series: [
      {
        name: "",
        type: "pie",
        radius: "70%",
        label: {
          // position: "inner",
          formatter: "{b} - {c} ({d}%)",
          color: "black",
        },
        center: ["50%", "50%"],
        labelLine: { lineStyle: { color: "black" } },
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 5,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  },
  // MindFramepie: {
  //   title: {
  //     text: "Title",
  //     x: "center",
  //     textStyle: {
  //       fontWeight: "normal",
  //     },
  //   },
  //   tooltip: {
  //     trigger: "item",
  //     formatter: "{b} : {c} ({d}%)",
  //   },
  //   legend: {
  //     orient: "vertical",
  //     left: "left",
  //     data: ["Personal", "Work", "Others"],
  //   },
  //   series: [
  //     {
  //       type: "pie",
  //       radius: "70%",
  //       label: {
  //         position: "inner",
  //         formatter: "{d}%",
  //       },
  //       center: ["50%", "50%"],
  //       labelLine: { lineStyle: { color: "black" } },
  //       data: [],
  //     },
  //   ],
  // },
  scatter: {
    tooltip: {
      trigger: "item",
      formatter: "({c})",
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: [0, 1],
        },
      ],
    },
    legend: {
      icon: "roundRect",
      selectedMode: false,
    },
    title: {
      text: "",
      x: "center",
      textStyle: {
        fontWeight: "normal",
      },
    },
    xAxis: {
      name: "Head Count",
      show: true,
      splitLine: { show: false },
      nameLocation: "middle",
      nameTextStyle: {
        padding: [10, 0, 0, 0],
      },
      minInterval: 1,
    },
    yAxis: {
      type: "value",
      //splitLine: { show: false },
      name: "In Percentage (%)",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [0, 0, 12, 0],
      },
      min: 0,
      max: 100,
    },
    grid: {
      top: 10,
      bottom: 50,
      left: 50,
    },
    series: [
      {
        silent: true,
        markArea: {
          label: {
            color: "black",
            position: "inside",
          },
          itemStyle: {
            color: "#fbbc0540",
          },
          data: [
            [
              {
                yAxis: "40",
              },
              {
                yAxis: "70",
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        silent: true,
        markArea: {
          label: {
            color: "black",
            position: "inside",
          },
          itemStyle: {
            color: "#8ff4a370",
          },
          data: [
            [
              {
                yAxis: "70",
              },
              {},
            ],
          ],
        },
        type: "custom",
      },
      {
        silent: true,
        markArea: {
          label: {
            color: "black",
            position: "inside",
          },
          itemStyle: {
            color: "#ffacac70",
          },
          data: [
            [
              {
                yAxis: "0",
              },
              {
                yAxis: "40",
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        name: "",
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [],
        color: "#4285f4",
        symbolSize: 0,
        smooth: true,
      },
      {
        name: "",
        type: "scatter",
        data: [],
        color: "#4285f4",
        symbolSize: 6,
      },
    ],
  },
  radarMe: {
    title: {
      text: "",
    },

    radar: {
      nameGap: 4,
      radius: "80%",
      scale: true,
      splitArea: {
        show: false,
      },
      triggerEvent: true,
      name: {
        textStyle: {
          shadowBlur: 5,
          shadowColor: "rgba(0, 0, 0, 0.3)",
          shadowOffsetY: 2,
          color: "#fff",
          backgroundColor: "#ffffff",
          borderRadius: 12,
          padding: [3, 5],
        },
      },
      indicator: [],
    },
    polar: {
      show: false,
    },
    angleAxis: {
      show: false,
    },
    radiusAxis: {
      axisTick: {
        lineStyle: {
          color: "#bbbbbb",
        },
      },
      axisLabel: {
        color: "#bbbbbb",
        verticalAlign: "top",
        padding: [1, -2],
      },
      splitLine: {
        show: false,
      },
      show: true,
      min: 0,
      max: 100,
      interval: 100,
    },
    series: [
      {
        name: "",
        silent: true,
        type: "radar",
        shape: "circle",
        itemStyle: {
          normal: {
            color: "#00d8ff",
          },
        },
        data: [],
        label: {
          show: true,
          formatter: "{c}",
          position: [5, -3],
          color: "black",
        },
      },
    ],
  },
  boxChart: {
    title: {
      text: "",
    },
    xAxis: {
      cursor: "pointer",

      name: "Performance",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [5, 0, 0, 0],
      },
      min: 0,
      max: 99,
      interval: 99,
      splitLine: { show: false },
      axisLabel: { show: false },
    },
    yAxis: {
      cursor: "pointer",
      name: "Potential",
      nameLocation: "middle",
      nameTextStyle: {
        padding: [0, 0, 5, 0],
      },
      min: 0,
      max: 99,
      interval: 99,
      splitLine: { show: false },
      axisLabel: { show: false },
    },
    grid: {
      top: 0,
    },
    series: [
      {
        cursor: "pointer",

        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ff000070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ff000070",
            },
          },
          data: [
            [
              {
                name: "Under\nPerformance",
                coord: [0, 0],
              },
              {
                coord: [33, 33],
              },
            ],
          ],
        },
        symbol: "diamond",
        symbolSize: 20,
        data: [],
        color: "#1c61b1",
        type: "scatter",
        emphasis: {
          itemStyle: {
            color: "#00d8ffc2",
            normal: {
              shadowBlur: 10,
              shadowColor: "rgba(120, 36, 50, 0.5)",
              shadowOffsetY: 3,
              color: "#00d8ffc2",
            },
          },
        },
        itemStyle: {
          normal: {
            shadowBlur: 10,
            shadowColor: "rgba(120, 36, 50, 0.5)",
            shadowOffsetY: 3,
            color: "#00d8ffc2",
          },
        },
      },
      {
        cursor: "pointer",

        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ffb90070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ffb90070",
            },
          },
          data: [
            [
              {
                name: "Effective\nContributor",
                coord: [33, 0],
              },
              {
                coord: [66, 33],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ffb90070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ffb90070",
            },
          },
          data: [
            [
              {
                name: "Inconsistent\nPlayer",
                coord: [0, 33],
              },
              {
                coord: [33, 66],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ffff0070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ffff0070",
            },
          },
          data: [
            [
              {
                name: "Skilled\nProfessional",
                coord: [66, 0],
              },
              {
                coord: [99, 33],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ffff0070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ffff0070",
            },
          },
          data: [
            [
              {
                name: "Core\nEmployee",
                coord: [33, 33],
              },
              {
                coord: [66, 66],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#ffff0070",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#ffff0070",
            },
          },
          data: [
            [
              {
                name: "Early\nPromise",
                coord: [0, 66],
              },
              {
                coord: [33, 99],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#7fefff70",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#7fefff70",
            },
          },
          data: [
            [
              {
                name: "Emerging\nPotential",
                coord: [33, 66],
              },
              {
                coord: [66, 99],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#7fefff70",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#7fefff70",
            },
          },
          data: [
            [
              {
                name: "Rising\nStar",
                coord: [66, 33],
              },
              {
                coord: [99, 66],
              },
            ],
          ],
        },
        type: "custom",
      },
      {
        markArea: {
          label: {
            color: "#7d7d7d",
            position: "inside",
          },
          itemStyle: {
            color: "#00c9e670",
          },
          emphasis: {
            label: {
              color: "#7d7d7d",
              position: "inside",
            },
            itemStyle: {
              color: "#00c9e670",
            },
          },
          data: [
            [
              {
                name: "Top\nStar",
                coord: [66, 66],
              },
              {
                coord: [99, 99],
              },
            ],
          ],
        },
        type: "custom",
      },
    ],
  },
};

export default optoins;
