import React, { Component } from "react";
import api from "../../api";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";

class DimScore extends Component {
  state = {
    dims: [],
    exp: {},
    exp1: false,
    dim: {},
    globalFilter: null,
    load_flag: true,
    loading_handle: true
  };

  componentDidMount() {
    api
      .get("/api/cce/dim_view")
      .then(res => {
        this.setState({
          dims: res.data.total_dim,
          exp: res.data.expmsg,
          load_flag: false,
          loading_handle: false
        });
      })
      .catch(err => console.log(err));
  }
  toggleApplications = () => {
    let dim = { ...this.state.dim };
    if (this.state.exp1 === true) {
      dim = {};
      this.setState({ exp1: false });
    } else {
      dim = this.state.exp;
      this.setState({ exp1: true });
    }
    this.setState({ dim: dim });
  };

  render() {
    const { dims, dim, globalFilter, load_flag, loading_handle } = this.state;
    let header = (
      <div className="row">
        <div className="col-md-6" style={{ textAlign: "left" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={e => this.setState({ globalFilter: e.target.value })}
            placeholder="Search"
            size="50"
          />
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-2">
          <Button
            onClick={this.toggleApplications}
            label="Expand/Collapse All"
          />
        </div>
      </div>
    );

    return (
      <div className="layout-main">
        {loading_handle === true ? (
          <>
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          </>
        ) : (
            <div className="p-grid">
              <div className="p-col-12">
                <Panel header={this.props.menuProps.label}>
                  <TreeTable
                    emptyMessage="Loading Data..."
                    value={dims}
                    expandedKeys={dim}
                    globalFilter={globalFilter}
                    header={header}
                    onToggle={e => this.setState({ dim: e.value })}
                    style={{ marginTop: ".5em" }}
                    emptyMessage={
                      dims.length > 0 && load_flag == false
                        ? "Loading data"
                        : dims.length == 0 && load_flag == true
                          ? "Loading Data"
                          : "No data"
                    }
                  >
                    <Column
                      field="title"
                      header="Dimension Name"
                      expander
                    ></Column>
                    <Column field="key" header="Dimension Id"></Column>
                    <Column field="freq.days" header="Frequency"></Column>
                  </TreeTable>
                </Panel>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default DimScore;
