import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import { authLogout } from "../../store/actions/auth";

class DimensionResults extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    if (
      !_.isEmpty(this.props.organisation) ||
      !this.props.user_details.isSuperUser
    )
      this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  fetchData = () => {
    const data = { org: this.props.organisation };
    api
      .post("api/cce/get_dim_res_data", data)
      .then((res) => {
        this.setState({
          data: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  generate = () => {
    const data = {
      org: this.props.organisation,
    };
    api
      .post("api/cce/generate_dim_res", data)
      .then((res) => {
        if (res.data.results) {
          this.fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.growl.show({
      severity: "success",
      summary:
        "Dimesnion results generation initiated, it might take sometime, please refresh and check below table for the file generated",
      sticky: true,
    });
  };

  delete = (rowData) => {
    const data = {
      org: this.props.organisation,
      id: rowData.id,
    };
    api
      .post("api/cce/del_dimres_url", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({ data: res.data.data });
          this.growl.show({
            severity: "success",
            summary: "Deleted successfully",
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  download = (rowData) => {
    const data = {
      org: this.props.organisation,
      id: rowData.id,
    };
    api
      .post("api/cce/get_dimres_url", data)
      .then((res) => {
        if (res.data.results) {
          this.filedownload(res.data.url);
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filedownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  download_btn = (rowData) => {
    return <Button label="Download" onClick={() => this.download(rowData)} />;
  };
  delete_btn = (rowData) => {
    return (
      <Button
        className="p-button-danger"
        label="Delete"
        onClick={() => this.delete(rowData)}
      />
    );
  };

  render() {
    var header = (
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3">
            <InputText
              type="search"
              style={{ marginRight: "" }}
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
              placeholder="Search..."
              size="30"
            />
          </div>
          <div className="col-md-9">
            <div style={{ float: "right" }}>
              <Button
                label="Refresh"
                onClick={this.fetchData}
                style={{ marginLeft: 18 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className={this.props.typelogin ? "" : "layout-main"}>
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Panel header={this.props.menuProps.label}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>Dimesnion Results :</div>
            <div>
              <Button
                label="Generate"
                onClick={this.generate}
                style={{ marginLeft: 18 }}
              />
            </div>
          </div>
        </Panel>
        <Panel header={"Generated dimension results"}>
          <DataTable
            value={this.state.data}
            paginator={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={"No data"}
            rows={10}
            resizableColumns={true}
            rowsPerPageOptions={[
              10,
              20,
              50,
              this.state.emps ? this.state.emps.length : "",
            ]}
            style={{ marginBottom: "20px" }}
          >
            <Column field="file_name" header="File name" sortable={true} />
            <Column
              field="generated_on"
              header="Generated on"
              sortable={true}
            />
            <Column
              style={{ textAlign: "center" }}
              body={this.download_btn}
              header="Download"
            />
            <Column
              style={{ textAlign: "center" }}
              body={this.delete_btn}
              header="Delete"
            />
          </DataTable>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DimensionResults);
