import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authLogin, orgSettings, keyLogin } from "../store/actions/auth";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import atma_logo from "../assets/images/atma-brand-icon.png";
import nav from "../utils/Utils";
import api from "../api";

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    company: "",
    passwordVisible: false,
    companyErr: false,
    usernameErr: false,
    passwordErr: false,
    showErrorMsg: false,
    isAdmin: false,
  };
  componentDidMount() {
    const { key = "", loginkey = "" } = this.props.match.params;
    if (key?.length > 0) {
      if (key === "admin") {
        this.setState({ isAdmin: true });
      } else {
        if (loginkey?.length > 0) {
          api
            .post(
              "api/organisation/get_login_key_token",
              { login_key: loginkey },
              {
                headers: {
                  "Content-Type": "application/json",
                  ORGKEY: key,
                },
              }
            )
            .then((res) => {
              const { token, user_details } = res.data;
              this.props.keyLogin(token, user_details);
            })
            .catch((err) => {});
        } else {
          this.props.getOrgSettings(key);
          this.setState({ company: key });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && prevProps.error !== this.props.error && this.props.error.length > 0) {
      console.log("adf");
      this.setState({ showErrorMsg: true });
    }
  }

  inputChange = (e) => {
    this.setState({ showErrorMsg: false });
    const { name, value } = e.target;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (name === "username" && value.length > 0) {
          this.setState({ usernameErr: false });
        } else if (name === "password" && value.length > 0) {
          this.setState({ passwordErr: false });
        } else if (name === "company" && value.length > 0) {
          this.setState({ companyErr: false });
        }
      }
    );
  };
  showGrowl = (messageArr) => {
    messageArr.forEach((m) => {
      setTimeout(() => {
        this.growl.show(m);
      }, 1);
    });
  };
  handleSubmit = () => {
    const { username, password, company, isAdmin } = this.state;
    const { orgSettings } = this.props;
    let errors = [];
    // e.preventDefault();
    if (!_.isEmpty(orgSettings) || isAdmin) {
      if (password.length === 0) {
        this.setState({ passwordErr: true });
        errors.push({
          severity: "error",
          detail: "Password cannot be empty",
        });
      }
      if (username.length === 0) {
        this.setState({ usernameErr: true });
        errors.push({
          severity: "error",
          detail: "Username cannot be empty",
        });
      }
      if (errors.length === 0) {
        this.props.login(username.trim() + company.trim().replace(" ", "").toLowerCase(), password);
      }
    } else {
      if (company.length === 0 && !isAdmin) {
        this.setState({ companyErr: true });
        errors.push({
          severity: "error",
          detail: "Company cannot be empty",
        });
      } else {
        this.props.getOrgSettings(company);
      }
    }
    if (errors.length === 0) {
      this.setState({ showErrorMsg: true });
    }
    this.showGrowl(errors);
  };

  render() {
    const { error = "", loading, token, orgSettings } = this.props;
    const { username, password, company, passwordVisible, companyErr, usernameErr, passwordErr, showErrorMsg, isAdmin } = this.state;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <Growl ref={(el) => (this.growl = el)} position="bottomright" style={{ marginRight: 20 }} />
        <div className="layout-main-public">
          <form>
            <div className="p-grid">
              <div className="p-col-12">
                <div className="auto-center-container-login">
                  <div className="login-header">
                    <div className="login-header-message">
                      <img style={{ width: "50%", objectFit: "contain" }} src={atma_logo}></img>
                    </div>
                    {/* <div className="login-subheader-message">
                      Your coach &amp; companion
                    </div> */}
                  </div>
                  <div className="login-form-center-align">
                    {isAdmin
                      ? null
                      : _.isEmpty(orgSettings) && (
                          <div className="p-inputgroup">
                            <InputText
                              name="company"
                              onChange={(e) => this.inputChange(e)}
                              value={company}
                              className={"input-text " + (companyErr ? "p-error" : "")}
                              placeholder="Company"
                            />
                            <span className="p-inputgroup-addon">
                              <FontAwesomeIcon icon={["fas", "building"]} />
                            </span>
                          </div>
                        )}
                    {!_.isEmpty(orgSettings) || isAdmin ? (
                      <React.Fragment>
                        <div className="p-inputgroup">
                          <InputText
                            name="username"
                            onChange={(e) => this.inputChange(e)}
                            value={username}
                            className={"input-text " + (usernameErr ? "p-error" : "")}
                            placeholder={!isAdmin ? orgSettings[0]["userid"] : "Username"}
                          />
                          <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                          </span>
                        </div>
                        <div className="p-inputgroup">
                          <InputText
                            className={"input-text " + (passwordErr ? "p-error" : "")}
                            name="password"
                            value={password}
                            onChange={(e) => this.inputChange(e)}
                            type={passwordVisible ? "text" : "password"}
                            placeholder={!isAdmin ? orgSettings[0]["password"] : "Password"}
                          />
                          <span className="p-inputgroup-addon">
                            <i
                              onClick={() =>
                                this.setState({
                                  passwordVisible: !passwordVisible,
                                })
                              }
                              className={passwordVisible ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                            ></i>
                          </span>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div className="error-text">{!loading && showErrorMsg ? error : ""}</div>
                    <div className="p-inputgroup">
                      {!_.isEmpty(orgSettings) || isAdmin ? (
                        <Button
                          type="submit"
                          disabled={loading}
                          icon={loading ? "pi pi-spin pi-spinner" : ""}
                          label={loading ? "" : "LOGIN"}
                          className="login-button p-button-warning"
                          onClick={this.handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          type="submit"
                          disabled={loading}
                          icon={loading ? "pi pi-spin pi-spinner" : ""}
                          label={loading ? "" : "NEXT"}
                          className="login-button p-button-warning"
                          onClick={this.handleSubmit}
                        ></Button>
                      )}
                    </div>
                    {orgSettings && orgSettings.length > 0 && orgSettings[0]["showForgotPassword"] ? (
                      <div className="p-inputgroup">
                        <span onClick={() => nav("/forgot-password")} className="cursor-pointer clickable-link small-size-font">
                          I Forgot My Password
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    orgSettings: state.auth.orgSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    keyLogin: (token, user_details) => dispatch(keyLogin(token, user_details)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
