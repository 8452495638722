import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  target: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Prefer: "return=representation"
  }
});
if (localStorage.getItem("token")) {
  instance.defaults.headers.common["Authorization"] = localStorage.getItem(
    "token"
  );
}
instance.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      if (localStorage.getItem("token"))
        alert("Session expired please login to continue !");
      localStorage.clear();
      window.location = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
