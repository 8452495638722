import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildRadar } from "../Helper";

class EChart extends Component {
  onChartClick = (param) => {
    this.props.callBack(param);
  };
  render() {
    let onEvents = {
      click: this.onChartClick,
      legendselectchanged: this.onChartLegendselectchanged,
    };
    const { chartData = [] } = this.props;
    return (
      <div
        style={{
          width: "90%",
          height: "90%",
          marginLeft: "5%",
          marginTop: "5%",
        }}
      >
        {this.props.emo ? (
          <ReactEcharts
            ref={(e) => {
              this.echarts_react = e;
            }}
            onEvents={onEvents}
            option={BuildRadar(chartData)}
          />
        ) : (
          <div className="">
            <div className="">
              <ReactEcharts
                ref={(e) => {
                  this.echarts_react = e;
                }}
                onEvents={onEvents}
                option={BuildRadar(chartData)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EChart;
