import React, { Component } from "react";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import api from "../../api";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { ProgressSpinner } from "primereact/progressspinner";

class Jobfamily extends Component {
  state = {
    loading: true,
    data: [],
    selected: [],
    changed: false,
    is_paid: true,
  };
  componentDidMount() {
    this.fetch();
  }
  fetch() {
    api
      .get("api/me/jobprofiles")
      .then((res) => {
        //console.log("res", res.data.sel_courses["sel_set1"]);
        this.setState({
          loading: false,
          data: res.data.data,
          selected: res.data.selected,
          is_paid: res.data.is_paid,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }
  panelhead = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          height: "40px",
        }}
      >
        <div style={{ fontSize: "18px" }}>Job Family Preference</div>
        <div>
          <Button style={{ marginLeft: "15px" }} className="p-button-warning whatbtn" onClick={this.savejobpref} label="Save" />
          <Button style={{ marginLeft: "15px" }} className="p-button-warning whatbtn" onClick={this.reset} label="Reset" />
        </div>
      </div>
    );
  };
  reset = () => {
    // this.fetch();
    let data = this.state.data;
    data.map((d) => (d.sel = 6));
    this.setState({ data, changed: true, selected: [] });
  };
  savejobpref = () => {
    // if (this.state.selected.length < 1) {
    //   this.growl.show({
    //     severity: "error",
    //     detail: "Select preferences",
    //   });
    // } else
    if (this.state.is_paid) {
      if (!this.state.changed) {
        this.growl.show({
          severity: "error",
          detail: "No change in the preferences",
        });
      } else {
        const dataset = {
          data: this.state.data,
          selCount: this.state.selected.length,
        };
        api
          .post("api/me/savejobpref", dataset)
          .then((res) => {
            console.log("save", res.data.results);
            if (res.data.results === true) {
              this.growl.show({
                severity: "success",
                detail: "Job preferences saved successfully.",
              });
              this.fetch();
            } else {
              this.growl.show({
                severity: "error",
                detail: "Job preferences not saved successfully.",
              });
            }
            this.setState({
              loading: false,
              changed: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log(err);
          });
      }
    } else {
      this.growl.show({
        severity: "warn",
        detail: "Buy full version to save preferences",
        sticky: true,
      });
    }
  };

  changeSel = (e, rowData) => {
    const index = this.state.selected.indexOf(rowData.sel);
    if (index > -1) {
      this.state.selected.splice(index, 1);
    }
    rowData.sel = parseInt(e.target.value);
    let selected = this.state.selected;
    if (parseInt(e.target.value) !== 6) {
      selected.push(parseInt(e.target.value));
    }
    this.setState({ selected: selected, changed: true });
  };
  sequence = (rowData) => {
    return (
      <div>
        <select value={rowData.sel} onChange={(e) => this.changeSel(e, rowData)}>
          <option value="6">None</option>
          <option disabled={this.state.selected.includes(1) ? true : false} value={1}>
            1
          </option>
          <option disabled={this.state.selected.includes(2) ? true : false} value={2}>
            2
          </option>
          <option disabled={this.state.selected.includes(3) ? true : false} value={3}>
            3
          </option>
          <option disabled={this.state.selected.includes(4) ? true : false} value={4}>
            4
          </option>
          <option disabled={this.state.selected.includes(5) ? true : false} value={5}>
            5
          </option>
        </select>
      </div>
    );
  };
  render() {
    return (
      <div className="layout-main item-wrapper">
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Panel header={this.panelhead()}>
          {this.state.loading ? (
            <div className="row">
              <div className="loading-spinner col-md-3">
                <ProgressSpinner />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12" style={{ marginRight: 18, marginBottom: 18 }}>
                Please select any three jobs from the list of job-families given in the table below. They are the one's you prefer to pursue as your
                career, with No.1 as the most preferred job, and No.2 and No.3 would indicate the next ones. You can read the descriptions given
                against each job-family to make those choices. Remember, ATMA will indicate your suitability to those jobs that you have mentioned as
                your preference, in the Job-profile page.
              </div>
              <div className="col-md-12">
                <DataTable value={this.state.data} className="oranget">
                  <Column style={{ width: 100, textAlign: "center" }} body={(rowData) => this.sequence(rowData)} header="Preference"></Column>
                  <Column field="jobfamilyname" header="Job family name"></Column>
                  <Column field="description" header="Description"></Column>
                  <Column field="departments" header="Sample"></Column>
                </DataTable>
              </div>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(Jobfamily);
