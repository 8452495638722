import React, { Component } from "react";
import api from "../../api";
import { excelexport } from "../../common/Helper";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";

export default class Aspirations extends Component {
  state = {
    dataStats: [],
    aspStats: [],
    aspCountStats: [],
    aspirationsStats: [],
    highaspStats: [],
    midaspStats: [],
    lowaspStats: [],
    aspnamesStats: [],
    Perc_total_aspirers: [],
    total_aspiration_count: "",
    total_aspirers_count: "",
    loading_handle: true,
    emps: "",
    resasp: ""
  };

  componentDidMount() {
    api
      .get("/hr/aspirations/")
      .then(res => {
        this.setState({
          dataStats: res.data.data,
          aspStats: res.data.data.asp,
          aspCountStats: res.data.data.aspires_count,
          aspirationsStats: res.data.data.no_aspirations,
          highaspStats: res.data.data1.highasp,
          midaspStats: res.data.data1.midasp,
          lowaspStats: res.data.data1.lowasp,
          aspnamesStats: res.data.data1.aspnames,
          total_aspiration_count: res.data.data.total_aspiration_count,
          total_aspirers_count: res.data.data.total_aspirers_count,
          loading_handle: false,
          Perc_total_aspirers: res.data.data.Perc_total_aspirers,
          emps: res.data.data.emps,
          resasp: res.data.data1.resasp
        });
      })
      .catch(err => console.log(err));
  }
  exportFileMA = () => {
    const {
      aspnamesStats,
      highaspStats,
      midaspStats,
      lowaspStats
    } = this.state;

    var headers = ["", ...aspnamesStats];
    const data = [
      ["High", ...highaspStats],
      ["Medium", ...midaspStats],
      ["Low", ...lowaspStats]
    ];
    excelexport({
      fname: "MeasuredAspirations",
      expfname: "MeasuredAspirations.xlsx",
      headers,
      data
    });
  };

  exportFileEA = () => {
    const {
      aspStats,
      aspCountStats,
      aspirationsStats,
      total_aspirers_count,
      total_aspiration_count
    } = this.state;

    var headers = ["", ...aspStats, "Total"];
    const data = [
      ["No. of Aspirers	", ...aspCountStats, total_aspirers_count],
      ["No. of Aspirations", ...aspirationsStats, total_aspiration_count]
    ];
    excelexport({
      fname: "ExpressedAspirations",
      expfname: "ExpressedAspirations.xlsx",
      headers,
      data
    });
  };
  phead() {
    return (
      <div className="row">
        <div className="col-md-8">
          <h3 className="box-title">Expressed Aspirations</h3>
        </div>

        <div className="col-md-4">
          <div style={{ float: "right" }}>
            <Button
              type="button"
              icon="pi pi-external-link"
              iconPos="left"
              label="excel"
              onClick={this.exportFileEA}
            />
          </div>
        </div>
      </div>
    );
  }
  phead2() {
    const { resasp, emps } = this.state;
    return (
      <div className="row">
        <div className="col-md-8">
          <h3 className="box-title">
            Measured Aspirations - Results of {resasp} / {emps}
          </h3>
        </div>
        <div className="col-md-4">
          <div style={{ float: "right" }}>
            <Button
              type="button"
              icon="pi pi-external-link"
              iconPos="left"
              label="excel"
              onClick={this.exportFileMA}
            />
          </div>
        </div>
      </div>
    );
  }
  render() {
    const excelAction = {
      float: "right"
    };
    const {
      dataStats,
      aspStats,
      aspCountStats,
      aspirationsStats,
      highaspStats,
      midaspStats,
      lowaspStats,
      aspnamesStats,
      loading_handle,
      Perc_total_aspirers,
      emps,
      resasp
    } = this.state;

    return (
      <div className="layout-main">
        {loading_handle === true ? (
          <>
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Panel header={this.phead()}>
                {/* <div className="box-header with-border">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="box-title">Expressed Aspirations</h3>
                  </div>

                  <div className="col-md-4">
                    <div style={excelAction}>
                      <Button
                        type="button"
                        icon="pi pi-external-link"
                        iconPos="left"
                        label="excel"
                        onClick={this.exportFileEA}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
                <div style={{ padding: 20 }}>
                  {dataStats.total_aspiration_count === 0 ? (
                    <h4>No Data</h4>
                  ) : (
                    <table
                      className="table text-center table-bordered table-hover"
                      id="tableEA"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {aspStats.map((asp, index) => (
                            <th key={index}>{asp}</th>
                          ))}
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>No. of Aspirers</b>
                          </td>
                          {aspCountStats.map((aspCount, index) => (
                            <td key={index}>{aspCount}</td>
                          ))}
                          <td>
                            {dataStats.total_aspirers_count} (
                            {Perc_total_aspirers}% of {emps})
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>No. of Aspirations</b>
                          </td>
                          {aspirationsStats.map((aspiration, index) => (
                            <td key={index}>{aspiration}</td>
                          ))}
                          <td>{dataStats.total_aspiration_count}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </Panel>
            </div>
            <div className="col-md-12">
              <Panel header={this.phead2()}>
                {/* <div className="box-header with-border">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="box-title">
                      Measured Aspirations - Results of {resasp} / {emps}
                    </h3>
                  </div>
                  <div className="col-md-4">
                    <div style={excelAction}>
                      <Button
                        type="button"
                        icon="pi pi-external-link"
                        iconPos="left"
                        label="excel"
                        onClick={this.exportFileMA}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
                <div style={{ padding: 20 }}>
                  {aspnamesStats.length > 0 ? (
                    <table
                      className="table text-center table-bordered table-hover"
                      id="tableMA"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {aspnamesStats.map((aspnames, index) => (
                            <th key={index}>{aspnames}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>High</b>
                          </td>
                          {highaspStats.map((highasp, index) => (
                            <td key={index}>{highasp}%</td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <b>Medium</b>
                          </td>
                          {midaspStats.map((midasp, index) => (
                            <td key={index}>{midasp}%</td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <b>Low</b>
                          </td>
                          {lowaspStats.map((lowasp, index) => (
                            <td key={index}>{lowasp}%</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <h4>No Data</h4>
                  )}
                </div>
              </Panel>
            </div>
          </div>
        )}
      </div>
    );
  }
}
