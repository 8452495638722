import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import { authLogout } from "../../store/actions/auth";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ReassignAdmin extends Component {
  state = {
    emps: [],
    sel_emp_details: [],
    sel_emp: "",
    data: [],
    confirm_dialog: false,
    password: "",
    password_error: false,
    passwordVisible: false,
    org: {},
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation) || !this.props.user_details.isSuperUser) this.fetchData();
    this.fetchData2();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
      this.fetchData2();
    }
  }
  fetchData2() {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { org: organisation };
    api
      .post("api/cce/get_org_data", data)
      .then((res) => {
        this.setState({
          org: res.data.org,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  fetchData() {
    api
      .get("api/me/get_managers")
      .then((res) => {
        this.setState({
          loading: false,
          emps: res.data.emps,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  assignbtn = () => {
    const data = {
      empid: this.state.sel_emp,
    };
    api
      .post("api/me/reassign_admin", data)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            loading: false,
            confirm_dialog: false,
            password: "",
            passwordVisible: false,
            sel_emp_details: [],
            sel_emp: "",
          });
          this.fetchData();
          this.fetchData2();
          this.growl.show({
            severity: "success",
            summary: "Successfully assigned",
          });

          this.props.logout();
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  getdetails = (e) => {
    this.setState({ sel_emp: e.value });

    this.setState({
      sel_emp_details: this.state.emps.filter((m) => {
        return m.value === e.value;
      }),
    });
  };

  onHide = () => {
    this.setState({
      confirm_dialog: false,
      password: "",
      passwordVisible: false,
    });
  };

  confirm_click = () => {
    if (this.state.password.length > 0) {
      api
        .post("/auth/login", {
          username: this.props.user_details.username,
          password: this.state.password,
        })
        .then((res) => {
          this.assignbtn();
        })
        .catch((error) => {
          this.setState({ password_error: true });
        });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Enter password",
      });
    }
  };
  assign_click = () => {
    if (this.state.sel_emp.toString().length > 0) {
      this.setState({ confirm_dialog: true });
    } else {
      this.growl.show({
        severity: "error",
        summary: "select user",
      });
    }
  };

  definition = () => {
    this.setState({
      visible: true,
    });
  };

  dialoghead = () => {
    return <span style={{ color: this.state.headercolor, fontSize: 18 }}>Reassign Admin</span>;
  };

  render() {
    const { org } = this.state;
    var header = (
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3">
            <InputText
              type="search"
              style={{ marginRight: "" }}
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
              placeholder="Search..."
              size="30"
            />
          </div>
        </div>
      </div>
    );
    return (
      <div className={"layout-main"}>
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Dialog
          className=""
          appendTo={document.body}
          header={"Confirmation"}
          visible={this.state.confirm_dialog}
          onHide={this.onHide}
          style={{ width: 500, overflow: "scroll" }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ paddingTop: 18 }}>Selected user : {this.state.sel_emp_details.length > 0 ? this.state.sel_emp_details[0].label : null}</div>
            <div style={{ marginTop: 18 }}>
              <InputText
                type={this.state.passwordVisible ? "text" : "password"}
                className={" input-text regforminput1 " + (!this.state.password_error ? "" : "p-error")}
                style={{ marginRight: "" }}
                onChange={(e) =>
                  this.setState({
                    password: e.target.value,
                    password_error: false,
                  })
                }
                value={this.state.password}
                placeholder="Password..."
                size="30"
              />
              <span className="p-inputgroup-addon">
                <i
                  onClick={() =>
                    this.setState({
                      passwordVisible: !this.state.passwordVisible,
                    })
                  }
                  className={this.state.passwordVisible ? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer"}
                ></i>
              </span>
            </div>
            {this.state.password_error ? <div style={{ fontSize: 12, color: "red" }}>Invalid Password</div> : null}
            {!this.props.user_details.isSuperUser ? (
              <div style={{ marginTop: 18 }}>Note: You will be logged out after assigning this role successfully</div>
            ) : null}

            <div>
              <Button style={{ marginTop: 18 }} label="confirm" onClick={this.confirm_click} />
            </div>
          </div>
        </Dialog>
        <Dialog
          className=""
          appendTo={document.body}
          header={this.dialoghead()}
          visible={this.state.visible}
          onHide={() => {
            this.setState({ visible: false });
          }}
          // style={{
          //   minWidth: hidd ? "80vw" : "60vw",
          //   marginLeft: "8vw",
          //   marginTop: "3vw",
          //   maxheight: "40vw"
          // }}
        >
          <span style={{ lineHeight: "2" }}>
            <p>
              You can assign your role to any other person as per the search list. The search list will have the names of administrators. Once
              reassigned, it will move all your existing data to the new administrator. Use this feature with Caution
            </p>
          </span>
        </Dialog>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {this.props.menuProps.label}{" "}
                <span>
                  <FontAwesomeIcon
                    onClick={this.definition}
                    style={{
                      cursor: "pointer",
                      color: "#00a3b4",
                      fontSize: "14px",
                    }}
                    icon={["far", "question-circle"]}
                  />
                </span>
              </div>
            </div>
          }
        >
          <div className="p-grid">
            <Dropdown
              className="readynextdd"
              style={{ width: "22em", marginLeft: "1em" }}
              //size={70}
              value={this.state.sel_emp}
              options={this.state.emps}
              placeholder="Click here..."
              onChange={(e) => this.getdetails(e)}
              filter={true}
              filterPlaceholder="Search user"
              filterBy="label"
            />
            <Button label="Reassign" onClick={this.assign_click} style={{ marginLeft: 18 }} />
          </div>
          {/* {this.state.sel_emp_details.length > 0 ? (
            <div className="p-grid">
              {this.state.sel_emp_details[0].label},
              {this.state.sel_emp_details[0].section},
              {this.state.sel_emp_details[0].dept}
            </div>
          ) : null} */}
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReassignAdmin);
