export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SIDEMENU = "AUTH_SIDEMENU";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const ORG_SETTINGS = "ORG_SETTINGS";
export const ORG_SETTINGS_FAIL = "ORG_SETTINGS_FAIL";
export const UTIL_SET_ORG = "UTIL_SET_ORG";
export const QUESTIONS_LOADING = "QUESTIONS_LOADING";
export const QUESTIONS_COMPLETED = "QUESTIONS_COMPLETED";
export const QUESTION_NEXT = "QUESTION_NEXT";
export const GROUP_ANSWERS = "GROUP_ANSWERS";
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const SETPROFILEPIC = "SET_PROFILE_PIC";
export const PROFILEPICLOADING = "PROFILE_PIC_LOADING";
export const MARK_READ_HELP = "MARK_READ_HELP";
export const INCREMENT_QUESTION_COUNT = "INCREMENT_QUESTION_COUNT";
export const MARK_PAID = "MARK_PAID";
export const SHOW_DETAILS = "SHOW_DETAILS";
export const SET_LOADING = "SET_LOADING";
