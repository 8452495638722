import React, { Component } from "react";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { connect } from "react-redux";
import { getquestion } from "../../store/actions/question";
import { orgSettings } from "../../store/actions/auth";

class OneInMany2 extends Component {
  state = {
    OneInMany2question: this.props.OneInMany2_question,
    selected_option: {},
    enable_next: true,
    user: this.props.user_details,
    sel_language: this.props.language,
    alloptions: this.props.OneInMany2_question.item.options,
  };

  componentDidUpdate() {
    if (this.state.OneInMany2question !== this.props.OneInMany2_question) {
      this.setState({
        OneInMany2question: this.props.OneInMany2_question,
        selected_option: {},
        enable_next: true,
        user: this.props.user_details,
        sel_language: this.props.language,
      });
    }
  }
  language_options = (selected_language) => {
    return this.state.OneInMany2question.item.options_translations.map(
      (Option) => {
        return (
          <div
            key={Option.key}
            className="p-grid cursor-pointer"
            style={{ marginRight: "25px", marginLeft: "15px" }}
          >
            <RadioButton
              value={Option.key}
              name={Option.value}
              onChange={(e) =>
                this.setState({ selected_option: Option, enable_next: false })
              }
              checked={this.state.selected_option === Option}
            />
            <label>{Option.value}</label>
          </div>
        );
      }
    );
  };
  options = () => {
    return this.state.OneInMany2question.item.options.map((Option) => {
      return (
        <div
          key={Option.key}
          className="p-grid cursor-pointer"
          style={{
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          <RadioButton
            className="p-col-1"
            inputId={Option.answer_id + Option.key}
            value={Option.key}
            name={Option.value}
            onChange={(e) =>
              this.setState({ selected_option: Option, enable_next: false })
            }
            checked={this.state.selected_option === Option}
          />
          <label
            htmlFor={Option.answer_id + Option.key}
            className="p-col-11 p-radiobutton-label"
            style={{ cursor: "pointer" }}
          >
            {Option.value}
          </label>
        </div>
      );
    });
  };
  loadQuestion = (isResponse) => {
    this.setState({ loading: true });
    let data = {
      response_type: this.state.OneInMany2question.item.response_type,
      version: this.state.OneInMany2question.currentUserItem.version,
      options: this.state.selected_option,
      alloptions: this.state.alloptions,
      itemid: this.state.OneInMany2question.item.guid,
    };
    this.props.getquestion(data, 1);
  };

  get_language_item = () => {};

  render() {
    const { enable_next, OneInMany2question } = this.state;
    const { user_details, language } = this.props;
    const item_response_lead_language =
      OneInMany2question.item.languages.item_response_lead.filter(
        (l) => l[language]
      );
    const item_language = OneInMany2question.item.languages.item
      ? OneInMany2question.item.languages.item.filter((l) => l[language])
      : [];
    const lead = this.state.OneInMany2question.item.item.split(";");
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-3 p-lg-3"></div>
        <div className="p-col-12 p-md-6 p-lg-6 relative no-padding">
          <Panel header={"ATMA - Questionnaire"}>
            <div
              className="dialog-p-container text-question-bold"
              style={{ paddingBottom: "10px" }}
            >
              {this.state.OneInMany2question.item.show_questions
                ? (lead.map((i, index) => <p key={index}>{i}</p>),
                  this.state.OneInMany2question.item.guid)
                : lead.map((i, index) => <p key={index}>{i}</p>)}
            </div>
            <div className="" style={{ padding: "10px", paddingBottom: 1 }}>
              {this.state.OneInMany2question.item.item_response_lead}
            </div>
            <div
              className="dialog-p-container"
              style={{ paddingBottom: "10px" }}
            >
              {this.state.OneInMany2question.item.item_image ? (
                <div className="p-col-12">
                  <img
                    className="img-question"
                    onError={(i) => (i.target.style.display = "none")}
                    src={this.state.OneInMany2question.item.item_image}
                  />
                </div>
              ) : (
                ""
              )}
              {this.options()}
            </div>
            <div className="dialog-action-button">
              {this.props.user_details.organisation.org_ver_type === "EV" && (
                <Button
                  style={{ marginRight: 9 }}
                  className="p-button-rounded p-button-warning purplebtn"
                  label="Take a Break"
                  // disabled={this.props.progress < 30}
                  // icon="pi pi-arrow-right"
                  // iconPos="right"
                  onClick={() => this.props.onBreak()}
                />
              )}
              <Button
                className="p-button-rounded p-button-warning"
                label="Next"
                disabled={enable_next}
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => this.loadQuestion(true)}
              />
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state,
    user_details: state.auth.user_details,
    language: state.auth.user_details.language_preference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getquestion: (data, return_item) =>
      dispatch(getquestion(data, return_item)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneInMany2);
