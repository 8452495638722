import React, { Component } from "react";
import api from "../../api";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MeDashboard from "./MeDashboard";
import { connect } from "react-redux";

class SharedDashboards extends Component {
  state = {
    empNo: null,
    options: [],
    load1: true,
    load2: false,
    medash: true,
    userb: false,
    value: null,
    name: "",
    key: 0,
  };
  componentDidMount() {
    if (
      this.props.user_details.organisation &&
      (this.props.user_details.organisation.share_mng === "share" || this.props.user_details.organisation.share_mng === "share_all")
    ) {
      api
        .get("/api/me/dashshared_emp_list")
        .then((res) => {
          if (res.data.success) {
            this.setState({ options: res.data.data });
          }
          this.setState({ load1: false });
        })

        .catch((err) => console.log(err));
    } else {
      this.setState({ load1: false });
    }
  }

  empSearch = () => {
    if (this.state.empNo !== null) {
      this.setState({
        medash: false,
        userb: true,
        value: this.state.empNo,
        key: this.state.key + 1,
      });
    }
  };
  render() {
    return (
      <div className="layout-main">
        <Panel header={<div style={{ fontSize: "18px" }}>{this.props.menuProps.label}</div>}>
          {this.state.load1 ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : (
            <div>
              {this.state.options.length === 0 ||
              (this.props.user_details.organisation && this.props.user_details.organisation.share_mng === "off") ? (
                <div className="col-md-12">
                  <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                    <div
                      className="medialog"
                      style={{
                        fontSize: "20px",
                        fontWeight: "800px",
                        textAlign: "center",
                      }}
                    >
                      No one has shared dashboard
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-grid">
                  <div className="p-col-2">
                    <label>Search: </label>
                  </div>
                  <Dropdown
                    style={{ width: "19em", marginLeft: "-3em" }}
                    size={30}
                    value={this.state.empNo}
                    options={this.state.options}
                    placeholder={this.state.options.length === 0 ? "No one shared the dashboard" : "Click here..."}
                    onChange={(e) => this.setState({ empNo: e.value })}
                    filter={true}
                    filterPlaceholder="Search"
                    filterBy="label,value"
                  />

                  <Button style={{ marginLeft: "5em" }} onClick={() => this.empSearch()} label="Go" />
                </div>
              )}
            </div>
          )}
        </Panel>
        {this.state.medash ? null : (
          <Panel
            header={
              <div
                style={{
                  fontSize: "15px",
                }}
              >
                {
                  this.state.options.filter((e) => {
                    return e.value === this.state.value;
                  })[0].label
                }
              </div>
            }
          >
            <>
              <MeDashboard key={this.state.key} user={this.state.value} userb={this.state.userb} callback={this.succsessmsg} />
            </>
          </Panel>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    configuration: state.auth.user_details.organisation.configuration,
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(SharedDashboards);
