import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildOptionsScatter } from "../Helper";

class EChartScatter extends Component {
  render() {
    const { chartData = {} } = this.props;
    return (
      <div>
        {this.props.pvsp ? (
          <ReactEcharts
            style={{ height: "35em" }}
            option={BuildOptionsScatter(chartData)}
          />
        ) : (
          <ReactEcharts option={BuildOptionsScatter(chartData)} />
        )}
      </div>
    );
  }
}
export default EChartScatter;
