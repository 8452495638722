import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import moment from "moment";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import AwakeConfig from "./AwakeConfig";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { object } from "prop-types";
import { Dropdown } from "primereact/dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class ManageUserData extends Component {
  state = {
    radioValue: "",
    cols: [],
    rows: [],
    errors: {},
    file: null,
    progress: false,
    result: [],
    sel_orgnames: [],
    loading: false,
    resultgenbtn: false,
    jobProfileDetails: false,
    msgDialog: false,
    fileLink: "",
    loader: false,
    selDecision: [],
    selStatus: [],
    columns: [],
    first: 0,
    trows: 10,
    org: {},
    dataStats: null,
    errorTableData: null,
    headers: {},
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    grade: "",
    department: "",
    native_language: "",
    gender: "",
    mobile_code: "91",
    errorMsg: "",
    levels: [],
    departmentsList: [],
    emailLinkDialog: false,
    newUserDialog: false,
    editUserDialog: false,
    userExistDialog: false,
    userExist: "",
    userDetails: null,
    hover_text: {},
    userConfig: {},
    lang_options: [],
    employeeNumberSearch: "",
    editUserDetails: null,
    userIdMapping: "",
  };

  handleFile = () => {
    this.setState({ rows: [], cols: [] });
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    // console.log("radio:",radioValue, "file:", file, "org:", organisation);
    const { file } = this.state;
    const files = file;
    if (!files) {
      this.growl.show({
        severity: "error",
        detail: "Choose File",
      });
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
      });

      let cols = [];
      let rows = [];
      let emptyRowCount = 0;
      data.some((r, index) => {
        if (index == 0) {
          r.forEach((r1) => {
            cols.push({
              field: r1,
            });
          });
        }
        if (index > 0) {
          let obj = {};
          r.some((r1, index2) => {
            obj[cols[index2].field] = r1;
            return emptyRowCount === 10;
          });
          if (isEmptyRow(obj)) emptyRowCount += 1;
          else rows.push(obj);
        }
        return emptyRowCount === 10;
      });
      this.setState({ rows, progress: false });

      let datasending = {
        data: rows,
        org: [organisation.name],
        name: file["name"],
      };

      this.setState({ loading: true, loader: true });
      // console.log("ds", datasending);
      api.post("api/organisation/employees", datasending).then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.msg,
            life: 10000,
          });
          //   this.filedownload(res.data.data);
          let columns = [];
          if (res.data.data && res.data.data.dataStats) {
            this.setState({ dataStats: res.data.data.dataStats });
          } else {
            this.setState({ dataStats: null });
          }
          if (res.data.data && res.data.data.failedData && res.data.data.failedData.length > 0) {
            Object.keys(res.data.data.failedData[0]).map((m) => columns.push(m));

            this.setState({ errorTableData: res.data.data.failedData, columns });
          } else {
            this.setState({ errorTableData: null, columns: [] });
          }
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.msg,
            life: 10000,
          });

          this.setState({
            // radioValue: "",
            result: [res.data.msg],
            // file: null,
            loading: false,
            loader: false,
          });
        }
      });
    };
    if (rABS) reader.readAsBinaryString(files);
    else reader.readAsArrayBuffer(files);
  };

  update = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const { errorTableData } = this.state;
    this.setState({ loading: true, loader: true });
    let datasending = {
      data: errorTableData,
      org: [organisation.name],
      name: "edited data",
    };

    api.post("api/organisation/employees", datasending).then((res) => {
      this.setState({ loading: false, loader: false });
      if (res.data.result) {
        this.growl.show({
          severity: "success",
          detail: res.data.msg,
          life: 10000,
        });
        //   this.filedownload(res.data.data);
        let columns = [];
        if (res.data.data.dataStats) {
          this.setState({ dataStats: res.data.data.dataStats });
        } else {
          this.setState({ dataStats: null });
        }
        if (res.data.data.failedData && res.data.data.failedData.length > 0) {
          Object.keys(res.data.data.failedData[0]).map((m) => columns.push(m));

          this.setState({ errorTableData: res.data.data.failedData, columns });
        } else {
          this.setState({ errorTableData: null, columns: [] });
        }
      } else {
        this.growl.show({
          severity: "error",
          detail: res.data.msg,
          life: 10000,
        });

        this.setState({
          // radioValue: "",
          result: [res.data.msg],
          // file: null,
          loading: false,
          loader: false,
        });
      }
    });
  };
  filedownload = (url) => {
    // console.log(url);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation) || !this.props.user_details.isSuperUser) this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  jsonCheck = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };
  fetchData() {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { org: organisation };
    api
      .post("api/cce/get_org_data", data)
      .then((res) => {
        this.setState({
          org: res.data.org,
          headers: res.data.headers,
          levels: res.data.levels,
          departmentsList: res.data.department,
          hover_text: res.data.hover_text,
          userConfig: res.data.userConfig,
          lang_options: res.data.lang_options,
          userIdMapping: res.data.userIdMapping,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onEditorValueChange = (props, value) => {
    let errorTableData = [...props.value];
    errorTableData[props.rowIndex][props.field] = value;
    this.setState({ errorTableData: errorTableData });
  };

  inputTextEditor = (props) => {
    return <InputText type="text" value={props.rowData[props.field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
  };

  valEditor = (props) => {
    return this.inputTextEditor(props);
  };
  requiredValidator = (props) => {
    let value = props.rowData[props.field];
    return value && value.length > 0;
  };

  editField(rowData, props) {
    return (
      <div style={{ textAlign: "center" }}>
        <InputText type="text" value={props.rowData[props.field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />
      </div>
    );
  }
  handleUserUpload = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const {
      first_name,
      last_name,
      mobile,
      email,
      grade,
      gender,
      mobile_code,
      headers,
      department,
      userConfig,
      native_language,
      editUserDialog,
      editUserDetails,
      newUserDialog,
    } = this.state;
    let errors = false;
    if (userConfig?.firstName?.field_include && userConfig?.firstName?.mandatory) {
      if (!first_name) {
        errors = true;
      }
    }
    if (userConfig?.lastName?.field_include && userConfig?.lastName?.mandatory) {
      if (!last_name) {
        errors = true;
      }
    }
    if (userConfig?.phone?.field_include && userConfig?.phone?.mandatory) {
      if (!mobile) {
        errors = true;
      }
    }
    if (userConfig?.email?.field_include && userConfig?.email?.mandatory) {
      if (!email) {
        errors = true;
      }
    }
    if (userConfig?.grade?.field_include && userConfig?.grade?.mandatory) {
      if (!grade) {
        errors = true;
      }
    }
    if (userConfig?.gender?.field_include && userConfig?.gender?.mandatory) {
      if (!gender) {
        errors = true;
      }
    }
    if (userConfig?.department?.field_include && userConfig?.department?.mandatory) {
      if (!department) {
        errors = true;
      }
    }
    if (userConfig?.nativeLanguage?.field_include && userConfig?.nativeLanguage?.mandatory) {
      if (!native_language) {
        errors = true;
      }
    }
    if (!errors) {
      let datasending = {
        data: editUserDialog
          ? [
              {
                [headers.action]: "U",
                [headers.firstName]: first_name,
                [headers.lastName]: last_name,
                [headers.email]: email,
                [headers.phone]: mobile,
                [headers.gender]: gender,
                [headers.grade]: grade,
                [headers.agencyName]: editUserDetails.agencyName,
                [headers.band]: editUserDetails.band,
                [headers.company]: organisation["account_name"],
                [headers.dateOfBirth]: editUserDetails.date_of_birth,
                [headers.dateOfJoining]: editUserDetails.date_of_joining,
                [headers.dateOfLeaving]: editUserDetails.date_of_leaving,
                [headers.department]: department,
                [headers.designation]: editUserDetails.designation,
                [headers.employeeNumber]: editUserDetails.employee_number,
                [headers.functionalHead]: editUserDetails.functional_head,
                [headers.gateCardNumber]: editUserDetails.gate_card_number,
                [headers.hrEmployeeNumber]: editUserDetails.reporting_hr_employee_number,
                [headers.hrName]: editUserDetails.hrName,
                [headers.location]: editUserDetails.location,
                [headers.managerEmployeeNumber]: editUserDetails.reportingManager_employeeNumber,
                [headers.managerName]: editUserDetails.managerName,
                [headers.maritalStatus]: editUserDetails.marital_status,
                [headers.nativeLanguage]: native_language,
                [headers.password]: "",
                [headers.poNumber]: editUserDetails.ponumber,
                [headers.section]: editUserDetails.section,
                [headers.skillTypes]: editUserDetails.skillTypes,
                [headers.subSection]: editUserDetails.subsection,
                [headers.subZone]: editUserDetails.subzone,
                [headers.title]: editUserDetails.title,
                [headers.userId]: editUserDetails.user_id,
                [headers.zone]: editUserDetails.zone,
              },
            ]
          : [
              {
                [headers.action]: "A",
                [headers.firstName]: first_name,
                [headers.lastName]: last_name,
                [headers.email]: email,
                [headers.phone]: mobile,
                [headers.gender]: gender,
                [headers.grade]: grade,
                [headers.agencyName]: organisation["account_name"],
                [headers.band]: "",
                [headers.company]: "",
                [headers.dateOfBirth]: "",
                [headers.dateOfJoining]: "",
                [headers.dateOfLeaving]: "",
                [headers.department]: department,
                [headers.designation]: "",
                [headers.employeeNumber]: "",
                [headers.functionalHead]: "",
                [headers.gateCardNumber]: "",
                [headers.hrEmployeeNumber]: "",
                [headers.hrName]: "",
                [headers.location]: "",
                [headers.managerEmployeeNumber]: "",
                [headers.managerName]: "",
                [headers.maritalStatus]: "",
                [headers.nativeLanguage]: native_language,
                [headers.password]: "",
                [headers.poNumber]: "",
                [headers.section]: "",
                [headers.skillTypes]: "",
                [headers.subSection]: "",
                [headers.subZone]: "",
                [headers.title]: "",
                [headers.userId]: "",
                [headers.zone]: "",
              },
            ],
        org: [organisation.name],
        name: "Single User Upload",
      };
      this.setState({ loading: true, loader: true });
      api.post("api/organisation/employees", datasending).then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: res.data.msg,
            life: 10000,
          });
          //   this.filedownload(res.data.data);

          if (newUserDialog && res.data.data && res.data.data.failedData && res.data.data.failedData.length > 0) {
            this.setState({
              errorMsg: res.data.data.failedData[0].Error,
            });
            if (res.data.data.failedData[0].userExist) {
              this.setState(
                {
                  errorMsg: res.data.data.failedData[0].Error,
                  userExist: res.data.data.failedData[0].userExist,
                  userExistDialog: true,
                  emailLinkDialog: false,
                  newUserDialog: false,
                  editUserDialog: false,
                  editUserDetails: null,
                  employeeNumberSearch: "",
                  errorMsg: "",
                },
                () => this.getUserExistDetails()
              );
            }
          } else if (editUserDialog) {
            this.setState({
              newUserDialog: false,
              editUserDialog: false,
              editUserDetails: null,
              employeeNumberSearch: "",
            });
          } else {
            this.setState({
              emailLinkDialog: true,
              errorMsg: "",
            });
            if (res.data.username) {
              this.setState({ username: res.data.username });
            }
          }
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.msg,
            life: 10000,
          });

          this.setState({
            // radioValue: "",
            result: [res.data.msg],
            // file: null,
            loading: false,
            loader: false,
          });
        }
      });
    } else {
      this.growl.show({
        severity: "error",
        detail: "Enter all required fields",
      });
    }
  };
  copy_mail_link = (username) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let datasending = {
      username: username,
      org: organisation,
    };
    api
      .post("api/cce/copy_mail_link", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          const email_link = res.data.email_link;
          if ("clipboard" in navigator) {
            this.growl.show({
              severity: "success",
              detail: "Link copied to clipboard",
              life: 10000,
            });
            return navigator.clipboard.writeText(email_link);
          } else {
            this.growl.show({
              severity: "success",
              detail: "Link copied to clipboard",
              life: 10000,
            });
            return document.execCommand("copy", true, email_link);
          }
        } else {
          this.growl.show({
            severity: "error",
            detail: "Failed to copy link",
            life: 10000,
          });
        }
      })
      .catch((err) => {
        // this.growl.show({
        //   severity: "error",
        //   detail: "Failed to copy link",
        //   life: 10000,
        // });
      });
  };
  getUserExistDetails = () => {
    this.setState({ loading: true, loader: true });

    api
      .post("api/organisation/getUserScoreDetails", { userId: this.state.userExist })
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.setState({ userDetails: res.data.data });
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.msg,
            life: 10000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          detail: "Something went wrong",
          life: 10000,
        });
      });
  };

  send_reg_mail = (username) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    let datasending = {
      emps: [{ username: username }],
      org: organisation,
    };
    api
      .post("api/cce/send_reg_mail_link", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          this.growl.show({
            severity: "success",
            detail: "Mail sent successfully",
            life: 10000,
          });
          this.setState({});
        } else {
          this.growl.show({
            severity: "error",
            detail: res.data.msg,
            life: 10000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          detail: "Something went wrong",
          life: 10000,
        });
      });
  };
  changeMobile = (e) => {
    const invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  handleEmployeeSearch = () => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    this.setState({ loading: true, loader: true });
    const datasending = { employeeNumber: this.state.employeeNumberSearch, org: organisation };
    api
      .post("api/cce/employee_search", datasending)
      .then((res) => {
        this.setState({ loading: false, loader: false });
        if (res.data.result) {
          const employee = res.data.employee;
          this.setState({
            editUserDetails: employee,
            employeeNumberSearch: "",
            first_name: employee.first_name,
            last_name: employee.last_name,
            mobile: employee.phone_number,
            email: employee.email,
            grade: employee.grade_desc,
            department: employee.dept_id,
            native_language: employee.language_preference,
            gender: employee.gender,
          });
        } else {
          this.growl.show({
            severity: "error",
            detail: "Employee not found",
            life: 10000,
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          detail: "Something went wrong",
          life: 10000,
        });
      });
  };
  render() {
    const {
      errorTableData,
      columns,
      org,
      dataStats,
      headers,
      errorMsg,
      first_name,
      last_name,
      mobile,
      email,
      grade,
      department,
      native_language,
      gender,
      mobile_code,
      levels,
      departmentsList,
      lang_options,
      emailLinkDialog,
      selDecision,
      selStatus,
      userExist,
      userExistDialog,
      userDetails,
      username,
      hover_text,
      userConfig,
      employeeNumberSearch,
      editUserDialog,
      editUserDetails,
      userIdMapping,
    } = this.state;
    const userStatus = [
      { label: "ALL", value: "ALL" },
      { label: "ACTIVE", value: "ACTIVE" },
      { label: "DEACTIVED", value: "DEACTIVED" },
    ];
    const hiringDecision = [
      { label: "ALL", value: "ALL" },
      { label: "SELECTED", value: "SELECTED" },
      { label: "NOT SELECTED", value: "NOT SELECTED" },
    ];
    const genderOptions = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "female" },
      { label: "Others", value: "others" },
    ];
    const columnComponents = columns.map((col) => {
      if (col) {
        if (col !== "Error" && col !== "userExist") {
          return (
            <Column
              field={col}
              body={(rowData, props) => this.editField(rowData, props)}
              header={col}
              //   editor={this.valEditor}
              style={{ height: "3.5em" }}
              //   editorValidator={this.requiredValidator}
            />
          );
        }
      }
    });
    return (
      <div className="p-col-12">
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll", width: 500 }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>User already exist</span>}
          visible={userExistDialog}
          onHide={() =>
            this.setState({
              userExistDialog: false,
              userDetails: null,
            })
          }
        >
          <div>
            {userDetails && (
              <div>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", overflowWrap: "anywhere" }}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>Name</div>
                    <div>
                      <b>{userDetails.name}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.email}</div>
                    <div>
                      <b>{userDetails.email}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.phone}</div>
                    <div>
                      <b>{userDetails.phone}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.grade}</div>
                    <div>
                      <b>{userDetails.grade}</b>
                    </div>
                  </div>
                  {/* <div style={{ width: "40%", textAlign: "left" }}>
                    <label>{headers.gender}</label>
                    <div>{userDetails.gender}</div>
                  </div> */}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                  {userDetails.overall_score ? (
                    <div style={{ fontSize: 18, textAlign: "center" }}>
                      {userDetails.lastans && (
                        <div>
                          The User has already taken the test on <b>{userDetails.lastans}</b>
                        </div>
                      )}
                      <div>
                        The test score is <b>{userDetails.overall_score}</b>
                      </div>
                    </div>
                  ) : userDetails.is_deleted ? (
                    <div>
                      <div style={{ fontSize: 15, textAlign: "center", fontStyle: "italic" }}>
                        User id is already existing and deactivated. Can not create new user in the same id. Try with new user id
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ fontSize: 15, textAlign: "center", fontStyle: "italic" }}>User has not completed the test</div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                          style={{ margin: 9 }}
                          className="p-button-warning"
                          onClick={() => this.copy_mail_link(userExist)}
                          label="Copy test link"
                        ></Button>

                        <Button
                          style={{ margin: 9 }}
                          className="p-button-warning"
                          onClick={() => this.send_reg_mail(userExist)}
                          label="Send email"
                        ></Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Dialog>
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll", width: emailLinkDialog ? 500 : 300 }}
          appendTo={document.body}
          header={
            <span style={{ color: "#FF9800", fontSize: 18 }}>
              {emailLinkDialog ? (
                <span>
                  User <b>{first_name + " " + last_name}</b> is created successfully
                </span>
              ) : editUserDialog ? (
                "Update User Details"
              ) : (
                "Enter New User Details"
              )}
            </span>
          }
          visible={this.state.newUserDialog || this.state.editUserDialog}
          onHide={() =>
            this.setState({
              emailLinkDialog: false,
              newUserDialog: false,
              editUserDialog: false,
              editUserDetails: null,
              employeeNumberSearch: "",
              errorMsg: "",
              first_name: "",
              last_name: "",
              mobile: "",
              email: "",
              grade: "",
              gender: "",
              department: "",
              native_language: "",
              mobile_code: "91",
            })
          }
        >
          <div>
            {emailLinkDialog ? (
              <div style={{ textAlign: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", overflowWrap: "anywhere" }}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>Name</div>
                    <div>
                      <b>{first_name + " " + last_name}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.email}</div>
                    <div>
                      <b>{email}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.phone}</div>
                    <div>
                      <b>{mobile}</b>
                    </div>
                  </div>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <div>{headers.grade}</div>
                    <div>
                      <b>{grade}</b>
                    </div>
                  </div>
                  {/* <div style={{ width: "40%", textAlign: "left" }}>
                    <label>{headers.gender}</label>
                    <div>{gender}</div>
                  </div> */}
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button
                    style={{ margin: 9 }}
                    className="p-button-warning"
                    onClick={() => this.copy_mail_link(username)}
                    label="Copy test link"
                  ></Button>
                  <Button style={{ margin: 9 }} className="p-button-warning" onClick={() => this.send_reg_mail(username)} label="Send email"></Button>
                </div>
              </div>
            ) : editUserDialog && !editUserDetails ? (
              <div>
                <div style={{ margin: "0px 9px" }}>
                  <div>{headers.employeeNumber}</div>
                  <div>
                    <InputText
                      onChange={(e) => this.setState({ employeeNumberSearch: e.target.value })}
                      size="30"
                      placeholder="enter..."
                      value={employeeNumberSearch}
                    />
                  </div>
                </div>
                <div style={{ margin: "0px 9px" }}>
                  <div style={{ margin: 9, textAlign: "center", marginTop: 60 }}>
                    <Button type="submit" onClick={this.handleEmployeeSearch} className="p-button-warning" label="Search"></Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {userConfig?.firstName?.field_include && (
                  <div style={{ margin: "0px 9px" }}>
                    <div className={userConfig?.firstName.mandatory ? "formlabel" : ""}>{headers.firstName}</div>
                    <div>
                      <InputText
                        onChange={(e) => this.setState({ first_name: e.target.value })}
                        size="30"
                        placeholder="enter..."
                        value={first_name}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.lastName?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.lastName.mandatory ? "formlabel" : ""}>{headers.lastName}</div>
                    <div>
                      <InputText onChange={(e) => this.setState({ last_name: e.target.value })} size="30" placeholder="enter..." value={last_name} />
                    </div>
                  </div>
                )}
                {userConfig?.phone?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div>
                      <span className={userConfig?.phone.mandatory ? "formlabel" : ""}>{headers.phone}</span>{" "}
                      <span style={{ fontSize: 12, fontStyle: "italic" }}>(only numbers)</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      {/* +
                    <InputText
                      onChange={(e) =>
                        this.setState({ mobile_code: e.target.value ? (e.target.value.length < 3 ? e.target.value : mobile_code) : "" })
                      }
                      style={{ margin: "0px 5px", width: 50 }}
                      value={mobile_code}
                      type="number"
                    /> */}
                      <InputText
                        style={{ width: 300 }}
                        type="number"
                        onChange={(e) => this.setState({ mobile: e.target.value })}
                        placeholder="enter..."
                        value={mobile}
                        onKeyDown={this.changeMobile}
                        disabled={editUserDialog && userIdMapping === 4}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.email?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.email.mandatory ? "formlabel" : ""}>{headers.email}</div>
                    <div>
                      <InputText
                        disabled={editUserDialog && userIdMapping === 3}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        size="30"
                        placeholder="enter..."
                        value={email}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.gender?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.gender.mandatory ? "formlabel" : ""}>{headers.gender}</div>
                    <div>
                      <Dropdown
                        value={gender}
                        options={genderOptions}
                        onChange={(e) => this.setState({ gender: e.target.value })}
                        placeholder="Select..."
                        style={{ width: 260 }}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.grade?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.grade.mandatory ? "formlabel" : ""}>{headers.grade}</div>
                    <div>
                      <Dropdown
                        value={grade}
                        options={levels}
                        onChange={(e) => this.setState({ grade: e.target.value })}
                        placeholder="Select..."
                        style={{ width: 260 }}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.department?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.department.mandatory ? "formlabel" : ""}>{headers.department}</div>
                    <div>
                      <Dropdown
                        value={department}
                        options={departmentsList}
                        onChange={(e) => this.setState({ department: e.target.value })}
                        placeholder="Select..."
                        style={{ width: 260 }}
                      />
                    </div>
                  </div>
                )}
                {userConfig?.nativeLanguage?.field_include && (
                  <div style={{ margin: 9 }}>
                    <div className={userConfig?.nativeLanguage.mandatory ? "formlabel" : ""}>{headers.nativeLanguage}</div>
                    <div>
                      <Dropdown
                        value={native_language}
                        options={lang_options}
                        onChange={(e) => this.setState({ native_language: e.target.value })}
                        placeholder="Select..."
                        style={{ width: 260 }}
                      />
                    </div>
                  </div>
                )}
                <div style={{ margin: 9, textAlign: "center", marginTop: 60 }}>
                  <Button
                    type="submit"
                    onClick={this.handleUserUpload}
                    className="p-button-warning"
                    label={editUserDialog ? "Update" : "Create"}
                  ></Button>
                </div>
                {errorMsg && <div style={{ margin: 9, textAlign: "center", fontSize: 20, color: "red" }}>Error Message: {errorMsg}</div>}
              </div>
            )}
          </div>
        </Dialog>
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll" }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>File Details</span>}
          visible={this.state.msgDialog}
          onHide={() => this.setState({ msgDialog: false })}
        >
          <div>
            <div style={{ padding: 18 }}>
              File Upload successful
              <button
                style={{ fontSize: 12, padding: 2, borderRadius: 20, borderWidth: 1, margin: 5 }}
                onClick={() => this.filedownload(this.state.fileLink)}
              >
                Click Here
              </button>
              to download results
            </div>
          </div>
        </Dialog>
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll" }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>Status</span>}
          visible={this.state.loader}
          onHide={() => this.setState({ loader: false })}
          closable={false}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner></ProgressSpinner>
            </div>
          </div>
        </Dialog>

        <Panel
          header={
            <div>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.hUpLoad}</Tooltip>}>
                <div style={{ width: "fit-content" }}>Upload User Data</div>
              </OverlayTrigger>
            </div>
          }
        >
          <div>
            <div className="container-upload user-upload">
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline", flexWrap: "wrap" }}>
                  <div>
                    <div>
                      <input
                        id={"upload"}
                        type="file"
                        accept=".xlsx,image/*"
                        onChange={(event) => {
                          this.setState({
                            file: event.target.files[0],
                            rows: [],
                            cols: [],
                          });
                        }}
                        style={{ marginLeft: "20px" }}
                      />
                    </div>
                    <label style={{ marginLeft: 20 }}>(File type .xls or .xlsx)</label>
                  </div>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bUpload}</Tooltip>}>
                    <Button type="submit" onClick={this.handleFile} className="p-button-warning" label="Upload" style={{ margin: 15 }}></Button>
                  </OverlayTrigger>
                  {(org["org_ver_type"] === "RV" || org["org_ver_type"] === "REV") && (
                    <div style={{ marginTop: "50px", marginLeft: "15px" }}>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bAddUser}</Tooltip>}>
                        <Button
                          type="submit"
                          className="p-button-warning"
                          label="+ Add a user"
                          onClick={() => {
                            this.setState({ newUserDialog: true });
                          }}
                        ></Button>
                      </OverlayTrigger>
                    </div>
                  )}
                  <div style={{ margin: "15px 50px" }}>
                    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bEditUser}</Tooltip>}>
                      <Button
                        type="submit"
                        className="p-button-warning"
                        label="Edit user"
                        onClick={() => {
                          this.setState({ editUserDialog: true });
                        }}
                      ></Button>
                    </OverlayTrigger> */}
                  </div>
                  <div>
                    <div>
                      Last updated filename:{" "}
                      {org && org["emp_upload_fname"] && this.jsonCheck(org["emp_upload_fname"])
                        ? JSON.parse(org["emp_upload_fname"]).fname
                        : org && org["emp_upload_fname"]}
                    </div>
                    <div>
                      Date & Time:
                      {org && org["emp_upload_date"] && moment(org["emp_upload_date"]).format("lll")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {dataStats && (
              <div style={{ marginLeft: 50 }}>
                <p>No of users reads from file: {dataStats.totalRecs}</p>
                <p>No of users newly added: {dataStats.addRecs}</p>
                <p>No of users modified: {dataStats.modRecs}</p>
                <p>No of users deleted: </p>
                <p>No of users with No Action: {dataStats.noActionRecs}</p>
                <p>No of users with Errors: {dataStats.failedRecs}</p>
              </div>
            )}
          </div>

          {errorTableData && columns && (
            <div>
              <div style={{ marginBottom: 9 }}>
                <span>Users with data error: Please edit and </span>
                <Button type="submit" onClick={this.update} className="p-button-warning" label="Click here"></Button>
              </div>
              <DataTable
                value={errorTableData}
                style={{ marginBottom: "20px", width: "100%", whiteSpace: "nowrap", textAlign: "center" }}
                responsive={true}
                paginator={true}
                first={this.state.first}
                onPage={(e) => this.setState({ first: e.first, trows: e.rows })}
                emptyMessage={errorTableData.length === 0 ? "No data" : "Loading..."}
                rows={this.state.trows}
                resizableColumns={true}
                rowsPerPageOptions={[10, 20, 50, errorTableData ? errorTableData.length : ""]}
                className="scrolltable"
              >
                <Column
                  field={"Error"}
                  header={"Error"}
                  //   editor={this.valEditor}
                  style={{ height: "3.5em" }}
                  //   editorValidator={this.requiredValidator}
                />
                {columnComponents}
              </DataTable>
            </div>
          )}
        </Panel>
        <Panel className="noPaddingPanel" header={<div style={{ color: "white" }}>-</div>}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
              <div>
                <p style={{ fontWeight: 700 }}>Download existing users</p>
                <p>Filters:</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>User Status: </p>
                  <MultiSelect
                    style={{ margin: 9 }}
                    // optionLabel="Status"
                    value={this.state.selStatus}
                    options={userStatus}
                    onChange={(event) => {
                      this.setState({ selStatus: event.value });
                    }}
                    placeholder="Select"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>Hiring Decision:</p>
                  <MultiSelect
                    style={{ margin: 9 }}
                    // optionLabel="Status"
                    value={this.state.selDecision}
                    options={hiringDecision}
                    onChange={(event) => {
                      this.setState({ selDecision: event.value });
                    }}
                    placeholder="Select"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bDnload}</Tooltip>}>
                    <Button
                      style={{ margin: 9 }}
                      className="p-button-warning"
                      onClick={() => this.props.getFullEmpData(selDecision, selStatus)}
                      label="Download"
                    ></Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hover_text.bDnTempl}</Tooltip>}>
                    <Button
                      style={{ margin: 9 }}
                      className="p-button-warning"
                      onClick={() => this.props.getFullEmpData(null, null, true)}
                      label="Download Template"
                    ></Button>
                  </OverlayTrigger>
                </div>
              </div>
              <div style={{ width: 500 }}>
                <p style={{ fontWeight: 700 }}>Current User Statistics</p>
                <p>Total Active Users:</p>
                <p>Total Deactivated Users:</p>
                <div>
                  <DataTable value={this.state.data} emptyMessage={"No data"} rows={10} style={{ marginBottom: "20px" }}>
                    <Column field="employee_number" header="" />
                    <Column field="employee_number" header="Current Month" />
                    <Column field="first_name" header="Previous Month" />
                    <Column field="designation" header="YTD" />
                    <Column field="grade_desc" header="Period" />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(ManageUserData);
