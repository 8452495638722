import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";
import { SETPROFILEPIC } from "../../store/actions/actionTypes";

class AwakeConfig extends Component {
  state = {
    radioValue: "",
    cols: [],
    rows: [],
    errors: {},
    file: null,
    progress: false,
    result: null,
    sel_orgnames: [],
    loading: false,
  };

  handleFile = () => {
    let { radioValue, file } = this.state;
    const { organisation } = this.props;
    if (radioValue === "") {
      this.growl.show({
        severity: "error",
        detail: "Select atleast one option",
      });
    } else {
      const files = file;
      if (!files) {
        this.growl.show({
          severity: "error",
          detail: "Choose File",
        });
        return;
      }
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const bstr = reader.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        for (var i = 0; i <= wb["Workbook"]["Sheets"].length; i++) {
          console.log(wb["Workbook"]["Sheets"]);
          const wsname = wb.SheetNames[i];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });
          let cols = [];
          let rows = [];
          let emptyRowCount = 0;
          data.some((r, index) => {
            if (index == 0) {
              r.forEach((r1) => {
                cols.push({
                  field: r1,
                });
              });
            }
            if (index > 0) {
              let obj = {};
              r.some((r1, index2) => {
                obj[cols[index2].field] = r1;
                return emptyRowCount === 10;
              });
              rows.push(obj);
            }
            return emptyRowCount === 10;
          });
          this.setState({ rows, progress: false });
          let datasending = rows;
          this.setState({ loading: true });
          // console.log("ds", datasending)
          if (radioValue == "Awake Configuration") {
            if (wb["Workbook"]["Sheets"][i]) {
              if (wb["Workbook"]["Sheets"][i]["name"] == "Courses") {
                this.setState({ radioValue: "Courses" });
                setTimeout(() => {
                  api.post("api/cce/courses", datasending).then((res) => {
                    this.setState({
                      radioValue: "",
                      result: res.data.result,
                      loading: false,
                    });
                    this.props.result(res.data.result);
                  });
                }, 6000);
              } else if (wb["Workbook"]["Sheets"][i]["name"] == "JobFamily") {
                this.setState({ radioValue: "JobProfiles" });
                setTimeout(() => {
                  api.post("api/cce/JobProfiles", datasending).then((res) => {
                    this.setState({
                      radioValue: "",
                      result: res.data.result,
                      loading: false,
                    });
                    this.props.result(res.data.result);
                  });
                }, 12000);
              } else if (wb["Workbook"]["Sheets"][i]["name"] == "CourseToolMapping") {
                this.setState({ radioValue: "CourseToolMapping" });
                setTimeout(() => {
                  api.post("api/cce/CourseToolMapping", datasending).then((res) => {
                    this.setState({
                      radioValue: "",
                      result: res.data.result,
                      loading: false,
                    });
                    this.props.result(res.data.result);
                  });
                }, 18000);
              } else if (wb["Workbook"]["Sheets"][i]["name"] == "CourseJFMapping") {
                this.setState({ radioValue: "CourseJPMapping" });
                setTimeout(() => {
                  api.post("api/cce/CourseJPMapping", datasending).then((res) => {
                    this.setState({
                      radioValue: "",
                      result: res.data.result,
                      loading: false,
                    });
                    this.props.result(res.data.result);
                  });
                }, 24000);
              } else if (wb["Workbook"]["Sheets"][i]["name"] == "JFToolMapping") {
                this.setState({ radioValue: "JpToolMapping" });
                setTimeout(() => {
                  api.post("api/cce/JobDimensionMapping", datasending).then((res) => {
                    this.setState({
                      radioValue: "",
                      result: res.data.result,
                      loading: false,
                    });
                    this.props.result(res.data.result);
                  });
                }, 30000);
              }
              console.log("wb", wb["Workbook"]["Sheets"][i]["name"], radioValue);
            }
          }
        }
      };

      if (rABS) reader.readAsBinaryString(files);
      else reader.readAsArrayBuffer(files);
    }
  };

  updatedim = () => {
    api.post("api/assessments/newdimensions").then((res) => {
      this.setState({
        result: res.data.result,
      });
    });
  };

  updateitem = () => {
    api.post("api/assessments/newitem").then((res) => {
      this.setState({
        result: res.data.result,
      });
    });
  };
  Radiobtn = (event) => {
    this.setState({ radioValue: event.value });
    this.setState({ sel_orgnames: [] });
  };

  render() {
    const { result, radioValue, loading } = this.state;
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} position="topright" />
        <div className="p-grid">
          <div className="p-col-3">
            <RadioButton
              name="filename_type"
              value="Awake Configuration"
              onChange={(event) => this.setState({ radioValue: event.value })}
              checked={this.state.radioValue === "Awake Configuration"}
            >
              Awake Configuration
            </RadioButton>
            <label htmlFor="Awake Configuration" className="p-radiobutton-label">
              Job family Configuration
            </label>
          </div>
        </div>
        <div className="p-grid container-upload">
          <div className="p-col">
            <input
              type="file"
              accept=".xlsx"
              onChange={(event) => {
                this.setState({
                  file: event.target.files[0],
                  rows: [],
                  cols: [],
                });
              }}
              style={{ marginLeft: "20px" }}
            />
            <label>(The file format should be of type .xls or .xlsx)</label>
          </div>
          <div className="p-col">
            <Button type="submit" onClick={this.handleFile} className="btn btn-primary" label="Upload"></Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organisation: state.util.organisation,
  };
};

export default connect(mapStateToProps, null)(AwakeConfig);
