import React, { Component } from "react";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";
import PublicRouter from "./publicroutes";
import * as actions from "./store/actions/auth";
import CustomLayout from "./containers/Layout";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/layout.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "./utils/history";
import PublicLayout from "./containers/PublicLayout";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fab, fas, far);

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <Router history={history}>
        {this.props.isAuthenticated ? (
          <CustomLayout {...this.props}>
            <BaseRouter />
          </CustomLayout>
        ) : (
          <PublicLayout>
            <PublicRouter />
          </PublicLayout>
        )}
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
