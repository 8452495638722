import React, { Component } from "react";

export default class Aspiration extends Component {
  render() {
    return (
      <div className="layout-main">
        <div className="p-grid">
          <label></label>
        </div>
      </div>
    );
  }
}
