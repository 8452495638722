import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./containers/Login";
import Init from "./pages/cce/Init";
import PasswordManagement from "./pages/cce/PasswordManagement";
import CceAdmin from "./pages/cce/CceAdmin";
import DimScore from "./pages/cce/DimView";
import DimEmpScore from "./pages/cce/DimEmpScore";
import PushNotification from "./pages/cce/PushNotification";
import Dashboard from "./pages/me/Dashboard";
import Ineedhelp from "./pages/me/Ineedhelp";
import Aspiration from "./pages/me/Aspiration";
import Support from "./pages/me/CommunicationPage";
import EmployeeDimension from "./pages/cce/EmployeeDimension";
import store from "./store/store";
import NotFoundPage from "./containers/404Page";
import LeadershipIndex from "./pages/hr/LeadershipIndex";
import TopStrengths from "./pages/hr/TopStrengths";
import Training from "./pages/hr/TrainingNeeds";
import TopBottomTalents from "./pages/hr/TopBottomTalents";
import PervsPotA from "./pages/hr/PervsPotA";
import ReadyNext from "./pages/hr/ReadyNext";
import EngagementIndex from "./pages/hr/EngagementIndex";
import EmotionalWellness from "./pages/hr/EmotionalWellness";
import DimInf from "./pages/hr/DimInference";
import Hradmin from "./pages/hr/Hradmin";
import IHelp from "./pages/hr/IHelp";
import Aspirations from "./pages/hr/Aspirations";
import MindFrame from "./pages/hr/Mindframe";
import Statistics from "./pages/hr/Statistics";
import SharedDashboards from "./pages/me/SharedDashboards";
import MyTeamParent from "./pages/me/MyTeamParent";
import PaymentCallback from "./pages/payments/PaymentCallback";
import GroupConfiguration from "./pages/cce/GroupConfiguration";
import CPCL from "./common/CPCL";
import AssignAtmaAdmin from "./pages/cce/AssignAtmaAdmin";
import ReassignAdmin from "./pages/me/ReassignAdmin";
import EmployeeManagement from "./pages/cce/EmployeeManagement";
import AssignManagement from "./pages/cce/AssignManagement";
import DimensionResults from "./pages/cce/DimensionResults";
import CustConfig from "./pages/cce/CustConfig";
import Softskill from "./pages/me/Softskill";
import Jobfamily from "./pages/me/Jobfamily";
import AwakeMeDashboard from "./pages/me/AwakeMeDash";
import MyTrainingNeeds from "./pages/me/MyTrainingNeeds";

const BaseRouter = () => {
  const menu = store.getState().auth.user_details.sideMenu;

  let comp = {};
  if (Object.keys(store.getState().auth.user_details.organisation).length > 0) {
    comp = store.getState().auth.user_details.organisation.configuration.competencies;
  }

  let availableLinks = [];
  let menuObjProps = {};
  if (menu) {
    availableLinks = menu
      .map((m) => {
        if (!m.items) {
          menuObjProps[m.link] = m;
          return m.link;
        } else
          return m.items.map((mc) => {
            menuObjProps[mc.link] = mc;
            return mc.link;
          });
      })
      .toString();
  }
  const getMenuProps = (link) => {
    return menuObjProps[link];
  };
  return (
    <Switch>
      <Route path="/confirm-payment/(softskill|home|me/jobprofile|)/" component={PaymentCallback} />
      <Route path="/(login|)/" exact component={Login} />
      {availableLinks.includes("cce/init") ? <Route path="/cce/init" exact component={() => <Init menuProps={getMenuProps("cce/init")} />} /> : null}
      {availableLinks.includes("cce/pwdreset") ? (
        <Route path="/cce/pwdreset" exact component={() => <PasswordManagement menuProps={getMenuProps("cce/pwdreset")} />} />
      ) : null}
      {availableLinks.includes("cce/groupconfig") ? (
        <Route path="/cce/groupconfig" exact component={() => <GroupConfiguration menuProps={getMenuProps("cce/groupconfig")} />} />
      ) : null}
      {availableLinks.includes("cce/Q&Astats") ? (
        <Route path="/cce/Q&Astats" exact component={() => <CceAdmin type="cce" menuProps={getMenuProps("cce/Q&Astats")} />} />
      ) : null}
      {availableLinks.includes("cce/dimview") ? (
        <Route path="/cce/dimview" exact component={() => <DimScore menuProps={getMenuProps("cce/dimview")} />} />
      ) : null}
      {availableLinks.includes("cce/dimempview") ? (
        <Route path="/cce/dimempview" exact component={() => <DimEmpScore menuProps={getMenuProps("cce/dimempview")} />} />
      ) : null}
      {availableLinks.includes("cce/pushnotification") ? (
        <Route path="/cce/pushnotification" exact component={() => <PushNotification menuProps={getMenuProps("cce/pushnotification")} />} />
      ) : null}
      {availableLinks.includes("cce/employeedimension") ? (
        <Route path="/cce/employeedimension" exact component={() => <EmployeeDimension menuProps={getMenuProps("cce/employeedimension")} />} />
      ) : null}
      {availableLinks.includes("cce/custconfig") ? (
        <Route path="/cce/custconfig" exact component={() => <CustConfig menuProps={getMenuProps("cce/custconfig")} />} />
      ) : null}
      {availableLinks.includes("me/mydescription") ? (
        <Route path="/me/mydescription" exact component={() => <CPCL menuProps={getMenuProps("me/mydescription")} />} />
      ) : null}
      {availableLinks.includes("me/mytechskill") ? (
        <Route path="/me/mytechskill" exact component={() => <CPCL menuProps={getMenuProps("me/mytechskill")} />} />
      ) : null}
      {availableLinks.includes("me/mytriningneeds") ? (
        <Route path="/me/mytriningneeds" exact component={() => <CPCL menuProps={getMenuProps("me/mytriningneeds")} />} />
      ) : null}
      {availableLinks.includes("me/mytrainingnomandatt") ? (
        <Route path="/me/mytrainingnomandatt" exact component={() => <CPCL menuProps={getMenuProps("me/mytrainingnomandatt")} />} />
      ) : null}
      {availableLinks.includes("me/mytrainingfeed") ? (
        <Route path="/me/mytrainingfeed" exact component={() => <CPCL menuProps={getMenuProps("me/mytrainingfeed")} />} />
      ) : null}
      {availableLinks.includes("me/mytrainevalform") ? (
        <Route path="/me/mytrainevalform" exact component={() => <CPCL menuProps={getMenuProps("me/mytrainevalform")} />} />
      ) : null}
      {availableLinks.includes("me/reassign") ? (
        <Route path="/me/reassign" exact component={() => <ReassignAdmin menuProps={getMenuProps("me/reassign")} />} />
      ) : null}
      {availableLinks.includes("myteam/jobdesc") ? (
        <Route path="/myteam/jobdesc" exact component={() => <CPCL menuProps={getMenuProps("myteam/jobdesc")} />} />
      ) : null}
      {availableLinks.includes("myteam/skillmatrix") ? (
        <Route path="/myteam/skillmatrix" exact component={() => <CPCL menuProps={getMenuProps("myteam/skillmatrix")} />} />
      ) : null}
      {availableLinks.includes("myteam/trainingneeds") ? (
        <Route path="/myteam/trainingneeds" exact component={() => <CPCL menuProps={getMenuProps("myteam/trainingneeds")} />} />
      ) : null}
      {availableLinks.includes("myteam/tainingnomandatt") ? (
        <Route path="/myteam/tainingnomandatt" exact component={() => <CPCL menuProps={getMenuProps("myteam/tainingnomandatt")} />} />
      ) : null}
      {availableLinks.includes("myteam/posttrain") ? (
        <Route path="/myteam/posttrain" exact component={() => <CPCL menuProps={getMenuProps("myteam/posttrain")} />} />
      ) : null}
      {availableLinks.includes("myteam/jobdescbank") ? (
        <Route path="/myteam/jobdescbank" exact component={() => <CPCL menuProps={getMenuProps("myteam/jobdescbank")} />} />
      ) : null}
      {availableLinks.includes("me/home") ? <Route path="/me/home" exact component={Dashboard} /> : null}
      {availableLinks.includes("home") ? <Route path="/home" exact component={Dashboard} /> : null}
      {availableLinks.includes("mytrainingneeds") ? (
        <Route path="/mytrainingneeds" exact component={() => <MyTrainingNeeds menuProps={getMenuProps("mytrainingneeds")} />} />
      ) : null}
      {availableLinks.includes("softskill") ? <Route path="/softskill" exact component={Softskill} /> : null}
      {availableLinks.includes("me/teamstatus") ? (
        <Route path="/me/teamstatus" exact component={() => <CceAdmin type="team" user="manager" menuProps={getMenuProps("me/teamstatus")} />} />
      ) : null}
      {availableLinks.includes("me/init") ? <Route path="/me/init" exact component={() => <Init menuProps={getMenuProps("me/init")} />} /> : null}
      {availableLinks.includes("me/groupstatus") ? (
        <Route
          path="/me/groupstatus"
          exact
          component={() => <CceAdmin type="team" user="groupmanager" menuProps={getMenuProps("me/groupstatus")} />}
        />
      ) : null}
      {availableLinks.includes("me/ineedhelp") ? (
        <Route path="/me/ineedhelp" exact component={() => <Ineedhelp menuProps={getMenuProps("me/ineedhelp")} />} />
      ) : null}
      {availableLinks.includes("me/aspiration") ? (
        <Route path="/me/aspiration" exact component={() => <Aspiration menuProps={getMenuProps("me/aspiration")} />} />
      ) : null}
      {availableLinks.includes("me/support") ? (
        <Route path="/me/support" exact component={() => <Support menuProps={getMenuProps("me/support")} />} />
      ) : null}
      {availableLinks.includes("support") ? <Route path="/support" exact component={() => <Support menuProps={getMenuProps("support")} />} /> : null}

      {availableLinks.includes("me/shareddash") ? (
        <Route path="/me/shareddash" exact component={() => <SharedDashboards menuProps={getMenuProps("me/shareddash")} />} />
      ) : null}
      {availableLinks.includes("me/jobprofile") ? <Route path="/me/jobprofile" exact component={AwakeMeDashboard} /> : null}
      {availableLinks.includes("me/jobpreference") ? <Route path="/me/jobpreference" exact component={Jobfamily} /> : null}

      {availableLinks.includes("cce/support") ? (
        <Route path="/cce/support" exact component={() => <Support menuProps={getMenuProps("cce/support")} />} />
      ) : null}
      {availableLinks.includes("myteamdashboard") ? (
        <Route path="/myteamdashboard" exact component={() => <MyTeamParent menuProps={getMenuProps("myteamdashboard")} type="My Team" />} />
      ) : null}
      {availableLinks.includes("mygroupdashboard") ? (
        <Route path="/mygroupdashboard" exact component={() => <MyTeamParent menuProps={getMenuProps("mygroupdashboard")} type="My Group" />} />
      ) : null}
      {availableLinks.includes("hradmin/hrstatus") ? (
        <Route path="/hradmin/hrstatus" exact component={() => <Statistics type="hradmin" menuProps={getMenuProps("hradmin/hrstatus")} />} />
      ) : null}
      {availableLinks.includes("hr/hrstatus") ? (
        <Route path="/hr/hrstatus" exact component={() => <Statistics type="hr" menuProps={getMenuProps("hr/hrstatus")} />} />
      ) : null}
      {availableLinks.includes("hr/leadershipindex") ? (
        <Route path="/hr/leadershipindex" exact component={() => <LeadershipIndex menuProps={getMenuProps("hr/leadershipindex")} />} />
      ) : null}
      {availableLinks.includes("hr/toppotential") ? (
        <Route
          path="/hr/toppotential"
          exact
          component={() => <TopBottomTalents type="Top Potential" menuProps={getMenuProps("hr/toppotential")} />}
        />
      ) : null}
      {availableLinks.includes("hr/trainingneeds") ? (
        <Route path="/hr/trainingneeds" exact component={() => <Training menuProps={getMenuProps("hr/trainingneeds")} />} />
      ) : null}
      {availableLinks.includes("hr/topstrengths") ? (
        <Route path="/hr/topstrengths" exact component={() => <TopStrengths menuProps={getMenuProps("hr/topstrengths")} />} />
      ) : null}
      {availableLinks.includes("hr/pvsp") ? (
        <Route path="/hr/pvsp" exact component={() => <PervsPotA menuProps={getMenuProps("hr/pvsp")} />} />
      ) : null}
      {availableLinks.includes("hr/readynext") ? (
        <Route path="/hr/readynext" exact component={() => <ReadyNext menuProps={getMenuProps("hr/readynext")} />} />
      ) : null}
      {availableLinks.includes("hr/engagementindex") ? (
        <Route path="/hr/engagementindex" exact component={() => <EngagementIndex menuProps={getMenuProps("hr/engagementindex")} />} />
      ) : null}
      {availableLinks.includes("hr/emotionalwell") ? (
        <Route path="/hr/emotionalwell" exact component={() => <EmotionalWellness menuProps={getMenuProps("hr/emotionalwell")} />} />
      ) : null}
      {availableLinks.includes("hr/companycomp") ? (
        <Route
          path="/hr/companycomp"
          exact
          component={() => (
            <DimInf
              dataProps={{ topq: false }}
              menuProps={getMenuProps("hr/companycomp")}
              dimid={comp.company_competence}
              type="Company Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("hr/leadershipcomp") ? (
        <Route
          path="/hr/leadershipcomp"
          exact
          component={() => (
            <DimInf
              dataProps={{ topq: false }}
              menuProps={getMenuProps("hr/leadershipcomp")}
              dimid={comp.leadership_competence}
              type="Leadership Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("hr/emotionalcomp") ? (
        <Route
          path="/hr/emotionalcomp"
          exact
          component={() => (
            <DimInf
              dataProps={{ topq: false }}
              menuProps={getMenuProps("hr/emotionalcomp")}
              dimid={comp.emotional_competence}
              type="Emotional Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("hr/effectivenesscomp") ? (
        <Route
          path="/hr/effectivenesscomp"
          exact
          component={() => (
            <DimInf
              dataProps={{ topq: false }}
              menuProps={getMenuProps("hr/effectivenesscomp")}
              dimid={comp.effectiveness_index}
              type="Effectiveness Index"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("hr/hradmin") ? (
        <Route exact path="/hr/hradmin" component={() => <Hradmin menuProps={getMenuProps("hr/hradmin")} />} />
      ) : null}
      {availableLinks.includes("hr/ihelp") ? <Route exact path="/hr/ihelp" component={() => <IHelp menuProps={getMenuProps("hr/ihelp")} />} /> : null}
      {availableLinks.includes("hr/aspirations") ? (
        <Route exact path="/hr/aspirations" component={() => <Aspirations menuProps={getMenuProps("hr/aspirations")} />} />
      ) : null}
      {availableLinks.includes("hr/mindframe") ? (
        <Route exact path="/hr/mindframe" component={() => <MindFrame menuProps={getMenuProps("hr/mindframe")} />} />
      ) : null}
      {availableLinks.includes("hr/trainbudgets") ? (
        <Route exact path="/hr/trainbudgets" component={() => <CPCL menuProps={getMenuProps("hr/trainbudgets")} />} />
      ) : null}
      {availableLinks.includes("hr/traincal") ? (
        <Route exact path="/hr/traincal" component={() => <CPCL menuProps={getMenuProps("hr/traincal")} />} />
      ) : null}
      {availableLinks.includes("hr/trainingeffeva") ? (
        <Route exact path="/hr/trainingeffeva" component={() => <CPCL menuProps={getMenuProps("hr/trainingeffeva")} />} />
      ) : null}
      {availableLinks.includes("hr/techandbehave") ? (
        <Route exact path="/hr/techandbehave" component={() => <CPCL menuProps={getMenuProps("hr/techandbehave")} />} />
      ) : null}
      {availableLinks.includes("hr/techskillneeds") ? (
        <Route exact path="/hr/techskillneeds" component={() => <CPCL menuProps={getMenuProps("hr/techskillneeds")} />} />
      ) : null}
      {availableLinks.includes("hr/techevalbank") ? (
        <Route exact path="/hr/techevalbank" component={() => <CPCL menuProps={getMenuProps("hr/techevalbank")} />} />
      ) : null}
      {availableLinks.includes("hr/techquebank") ? (
        <Route exact path="/hr/techquebank" component={() => <CPCL menuProps={getMenuProps("hr/techquebank")} />} />
      ) : null}
      {availableLinks.includes("hr/techandbehav") ? (
        <Route exact path="/hr/techandbehav" component={() => <CPCL menuProps={getMenuProps("hr/techandbehav")} />} />
      ) : null}
      {availableLinks.includes("hr/jobdescbank") ? (
        <Route exact path="/hr/jobdescbank" component={() => <CPCL menuProps={getMenuProps("hr/jobdescbank")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/init") ? (
        <Route path="/atmaadmin/init" exact component={() => <Init menuProps={getMenuProps("atmaadmin/init")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/pwdreset") ? (
        <Route path="/atmaadmin/pwdreset" exact component={() => <PasswordManagement menuProps={getMenuProps("atmaadmin/pwdreset")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/assignatmaadmin") ? (
        <Route
          path="/atmaadmin/assignatmaadmin"
          exact
          component={() => <AssignAtmaAdmin type="atmaadmin" menuProps={getMenuProps("atmaadmin/assignatmaadmin")} />}
        />
      ) : null}
      {availableLinks.includes("cce/assignatmaadmin") ? (
        <Route
          path="/cce/assignatmaadmin"
          exact
          component={() => <AssignAtmaAdmin type="atmaadmin" menuProps={getMenuProps("cce/assignatmaadmin")} />}
        />
      ) : null}
      {availableLinks.includes("cce/assignhradmin") ? (
        <Route path="/cce/assignhradmin" exact component={() => <AssignAtmaAdmin type="hradmin" menuProps={getMenuProps("cce/assignhradmin")} />} />
      ) : null}
      {availableLinks.includes("hradmin/assignhradmin") ? (
        <Route
          path="/hradmin/assignhradmin"
          exact
          component={() => <AssignAtmaAdmin type="hradmin" menuProps={getMenuProps("hradmin/assignhradmin")} />}
        />
      ) : null}
      {availableLinks.includes("cce/employeemanagement") ? (
        <Route path="/cce/employeemanagement" exact component={() => <EmployeeManagement menuProps={getMenuProps("cce/employeemanagement")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/employeemanagement") ? (
        <Route
          path="/atmaadmin/employeemanagement"
          exact
          component={() => <EmployeeManagement menuProps={getMenuProps("atmaadmin/employeemanagement")} />}
        />
      ) : null}
      {availableLinks.includes("cce/assignmanagement") ? (
        <Route
          path="/cce/assignmanagement"
          exact
          component={() => <AssignManagement type="management" menuProps={getMenuProps("cce/assignmanagement")} />}
        />
      ) : null}
      {availableLinks.includes("cce/assignceo") ? (
        <Route path="/cce/assignceo" exact component={() => <AssignManagement type="ceo" menuProps={getMenuProps("cce/assignceo")} />} />
      ) : null}
      {availableLinks.includes("cce/assignbu") ? (
        <Route path="/cce/assignbu" exact component={() => <AssignManagement type="bu" menuProps={getMenuProps("cce/assignbu")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/assignceo") ? (
        <Route path="/atmaadmin/assignceo" exact component={() => <AssignManagement type="ceo" menuProps={getMenuProps("atmaadmin/assignceo")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/assignbu") ? (
        <Route path="/atmaadmin/assignbu" exact component={() => <AssignManagement type="bu" menuProps={getMenuProps("atmaadmin/assignbu")} />} />
      ) : null}
      {availableLinks.includes("atmaadmin/assignmanagement") ? (
        <Route
          path="/atmaadmin/assignmanagement"
          exact
          component={() => <AssignManagement type="management" menuProps={getMenuProps("atmaadmin/assignmanagement")} />}
        />
      ) : null}
      {availableLinks.includes("cce/dimresults") ? (
        <Route path="/cce/dimresults" exact component={() => <DimensionResults menuProps={getMenuProps("cce/dimresults")} />} />
      ) : null}

      {availableLinks.includes("management/hrstatus") ? (
        <Route path="/management/hrstatus" exact component={() => <Statistics type="management" menuProps={getMenuProps("management/hrstatus")} />} />
      ) : null}
      {availableLinks.includes("management/leadershipindex") ? (
        <Route
          path="/management/leadershipindex"
          exact
          component={() => <LeadershipIndex user="management" menuProps={getMenuProps("management/leadershipindex")} />}
        />
      ) : null}
      {availableLinks.includes("management/toppotential") ? (
        <Route
          path="/management/toppotential"
          exact
          component={() => <TopBottomTalents user="management" type="Top Potential" menuProps={getMenuProps("management/toppotential")} />}
        />
      ) : null}
      {availableLinks.includes("management/trainingneeds") ? (
        <Route
          path="/management/trainingneeds"
          exact
          component={() => <Training user="management" menuProps={getMenuProps("management/trainingneeds")} />}
        />
      ) : null}
      {availableLinks.includes("management/topstrengths") ? (
        <Route
          path="/management/topstrengths"
          exact
          component={() => <TopStrengths user="management" menuProps={getMenuProps("management/topstrengths")} />}
        />
      ) : null}
      {availableLinks.includes("management/pvsp") ? (
        <Route path="/management/pvsp" exact component={() => <PervsPotA user="management" menuProps={getMenuProps("management/pvsp")} />} />
      ) : null}
      {availableLinks.includes("management/readynext") ? (
        <Route
          path="/management/readynext"
          exact
          component={() => <ReadyNext user="management" menuProps={getMenuProps("management/readynext")} />}
        />
      ) : null}
      {availableLinks.includes("management/engagementindex") ? (
        <Route
          path="/management/engagementindex"
          exact
          component={() => <EngagementIndex user="management" menuProps={getMenuProps("management/engagementindex")} />}
        />
      ) : null}
      {availableLinks.includes("management/emotionalwell") ? (
        <Route
          path="/management/emotionalwell"
          exact
          component={() => <EmotionalWellness user="management" menuProps={getMenuProps("management/emotionalwell")} />}
        />
      ) : null}
      {availableLinks.includes("management/companycomp") ? (
        <Route
          path="/management/companycomp"
          exact
          component={() => (
            <DimInf
              user="management"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("management/companycomp")}
              dimid={comp.company_competence}
              type="Company Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("management/leadershipcomp") ? (
        <Route
          path="/management/leadershipcomp"
          exact
          component={() => (
            <DimInf
              user="management"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("management/leadershipcomp")}
              dimid={comp.leadership_competence}
              type="Leadership Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("management/emotionalcomp") ? (
        <Route
          path="/management/emotionalcomp"
          exact
          component={() => (
            <DimInf
              user="management"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("management/emotionalcomp")}
              dimid={comp.emotional_competence}
              type="Emotional Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("management/effectivenesscomp") ? (
        <Route
          path="/management/effectivenesscomp"
          exact
          component={() => (
            <DimInf
              user="management"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("management/effectivenesscomp")}
              dimid={comp.effectiveness_index}
              type="Effectiveness Index"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("ceo/hrstatus") ? (
        <Route path="/ceo/hrstatus" exact component={() => <Statistics type="ceo" menuProps={getMenuProps("ceo/hrstatus")} />} />
      ) : null}
      {availableLinks.includes("ceo/leadershipindex") ? (
        <Route path="/ceo/leadershipindex" exact component={() => <LeadershipIndex user="ceo" menuProps={getMenuProps("ceo/leadershipindex")} />} />
      ) : null}
      {availableLinks.includes("ceo/toppotential") ? (
        <Route
          path="/ceo/toppotential"
          exact
          component={() => <TopBottomTalents user="ceo" type="Top Potential" menuProps={getMenuProps("ceo/toppotential")} />}
        />
      ) : null}
      {availableLinks.includes("ceo/trainingneeds") ? (
        <Route path="/ceo/trainingneeds" exact component={() => <Training user="ceo" menuProps={getMenuProps("ceo/trainingneeds")} />} />
      ) : null}
      {availableLinks.includes("ceo/topstrengths") ? (
        <Route path="/ceo/topstrengths" exact component={() => <TopStrengths user="ceo" menuProps={getMenuProps("ceo/topstrengths")} />} />
      ) : null}
      {availableLinks.includes("ceo/pvsp") ? (
        <Route path="/ceo/pvsp" exact component={() => <PervsPotA user="ceo" menuProps={getMenuProps("ceo/pvsp")} />} />
      ) : null}
      {availableLinks.includes("ceo/readynext") ? (
        <Route path="/ceo/readynext" exact component={() => <ReadyNext user="ceo" menuProps={getMenuProps("ceo/readynext")} />} />
      ) : null}
      {availableLinks.includes("ceo/engagementindex") ? (
        <Route path="/ceo/engagementindex" exact component={() => <EngagementIndex user="ceo" menuProps={getMenuProps("ceo/engagementindex")} />} />
      ) : null}
      {availableLinks.includes("ceo/emotionalwell") ? (
        <Route path="/ceo/emotionalwell" exact component={() => <EmotionalWellness user="ceo" menuProps={getMenuProps("ceo/emotionalwell")} />} />
      ) : null}
      {availableLinks.includes("ceo/companycomp") ? (
        <Route
          path="/ceo/companycomp"
          exact
          component={() => (
            <DimInf
              user="ceo"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("ceo/companycomp")}
              dimid={comp.company_competence}
              type="Company Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("ceo/leadershipcomp") ? (
        <Route
          path="/ceo/leadershipcomp"
          exact
          component={() => (
            <DimInf
              user="ceo"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("ceo/leadershipcomp")}
              dimid={comp.leadership_competence}
              type="Leadership Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("ceo/emotionalcomp") ? (
        <Route
          path="/ceo/emotionalcomp"
          exact
          component={() => (
            <DimInf
              user="ceo"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("ceo/emotionalcomp")}
              dimid={comp.emotional_competence}
              type="Emotional Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("ceo/effectivenesscomp") ? (
        <Route
          path="/ceo/effectivenesscomp"
          exact
          component={() => (
            <DimInf
              user="ceo"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("ceo/effectivenesscomp")}
              dimid={comp.effectiveness_index}
              type="Effectiveness Index"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("bu/hrstatus") ? (
        <Route path="/bu/hrstatus" exact component={() => <Statistics type="bu" menuProps={getMenuProps("bu/hrstatus")} />} />
      ) : null}
      {availableLinks.includes("bu/leadershipindex") ? (
        <Route path="/bu/leadershipindex" exact component={() => <LeadershipIndex user="bu" menuProps={getMenuProps("bu/leadershipindex")} />} />
      ) : null}
      {availableLinks.includes("bu/toppotential") ? (
        <Route
          path="/bu/toppotential"
          exact
          component={() => <TopBottomTalents user="bu" type="Top Potential" menuProps={getMenuProps("bu/toppotential")} />}
        />
      ) : null}
      {availableLinks.includes("bu/trainingneeds") ? (
        <Route path="/bu/trainingneeds" exact component={() => <Training user="bu" menuProps={getMenuProps("bu/trainingneeds")} />} />
      ) : null}
      {availableLinks.includes("bu/topstrengths") ? (
        <Route path="/bu/topstrengths" exact component={() => <TopStrengths user="bu" menuProps={getMenuProps("bu/topstrengths")} />} />
      ) : null}
      {availableLinks.includes("bu/pvsp") ? (
        <Route path="/bu/pvsp" exact component={() => <PervsPotA user="bu" menuProps={getMenuProps("bu/pvsp")} />} />
      ) : null}
      {availableLinks.includes("bu/readynext") ? (
        <Route path="/bu/readynext" exact component={() => <ReadyNext user="bu" menuProps={getMenuProps("bu/readynext")} />} />
      ) : null}
      {availableLinks.includes("bu/engagementindex") ? (
        <Route path="/bu/engagementindex" exact component={() => <EngagementIndex user="bu" menuProps={getMenuProps("bu/engagementindex")} />} />
      ) : null}
      {availableLinks.includes("bu/emotionalwell") ? (
        <Route path="/bu/emotionalwell" exact component={() => <EmotionalWellness user="bu" menuProps={getMenuProps("bu/emotionalwell")} />} />
      ) : null}
      {availableLinks.includes("bu/companycomp") ? (
        <Route
          path="/bu/companycomp"
          exact
          component={() => (
            <DimInf
              user="bu"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("bu/companycomp")}
              dimid={comp.company_competence}
              type="Company Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("bu/leadershipcomp") ? (
        <Route
          path="/bu/leadershipcomp"
          exact
          component={() => (
            <DimInf
              user="bu"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("bu/leadershipcomp")}
              dimid={comp.leadership_competence}
              type="Leadership Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("bu/emotionalcomp") ? (
        <Route
          path="/bu/emotionalcomp"
          exact
          component={() => (
            <DimInf
              user="bu"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("bu/emotionalcomp")}
              dimid={comp.emotional_competence}
              type="Emotional Competence"
            />
          )}
        />
      ) : null}
      {availableLinks.includes("bu/effectivenesscomp") ? (
        <Route
          path="/bu/effectivenesscomp"
          exact
          component={() => (
            <DimInf
              user="bu"
              dataProps={{ topq: false }}
              menuProps={getMenuProps("bu/effectivenesscomp")}
              dimid={comp.effectiveness_index}
              type="Effectiveness Index"
            />
          )}
        />
      ) : null}

      <Route component={NotFoundPage}></Route>
    </Switch>
  );
};

export default BaseRouter;
