import React, { Component } from "react";
import api from "../../api";
import Pagination from "./pagination";
import { paginate } from "./paginate";
import _ from "lodash";
import { excelexport } from "../../common/Helper";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

class IHelp extends Component {
  state = {
    empStats: [],
    sortColumn: { path: "created_on", order: "desc" },
    currentPage: 1,
    pageSize: 10,
    isOpen: false,
    modelMsg: "",
    visible: false,
    loading_handle: true
  };

  componentDidMount() {
    api
      .get("/hr/ihelp/")
      .then(res => {
        this.setState({ empStats: res.data.data, loading_handle: false });
      })
      .catch(err => console.log(err));
  }

  actnBtnClick = help => {
    const empStats = [...this.state.empStats];
    help.actn = !help.actn;
    help.ack = help.actn ? true : help.ack;
    help.com = help.actn
      ? help.com
      : help.com === "---No Comments---"
      ? ""
      : help.com;

    const fData = help.actn
      ? {
          help_id: help.id,
          who: "hr",
          action: "actn",
          ack: "ack",
          comment: help.com
        }
      : { help_id: help.id, who: "hr", action: "noactn", ack: "", comment: "" };

    this.setState({ empStats: empStats });

    api
      .post("/hr/ihelp/ack/", JSON.stringify(fData))
      .then(res => {})
      .catch(error => console.error("Error:", error));
  };

  ackBtnClick = help => {
    const empStats = this.state.empStats;
    help.ack = !help.ack;
    help.actn = help.ack ? help.actn : false;
    help.com = help.actn
      ? help.com
      : help.com === "---No Comments---"
      ? ""
      : help.com;

    this.setState({ empStats: empStats });

    const fData = help.ack
      ? { help_id: help.id, who: "hr", ack: "ack", action: "", comment: "" }
      : {
          help_id: help.id,
          who: "hr",
          ack: "unack",
          action: "noactn",
          comment: ""
        };

    api
      .post("/hr/ihelp/ack/", JSON.stringify(fData))
      .then(res => {})
      .catch(error => console.error("error", error));
  };

  actnClasses(help) {
    let classes = "fas fa-check-circle ";
    classes += help.actn === true ? "text-actn" : "text-noactn";
    return classes;
  }

  ackClasses(help) {
    let classes = "fas fa-check-circle ";
    classes += help.ack === true ? "text-actn" : "text-noactn";
    return classes;
  }

  textShow = (help, event) => {
    const empStats = [...this.state.empStats];
    help.com = event.target.value;

    this.setState({ empStats: empStats });
  };

  handleSort = path => {
    const sortColumn = { ...this.state.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.setState({ sortColumn });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  renderSortIcon(path) {
    if (path !== this.state.sortColumn.path)
      return (
        <i className="fas fa-caret-up">
          <i className="fas fa-caret-down" />
        </i>
      );
    let classess = "";
    classess =
      this.state.sortColumn.order === "asc" ? (
        <i className="fas fa-caret-square-up">
          <i className="fas fa-caret-down" />
        </i>
      ) : (
        <i className="fas fa-caret-up">
          <i className="fas fa-caret-square-down" />
        </i>
      );
    return classess;
  }

  helpMsg(help) {
    let msgg = help.ack ? (
      <p>{help.message.substring(0, 12)}...</p>
    ) : (
      <b>{help.message.substring(0, 12)}...</b>
    );
    return msgg;
  }
  exportFile = () => {
    var fname = "HelpDesk";
    var expfname = "HelpDesk.xls";
    var headers = [
      "Date",
      "From",
      "Dept",
      "Subject",
      "Message",
      "Action Taken Date"
    ];

    var data = this.state.empStats.map(emp => [
      emp.created_on,
      emp.msg_from,
      emp.designation,
      emp.subject,
      emp.message,
      emp.actn_updated
    ]);
    excelexport({ fname, expfname, headers, data });
  };

  onClick = help => {
    this.setState({ visible: true, modelMsg: help.message });
    help.ack = true;
    const fData = {
      help_id: help.id,
      who: "hr",
      ack: "ack",
      action: "",
      comment: ""
    };
    api
      .post("/hr/ihelp/ack/", JSON.stringify(fData))
      .then(res => {})
      .catch(error => console.error("error", error));
  };

  onHide = () => {
    this.setState({ visible: false, modelMsg: "" });
  };

  render() {
    const sorted = _.orderBy(
      this.state.empStats,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );
    const empStats = paginate(
      sorted,
      this.state.currentPage,
      this.state.pageSize
    );
    const {
      loading_handle,
      currentPage,
      pageSize,

      modelMsg,
      visible
    } = this.state;
    const footer = (
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={this.onHide}
          className="p-button-danger"
        />
      </div>
    );

    return (
      <div className="layout-main">
        {loading_handle === true ? (
          <>
            <div className="row">
              <div className="loading-spinner col-md-3">
                <ProgressSpinner />
              </div>
            </div>
          </>
        ) : (
          <div>
            <Dialog
              appendTo={document.body}
              header="Message"
              visible={visible}
              style={{ width: "50vw" }}
              footer={footer}
              onHide={this.onHide}
            >
              {modelMsg}
            </Dialog>

            <div className="box box-no-topborder">
              <div className="box-header with-border">
                <div className="row">
                  <div className="col-md-11">
                    <h3 className="box-title">{this.props.menuProps.label}</h3>
                  </div>
                  <div className="col-md-1">
                    <Button
                      type="button"
                      icon="pi pi-external-link"
                      iconPos="left"
                      label="excel"
                      onClick={this.exportFile}
                    />
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div className="row">
                  {empStats.length > 0 ? (
                    <table
                      className="table text-center table-bordered table-hover thead-dark"
                      id="tablehelp"
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th
                            style={{ width: 110, cursor: "pointer" }}
                            onClick={() => this.handleSort("created_on")}
                          >
                            Date {this.renderSortIcon("created_on")}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSort("msg_from")}
                          >
                            From {this.renderSortIcon("msg_from")}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSort("designation")}
                          >
                            Dept {this.renderSortIcon("designation")}
                          </th>
                          <th
                            style={{ width: 150, cursor: "pointer" }}
                            onClick={() => this.handleSort("subject")}
                          >
                            Subject {this.renderSortIcon("subject")}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSort("message")}
                          >
                            Message {this.renderSortIcon("message")}
                          </th>
                          <th
                            style={{ width: 120, cursor: "pointer" }}
                            onClick={() => this.handleSort("ack")}
                          >
                            Mark as Read /UnRead {this.renderSortIcon("ack")}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSort("com")}
                          >
                            Comments {this.renderSortIcon("com")}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSort("actn")}
                          >
                            Action Taken {this.renderSortIcon("actn")}
                          </th>
                          <th
                            style={{ width: 120, cursor: "pointer" }}
                            onClick={() => this.handleSort("actn_updated")}
                          >
                            Action Taken Date{" "}
                            {this.renderSortIcon("actn_updated")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {empStats.map(help => (
                          <tr key={help.id}>
                            <td>{help.created_on}</td>
                            <td>{help.msg_from}</td>
                            <td>{help.designation}</td>
                            <td>{help.subject}</td>
                            <td
                              onClick={() => this.onClick(help)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>{this.helpMsg(help)}</span>
                            </td>
                            <td>
                              <button
                                onClick={() => this.ackBtnClick(help)}
                                className="btn btn-xs btn-default"
                                title="Action Taken"
                              >
                                <i className={this.ackClasses(help)} />
                              </button>
                            </td>
                            <td>
                              {help.actn ? (
                                <p>
                                  {
                                    (help.com =
                                      help.com === ""
                                        ? "---No Comments---"
                                        : help.com)
                                  }
                                </p>
                              ) : (
                                <textarea
                                  type="text"
                                  placeholder="Enter Comments here..."
                                  onChange={event => this.textShow(help, event)}
                                  value={help.com}
                                />
                              )}
                            </td>
                            <td>
                              <button
                                onClick={() => this.actnBtnClick(help)}
                                className="btn btn-xs btn-default"
                                title="Action Taken"
                              >
                                <i className={this.actnClasses(help)} />
                              </button>
                            </td>
                            <td>{help.actn_updated}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3>No Help Requests</h3>
                  )}
                  <Pagination
                    itemsCount={empStats.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default IHelp;
