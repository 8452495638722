import React, { Component } from "react";
import OneInMany from "../questions/OneInMany";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { getquestion } from "../../store/actions/question";
import { orgSettings } from "../../store/actions/auth";

class GroupQuestions extends Component {
  state = {
    opt_vis: this.props.question[0].item.guid,
    questions: this.props.question,
  };
  componentDidUpdate() {
    if (this.state.questions !== this.props.question) {
      this.refs.refhere.scrollIntoView({ behavior: "smooth", block: "start" });
      this.setState({
        questions: this.props.question,
        opt_vis: this.props.question[0].item.guid,
      });
    }
  }
  componentDidMount() {
    this.refs.refhere.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  opttrigger = (param) => {
    let iwe = this.state.questions
      .map(function (e) {
        return e.item.guid;
      })
      .indexOf(param.guid);
    this.props.question[iwe + 1]
      ? this.props.question[iwe + 1].item.guid in this.props.group_answer
        ? this.opttrigger(this.props.question[iwe + 1].item)
        : this.setState({
            opt_vis: this.props.question[iwe + 1].item.guid,
          })
      : this.setState({
          opt_vis:
            this.props.question.filter((item) => {
              return !(item.item.guid in this.props.group_answer);
            }).length > 1
              ? this.props.question.filter((item) => {
                  return !(item.item.guid in this.props.group_answer);
                })[0].item.guid
              : "",
        });
  };
  opttrigger2 = (param) => {
    this.setState({
      // opt_vis: this.state.opt_vis === param.guid ? null : param.guid,
      opt_vis: param.guid,
    });
  };
  allqeustions() {
    return this.state.questions.map((question) => {
      return (
        <OneInMany
          callback2={this.opttrigger2}
          callback={this.opttrigger}
          visible={this.state.opt_vis}
          key={question.item.guid}
          questn={question}
          noofquestn={this.state.questions.length}
        ></OneInMany>
      );
    });
  }
  leadqeustion = () => {
    const { user_details } = this.props;
    const enableLanguage = user_details && user_details.organisation && user_details.organisation.enableLanguage;
    const language_preference = user_details && user_details.language_preference;
    const lead = this.state.questions[0].item.item.split(";");
    return this.state.questions[0].item.show_questions ? (
      <label>
        <div>
          {lead.map((i, index) => (
            <p key={index}>{i}</p>
          ))}
        </div>
        <div>
          {enableLanguage && this.state.questions[0].item.languages.item[language_preference]
            ? this.state.questions[0].item.languages.item[language_preference]
            : ""}
        </div>

        {this.state.questions[0].item.guid}
      </label>
    ) : (
      <label>
        <div>
          {lead.map((i, index) => (
            <p key={index}>{i}</p>
          ))}
        </div>
        <div>
          {enableLanguage && this.state.questions[0].item.languages.item[language_preference]
            ? this.state.questions[0].item.languages.item[language_preference]
            : ""}
        </div>
      </label>
    );
  };
  loadQuestion = () => {
    this.setState({ loading: true, allselectedranking: false });
    let data = {
      response_type: this.state.questions[0].response_type,
      group_answers: this.props.group_answer,
    };
    this.props.getquestion(data, 1);
  };
  render() {
    const { questions } = this.state;
    const { group_answer } = this.props;
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-2 p-lg-2"></div>
        <div className="p-col-12 p-md-8 p-lg-8 relative no-padding" ref="refhere">
          <Panel
            header={
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: 50 }}>
                <div>ATMA - Questionnaire</div>
                <div style={{ border: "6px solid #00a3b4", borderStyle: "double", padding: 5, color: "#00a3b4" }}>CHOOSE</div>
              </div>
            }
          >
            <div className="dialog-p-container text-question-bold">{questions ? this.leadqeustion() : ""}</div>
            <div className="dialog-p-container">{questions ? this.allqeustions() : ""}</div>
            <div className="dialog-action-button">
              {this.props.user_details.organisation.org_ver_type === "EV" && (
                <Button
                  style={{ marginRight: 9 }}
                  className="p-button-rounded p-button-warning purplebtn"
                  label="Take a Break"
                  // disabled={this.props.progress < 30}
                  // icon="pi pi-arrow-right"
                  // iconPos="right"
                  onClick={() => this.props.onBreak()}
                />
              )}
              <Button
                className="p-button-rounded p-button-warning"
                label="Next"
                disabled={Object.keys(group_answer).map((key) => group_answer[key]).length === questions.length ? false : true}
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => this.loadQuestion(true)}
              />
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group_answer: state.question.answer,
    user_details: state.auth.user_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getquestion: (data, return_item) => dispatch(getquestion(data, return_item)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupQuestions);
