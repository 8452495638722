import React, { useEffect } from "react";
import api from "../../api";
import TrainingNeedsComp from "../../common/TrainingNeedsComp";
import { Panel } from "primereact/panel";
import XLSX from "xlsx";
import { Button } from "primereact/button";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function MyTrainingNeeds(props) {
  const [training_data, SetTrainingData] = React.useState([]);
  const [learn_details, SetLearnDetails] = React.useState({});
  const [loading, SetLoading] = React.useState(true);
  const [atmaScore, SetAtmaScore] = React.useState(true);
  const [is_paid, SetIs_paid] = React.useState(false);
  const [is_completed, SetIs_completed] = React.useState(false);
  const [link, SetLink] = React.useState("");
  const { user_details, menuProps, data } = props;
  useEffect(() => {
    console.log("ads");
    api
      .get("/api/assessments/get_user_training")
      .then((res) => {
        if (res.data.results) {
          SetTrainingData(res.data.training_data);
          SetLearnDetails(res.data.learn_details);
          SetIs_paid(res.data.is_paid);
          SetIs_completed(res.data.is_completed);
          SetLink(res.data.link);
          SetAtmaScore(res.data.atma_score);
        }
        SetLoading(false);
      })
      .catch((err) => {
        SetLoading(false);
      });
  }, []);
  function exportFile() {
    var fname = "My Development Needs";

    var expfname = "MyDevelopmentNeeds.xlsx";
    var currdate = moment().format("lll");
    var training_headers = ["S.No", "Training ID", "Module", "You need to learn in"];
    const { employee_number, first_name, last_name, username, email, organisation } = user_details;
    const unlen = organisation?.account_name ? organisation.account_name.length : 0;
    const un = username.slice(0, username.length - unlen);

    var training_datas = training_data.map((train) => [
      train.sno,
      train.training_id,
      train.module_name,
      learn_details[train.training_id].replaceAll("*", "\n"),
    ]);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([training_headers, ...training_datas], {
      origin: "A5",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Date:"]], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_aoa(ws, [[currdate]], {
      origin: "B1",
    });

    XLSX.utils.sheet_add_aoa(ws, [["Employe Number:"]], {
      origin: "A2",
    });
    XLSX.utils.sheet_add_aoa(ws, [[employee_number]], {
      origin: "B2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["User Id:"]], {
      origin: "D2",
    });
    XLSX.utils.sheet_add_aoa(ws, [[un]], {
      origin: "E2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Name:"]], {
      origin: "G2",
    });
    XLSX.utils.sheet_add_aoa(ws, [[first_name + last_name]], {
      origin: "H2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Email:"]], {
      origin: "J2",
    });
    XLSX.utils.sheet_add_aoa(ws, [[email]], {
      origin: "K2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["My Development Needs"]], {
      origin: "A3",
    });

    XLSX.utils.book_append_sheet(wb, ws, fname);

    // var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20, alignment: { wrapText: "1" } }];

    // ws["!cols"] = wscols;

    XLSX.writeFile(wb, expfname);
  }
  function filedownload() {
    const dlink = document.createElement("a");
    dlink.href = link;
    document.body.appendChild(dlink);
    dlink.click();
    document.body.removeChild(dlink);
  }
  return (
    <div>
      <div className="layout-main item-wrapper">
        {/* <Growl ref={(el) => (this.growl = el)} position="bottomright" /> */}
        <Panel
          header={
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>{menuProps.label}</div>
              <div>
                {training_data?.length > 0 && (
                  <Button
                    style={{ float: "right", marginRight: "20px" }}
                    // disabled={this.state.enableExport}
                    onClick={filedownload}
                    label="Export"
                    icon="fas fa-download"
                  />
                )}
              </div>
            </div>
          }
        >
          {loading ? (
            <div className="row">
              <div className="loading-spinner col-md-3">
                <ProgressSpinner />
              </div>
            </div>
          ) : (
            <div>
              {is_paid ? (
                is_completed ? (
                  atmaScore ? (
                    training_data?.length > 0 ? (
                      <div>
                        <ul>
                          <li>
                            Based on your behavioural assessment score in various competencies, ATMA has identified the following topics as focus
                            areas for your development.
                          </li>
                          <li>They are listed in descending order, from highest priority to lowest priority.</li>
                        </ul>
                        <div>
                          <TrainingNeedsComp
                            empModel={true}
                            teamDash={false}
                            learn_details={learn_details}
                            training_data={training_data}
                            empDetails={{}}
                            empSel={{}}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: "center",
                            fontWeight: 700,
                            color: "green",
                          }}
                        >
                          Your behavioural skills are very good. You may look at the ones where the scores are shown with grey background and focus to
                          develop them further to move to green
                        </p>
                      </div>
                    )
                  ) : (
                    <div>Feature not enabled</div>
                  )
                ) : (
                  <div>
                    <p
                      style={{
                        fontSize: 20,
                        textAlign: "center",
                        fontWeight: 700,
                        color: "#ff6c6c",
                      }}
                    >
                      Please complete the assessment.
                    </p>
                  </div>
                )
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      fontWeight: 700,
                      color: "#ff6c6c",
                    }}
                  >
                    Buy full version to see your development needs
                  </p>
                </div>
              )}
            </div>
          )}
        </Panel>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    data: state.question.data,
  };
};

export default connect(mapStateToProps, null)(MyTrainingNeeds);
