import React, { Component } from "react";
import api from "../../api";
import EChart from "../../common/ECharts/ECharts";
import EChartsBarStacked from "../../common/ECharts/EChartsBarStacked";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { renderPanelHeader } from "../../utils/Utils";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import ScoreDistribution from "./DimInfLayout";
import { Dialog } from "primereact/dialog";

class EmotionalWellness extends Component {
  state = {
    data: [],
    work_stress: [],
    personal_stress: [],
    loading_handle: true,
    overallemp: "",
    dataprogress: true,
    filterData: {},
    seldep: [],
    selloc: [],
    sellev: [],
    dep: [],
    loc: [],
    lev: [],
    filterApplied: false,
    nodata: false,
    load2: false,
    tabsel: "",
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    distdata: [],
    emotionalWellbeing: [],
    dimsel: {},
    visible: false,
    oempcount: "",
    secwin: false,
  };

  componentDidMount() {
    api.get("api/cce/get-dashboard-filters").then((res) => {
      this.setState({ ...res.data });
      this.fetchData();
    });
  }
  applyf() {
    if (this.state.filterApplied) {
      this.fetchData();
    } else {
      this.growl.show({
        severity: "error",
        detail: "No Filter Selected",
      });
    }
  }
  fetchData() {
    const { seldep, sellev, selloc } = this.state;
    const data = {
      dep: seldep,
      loc: selloc,
      lev: sellev,
      user: this.props.user,
    };
    this.setState({
      dep: seldep,
      loc: selloc,
      lev: sellev,
      secwin: false,
      load2: true,
    });

    api
      .post("/api/assessments/emodashboard", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            overallemp: res.data.overallCount,
            data: res.data.result["Professional stress and coping"],
            emotionalWellbeing: res.data.result["emotionalData"],
            work_stress: res.data.result["Professional stress and coping"].map(
              (stress) => ({
                name: stress.rangename,
                value: stress.range,
              })
            ),
            personal_stress: res.data.result["Personal stress and coping"].map(
              (stress) => ({
                name: stress.rangename,
                value: stress.range,
              })
            ),
            loading_handle: false,
            dataprogress: true,
            nodata: false,
            load2: false,
          });
        } else {
          if (this.state.filterApplied) {
            this.setState({
              loading_handle: false,
              dataprogress: true,
              nodata: true,
              load2: false,
              overallemp: 0,
            });
          } else {
            this.setState({
              loading_handle: false,
              load2: false,
              overallemp: 0,
              dataprogress: false,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }

  buildOption = () => {
    const { emotionalWellbeing } = this.state;
    let options = {};
    options["title"] = "";
    let highArray = [];
    let moderateArray = [];
    let lowArray = [];
    let xAxisLabelArray = [];
    let totalEmployees = 0;
    emotionalWellbeing.forEach((e) => {
      xAxisLabelArray.push(e.dimensionName);
      highArray.push(e.high_count);
      moderateArray.push(e.mid_count);
      lowArray.push(e.low_count);
      if (totalEmployees === 0) {
        totalEmployees = e.high_count + e.mid_count + e.low_count;
      }
    });
    options["totalEmployees"] = totalEmployees;
    options["xAxisLabel"] = xAxisLabelArray;
    options["series"] = { highArray, moderateArray, lowArray };
    return options;
  };
  ondimclick = (param) => {
    if (this.state.dimsel.dimensionName !== param.value) {
      this.setState(
        {
          secwin: true,
          load3: true,
        },
        () => this.onDimensionClickfun(param)
      );
    } else {
      this.refs.demo.scrollIntoView({ behavior: "smooth" });
    }
  };
  onDimensionClickfun = (param) => {
    this.refs.demo.scrollIntoView({ behavior: "smooth" });
    const { dep, loc, lev } = this.state;
    console.log(param, this.state.emotionalWellbeing);
    const dim = this.state.emotionalWellbeing.filter((e) => {
      return e.dimensionName === param.value;
    });
    console.log(dim[0]);
    this.setState({
      dimsel: dim[0],
      tabsel: "good",
    });
    const data = {
      type: "good",
      dim_id: dim[0].dimensionId,
      dep: dep,
      loc: loc,
      lev: lev,
      user: this.props.user,
    };

    api
      .post("/api/assessments/emotionaldash_post", data)
      .then((res) => {
        const { demograph } = res.data;
        if (res.data.results) {
          this.setState({
            load3: false,
            oempcount: demograph.overallCount,
            distdata: demograph,
            totalempcount: demograph.totalempcount,
            leveldata: demograph.levelWise.map((grade) => ({
              name: grade.role,
              value: grade.percentage,
            })),
            deptdata: demograph.deptWise.map((grade) => ({
              name: grade.name,
              value: grade.percentage,
            })),
            agedata: demograph.ageWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            workexpdata: demograph.workexpWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
          });
        } else {
          this.setState({ secwin: false });
        }
      })
      .catch((err) => console.log(err));
  };
  tabsel = (name) => {
    if (this.state.tabsel !== name) {
      const { dep, loc, lev } = this.state;
      this.setState({ tabsel: name, load4: true });
      const data = {
        type: name,
        dim_id: this.state.dimsel.dimensionId,
        dep: dep,
        loc: loc,
        lev: lev,
        user: this.props.user,
      };
      api
        .post("/api/assessments/emotionaldash_post", data)
        .then((res) => {
          const { demograph } = res.data;
          this.setState({
            load4: false,
            oempcount: demograph.overallCount,
            distdata: demograph,
            totalempcount: demograph.totalempcount,
            leveldata: demograph.levelWise.map((grade) => ({
              name: grade.role,
              value: grade.percentage,
            })),
            deptdata: demograph.deptWise.map((grade) => ({
              name: grade.name,
              value: grade.percentage,
            })),
            agedata: demograph.ageWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            workexpdata: demograph.workexpWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.refs.demo.scrollIntoView({ behavior: "smooth" });
    }
  };
  popup = () => {
    this.setState({
      visible: true,
    });
  };
  onHide = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      filterData,
      filterApplied,
      seldep,
      sellev,
      selloc,
      dimsel,
    } = this.state;
    const chartData2 = {
      type: "doughnut",
      title: "",
      data: this.state.work_stress,
    };
    const chartData1 = {
      type: "doughnut",
      title: "",
      data: this.state.personal_stress,
    };
    return (
      <div className="layout-main">
        <div className="row">
          <Dialog
            style={{ width: "40vw" }}
            //className="medialog"
            appendTo={document.body}
            header={this.state.dimsel.dimensionName}
            visible={this.state.visible}
            onHide={this.onHide}
          >
            <span style={{ lineHeight: "2" }}>
              {this.state.dimsel.dimensionInf}
            </span>
          </Dialog>
          <Growl
            ref={(el) => (this.growl = el)}
            style={{ marginTop: 20 }}
            position="bottomright"
          />
          <div className="col-md-12">
            <Panel
              header={
                <div style={{ marginBottom: 15 }}>
                  <span style={{ fontSize: "18px" }}>
                    {this.props.menuProps.label}
                  </span>
                  <span style={{ float: "right" }}>
                    <div className="total-employees-container">
                      N - {this.state.overallemp}
                    </div>
                  </span>
                </div>
              }
            >
              {/* <div className="box-header with-border">
                <h3 className="box-title">Emotional Wellness</h3>
              </div> */}
              <div>
                {this.state.loading_handle === true ? (
                  <>
                    <div className="loading-spinner-container">
                      <ProgressSpinner></ProgressSpinner>
                    </div>
                  </>
                ) : this.state.dataprogress ? (
                  <>
                    <Panel header="Filters">
                      <div className="box-body box-body-cust-250">
                        <div className="row">
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.selloc}
                              options={
                                filterData.locations ? filterData.locations : []
                              }
                              onChange={(e) =>
                                this.setState({ selloc: e.value })
                              }
                              filter={true}
                              placeholder="Location..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.seldep}
                              options={
                                filterData.departments
                                  ? filterData.departments
                                  : []
                              }
                              onChange={(e) =>
                                this.setState({ seldep: e.value })
                              }
                              filter={true}
                              placeholder="Department..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <MultiSelect
                              value={this.state.sellev}
                              options={
                                filterData.levels ? filterData.levels : []
                              }
                              onChange={(e) =>
                                this.setState({ sellev: e.value })
                              }
                              filter={true}
                              placeholder="Grade..."
                              scrollHeight="200px"
                            />
                          </div>
                          <div className="col-md-3">
                            <Button
                              type="button"
                              label="Apply"
                              onClick={() =>
                                this.setState(
                                  {
                                    filterApplied:
                                      seldep.length === 0 &&
                                      selloc.length === 0 &&
                                      sellev.length === 0
                                        ? false
                                        : true,
                                  },
                                  () => this.applyf()
                                )
                              }
                              style={{ marginRight: "15px" }}
                            />
                            <Button
                              type="button"
                              label="Reset"
                              onClick={() =>
                                this.setState(
                                  {
                                    seldep: [],
                                    selloc: [],
                                    sellev: [],
                                    filterApplied: false,
                                    load2: true,
                                  },
                                  () => this.fetchData()
                                )
                              }
                              style={{ marginRight: "15px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                    {this.state.load2 ? (
                      <div className="loading-spinner-container">
                        <ProgressSpinner></ProgressSpinner>
                      </div>
                    ) : this.state.nodata ? (
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        No Data
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <Panel
                              header={
                                <div style={{ marginBottom: 15 }}>
                                  <span style={{ fontSize: "18px" }}>
                                    Personal Stress and Coping
                                  </span>
                                  <span style={{ float: "right" }}>
                                    <div className="total-employees-container">
                                      N - {this.state.overallemp}
                                    </div>
                                  </span>
                                </div>
                              }
                            >
                              {/* <div className="box-header with-border">Personal Stress and Coping</div> */}

                              <EChart height={"300px"} chartData={chartData1} />
                            </Panel>
                          </div>

                          <div className="col-md-6">
                            <Panel
                              header={
                                <div style={{ marginBottom: 15 }}>
                                  <span style={{ fontSize: "18px" }}>
                                    Professional Stress and Coping
                                  </span>
                                  <span style={{ float: "right" }}>
                                    <div className="total-employees-container">
                                      N - {this.state.overallemp}
                                    </div>
                                  </span>
                                </div>
                              }
                            >
                              {/* <div className="box-header with-border">Work Stress and Coping</div> */}

                              <EChart height={"300px"} chartData={chartData2} />
                            </Panel>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Panel
                              header={
                                <div style={{ marginBottom: 15 }}>
                                  <span style={{ fontSize: "18px" }}>
                                    Emotional Wellbeing
                                  </span>
                                  <span style={{ float: "right" }}>
                                    <div className="total-employees-container">
                                      N - {this.state.overallemp}
                                    </div>
                                  </span>
                                </div>
                              }
                            >
                              <div>
                                <EChartsBarStacked
                                  callBack={this.ondimclick}
                                  height={"300px"}
                                  chartData={this.buildOption()}
                                />
                              </div>
                              <div ref="demo">
                                {this.state.secwin ? (
                                  this.state.load3 ? (
                                    <div className="row">
                                      <div className="loading-spinner col-md-3">
                                        <ProgressSpinner />
                                      </div>
                                    </div>
                                  ) : (
                                    <div style={{ marginTop: 20 }}>
                                      <Panel
                                        header={
                                          <>
                                            <span>{dimsel.dimensionName} </span>
                                            <span>
                                              <i
                                                onClick={() => this.popup()}
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#00a3b4",
                                                  fontSize: "14px",
                                                }}
                                                className="far fa-question-circle"
                                              ></i>
                                            </span>
                                          </>
                                        }
                                      >
                                        <div
                                          className="row"
                                          style={{ marginTop: 30 }}
                                        >
                                          <div
                                            className="col-md-4"
                                            style={{ textAlign: "center" }}
                                          >
                                            <Button
                                              className="p-button-rounded p-button-secondary"
                                              style={
                                                this.state.tabsel === "good"
                                                  ? {
                                                      background: "#cefad7",
                                                      border:
                                                        "3px solid #39cccc",
                                                      color: "black",
                                                    }
                                                  : {
                                                      background: "#cefad7",
                                                      border: "3px solid white",
                                                      color: "black",
                                                    }
                                              }
                                              onClick={() =>
                                                this.tabsel("good")
                                              }
                                              label={
                                                "Good - " +
                                                this.state.dimsel.high_count
                                              }
                                            />
                                          </div>
                                          <div
                                            className="col-md-4"
                                            style={{ textAlign: "center" }}
                                          >
                                            <Button
                                              className="p-button-rounded p-button-secondary"
                                              style={
                                                this.state.tabsel === "moderate"
                                                  ? {
                                                      background: "#feefc8",
                                                      border:
                                                        "3px solid #ffb800",
                                                      color: "black",
                                                    }
                                                  : {
                                                      background: "#feefc8",
                                                      border: "3px solid white",
                                                      color: "black",
                                                    }
                                              }
                                              onClick={() =>
                                                this.tabsel("moderate")
                                              }
                                              label={
                                                "Moderate - " +
                                                this.state.dimsel.mid_count
                                              }
                                            />
                                          </div>
                                          <div
                                            className="col-md-4 "
                                            style={{ textAlign: "center" }}
                                          >
                                            <Button
                                              className="p-button-rounded p-button-secondary"
                                              style={
                                                this.state.tabsel === "concern"
                                                  ? {
                                                      background: "#ffdbdc",
                                                      border:
                                                        "3px solid #ff5050",
                                                      color: "black",
                                                    }
                                                  : {
                                                      background: "#ffdbdc",
                                                      border: "3px solid white",
                                                      color: "black",
                                                    }
                                              }
                                              onClick={() =>
                                                this.tabsel("concern")
                                              }
                                              label={
                                                "Area of Concern - " +
                                                this.state.dimsel.low_count
                                              }
                                            />
                                          </div>
                                        </div>

                                        {this.state.load4 ? (
                                          <div className="row">
                                            <div className="loading-spinner col-md-3">
                                              <ProgressSpinner />
                                            </div>
                                          </div>
                                        ) : (
                                          <ScoreDistribution
                                            nCount={this.state.oempcount}
                                            menuProps={this.props.menuProps}
                                            data={this.state.distdata}
                                            potential={[]}
                                            leveldata={this.state.leveldata}
                                            deptdata={this.state.deptdata}
                                            agedata={this.state.agedata}
                                            workexpdata={this.state.workexpdata}
                                            //  name={this.props.type}
                                            //globalfilter={globalfilter}
                                            height={"380px"}
                                            dheight={"440px"}
                                            total={true}

                                            // type={this.props.type}
                                          />
                                        )}
                                      </Panel>
                                    </div>
                                  )
                                ) : null}
                              </div>
                            </Panel>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="col-md-12">
                    <div
                      style={{ color: "#6a6b6cba" }}
                      className=" box-body box-body-cust-250"
                    >
                      <div
                        className="medialog"
                        style={{
                          fontSize: "20px",
                          fontWeight: "800px",
                          textAlign: "center",
                        }}
                      >
                        Data Collection is in Progress
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

export default EmotionalWellness;
