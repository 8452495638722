import React, { Component } from "react";
import api from "../../api";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import ScoreDistribution from "./ScoreDistribution";
import XLSX from "xlsx";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";

let key = 0;

class TopBottomTalents extends Component {
  state = {
    data: [],
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    potential: [],
    percentage: "10",
    loading_handle: false,
    load: false,
    disableExport: true,
    filename: "",
    filename_dialog: false,
  };

  exportFile = (fname) => {
    if (fname) {
      if (fname.length > 0) {
        this.fonHide();
        var fname = fname;
        var expfname = fname + ".xls";
        const {
          levelWise,
          workexpWise,
          employees,
          ageWise,
          deptWise,
          currdate,
        } = this.state.data;
        if (this.props.type !== "Top Potential") {
          fname = fname;
          expfname = expfname;
        }
        var level_headers = [
          "Grade-wise",
          "Count",
          "Total Count",
          "Percentage",
        ];
        var workexp_headers = [
          "Tenure with company",
          "Count",
          "Total Count",
          "Percentage",
        ];
        var age_headers = ["Age-wise", "Count", "Total Count", "Percentage"];
        var dept_headers = [
          "Department-wise",
          "Count",
          "Total Count",
          "Percentage",
        ];
        var emp_headers = [
          "ID",
          "Name",
          "Department",
          "Grade",
          "Section",
          "Designation",
          "Manager",
        ];
        var level_data = levelWise.map((level) => [
          level.role,
          level.count,
          level.totalCount,
          level.percentage,
        ]);
        var workexp_data = workexpWise.map((workexp) => [
          workexp.range,
          workexp.count,
          workexp.totalCount,
          workexp.percentage,
        ]);
        var age_data = ageWise.map((age) => [
          age.range,
          age.count,
          age.totalCount,
          age.percentage,
        ]);
        var dept_data = deptWise.map((dept) => [
          dept.name,
          dept.count,
          dept.totalCount,
          dept.percentage,
        ]);
        var emp_data = employees.map((emp) => [
          emp.employee_number,
          emp.name,
          emp.department,
          emp.grade,
          emp.section,
          emp.designation,
          emp.manager,
        ]);
        const ws = XLSX.utils.aoa_to_sheet([level_headers, ...level_data], {
          origin: "B4",
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [["Date:"]], {
          origin: "A1",
        });
        XLSX.utils.sheet_add_aoa(ws, [[currdate]], {
          origin: "B1",
        });
        XLSX.utils.sheet_add_aoa(ws, [["Report Type:"]], {
          origin: "A2",
        });
        XLSX.utils.sheet_add_aoa(ws, [["Top Potential"]], {
          origin: "B2",
        });
        XLSX.utils.sheet_add_aoa(ws, [age_headers, ...age_data], {
          origin: "B" + (level_data.length + 6),
        });
        XLSX.utils.sheet_add_aoa(ws, [workexp_headers, ...workexp_data], {
          origin: "G4",
        });
        XLSX.utils.sheet_add_aoa(ws, [dept_headers, ...dept_data], {
          origin: "G" + (workexp_data.length + 6),
        });
        XLSX.utils.sheet_add_aoa(ws, [emp_headers, ...emp_data], {
          origin: "L4",
        });
        XLSX.utils.book_append_sheet(wb, ws, fname);
        XLSX.writeFile(wb, expfname);
      } else {
        this.growl.show({
          severity: "error",
          summary: "Please enter the filename.",
        });
      }
    } else {
      this.growl.show({
        severity: "error",
        summary: "Please enter the filename.",
      });
    }
  };
  fonHide = () => {
    this.setState({
      filename_dialog: false,
      filename: null,
    });
  };
  componentDidMount() {
    key = key + 1;
    const data =
      this.props.type === "Top Potential"
        ? { topPercentage: this.state.percentage, user: this.props.user }
        : { bottomPercentage: this.state.percentage, user: this.props.user };
    if (this.state.percentage > 100 || this.state.percentage < 1) {
      this.growl.show({
        severity: "error",
        detail: "Percentage should be in between 1 to 100",
      });
    } else {
      this.setState({ loading_handle: true });
      api
        .post("api/cce/top_or_bottom_talent", JSON.stringify(data))
        .then((res) => {
          if (res.data.success) {
            this.setState({
              disableExport: false,
              currdate: res.data.currdate,
              data: res.data,
              leveldata: res.data.levelWise.map((grade) => ({
                name: grade.role,
                value: grade.percentage,
              })),
              deptdata: res.data.deptWise.map((grade) => ({
                name: grade.name,
                value: grade.percentage,
              })),
              agedata: res.data.ageWise.map((grade) => ({
                name: grade.range,
                value: grade.percentage,
              })),
              workexpdata: res.data.workexpWise.map((grade) => ({
                name: grade.range,
                value: grade.percentage,
              })),
              potential: res.data.potentialData.map((grade, index) => [
                index + 1,
                grade.highpc,
              ]),
              loading_handle: false,
              load: true,
            });
          } else {
            this.setState({ loading_handle: false });
          }
          //console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  }

  percentage = () => {
    key = key + 1;
    const data =
      this.props.type === "Top Potential"
        ? { topPercentage: this.state.percentage, user: this.props.user }
        : { bottomPercentage: this.state.percentage, user: this.props.user };
    if (this.state.percentage > 100 || this.state.percentage < 1) {
      this.growl.show({
        severity: "error",
        detail: "Percentage should be in between 1 to 100",
      });
    } else {
      this.setState({ loading_handle: true });
      api
        .post("api/cce/top_or_bottom_talent", JSON.stringify(data))
        .then((res) => {
          this.setState({
            disableExport: false,
            data: res.data,
            leveldata: res.data.levelWise.map((grade) => ({
              name: grade.role,
              value: grade.percentage,
            })),
            deptdata: res.data.deptWise.map((grade) => ({
              name: grade.name,
              value: grade.percentage,
            })),
            agedata: res.data.ageWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            workexpdata: res.data.workexpWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            potential: res.data.potentialData.map((grade, index) => [
              index + 1,
              grade.highpc,
            ]),
            loading_handle: false,
            load: true,
          });

          // console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  getfilename = () => {
    console.log(this.state.selectedemps);

    this.setState({ filename_dialog: true });
  };

  render() {
    const {
      data,
      potential,
      leveldata,
      deptdata,
      agedata,
      workexpdata,
      disableExport,
    } = this.state;
    const { menuProps } = this.props;
    const { enableExport } = menuProps;
    return (
      <div className="layout-main">
        {this.state.filename_dialog ? (
          <Dialog
            header="Please Provide the filename by which you want to save the file."
            visible={this.state.filename_dialog}
            style={{ width: "50vw" }}
            onHide={() => this.fonHide()}
          >
            <InputText
              style={{ marginRight: "15px" }}
              value={this.state.filename}
              onChange={(e) => this.setState({ filename: e.target.value })}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => this.exportFile(this.state.filename)}
            />
          </Dialog>
        ) : null}
        <Growl
          ref={(el) => (this.growl = el)}
          style={{ marginTop: 20 }}
          position="bottomright"
        />
        <Panel
          header={
            <div
              className="box-header with-border"
              style={{ fontSize: "18px", paddingBottom: 20 }}
            >
              {this.props.menuProps.label}
              {enableExport ? (
                <Button
                  style={{ float: "right", marginRight: "20px" }}
                  disabled={disableExport}
                  onClick={this.getfilename}
                  label="Export"
                  icon="fas fa-download"
                />
              ) : null}
            </div>
          }
        >
          <div className="box-body">
            {this.state.loading_handle === true ? (
              <>
                <div className="row">
                  <div className="loading-spinner col-md-3">
                    <ProgressSpinner />
                  </div>
                </div>
              </>
            ) : this.state.load === false ? (
              <div className="p-col-12">
                <div
                  style={{ color: "#6a6b6cba" }}
                  className=" box-body box-body-cust-250"
                >
                  <div
                    className="medialog"
                    style={{
                      fontSize: "20px",
                      fontWeight: "800px",
                      textAlign: "center",
                    }}
                  >
                    Data Collection is in Progress
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-8">
                    {this.props.type} Percentage(%) :
                    <InputText
                      placeholder=""
                      style={{ marginLeft: "10px", width: "80px" }}
                      type="text"
                      keyfilter="pint"
                      value={this.state.percentage}
                      onChange={(e) =>
                        this.setState({ percentage: e.target.value })
                      }
                    />
                    <Button
                      style={{ marginLeft: "10px" }}
                      icon="pi pi-check"
                      onClick={this.percentage}
                    />
                  </div>
                  <div className="col-md-4" style={{ float: "right" }}>
                    <div className="total-employees-container">
                      <div>N - {data.overallCount}</div>
                      <div>Top Potential - {data.potentialData.length}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <ScoreDistribution
                    menuProps={menuProps}
                    data={data}
                    potential={potential}
                    leveldata={leveldata}
                    deptdata={deptdata}
                    agedata={agedata}
                    workexpdata={workexpdata}
                    name={this.props.type}
                    height={"400px"}
                    type={this.props.type}
                    dheight={"350px"}
                    nCount={data.potentialData.length}
                  />
                </div>
              </>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

export default TopBottomTalents;
