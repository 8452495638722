import React, { Component } from "react";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from "primereact/autocomplete";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { connect } from "react-redux";
import _ from "lodash";

class PushNotification extends Component {
  state = {
    empDetail: [],
    desg: [],
    selectedEmp: "",
    designation: null,
    rep_man: [],
    rep_hr: [],
    reportingMan: null,
    reportingHr: null,
    text: null,
    mess: [],
    text2: "",
    msglength: "500",
    globalFilter: null,
    loading: true,
    filterState: {}
  };
  componentDidMount() {
    if (!_.isEmpty(this.props.organisation)) this.fetchData();
  }
  fetchData = () => {
    this.setState({ loading: true });
    const { organisation } = this.props;
    api
      .post("api/cce/emp_notif_msgs", { organisation })
      .then(res => {
        this.setState({
          empDetail: res.data.emps,
          desg: res.data.desg,
          rep_man: res.data.rep_man,
          rep_hr: res.data.rep_hr,
          mess: res.data.mess,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log(err);
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchData();
    }
  }
  msgDel = () => {
    const fData = {
      message: "",
      emp: "",
      msgdel: this.state.text2.name,
      msg2: ""
    };
    const found = this.state.mess.some(
      el => el.name === (this.state.text2.name || this.state.text2)
    );
    {
      this.state.text2 === ""
        ? this.growl.show({
            severity: "warn",
            summary: "Warn Message",
            detail: "Message can not be Empty"
          })
        : this.state.selectedEmp.length > 0
        ? this.growl.show({
            severity: "warn",
            summary: "Warn Message",
            detail: "Diselect all the employees"
          })
        : found
        ? api
            .post("api/cce/emp_notif_msgs_post", JSON.stringify(fData))
            .then(res => {
              this.setState({ mess: res.data.data });

              if (res.data.success === true) {
                this.setState({ text2: "" });
                this.growl.show({
                  severity: "success",
                  summary: "Success Message",
                  detail: "Template Deleted"
                });
              }
            })
            .catch(error => console.error("Error:", error))
        : this.growl.show({
            severity: "warn",
            summary: "Warn Message",
            detail: "Template does not exists"
          });
      this.setState({ text2: "" });
    }
  };

  MessagSave = () => {
    const found = this.state.mess.some(
      el => el.name === (this.state.text2.name || this.state.text2)
    );

    if (this.state.text2 === "") {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Message can not be Empty"
      });
    } else if (this.state.text2.length > this.state.msglength) {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Template Exceeded the Character Limit of 500"
      });
    } else if (this.state.selectedEmp.length > 0) {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Diselect all the employees"
      });
    } else {
      if (!found) {
        const fData = {
          msg2: this.state.text2,
          message: "",
          emp: "",
          msgdel: ""
        };
        api
          .post("api/cce/emp_notif_msgs_post", JSON.stringify(fData))
          .then(res => {
            this.setState({ mess: res.data.data });

            if (res.data.success === true) {
              this.setState({ text2: "" });
              this.growl.show({
                severity: "success",
                summary: "Success Message",
                detail: "Template Saved"
              });
            }
          })
          .catch(error => console.error("Error:", error));
      } else {
        this.growl.show({
          severity: "warn",
          summary: "Warn Message",
          detail: "Template already exists"
        });
      }
      this.setState({ text2: "" });
    }
  };

  MessagSend = () => {
    const found = this.state.mess.some(el => el.name === this.state.text2.name);

    if (this.state.text2 === "") {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Message can not be Empty"
      });
    } else if (this.state.selectedEmp.length === 0) {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "select atleast one user"
      });
    } else if (this.state.text2.length > this.state.msglength) {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Message Exceeded the Character Limit of 500"
      });
    } else {
      if (!found) {
        const message = this.state.text2;
        const fData = {
          emp: this.state.selectedEmp,
          message: message,
          msgdel: "",
          msg2: ""
        };
        api
          .post("api/cce/emp_notif_msgs_post", JSON.stringify(fData))
          .then(res => {
            if (res.data.success === true) {
              this.setState({ text2: "", selectedEmp: "" });
              this.growl.show({
                severity: "success",
                summary: "Success Message",
                detail: "Message Sent"
              });
            }
          })
          .catch(error => console.error("Error:", error));
      } else {
        const message = this.state.text2.name;
        const fData = {
          emp: this.state.selectedEmp,
          message: message,
          msgdel: "",
          msg2: ""
        };
        api
          .post("api/cce/emp_notif_msgs_post", JSON.stringify(fData))
          .then(res => {
            if (res.data.success === true) {
              this.setState({ text2: "", selectedEmp: "" });
              this.growl.show({
                severity: "success",
                summary: "Success Message",
                detail: "Message Sent"
              });
            }
          })
          .catch(error => console.error("Error:", error));
      }
    }
  };

  ondesgChange = event => {
    this.dt.filter(event.value, "designation", "in");
    this.setState({ designation: event.value });
  };
  onrepManChange = event => {
    this.dt.filter(event.value, "reporting_manager", "in");
    this.setState({ reportingMan: event.value });
  };
  onrepHrChange = event => {
    this.dt.filter(event.value, "reporting_hr", "in");
    this.setState({ reportingHr: event.value });
  };

  filterMsg = event => {
    setTimeout(() => {
      var results = this.state.mess.filter(country => {
        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
      });

      this.setState({ text: results });
    }, 250);
  };

  sendButton = () => {
    let classes = "p-button-success ";
    classes +=
      this.state.text2 === "" || this.state.text2.length > this.state.msglength
        ? "disabled"
        : this.state.selectedEmp.length === 0
        ? "disabled"
        : "enabled";
    return classes;
  };
  delButton = () => {
    const found = this.state.mess.some(
      el => el.name === (this.state.text2.name || this.state.text2)
    );
    let classes = "p-button-danger ";
    classes +=
      this.state.text2 === "" ||
      !found ||
      this.state.text2.length > this.state.msglength
        ? "disabled"
        : this.state.selectedEmp.length > 0
        ? "disabled"
        : "enabled";
    return classes;
  };
  saveButton = () => {
    const found = this.state.mess.some(
      el => el.name === (this.state.text2.name || this.state.text2)
    );
    let classes = "p-button-info ";
    classes +=
      this.state.text2 === "" ||
      found ||
      this.state.text2.length > this.state.msglength
        ? "disabled"
        : this.state.selectedEmp.length > 0
        ? "disabled"
        : "enabled";
    return classes;
  };
  showError = () => {
    let classes = "";
    if (this.state.text2.length > this.state.msglength) {
      classes = "p-error";
    } else {
      classes = "";
    }
    return classes;
  };

  setFilterState = (target, value) => {
    let filterState = this.state.filterState;
    filterState[target] = value;
    this.setState({ filterState });
  };

  render() {
    const {
      empDetail,
      selectedEmp,
      text,
      loading,
      text2,
      msglength,
      globalFilter
    } = this.state;

    var header = (
      <div className="p-grid">
        <div className="p-col-6" style={{ textAlign: "left" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={e => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="50"
          />
        </div>
        <div className="p-col-5">
          <Button
            style={{ float: "right" }}
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            label="excel"
            onClick={this.exportFile}
          />
        </div>
      </div>
    );
    let desgFilter = (
      <MultiSelect
        style={{ width: "100%" }}
        value={this.state.designation}
        options={this.state.desg}
        onChange={this.ondesgChange}
      />
    );
    let repMan_Filter = (
      <MultiSelect
        style={{ width: "100%" }}
        value={this.state.reportingMan}
        options={this.state.rep_man}
        onChange={this.onrepManChange}
      />
    );
    let repHr_Filter = (
      <MultiSelect
        style={{ width: "100%" }}
        value={this.state.reportingHr}
        options={this.state.rep_hr}
        onChange={this.onrepHrChange}
      />
    );

    return (
      <div className="layout-main">
        {loading ? (
          <>
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          </>
        ) : (
          <div>
            <div className="p-grid">
              <div className="p-col-12">
                <Panel header={this.props.menuProps.label}>
                  <div className="p-grid">
                    <div className="p-col-3">
                      <AutoComplete
                        //style={{ width: "50%" }}
                        value={text2}
                        field="name"
                        suggestions={text}
                        completeMethod={this.filterMsg}
                        size={40}
                        minLength={1}
                        placeholder="Message..."
                        dropdown={true}
                        onChange={e => this.setState({ text2: e.value })}
                        className={this.showError()}
                      />
                      {text2.length > msglength ? (
                        <span style={{ color: "red" }}>
                          Exceeded the character limit of 500
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>

                    <div className="p-col-8">
                      <Button
                        style={{ marginLeft: "2em" }}
                        className={this.sendButton()}
                        onClick={() => this.MessagSend()}
                        label="Send"
                      />

                      <Button
                        style={{ marginLeft: "2em" }}
                        className={this.saveButton()}
                        onClick={() => this.MessagSave()}
                        label="Save Template"
                      />

                      <Button
                        style={{ marginLeft: "2em" }}
                        className={this.delButton()}
                        onClick={() => this.msgDel()}
                        label="Delete Template"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="p-grid">
                    <Growl
                      ref={el => (this.growl = el)}
                      style={{ marginTop: 20 }}
                      position="bottomright"
                    />
                    <DataTable
                      emptyMessage={
                        empDetail.length === 0 && !loading
                          ? "No data"
                          : "Loading..."
                      }
                      responsive={true}
                      ref={el => (this.dt = el)}
                      value={empDetail}
                      paginator={true}
                      rows={20}
                      rowsPerPageOptions={[20, 30, 40, 50, empDetail.length]}
                      globalFilter={globalFilter}
                      header={header}
                      selection={selectedEmp}
                      onSelectionChange={e =>
                        this.setState({ selectedEmp: e.value })
                      }
                    >
                      <Column
                        selectionMode="multiple"
                        style={{ width: "4em", textAlign: "center" }}
                      />
                      <Column
                        field="emp_num"
                        header="Employee Id"
                        sortable={true}
                        style={{ width: "10em" }}
                      />
                      <Column field="name" header="Name" sortable={true} />
                      <Column field="email" header="Mail Id" sortable={true} />
                      <Column
                        field="phn_num"
                        header="Contact"
                        sortable={true}
                      />
                      <Column
                        field="designation"
                        header="Designation"
                        sortable={true}
                        filter={true}
                        filterElement={desgFilter}
                      />
                      <Column
                        field="reporting_manager"
                        header="Reporting Manager"
                        sortable={true}
                        filter={true}
                        filterElement={repMan_Filter}
                      />
                      <Column
                        field="reporting_hr"
                        header="Reporting Hr"
                        sortable={true}
                        filter={true}
                        filterElement={repHr_Filter}
                      />
                    </DataTable>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organisation: state.util.organisation
  };
};

export default connect(mapStateToProps, null)(PushNotification);
