import React, { Component } from "react";
import { Panel } from "primereact/panel";

class CPCL extends Component {
  render() {
    return (
      <div className="layout-main">
        <Panel
          className="cpcl_panel"
          header={
            <div style={{ fontSize: "18px" }}>{this.props.menuProps.label}</div>
          }
        >
          <div>
            {this.props.menuProps
              ? this.props.menuProps.cpcl_img
                ? this.props.menuProps.cpcl_img.map((img) => (
                    <img src={img} style={{ margin: 9 }} />
                  ))
                : null
              : null}
          </div>
        </Panel>
      </div>
    );
  }
}

export default CPCL;
