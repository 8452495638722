import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import EChartsRadar from "../../common/ECharts/EChartsRadar";
import EChartsBox from "../../common/ECharts/EChartsBox";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Panel } from "primereact/panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

class AwakeMeDashboard extends Component {
  state = {
    atmascore: [],
    jobf: [],
    jobn: [],
    jobs: [],
    jobinf: {},
    span: null,
    loading: true,
    user: this.props.user,
    userb: this.props.userb,
    visible: false,
    dialogbody: "",
    dialoghead: "",
    backcolor: "#ffd966",
    is_paid: true,
  };
  componentDidUpdate() {
    if (this.props.user !== this.state.user) {
      this.setState({
        user: this.props.user,
        userb: this.props.userb,
        loading: true,
      });
      this.fetch();
    }
  }
  componentDidMount() {
    this.fetch();
  }
  fetch() {
    api
      .get("/api/me/me_awake_competencies")
      .then((res) => {
        if (res.data.success) {
          const { atmascore, jobf, jobn, jobs, span, jobinf, is_paid } = res.data;
          this.setState({
            atmascore: atmascore,
            jobf: jobf,
            jobn: jobn,
            jobs: jobs,
            span,
            jobinf,
            is_paid: is_paid,
          });

          //   if (atmascore.length > 0) {
          //     this.props.callback(true, false);
          //   } else {
          //     this.props.callback(false, true);
          //   }
          // } else {
          //   this.setState({ loading: false });
          //   this.props.callback(false, true);
        }
        this.setState({ loading: false });
      })

      .catch((err) => console.log(err));
  }
  headerTemplate = (data) => {
    return data.pname;
  };
  footerTemplate = (data) => {
    return <td style={{ display: "none" }}></td>;
  };
  coldata = (rowData, job) => {
    if (rowData[job] === "#") {
      return <i className="fas fa-lock"></i>;
    } else {
      return (
        <div
          style={{
            color: rowData[job] === "Average" || rowData[job] === "Poor" ? "red" : "green",
          }}
        >
          {rowData[job] ? rowData[job] : "-"}
        </div>
      );
    }
  };
  onHide = () => {
    this.setState({
      visible: false,
      dialogbody: "",
      dialoghead: "",
    });
  };
  dialogtoggle = (dialoghead, dialogbody) => {
    let regex = /\/\*(.*)\*\//;
    let diminf = dialogbody?.replace(regex, dialoghead);
    this.setState({
      visible: true,
      dialogbody: diminf,
      dialoghead: dialoghead,
    });
  };
  coldatadim = (rowData) => {
    return (
      <span>
        <span>{rowData["dimname"]} </span>
        <span>
          <FontAwesomeIcon
            onClick={() => this.dialogtoggle(rowData["dimname"], rowData["diminf"])}
            style={{
              cursor: "pointer",
              color: "#00a3b4",
            }}
            icon={["far", "question-circle"]}
          />
        </span>
      </span>
    );
  };
  scorebody = (rowData) => {
    if (rowData["score"] === "#") {
      return <i className="fas fa-lock"></i>;
    } else {
      return <span>{rowData["score"]}</span>;
    }
  };
  atmascorehead = (job) => {
    if (job === "#") {
      return <i className="fas fa-lock"></i>;
    } else {
      return <span>{job}</span>;
    }
  };
  render() {
    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column style={{ backgroundColor: "#ffffff" }} header="Job Family" rowSpan={2} />
          <Column
            style={{
              backgroundColor: this.state.backcolor,
              fontSize: "12px",
              height: "30px",
            }}
            header="Your Preference"
          />
        </Row>
        <Row>
          <Column style={{ height: "30px", backgroundColor: "#d8d8d8" }} header="Atma Score" />
        </Row>
        <Row>
          <Column header="Key Success Behaviors" style={{ backgroundColor: this.state.backcolor }} />
          <Column style={{ height: "50px", backgroundColor: this.state.backcolor }} header="Your Score" />
        </Row>
      </ColumnGroup>
    );
    let headerGroup2 = (
      <ColumnGroup>
        <Row>
          {this.state.jobn.map((job, index) => (
            <Column
              style={{
                backgroundColor: this.state.backcolor,
                height: "30px",
                borderLeft: "1px solid #ffd966",
              }}
              key={index}
              header={job ? job : "-"}
            />
          ))}
          <Column
            colSpan={this.state.span}
            style={{
              backgroundColor: this.state.backcolor,
              height: "30px",
              // borderLeft: "1px solid white",
            }}
            header="-"
          />
        </Row>
        <Row>
          {this.state.jobs.map((job, index) => (
            <Column
              style={{
                backgroundColor: "#d8d8d8",
                height: "30px",
                borderLeft: "1px solid #d8d8d8",
              }}
              key={index}
              header={this.atmascorehead(job)}
            />
          ))}
        </Row>
        <Row>
          {this.state.jobf.map((job, index) => (
            <Column
              style={{
                height: "50px",
                borderLeft: "1px solid #ffd966",
                backgroundColor: this.state.backcolor,
              }}
              key={index}
              header={
                <span>
                  <span>{job} </span>
                  <span>
                    <FontAwesomeIcon
                      onClick={() => this.dialogtoggle(job, this.state.jobinf[job])}
                      style={{
                        cursor: "pointer",
                        color: "#00a3b4",
                      }}
                      icon={["far", "question-circle"]}
                    />
                  </span>
                </span>
              }
            />
          ))}
        </Row>
      </ColumnGroup>
    );
    const columnsdt = this.state.jobf.map((job, index) => (
      <Column
        key={index}
        //field={job}
        body={(rowData) => this.coldata(rowData, job)}
        header={job}
        style={{
          //color: rowData[job] === "Low" || job === "Very Low" ? "red" : "green",
          width: "200px",
          textAlign: "center",
          borderLeft: "1px solid white",
        }}
      />
    ));
    return (
      <div className="layout-main item-wrapper">
        <Growl ref={(el) => (this.growl = el)} position="topright" />
        <Dialog
          className="me-datatable"
          appendTo={document.body}
          header={this.state.dialoghead}
          visible={this.state.visible}
          onHide={this.onHide}
          style={{
            maxWidth: "40vw",
            marginLeft: "8vw",
            marginTop: "3vw",
            maxheight: "40vw",
          }}
        >
          {this.state.dialogbody}
        </Dialog>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                height: "40px",
              }}
            >
              <div style={{ float: "left" }}>Job Profile</div>
              <div>
                {this.props.user_details && !this.state.is_paid && (
                  <form method="post" name="redirect" action="https://www.skillablers.com/BillingTesting/Billing.aspx">
                    <input
                      type="hidden"
                      id="encb_name"
                      name="encb_name"
                      value={this.props.user_details.first_name + " " + this.props.user_details.last_name}
                    />
                    <input type="hidden" id="encb_email" name="encb_email" value={this.props.user_details.email} />
                    <input type="hidden" id="encb_phone" name="encb_phone" value={this.props.user_details.phone_number} />
                    <input type="hidden" id="enc_RegId" name="enc_RegId" value={this.props.user_details.employee_number} />
                    <input type="hidden" id="enc_servicetype" name="enc_servicetype" value="9" />
                    <input
                      type="hidden"
                      id="enc_urlpage"
                      name="enc_urlpage"
                      value={process.env.REACT_APP_BASE_URL + "confirm-payment/me/jobprofile"}
                    />
                    {/* <input type="submit" value="Pay" style={{ marginRight: "15px", float: "right" }} className="p-button-warning whatbtn" /> */}
                    <Button
                      type="submit"
                      style={{ marginRight: "15px", float: "right", marginBottom: 9 }}
                      className="p-button-warning whatbtn"
                      label="Buy Full Version"
                      // icon={this.state.loading ? "pi pi-spin pi-spinner" : "pi pi-download"}
                      // onClick={this.save_biopref}
                    />
                    {/* <script language="javascript">document.redirect.submit();</script> */}
                  </form>
                )}{" "}
              </div>
            </div>
          }
        >
          {this.state.loading ? (
            <div className="row">
              <div className="loading-spinner col-md-3">
                <ProgressSpinner />
              </div>
            </div>
          ) : this.state.atmascore.length > 0 ? (
            <DataTable
              className="me-datatable stutable"
              style={{
                fontSize: "12px",
                borderBottom: "1px solid #ffd966",
                borderRight: "1px solid #ffd966",
              }}
              frozenHeaderColumnGroup={headerGroup}
              headerColumnGroup={headerGroup2}
              //header="SubHeader"
              value={this.state.atmascore}
              sortField="pname"
              sortOrder={1}
              groupField="pname"
              rowGroupMode="subheader"
              rowGroupHeaderTemplate={this.headerTemplate}
              rowGroupFooterTemplate={this.footerTemplate}
              scrollable={true}
              scrollHeight="400px"
              frozenWidth="420px"
              //unfrozenWidth="600px"
            >
              <Column
                //field="dimname"
                body={(rowData) => this.coldatadim(rowData)}
                style={{ width: "300px" }}
                frozen={true}
              />
              <Column
                body={(rowData) => this.scorebody(rowData)}
                // header="Your Score"
                style={{ width: "120px", textAlign: "center" }}
                frozen={true}
              />
              {columnsdt}
            </DataTable>
          ) : (
            <div className="col-md-12">
              <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                <div
                  className="medialog"
                  style={{
                    fontSize: "20px",
                    fontWeight: "800px",
                    textAlign: "center",
                  }}
                >
                  Data Collection is in Progress
                </div>
              </div>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AwakeMeDashboard);
