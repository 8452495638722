import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import EChartsRadar from "../../common/ECharts/EChartsRadar";
import { ProgressSpinner } from "primereact/progressspinner";
import DimInf from "./DimInference";
import { Panel } from "primereact/panel";
import { renderPanelHeader } from "../../utils/Utils";
import ScoreDistribution from "./DimInfLayout";

class LeadershipIndex extends Component {
  state = {
    topquartile: [],
    overallorg: [],
    leadershipteam: [],
    loading: true,
    containerKey: 1,
    table_id: null,
    table_name: null,
    type: "",
    lead_potential_index: "",
    top_potential_index: "",
    dataprogress: false,
    topquarter_len: "",
    leadership_len: "",
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    distdata: [],
    load2: false,
    secwin: false,
    totalempcount: "",
  };
  componentDidMount() {
    const data = { user: this.props.user };
    api
      .post("/api/assessments/leadershipindex", data)
      .then((res) => {
        if (res.data.results) {
          const {
            topquartile,
            overallorg,
            leadershipteam,
            lead_potential_index,
            topq_potential_index,
            topquarter_len,
            leadership_len,
          } = res.data;
          this.setState({
            topquarter_len: topquarter_len,
            leadership_len: leadership_len,
            lead_potential_index: lead_potential_index,
            topq_potential_index: topq_potential_index,
            dataprogress: true,
            //topquartile: topquartile,
            overallorg: overallorg,
            leadershipteam:
              leadershipteam.length > 0
                ? leadershipteam
                : [
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "\n \n \n \n \n \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t   NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                  ],
            topquartile:
              topquartile.length > 0
                ? topquartile
                : [
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "\n \n \n \n \n \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t \t   NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                    {
                      dimensionName: "",
                      score: 0,
                      title:
                        "Leadership Team Competency Score \n \n \n \n NO DATA",
                    },
                  ],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => console.log(err));
  }

  onDimensionClick = (text) => {
    // const dimensionSelected = param.name.replace("\n", " ");
    // const dimensionSel = this.state.topquartile.filter(
    //   (m) => m.dimensionName === dimensionSelected
    // );
    if (this.state.type !== text) {
      this.setState({
        secwin: true,
        load2: true,
        type: text,
        // table_name: dimensionSel[0].dimensionName,
        // table_id: dimensionSel[0].dimension_id + "",
        containerKey: this.state.containerKey + 1,
      });
      //console.log(dimensionSel[0].dimensionName, dimensionSel[0].dimension_id);
      const data = {
        lead_h: text === "leadership" ? true : false,
        user: this.props.user,
      };
      api
        .post("/api/assessments/leadershipindex_post", data)
        .then((res) => {
          const { demograph } = res.data;
          this.setState({
            secwin: true,
            load2: false,
            distdata: demograph,
            totalempcount: demograph.overallCount,
            leveldata: demograph.levelWise.map((grade) => ({
              name: grade.role,
              value: grade.percentage,
            })),
            deptdata: demograph.deptWise.map((grade) => ({
              name: grade.name,
              value: grade.percentage,
            })),
            agedata: demograph.ageWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
            workexpdata: demograph.workexpWise.map((grade) => ({
              name: grade.range,
              value: grade.percentage,
            })),
          });
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const chartData = {
      type: "radarMe",
      trigger: false,
      title:
        this.state.topquartile.length > 0
          ? this.state.topquartile[0].title
          : "",
      data: this.state.topquartile.map((c) => {
        return {
          name: c.dimensionName,
          value: c.score,
          color: c.color,
          id: c.dimension_id,
        };
      }),
    };
    const chartData2 = {
      type: "radarMe",
      title:
        this.state.overallorg.length > 0 ? this.state.overallorg[0].title : "",
      data: this.state.overallorg.map((c) => {
        return { name: c.dimensionName, value: c.score, color: c.color };
      }),
    };
    const chartData3 = {
      type: "radarMe",
      trigger: false,
      title:
        this.state.leadershipteam.length > 0
          ? this.state.leadershipteam[0].title
          : this.state.leadershipteam.length > 0
          ? this.state.leadershipteam[0].title
          : "",
      data: this.state.leadershipteam.map((c) => {
        return {
          name: c.dimensionName,
          value: c.score,
          color: c.color,
          id: c.dimension_id,
        };
      }),
    };
    return (
      <div className="layout-main">
        <Panel
          className="topqartile"
          header={
            <div style={{ fontSize: "18px" }}>{this.props.menuProps.label}</div>
          }
        >
          {this.state.loading === true ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : this.state.dataprogress ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <span className="potentail-value-span">
                    <span className="potentail-value">
                      {this.state.lead_potential_index}
                    </span>
                  </span>

                  <Panel
                    header={renderPanelHeader({
                      heading: "ATMA Index - Leadership Team",
                      count: this.state.leadership_len,
                    })}
                  >
                    <div
                      className="box-body leaderradarpanel"
                      onClick={() => this.onDimensionClick("leadership")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row">
                        <EChartsRadar
                          callBack={(param) =>
                            this.onDimensionClick1(param, "leadership")
                          }
                          emo={true}
                          chartData={chartData3}
                        />
                      </div>
                    </div>
                  </Panel>
                </div>

                <div className="col-md-6">
                  <span className="potentail-value-span">
                    <span className="potentail-value">
                      {this.state.topq_potential_index}
                    </span>
                  </span>

                  <Panel
                    className="topquartilepanel"
                    header={renderPanelHeader({
                      heading: "ATMA Index - Top Quartile Team",
                      count: this.state.topquarter_len,
                    })}
                  >
                    <div
                      className="box-body leaderradarpanel"
                      onClick={() => this.onDimensionClick("topq")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row">
                        <EChartsRadar
                          callBack={(param) =>
                            this.onDimensionClick1(param, "topq")
                          }
                          emo={true}
                          //callBack={this.onDimensionClick}
                          chartData={chartData}
                        />
                      </div>
                    </div>
                  </Panel>
                </div>
              </div>

              {this.state.secwin ? (
                this.state.load2 ? (
                  <div className="loading-spinner-container">
                    <ProgressSpinner></ProgressSpinner>
                  </div>
                ) : (
                  <Panel
                    className={
                      this.state.type === "topq" ? "topquartilepanel" : ""
                    }
                    header={
                      <div style={{ marginBottom: 15 }}>
                        <span>
                          {this.state.type === "leadership"
                            ? "Leadership Team"
                            : "Top Quartile Team"}
                        </span>
                        {/* <span
                          className="total-employees-container"
                          style={{ float: "right" }}
                        >
                          N - {this.state.totalempcount}
                        </span> */}
                      </div>
                    }
                  >
                    <ScoreDistribution
                      nCount={this.state.totalempcount}
                      menuProps={this.props.menuProps}
                      data={this.state.distdata}
                      potential={[]}
                      leveldata={this.state.leveldata}
                      deptdata={this.state.deptdata}
                      agedata={this.state.agedata}
                      workexpdata={this.state.workexpdata}
                      //  name={this.props.type}
                      //globalfilter={globalfilter}
                      height={"380px"}
                      dheight={"440px"}
                      total={true}

                      // type={this.props.type}
                    />
                  </Panel>
                )
              ) : null}
              {/* <div className="row">
              <div className="p-col-12">
                <div className="col-md-12">
                  <div className="col-md-6">
                    <EChartsRadar chartData={chartData3} />
                  </div>
                </div>
              </div>
            </div> */}
              {/* {this.state.table_id ? (
                <div>
                  <br />
                  <div key={this.state.containerKey}>
                    {this.state.type === "leadership" ? (
                      <DimInf
                        menuProps={this.props.menuProps}
                        layoutcheck={true}
                        topquartile={true}
                        dimid={this.state.table_id}
                        dataProps={{
                          topq: true,
                          lead_h: true,
                          leadership: true,
                          type: this.state.table_name
                        }}
                        type={this.state.table_name}
                      />
                    ) : (
                      <DimInf
                        menuProps={this.props.menuProps}
                        layoutcheck={true}
                        topquartile={true}
                        dimid={this.state.table_id}
                        dataProps={{
                          topquartile: true,
                          topq: true,
                          lead_h: false,
                          type: this.state.table_name
                        }}
                        type={this.state.table_name}
                      />
                    )}
                  </div>
                </div>
              ) : null} */}
            </>
          ) : (
            <div className="col-md-12">
              <div
                style={{ color: "#6a6b6cba" }}
                className=" box-body box-body-cust-250"
              >
                <div
                  className="medialog"
                  style={{
                    fontSize: "20px",
                    fontWeight: "800px",
                    textAlign: "center",
                  }}
                >
                  Data Collection is in Progress
                </div>
              </div>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}
export default LeadershipIndex;
