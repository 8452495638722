import React from "react";
import { withRouter } from "react-router-dom";

class PublicLayout extends React.Component {
  render() {
    return (
      <div className="tot">
        <div className="p-grid login-nav-header">
          <div className="p-col-6">
            <a href="/login">
              <img
                src="https://res.cloudinary.com/yeta/image/upload/c_scale,h_50,w_105/v1534747791/yeta_logo.png"
                alt="Yeta - Enabling people"
                className="img-responsive"
              />
            </a>
          </div>
          <div className="p-col-6">
            <div className="topbar-login-text small-size-font">
              <i className="login-nav-icon pi pi-envelope"></i>
              <a
                href="mailto:support@yetasolutions.com"
                style={{ color: "#999" }}
              >
                support@yetasolutions.com
              </a>
            </div>
          </div>
        </div>

        {this.props.children}

        <div className="layout-main powered-by-yeta">
          <span className="layout-text-footer">
            Powered by{" "}
            <a href="http://www.yetasolutions.com/" target="_blank">
              <b>yeta</b>
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(PublicLayout);
