import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../api";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function TrainingNeedsComp(props) {
  const [selectedTraining, SetSelectedTraining] = React.useState(props?.training_data[0]);
  const [trainingData, SetTrainingData] = React.useState([]);
  const [trainingDataModal, SetTrainingDataModal] = React.useState(false);
  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column headerStyle={{ backgroundColor: "#ff9800", color: "#ffffff" }} header={selectedTraining["module_name"]} colSpan={2} />
      </Row>
      <Row>
        <Column headerStyle={{ backgroundColor: "#ffffff" }} header="Number" />
        <Column headerStyle={{ backgroundColor: "#ffffff" }} header="Name" />
      </Row>
    </ColumnGroup>
  );
  const linkBody = (e) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          disabled={!e.links}
          onClick={() => {
            SetTrainingDataModal(true);
            SetTrainingData(e);
          }}
          label="Open links"
        />
      </div>
    );
  };
  return (
    <div>
      <Dialog
        className=""
        appendTo={document.body}
        header={trainingData.training_id + " - " + trainingData.module_name}
        visible={trainingDataModal}
        onHide={() => {
          SetTrainingData([]);
          SetTrainingDataModal(false);
        }}
        style={{ width: 500, overflow: "scroll" }}
      >
        <div>
          <div>
            {trainingData && trainingData.links ? (
              <div>
                <ul>
                  {trainingData.links.map((td) => (
                    <li>
                      <a href={td} target="_blank">
                        {td}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>No links available</div>
            )}
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ margin: 18, backgroundColor: "red", border: "1px solid red" }}
                className="button-excel"
                type="button"
                // icon="pi pi-external-link"
                iconPos="left"
                label="Close"
                onClick={() => {
                  SetTrainingData([]);
                  SetTrainingDataModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <DataTable
          className="metable-me-dashboard training_table"
          style={{
            textAlign: "center",
            margin: "10px",
            borderTop: "5px solid red",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          header={
            <div style={{ textAlign: "center", fontSize: 18 }}>
              {props.teamDash ? (props.empModel ? props.empSel.label : "Suggested Training Areas for Team") : "Suggested Training Areas"}
            </div>
          }
          responsive={true}
          value={props.training_data}
          emptyMessage={"No data"}
          selectionMode="single"
          selection={selectedTraining}
          onSelectionChange={(e) => SetSelectedTraining(e.value)}
        >
          <Column headerStyle={{ backgroundColor: "#ffffff", width: 80 }} header={"S.No"} field={"sno"} />
          <Column
            headerStyle={{ backgroundColor: "#ffffff", width: 150 }}
            header={"Training id"}
            field={"training_id"}
            style={{ textAlign: "left" }}
          />
          <Column headerStyle={{ backgroundColor: "#ffffff" }} header={"Topic Name"} field={"module_name"} style={{ textAlign: "left" }} />
          <Column headerStyle={{ backgroundColor: "#ffffff" }} body={linkBody} field={"links"} style={{ textAlign: "left" }} />
        </DataTable>
        {props.empModel ? (
          <DataTable
            className="metable-me-dashboard"
            style={{
              textAlign: "center",
              margin: "10px",
              borderTop: "5px solid red",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            header={
              <div style={{ textAlign: "center", fontSize: 18 }}>
                {props.teamDash ? (props.empModel ? props.empSel.label : "Team Training Topic Coverage") : "Training Topic Coverage"}
              </div>
            }
            resizableColumns={true}
            responsive={true}
            value={selectedTraining && [{ sample: "sample" }]}
            emptyMessage={"No data"}
            resizableColumns={true}
          >
            <Column
              headerStyle={{ backgroundColor: "#ff9800", color: "#ffffff" }}
              header={selectedTraining && selectedTraining["module_name"]}
              //rowSpan={2}
              body={() => (
                <div>
                  <div>
                    <ul style={{ textAlign: "left" }}>
                      {props?.learn_details &&
                        props.learn_details[selectedTraining["training_id"]] &&
                        props.learn_details[selectedTraining["training_id"]].split("::").map((learn) => learn && <li>{learn}</li>)}
                    </ul>
                  </div>

                  <div style={{ textAlign: "left" }}>
                    <u>
                      <b>Training Links</b>
                    </u>
                  </div>

                  <br />
                  {selectedTraining && selectedTraining["links"] ? (
                    <div>
                      <ul style={{ textAlign: "left" }}>
                        {selectedTraining["links"].map((td) => (
                          <li>
                            <a href={td} target="_blank">
                              {td}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div>No links available</div>
                  )}
                </div>
              )}
            />
          </DataTable>
        ) : (
          <DataTable
            className="metable-me-dashboard" //emp-train-details
            style={{
              textAlign: "center",
              margin: "10px",
              borderTop: "5px solid red",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            header={<div style={{ textAlign: "center", fontSize: 18 }}>Team Member Names</div>}
            resizableColumns={true}
            responsive={true}
            value={props.empDetails[selectedTraining["training_id"]]}
            emptyMessage={"No data"}
            resizableColumns={true}
            headerColumnGroup={headerGroup}
          >
            <Column headerStyle={{ backgroundColor: "#ffffff" }} field="empNo" />
            <Column headerStyle={{ backgroundColor: "#ffffff" }} field="name" />
          </DataTable>
        )}
      </div>
    </div>
  );
}
