import React, { Component } from "react";
import { RadioButton } from "primereact/radiobutton";
import { connect } from "react-redux";
import { group_answers } from "../../store/actions/question";
import { orgSettings } from "../../store/actions/auth";

class OneInMany extends Component {
  state = {
    singlequestion: this.props.questn,
    noofquestions: this.props.noofquestn,
    answer: {},
    answer_text: "",
    answer_text2: "",
  };
  componentDidUpdate() {
    if (this.state.singlequestion !== this.props.questn) {
      this.setState({
        singlequestion: this.props.questn,
        noofquestions: this.props.noofquestn,
        answer_text: "",
        answer_text2: "",
      });
    }
  }
  generate_answer = (ans_text, result, ans_id, item, ans_text2) => {
    this.props.callback(item);
    this.setState({
      answer_text: ans_text,
      answer_text2: ans_text2,
    });
    let ans_arr = this.props.group_answer;
    ans_arr[item.guid] = {
      result: result,
      answer_id: ans_id,
      item_id: item.guid,
      version: this.state.singlequestion.currentUserItem.version,
    };
    this.props.group_answers(ans_arr);
  };

  show_dailog = (item) => {
    this.props.callback2(item);
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }
  };
  onHide = () => {
    this.setState({ visible: false });
  };

  scrollSmoothHandler = () => {
    this.refs.register.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  options = () => {
    const { singlequestion, answer_text, answer_text2 } = this.state;
    const { user_details } = this.props;
    const enableLanguage =
      user_details &&
      user_details.organisation &&
      user_details.organisation.enableLanguage;
    const language_preference =
      user_details && user_details.language_preference;
    return singlequestion.item.options.map((Option) => {
      return (
        <div
          onClick={this.scrollSmoothHandler}
          key={Option.key}
          className="p-grid "
          style={{
            marginRight: "25px",
            marginLeft: "15px",
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RadioButton
            inputId={Option.answer_id + Option.key}
            name={Option.answer_id}
            value={Option.value}
            onChange={() =>
              this.generate_answer(
                Option.value,
                Option.key,
                Option.answer_id,
                singlequestion.item,
                Option && Option[language_preference]
                  ? Option[language_preference]
                  : ""
              )
            }
            checked={answer_text === Option.value}
          />
          <label
            htmlFor={Option.answer_id + Option.key}
            className="p-radiobutton-label"
          >
            <div>{Option.value}</div>
            <div>
              {enableLanguage &&
                Option[language_preference] &&
                Option[language_preference]}
            </div>
          </label>
        </div>
      );
    });
  };
  render() {
    const { visible, user_details } = this.props;
    const { singlequestion, answer_text, answer_text2 } = this.state;
    const enableLanguage =
      user_details &&
      user_details.organisation &&
      user_details.organisation.enableLanguage;
    const language_preference =
      user_details && user_details.language_preference;
    return (
      <div className="p-grid que-tile" ref="register">
        <div className="p-col-12 p-md-12 p-lg-12" style={{ paddingTop: 0 }}>
          <div
            style={{
              cursor: "pointer",
              padding: "0px",
              paddingLeft: "5px",
            }}
            onClick={() => this.show_dailog(singlequestion.item)}
          >
            <div className="p-grid">
              <div
                className={answer_text ? "p-col-9" : "p-col-12"}
                style={{ fontWeight: 700 }}
              >
                <div>{singlequestion.item.item_response_lead}</div>
                <div>
                  {enableLanguage &&
                  singlequestion.item.languages.item_response_lead[
                    language_preference
                  ]
                    ? singlequestion.item.languages.item_response_lead[
                        language_preference
                      ]
                    : ""}
                </div>
              </div>
              {answer_text ? (
                <div className="p-col-3">
                  {enableLanguage && (
                    <div
                      style={{
                        float: "right",
                        marginRight: "5px",
                        textAlign: "center",
                      }}
                      className="anschip"
                    >
                      {this.state.answer_text2}{" "}
                    </div>
                  )}
                  <div
                    style={{
                      float: "right",
                      marginRight: "5px",
                      textAlign: "center",
                    }}
                    className="anschip"
                  >
                    {this.state.answer_text}{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div hidden={visible !== singlequestion.item.guid} className="p-grid">
            {singlequestion.item.item_image ? (
              <div className="p-col-12">
                <img
                  className="img-question"
                  onError={(i) => (i.target.style.display = "none")}
                  src={singlequestion.item.item_image}
                />
              </div>
            ) : (
              ""
            )}
            {this.options()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group_answer: state.question.answer,
    user_details: state.auth.user_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    group_answers: (ans_arr) => dispatch(group_answers(ans_arr)),
    getOrgSettings: (company) => dispatch(orgSettings(company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneInMany);
