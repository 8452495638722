import * as actionTypes from "./actionTypes";
import api from "../../api";

export const setOrgSelected = org => {
  return {
    type: actionTypes.UTIL_SET_ORG,
    org
  };
};

export const setNotificationCount = count => {
  return {
    type: actionTypes.NOTIFICATION_COUNT,
    count
  };
};

export const setProfilePic = profile_pic_url => {
  return {
    type: actionTypes.SETPROFILEPIC,
    profile_pic_url
  };
};

export const profile_pic_loading = profile_pic_loading => {
  return {
    type: actionTypes.PROFILEPICLOADING,
    profile_pic_loading
  }
}

export const fetchNotificationCount = () => {
  return dispatch => {
    api
      .get("api/cce/get_unread_message_count")
      .then(res =>
        dispatch(
          setNotificationCount(
            res.data && res.data.unreadMessageCount
              ? res.data.unreadMessageCount
              : 0
          )
        )
      );
  };
};

export const updateProfilePic = () => {
  return dispatch => {
    dispatch(profile_pic_loading(true))
    api
      .get("api/me/get-profile-picture")
      .then(res => dispatch(setProfilePic(res.data.profile_pic_url)))
      .catch(error => console.log(error));
  };
};
