import React, { Component } from "react";
import EChartScatter from "../../common/ECharts/EChartScatter";
import EChart from "../../common/ECharts/ECharts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import api from "../../api";
import { Dialog } from "primereact/dialog";
import { trainexcelexport } from "../../common/Helper";
import XLSX from "xlsx";
import { renderPanelHeader } from "../../utils/Utils";
import { Growl } from "primereact/growl";

let key = 0;

class ScoreDistribution extends Component {
  state = {
    globalFilter: "",
    fontSize: "12px",
    top_strengths: [],
    train_needs: [],
    emp_name: "",
    table_dialog: false,
    inference_dialog: false,
    inference: "",
    dimname: "",
    filename_dialog: false,
    filename: "",
    rowsel: null,
    first: 0,
  };
  getfilename = () => {
    this.setState({ filename_dialog: true });
  };
  fonHide = () => {
    this.setState({ filename_dialog: false, filename: "" });
  };

  empTemplate = (rowData) => {
    return (
      <Button
        className="clickbtn p-button-raised p-button-secondary p-button-rounded"
        label={rowData["dimension_name"]}
        onClick={() => this.emppopup(rowData)}
      />
    );
  };
  emppopup = (rowData) => {
    this.setState({
      inference_dialog: true,
      inference: rowData["dimension_inference"],
      dimname: rowData["dimension_name"],
    });
  };
  infonHide = () => {
    this.setState({ inference_dialog: false });
  };
  onHide = () => {
    this.setState({ table_dialog: false });
  };
  actionTemplate = (rowData) => {
    return (
      <Button
        className="clickbtn p-button-raised p-button-secondary p-button-rounded"
        label={rowData["employee_number"]}
        onClick={() => this.popup(rowData)}
      />
    );
  };

  popup = (rowData) => {
    if (rowData["value"]) {
      api
        .post("/api/assessments/strengthtrainemployee", {
          empno: rowData["value"]["employee_number"],
          user: this.props.user,
        })
        .then((res) => {
          if (res.data.results == true) {
            this.setState({
              train_needs: res.data.train_needs,
              top_strengths: res.data.top_strengths,
              table_dialog: true,
              emp_name: res.data.emp_name,
            });
          } else {
            this.growl.show({
              severity: "error",
              summary: "Data is not available for the employee",
            });
            console.log("false", res.data);
            this.setState({ no_data: true, empcount: res.data.empcount });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  trainexcelexport = (filename) => {
    var fname = filename;
    var empname = this.state.emp_name;
    var expfname = filename + ".xls";
    var Sheaders = ["Strengths"];
    var Wheaders = ["Weakness"];
    /* convert from workbook to array of arrays */
    var topstrengths = this.state.top_strengths.map((emp) => [emp.dimension_name]);
    var trainingneeds = this.state.train_needs.map((emp) => [emp.dimension_name]);
    const ws = XLSX.utils.aoa_to_sheet([Sheaders, ...topstrengths], {
      origin: "A4",
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [Wheaders, ...trainingneeds], {
      origin: "B4",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Employee Name:"]], {
      origin: "A2",
    });
    XLSX.utils.sheet_add_aoa(ws, [[empname]], {
      origin: "B2",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Top Strengths and Weakness"]], {
      origin: "B1",
    });
    XLSX.utils.sheet_add_aoa(ws, [["Report Name:"]], {
      origin: "A1",
    });
    XLSX.utils.book_append_sheet(wb, ws, fname);
    XLSX.writeFile(wb, expfname);
    this.setState({ filename_dialog: false });
  };

  render() {
    const { data, potential, leveldata, deptdata, agedata, workexpdata, name, height, dheight, type, nCount, menuProps } = this.props;
    const chartData = {
      type: "scatter",
      title: "",
      data: potential,
      lowd: data.low_val,
      highd: data.high_val,
      yaxis_label: "ATMA Index",
      graph: "toppot",
    };

    const levelData = {
      type: "bar",
      data: leveldata,
      title: "Grade - wise",
      employeesCount: leveldata.length,
      width: "65%",
    };
    const deptData = {
      type: "bar",
      data: deptdata,
      title: "Depatment - wise",
      employeesCount: leveldata.length,
      width: "60%",
    };
    const ageData = {
      type: "bar",
      data: agedata,
      title: "Age - wise",
      employeesCount: agedata.length,
      width: "65%",
    };
    const workexpData = {
      type: "bar",
      data: workexpdata,
      title: "Tenure with company",
      employeesCount: workexpdata.length,
      width: "65%",
    };
    var header = (
      <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value, first: 0 })} placeholder="Search..." size="50" />
    );

    return (
      <div key={key} className="col-md-12">
        <Growl ref={(el) => (this.growl = el)} />
        {this.state.filename_dialog ? (
          <Dialog
            header="Please Provide the filename by which you want to save the file."
            visible={this.state.filename_dialog}
            style={{ width: "50vw" }}
            onHide={() => this.fonHide()}
          >
            <InputText style={{ marginRight: "15px" }} value={this.state.filename} onChange={(e) => this.setState({ filename: e.target.value })} />
            <Button label="Save" icon="pi pi-check" onClick={() => this.trainexcelexport(this.state.filename)} />
          </Dialog>
        ) : null}
        <Dialog
          style={{ width: "40vw" }}
          //className="medialog"
          appendTo={document.body}
          header={this.state.dimname}
          visible={this.state.inference_dialog}
          onHide={this.infonHide}
        >
          <span style={{ lineHeight: "2" }}>{this.state.inference}</span>
        </Dialog>
        <div className="row flex-container">
          <div className="col-md-7 flex">
            <div className="row flex-container">
              <div className="col-md-6 flex">
                <Panel
                  header={renderPanelHeader({
                    heading: "Age - wise",
                    count: nCount,
                  })}
                >
                  <div className="row">
                    <div className="p-col-12">
                      <EChart chartData={ageData} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="p-col-12">
                      <DataTable
                        style={{
                          fontSize: this.state.fontSize,
                          textAlign: "center",
                          marginTop: "2%",
                        }}
                        resizableColumns={true}
                        className="orangep"
                        value={data.ageWise}
                      >
                        <Column field="range" header="Age" style={{ fontSize: this.state.fontSize }} />
                        <Column style={{ fontSize: this.state.fontSize }} field="count" header="Employee Count" />
                        <Column field="totalCount" header="Total Employee Count" style={{ fontSize: this.state.fontSize }} />
                        <Column
                          header="Employee Percentage %"
                          body={(rowData) => rowData.percentage + "%"}
                          style={{ fontSize: this.state.fontSize }}
                        />
                      </DataTable>
                    </div>
                  </div>
                </Panel>
              </div>
              <div className="col-md-6 flex">
                <Panel
                  header={renderPanelHeader({
                    heading: "Grade - wise",
                    count: nCount,
                  })}
                >
                  <div className="row">
                    <div className="p-col-12">
                      <EChart chartData={levelData} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="p-col-12">
                      <DataTable
                        style={{
                          fontSize: this.state.fontSize,
                          textAlign: "center",
                          marginTop: "2%",
                        }}
                        resizableColumns={true}
                        className="orangep"
                        value={data.levelWise}
                      >
                        <Column field="role" header="Grade" style={{ fontSize: this.state.fontSize }} />
                        <Column
                          field="count"
                          header="Employee Count"
                          style={{
                            textAlign: "center",
                            fontSize: this.state.fontSize,
                          }}
                        />
                        <Column
                          field="totalCount"
                          header="Total Employee Count"
                          style={{
                            textAlign: "center",
                            fontSize: this.state.fontSize,
                          }}
                        />
                        <Column
                          body={(rowData) => rowData.percentage + "%"}
                          header="Employee Percentage %"
                          style={{
                            textAlign: "center",
                            fontSize: this.state.fontSize,
                          }}
                        />
                      </DataTable>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
            <div className="row flex-container">
              <div className="col-md-6 flex">
                <Panel
                  header={renderPanelHeader({
                    heading: "Tenure with company - wise",
                    count: nCount,
                  })}
                >
                  <div className="row">
                    <div className="p-col-12">
                      <EChart chartData={workexpData} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="p-col-12">
                      <DataTable
                        style={{
                          fontSize: this.state.fontSize,
                          textAlign: "center",
                          marginTop: "2%",
                        }}
                        resizableColumns={true}
                        className="orangep"
                        value={data.workexpWise}
                      >
                        <Column field="range" header="Tenure with company" style={{ fontSize: this.state.fontSize }} />
                        <Column field="count" header="Employee Count" style={{ fontSize: this.state.fontSize }} />
                        <Column field="totalCount" header="Total Employee Count" style={{ fontSize: this.state.fontSize }} />
                        <Column
                          body={(rowData) => rowData.percentage + "%"}
                          header="Employee Percentage %"
                          style={{ fontSize: this.state.fontSize }}
                        />
                      </DataTable>
                    </div>
                  </div>
                </Panel>
              </div>

              <div className="col-md-6 flexstretch">
                <Panel
                  header={
                    <div style={{ marginBottom: 20 }}>
                      <span style={{ fontSize: "15px" }}>{name}</span>
                      <span style={{ float: "right" }}>
                        <div className="total-employees-container">N - {data.potentialData.length + "(" + data.totalempcountper + "%)"}</div>
                      </span>
                    </div>
                  }
                >
                  <div className="row">
                    <div className="p-col-12">
                      <EChartScatter height={height} chartData={chartData} />
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
          <div className="col-md-5 flex padding-bottom-10">
            <Panel
              header={renderPanelHeader({
                heading: "Department - wise",
                count: nCount,
              })}
            >
              <div className="row">
                <div className="p-col-12">
                  <EChart height="350px" chartData={deptData} />
                </div>
              </div>
              <div className="row">
                <div className="p-col-12">
                  <DataTable
                    style={{
                      fontSize: this.state.fontSize,
                      textAlign: "center",
                      marginTop: "2%",
                    }}
                    resizableColumns={true}
                    className="orangep"
                    value={data.deptWise}
                  >
                    <Column field="name" header="Department" style={{ fontSize: this.state.fontSize }} />
                    <Column
                      field="count"
                      header="Employee Count"
                      style={{
                        textAlign: "center",
                        fontSize: this.state.fontSize,
                      }}
                    />
                    <Column
                      field="totalCount"
                      header="Total Employee Count"
                      style={{
                        textAlign: "center",
                        fontSize: this.state.fontSize,
                      }}
                    />
                    <Column
                      body={(rowData) => rowData.percentage + "%"}
                      header="Employee Percentage %"
                      style={{
                        textAlign: "center",
                        fontSize: this.state.fontSize,
                      }}
                    />
                  </DataTable>
                </div>
              </div>
            </Panel>
          </div>
        </div>
        {this.state.table_dialog ? (
          <Dialog header={this.state.emp_name} visible={this.state.table_dialog} style={{ width: "50vw" }} onHide={() => this.onHide()}>
            <div className="row">
              <div className="col-md-10"></div>
              <div className="col-md-2">
                <Button className="button-excel" type="button" icon="pi pi-external-link" iconPos="left" label="Export" onClick={this.getfilename} />
              </div>
            </div>
            <div className="row" style={{ maxHeight: "40vw", overflow: "scroll" }}>
              <div className="col-md-6 clk-table-btn">
                <DataTable
                  className="team_green"
                  style={{
                    textAlign: "center",
                  }}
                  resizableColumns={true}
                  responsive={true}
                  // header="Strengths"
                  value={this.state.top_strengths}
                  resizableColumns={true}
                >
                  {/* <Column sortable={true} field="score" header="score" /> */}
                  <Column
                    headerStyle={{
                      color: "#31c736",
                      backgroundColor: "white",
                      fontSize: "17px",
                    }}
                    style={{
                      backgroundColor: "white",
                    }}
                    body={this.empTemplate}
                    header="Strengths"
                  />
                </DataTable>
              </div>
              <div className="col-md-6 clk-table-btn">
                <DataTable
                  className="team_red"
                  style={{
                    textAlign: "center",
                  }}
                  resizableColumns={true}
                  responsive={true}
                  // header="Weakness"
                  value={this.state.train_needs}
                  resizableColumns={true}
                >
                  {/* <Column sortable={true} field="score" header="score" /> */}
                  <Column
                    headerStyle={{
                      color: "#f34949",
                      backgroundColor: "white",
                      fontSize: "17px",
                    }}
                    style={{
                      backgroundColor: "white",
                    }}
                    body={this.empTemplate}
                    header="Weakness"
                  />
                </DataTable>
              </div>
            </div>
          </Dialog>
        ) : null}
        {menuProps.showEmpDetails ? (
          <div className="row">
            <div className="col-md-12">
              <DataTable
                className="orange toppoten"
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
                //header={<div>Employee Details</div>}
                value={data.employees}
                paginator={true}
                ref={(el) => (this.dt = el)}
                first={this.state.first}
                onPage={(e) => this.setState({ first: e.first })}
                globalFilter={this.state.globalFilter}
                rows={20}
                header={header}
                resizableColumns={true}
                onSelectionChange={((e) => this.setState({ rowsel: e.value }), this.popup)}
                selectionMode="single"
                selection={this.state.rowsel}
                rowsPerPageOptions={[10, 20, 50, data.employees ? data.employees.length : ""]}
              >
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} sortable={true} field="employee_number" header="ID" />
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} field="name" sortable={true} header="Name" />
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} field="department" sortable={true} header="Department" />
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} field="grade" header="Grade" sortable={true} />
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} field="section" header="Section" sortable={true} />
                <Column
                  style={{ textAlign: "left" }}
                  headerStyle={{ textAlign: "center" }}
                  field="designation"
                  header="Designation"
                  sortable={true}
                />
                <Column style={{ textAlign: "left" }} headerStyle={{ textAlign: "center" }} field="manager" header="Manager" sortable={true} />
              </DataTable>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ScoreDistribution;
