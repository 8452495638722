import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import EChartsRadar from "../../common/ECharts/EChartsRadar";
import EChartsBox from "../../common/ECharts/EChartsBox";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Panel } from "primereact/panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import EmotionalHelp from "../../assets/images/emotional-help.png";
import view from "../../assets/images/view.png";
import EmotionalHelpDisabled from "../../assets/images/emotional-help-disabled.png";
import TrainingNeedsComp from "../../common/TrainingNeedsComp";

class MeDashboard extends Component {
  state = {
    competencies: [],
    dimensionwise: [],
    loading: true,
    loadingDetails: true,
    dimensionwiseHeading: "",
    dimensionHigh: 0,
    dimensionLow: 0,
    containerKey: 1,
    visible: false,
    popupmsg: "",
    dimname: "",
    is_negative: null,
    sharedis: "Share",
    sharewith: "",
    sharedwithmng: null,
    sharedwithhr: "",
    potentialindexinf: {},
    gridinf: {},
    scoredescription: [],
    color: "black",
    griddescription: [],
    hidd: false,
    hidds: false,
    gridval: {},
    gridvallist: [],
    pot: "",
    opiniondescription: "",
    btnname: "choose your opinion",
    btnstate: true,
    textarea: "",
    rowData: [],
    modalpop: false,
    agree: false,
    neutral: false,
    disagree: false,
    pvsp_graph: false,
    user: this.props.user,
    userb: this.props.userb,
    isEmotional: false,
    summary_dialog: false,
    summary_data: "",
    headercolor: "black",
    score_index: "",
    is_paid: true,
  };

  componentDidUpdate() {
    if (this.props.user !== this.state.user) {
      this.setState({
        user: this.props.user,
        userb: this.props.userb,
        loading: true,
      });
      this.fetch();
    }
    if (document.getElementsByClassName("background-grey").length > 0) {
      for (let i = 0; i < document.getElementsByClassName("background-grey").length; i++) {
        document.getElementsByClassName("background-grey")[i].parentNode.classList.add("background-cell-grey");
      }
    }
    if (document.getElementsByClassName("background-green").length > 0) {
      for (let i = 0; i < document.getElementsByClassName("background-green").length; i++) {
        document.getElementsByClassName("background-green")[i].parentNode.classList.add("background-cell-green");
      }
    }
    if (document.getElementsByClassName("background-red").length > 0) {
      for (let i = 0; i < document.getElementsByClassName("background-red").length; i++) {
        document.getElementsByClassName("background-red")[i].parentNode.classList.add("background-cell-red");
      }
    }
  }

  componentDidMount() {
    this.fetch();
    // api.get("/api/cce/temp_softskill").then((res) => {});
  }

  fetch() {
    const user = { user: this.props.user };
    if (this.props.userb) {
      api
        .post("/api/me/shared-dashboard", user)
        .then((res) => {
          if (res.data.success) {
            const {
              dashboardData,
              performance,
              sharedwithmng,
              sharedwithhr,
              grid,
              potentialindex,
              scoredescription,
              griddescription,
              gridval,
              gridvallist,
              pot,
              opiniondescription,
              pvsp_graph,
              summary_data,
              score_index,
            } = res.data;
            this.setState({
              competencies: dashboardData,
              performance: performance,
              loading: false,
              sharedwithmng: sharedwithmng,
              sharedwithhr: sharedwithhr,
              gridinf: grid,
              potentialindexinf: potentialindex,
              scoredescription: scoredescription,
              griddescription: griddescription,
              gridval: gridval,
              gridvallist: gridvallist,
              pot: pot,
              opiniondescription: opiniondescription,
              pvsp_graph: pvsp_graph,
              summary_data: summary_data,
              score_index: score_index,
            });
            if (dashboardData.length > 0) {
              this.props.callback(true, false);
            } else {
              this.props.callback(false, true);
            }
          } else {
            this.setState({ loading: false });
          }
        })

        .catch((err) => console.log(err));
    } else {
      api
        .get("/api/me/me-competencies")
        .then((res) => {
          if (res.data.success) {
            const {
              dashboardData,
              performance,
              sharedwithmng,
              sharedwithhr,
              grid,
              potentialindex,
              scoredescription,
              griddescription,
              gridval,
              gridvallist,
              pot,
              opiniondescription,
              pvsp_graph,
              summary_data,
              score_index,
              is_paid,
            } = res.data;
            this.setState({
              competencies: dashboardData,
              performance: performance,
              loading: false,
              sharedwithmng: sharedwithmng,
              sharedwithhr: sharedwithhr,
              gridinf: grid,
              potentialindexinf: potentialindex,
              scoredescription: scoredescription,
              griddescription: griddescription,
              gridval: gridval,
              gridvallist: gridvallist,
              pot: pot,
              opiniondescription: opiniondescription,
              pvsp_graph: pvsp_graph,
              summary_data: summary_data,
              score_index: score_index,
              is_paid: is_paid,
            });
            if (dashboardData.length > 0) {
              this.props.callback(true, false);
            } else {
              this.props.callback(false, true);
            }
          } else {
            this.setState({ loading: false });
          }
        })

        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  }
  detailDisplay = (highdetail, lowdetail, groupName) => {
    let popupmsg = null;
    if (lowdetail && lowdetail.length === 0) {
      popupmsg = (
        <div style={{ maxHeight: "60vh", overflow: "auto" }}>
          <div style={{ fontWeight: 600, color: "#5880FF" }}>
            <u>You tend to be balanced and you will adopt the behaviour according to the arising situations</u>
          </div>
          {highdetail &&
            highdetail.map((hd) => (
              <div>
                <div>
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{hd.dimName}</span> with a score of{" "}
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{hd.dimScore}</span>
                </div>
                <div style={{ marginLeft: 9 }}>
                  {hd.dimInf.split("::") &&
                    hd.dimInf.split("::").length > 0 &&
                    hd.dimInf
                      .split("::")[0]
                      .split(":")
                      .map((m, i) => <div key={i}>{m}</div>)}
                </div>
              </div>
            ))}
        </div>
      );
    } else {
      popupmsg = (
        <div style={{ maxHeight: "60vh", overflow: "auto" }}>
          <div style={{ fontWeight: 600, color: "#5880FF" }}>
            <u>Your dominant / preferred {highdetail && highdetail.length === 1 ? "behaviour is" : "behaviours are"}</u>
          </div>
          {highdetail &&
            highdetail.map((hd) => (
              <div>
                <div>
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{hd.dimName}</span> with a score of{" "}
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{hd.dimScore}</span>
                </div>
                <div style={{ marginLeft: 9 }}>
                  {hd.dimInf.split("::") &&
                    hd.dimInf.split("::").length > 0 &&
                    hd.dimInf
                      .split("::")[0]
                      .split(":")
                      .map((m, i) => <div key={i}>{m}</div>)}
                </div>
              </div>
            ))}
          <div style={{ fontWeight: 600, color: "#5880FF" }}>
            <u>You tend to adopt / prefer the behaviours in the following order</u>
          </div>
          {lowdetail &&
            lowdetail.map((ld) => (
              <div>
                <div>
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{ld.dimName}</span> with a score of{" "}
                  <span style={{ fontWeight: 600, color: "#00a3b4" }}>{ld.dimScore}</span>
                </div>
                <div style={{ marginLeft: 9 }}>
                  {ld.dimInf.split("::") &&
                    ld.dimInf.split("::").length > 0 &&
                    ld.dimInf
                      .split("::")[0]
                      .split(":")
                      .map((m, i) => <div key={i}>{m}</div>)}
                </div>
              </div>
            ))}
        </div>
      );
    }
    this.setState({
      headercolor: "black",
      popupmsg: popupmsg,
      visible: true,
      dimname: groupName,
    });
  };
  dimensionHeadTemplate = (group) => {
    const dim = this.state.dimensionwise.filter((f) => f.groupName === group);
    if (dim[0]["highdetail"] && dim[0]["highdetail"].length === 0) {
      return <div>{dim[0]["groupName"]}</div>;
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div>{dim[0]["groupName"]}</div>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Composite behaviour analysis</Tooltip>}>
            <FontAwesomeIcon
              onClick={() => this.detailDisplay(dim[0]["highdetail"], dim[0]["lowdetail"], dim[0]["groupName"])}
              style={{ color: "#00a3b4", fontSize: 20, marginLeft: 9, cursor: "pointer" }}
              icon={["fas", "info-circle"]}
            />
          </OverlayTrigger>
        </div>
      );
    }
  };
  dimensionNameTemplate = (rowData) => {
    const { isEmotional } = this.state;
    const { user_details } = this.props;
    return (
      <>
        <Button
          className="clickbtn p-button-raised p-button-secondary p-button-rounded"
          label={rowData.dimension_name}
          onClick={() => this.setState({ visible: true, headercolor: this.state.color }, () => this.popup(rowData))}
        />
        {this.state.userb ? null : user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV" ? (
          <span style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img
              src={view}
              width={20}
              height={20}
              style={{ marginLeft: "10px", color: "green", cursor: "pointer" }}
              onClick={() => this.opinion(rowData)}
            />
          </span>
        ) : (
          <span style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {isEmotional ? (
              <OverlayTrigger placement="right" overlay={<Tooltip>Need help</Tooltip>}>
                <button onClick={() => this.training(rowData, "emotional-help")} className="p-link">
                  <img
                    style={{
                      width: "28px",
                      position: "relative",
                      top: "-5px",
                      objectFit: "contain",
                    }}
                    src={rowData.emotional_help ? EmotionalHelp : EmotionalHelpDisabled}
                  />
                </button>
              </OverlayTrigger>
            ) : (
              <>
                <span
                  style={{
                    border: "1px solid #c9c9c9",
                    borderRadius: "5px",
                    // paddingTop: "5px",
                  }}
                >
                  <span
                    style={{
                      borderRight: "1px solid #c9c9c9",
                      borderRadius: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        color: "#c9c9c9",
                        padding: "3px",
                      }}
                      icon={["fas", "chalkboard-teacher"]}
                    />
                  </span>
                  <OverlayTrigger placement="left" overlay={<Tooltip>Company funded training</Tooltip>}>
                    <button onClick={() => this.training(rowData, "company_training")} className="p-link">
                      <FontAwesomeIcon
                        style={{
                          fontSize: "25px",
                          color: rowData.company_training ? "black" : "grey",
                          marginLeft: "10px",
                          cursor: "pointer",
                          padding: "3px",
                        }}
                        icon={["fas", "building"]}
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="right" overlay={<Tooltip placement="top">Self funded training</Tooltip>}>
                    <button onClick={() => this.training(rowData, "selffunded_training")} className="p-link">
                      <FontAwesomeIcon
                        style={{
                          padding: "3px",
                          marginRight: "10px",
                          marginLeft: "10px",
                          fontSize: "25px",
                          color: rowData.selffunded_training ? "black" : "grey",
                          cursor: "pointer",
                        }}
                        icon={["fas", "portrait"]}
                      />
                    </button>
                  </OverlayTrigger>
                </span>
              </>
            )}
            {/* <FontAwesomeIcon
              onClick={() => this.opinion(rowData)}
              style={{
                fontSize: "25px",
                marginLeft: "10px",
                color: "green",
                cursor: "pointer",
              }}
              icon={["far", "smile"]}
            /> */}

            <img
              src={view}
              width={20}
              height={20}
              style={{ marginLeft: "10px", color: "green", cursor: "pointer" }}
              onClick={() => this.opinion(rowData)}
            />
          </span>
        )}
      </>
    );
  };

  opinionemojistyle = (text, type) => {
    let classes = " ";
    if (type === "agree") {
      classes += text === true ? " opinionemojistyle" : " opinionemojistyle4";
      return classes;
    } else if (type === "neutral") {
      classes += text === true ? " opinionemojistyle2" : " opinionemojistyle4";
      return classes;
    } else {
      classes += text === true ? " opinionemojistyle3" : " opinionemojistyle4";
      return classes;
    }
  };

  btnname() {
    const { agree, neutral, disagree } = this.state;
    if (agree === true) {
      return "Agree,";
    } else if (neutral === true) {
      return "Neutral,";
    } else if (disagree === true) {
      return "Disagree,";
    } else {
      return "";
    }
  }

  btnstate() {
    const { agree, neutral, disagree, textarea } = this.state;
    let st = (agree === true || neutral === true || disagree === true) && textarea.length <= 2048 ? false : true;
    return st;
  }

  opinion = (rowData) => {
    let color = "";
    this.state.competencies.map((c) => {
      if (c.dimensionName + " " === this.state.dimensionwiseHeading) {
        color = c.color;
      }
    });
    this.setState({
      color: color,
      modalpop: true,
      visible: true,
      dimname: rowData.score === "#" ? rowData.dimension_name : rowData.dimension_name + " - " + rowData.score + "%",
      rowData: rowData,
    });
  };

  opinionbtn = (rowData) => {
    const { agree, neutral, disagree, textarea } = this.state;
    const comments = textarea.length === 0 ? null : textarea;
    const fData = {
      dimension: rowData.dimension,
      dimension_name: rowData.dimension_name,
      parent_dimension: rowData.parent_dimension_id,
      parent_dimension_name: rowData.parent_dimension_name,
      agree: agree,
      neutral: neutral,
      disagree: disagree,
      comments: comments,
    };
    api
      .post("/api/me/employee-opinion", JSON.stringify(fData))
      .then((res) => {
        this.growl.show({
          severity: "success",
          summary: res.data.results,
        });
        this.setState({
          visible: false,
          popupmsg: "",
          dimname: "",
          hidd: false,
          modalpop: false,
          textarea: "",
          agree: false,
          neutral: false,
          disagree: false,
        });
      })
      .catch((error) => console.error("error", error));
  };
  opinionclick = (opinion) => {
    if (opinion === "agree") {
      this.setState({ agree: true, neutral: false, disagree: false });
    } else if (opinion === "neutral") {
      this.setState({ agree: false, neutral: true, disagree: false });
    } else {
      this.setState({ agree: false, neutral: false, disagree: true });
    }
  };

  popup = (rowData) => {
    let color = "";
    let regex = /\/\*(.*)\*\//;
    let diminf = rowData.diminf?.replace(regex, rowData.dimension_name);
    this.state.competencies.map((c) => {
      if (c.dimensionName + " " === this.state.dimensionwiseHeading) {
        color = c.color;
      }
    });
    let msg = (
      <div>
        <div>
          {diminf?.split(":").map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </div>
        <div>
          {rowData.links.map((link, index) => (
            <div key={index}>
              <a href={link} target="_blank">
                {link}
              </a>
            </div>
          ))}
        </div>
        {rowData.training_links && (
          <div>
            <div>
              <b>Taining Links</b>
            </div>
            {rowData.training_links.map((link, index) => (
              <div key={index}>
                <a href={link} target="_blank">
                  {link}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    );
    this.setState({
      headercolor: color,
      popupmsg: msg,
      dimname: rowData.dimension_name,
    });
  };
  training = (rowData, text) => {
    let isEmotional = false;
    if (text === "emotional-help") {
      if (rowData.emotional_help) rowData.emotional_help = false;
      else rowData.emotional_help = true;
      isEmotional = true;
    } else {
      if (text === "company_training") {
        rowData.selffunded_training = false;
        if (rowData.company_training) rowData.company_training = false;
        else rowData.company_training = true;
      } else if (text === "selffunded_training") {
        rowData.company_training = false;
        if (rowData.selffunded_training) rowData.selffunded_training = false;
        else rowData.selffunded_training = true;
      }
    }
    this.popup(rowData);
    const fData = {
      dimension: rowData.dimension,
      dimension_name: rowData.dimension_name,
      parent_dimension: rowData.parent_dimension_id,
      parent_dimension_name: rowData.parent_dimension_name,
      company_training: rowData.company_training,
      selffunded_training: rowData.selffunded_training,
      emotional_help: rowData.emotional_help,
      isEmotional,
    };
    api
      .post("/api/me/employee-training", JSON.stringify(fData))
      .then((res) => {
        this.growl.show({
          severity: "success",
          summary: res.data.results,
        });
      })
      .catch((error) => console.error("error", error));
  };
  onHide = () => {
    const { gridval } = { ...this.state };
    const currents = gridval;
    const asd = this.state.pot.replace(" ", "_");
    this.state.gridvallist.map((s) => {
      if (s === asd) {
        {
          currents[s] = false;
        }
      } else {
        {
          currents[s] = true;
        }
      }
    });
    this.setState({
      visible: false,
      popupmsg: "",
      dimname: "",
      hidd: false,
      gridval: currents,
      modalpop: false,
      textarea: "",
      agree: false,
      neutral: false,
      disagree: false,
    });
  };

  myScoreTemplate = (rowData) => {
    if (rowData.heading) {
      return <span className="cluster-row"></span>;
    } else {
      const { dimensionHigh, dimensionLow, dimensionIsNegative } = this.state;
      let className = "background-green";
      let fontColor = "text-black";
      if (dimensionIsNegative) {
        if (parseInt(rowData.score) < parseInt(dimensionLow) && parseInt(rowData.score) > parseInt(dimensionHigh)) {
          className = "background-grey";
        } else if (parseInt(rowData.score) >= parseInt(dimensionLow)) {
          className = "background-red";
        }
        // if (
        //   parseInt(rowData.score) <= parseInt(rowData.grade_top_pc) &&
        //   parseInt(rowData.score) <= parseInt(rowData.dept_top_pc) &&
        //   parseInt(rowData.score) <= parseInt(rowData.org_top_pc)
        // )
        //   fontColor = "text-value-green";
        // if (
        //   parseInt(rowData.score) > parseInt(rowData.grade_top_pc) &&
        //   parseInt(rowData.score) > parseInt(rowData.dept_top_pc) &&
        //   parseInt(rowData.score) > parseInt(rowData.org_top_pc)
        // )
        //   fontColor = "text-red";
      } else {
        if (parseInt(rowData.score) > parseInt(dimensionLow) && parseInt(rowData.score) < parseInt(dimensionHigh)) {
          className = "background-grey";
        } else if (parseInt(rowData.score) <= parseInt(dimensionLow)) {
          className = "background-red";
        }
        // if (
        //   parseInt(rowData.score) >= parseInt(rowData.grade_top_pc) &&
        //   parseInt(rowData.score) >= parseInt(rowData.dept_top_pc) &&
        //   parseInt(rowData.score) >= parseInt(rowData.org_top_pc)
        // )
        //   fontColor = "text-value-green";
        // if (
        //   parseInt(rowData.score) < parseInt(rowData.grade_top_pc) &&
        //   parseInt(rowData.score) < parseInt(rowData.dept_top_pc) &&
        //   parseInt(rowData.score) < parseInt(rowData.org_top_pc)
        // )
        //   fontColor = "text-red";
      }
      return (
        <span className={rowData.score === "#" ? "background-white" + " " + fontColor : className}>
          {rowData.score === "#" ? <i className="fas fa-lock"></i> : rowData.score + "%"}
        </span>
      );
    }
  };

  topScoreTemplate = (rowData, target) => {
    if (rowData.heading) {
      return <span className="cluster-row"></span>;
    } else {
      const { dimensionHigh, dimensionLow, dimensionIsNegative } = this.state;
      let className = "background-green";
      if (dimensionIsNegative) {
        if (parseInt(rowData[target]) <= parseInt(dimensionLow) && parseInt(rowData[target]) > parseInt(dimensionHigh)) {
          className = "background-grey";
        } else if (parseInt(rowData[target]) > parseInt(dimensionLow)) {
          className = "background-red";
        }
      } else {
        if (parseInt(rowData[target]) >= parseInt(dimensionLow) && parseInt(rowData[target]) < parseInt(dimensionHigh)) {
          className = "background-grey";
        } else if (parseInt(rowData[target]) < parseInt(dimensionLow)) {
          className = "background-red";
        }
      }
      return rowData[target] !== null ? (
        <span className={rowData[target] === "#" ? "background-white" : className}>
          {rowData[target] === "#" ? <i className="fas fa-lock"></i> : rowData[target] + "%"}
        </span>
      ) : (
        <span className={className}>-</span>
      );
    }
  };

  onDimensionClick = (param) => {
    const dimensionSelected = param.name;
    const { competencies, containerKey } = this.state;
    const { organisation } = this.props.user_details;
    let isEmotionalIds = organisation.configuration.isEmotional;
    const selectedCompetencies = competencies.filter((c) => c.dimensionName.replace(" ", "\n") === dimensionSelected)[0];
    if (isEmotionalIds.includes(selectedCompetencies.dimension_id)) this.setState({ isEmotional: true });
    else this.setState({ isEmotional: false });
    let childValues = selectedCompetencies.child;
    let uniqueGroupNames = [];
    childValues.forEach((element) => {
      if (!uniqueGroupNames.includes(element.groupName)) uniqueGroupNames.push(element.groupName);
    });
    // let newChildValues = [];
    // uniqueGroupNames.forEach(element => {
    //   newChildValues.push({ groupName: element, heading: true });
    //   newChildValues = newChildValues.concat(
    //     childValues.filter(f => f.groupName === element)
    //   );
    // });
    this.setState(
      {
        containerKey: containerKey + 1,
        dimensionwise: selectedCompetencies.child,
        dimensionwiseHeading: selectedCompetencies.dimensionName + " ",
        dimensionHigh: selectedCompetencies.hrDimensionData.high,
        dimensionLow: selectedCompetencies.hrDimensionData.low,
        dimensionIsNegative: selectedCompetencies.hrDimensionData.is_negative,
        is_negative: selectedCompetencies.is_negative,
        uniqueGroupNames,
        color: selectedCompetencies.color,
        headercolor: selectedCompetencies.color,
      },
      () => this.refs.demo.scrollIntoView({ behavior: "smooth" })
    );
  };

  forcolor = () => {
    let color = "";
    this.state.competencies.map((c) => {
      if (c.dimensionName + " " === this.state.dimensionwiseHeading) {
        color = c.color + "70";
      }
    });
    return color;
  };
  dialoghead = () => {
    return <span style={{ color: this.state.headercolor, fontSize: 18 }}>{this.state.dimname}</span>;
  };
  shareScoremng = (e) => {
    this.setState({ sharedwithmng: e.value });
    const fData = { value: e.value, sharewith: "manager" };
    api
      .post("/api/me/me-sharescore", JSON.stringify(fData))
      .then((res) => {
        this.growl.show({
          severity: "success",
          summary: res.data.results,
        });
      })
      .catch((error) => console.error("Error:", error));
  };
  shareScorehr = (e) => {
    this.setState({ sharedwithhr: e.value });
    const fData = { value: e.value, sharewith: "hr" };
    api
      .post("/api/me/me-sharescore", JSON.stringify(fData))
      .then((res) => {
        this.growl.show({
          severity: "success",
          summary: res.data.results,
        });
      })
      .catch((error) => console.error("Error:", error));
  };
  potentialindexinf = () => {
    const header = (
      <>
        <div>{this.state.potentialindexinf["header"]}</div>
        <ul>
          {this.state.potentialindexinf["data"].map((e, index) => {
            return <li key={index}>{e}</li>;
          })}
        </ul>
      </>
    );
    this.setState({
      headercolor: "black",
      popupmsg: header,
      visible: true,
      dimname: "ATMA Potential Index",
    });
  };

  gridinf = () => {
    const header = (
      <>
        <div>{this.state.gridinf["header"]}</div>
        <ul>
          {this.state.gridinf["data"].map((e, index) => {
            return <li key={index}>{e}</li>;
          })}
        </ul>
      </>
    );
    this.setState({
      headercolor: "black",
      popupmsg: header,
      visible: true,
      dimname: "9 Grid Potential vs Performance",
    });
  };
  hidd() {
    return this.state.hidd;
  }
  griddescription = () => {
    this.setState({
      hidd: true,
      headercolor: "black",
      visible: true,
      dimname: "9 Grid Potential vs Performance",
    });
  };
  toggle = (e) => {
    const { gridval } = { ...this.state };
    const currents = gridval;
    const asd = e.replace(" ", "_");
    this.state.gridvallist.map((s) => {
      if (s === asd) {
        {
          currents[s] = false;
        }
      } else {
        {
          currents[s] = true;
        }
      }
    });
    this.setState({ gridval: currents });
  };
  render() {
    const {
      competencies,
      dimensionwise,
      loading,
      loadingDetails,
      containerKey,
      performance,
      dimensionwiseHeading,
      is_negative,
      rowData,
      agree,
      neutral,
      disagree,
      uniqueGroupNames,
      score_index,
    } = this.state;
    const { dashboardClip } = this.props.configuration;
    const { user_details } = this.props;
    const { headers } = user_details;
    const chartData = {
      type: "radarMe",
      trigger: true,
      title: "",
      data: competencies.map((c) => {
        return {
          name: c.dimensionName,
          value: c.score,
          color: c.color,
          id: c.dimension_id,
        };
      }),
    };
    const sum = competencies.map((c) => c.score).reduce((a, b) => a + b, 0);
    const potential = sum / competencies.map((c) => c.score).length || 0;
    const chartData2 = {
      xlabel: "Performance",
      data: {
        potential: score_index,
        performance: performance,
      },
    };
    const header = (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <span className="selected-dimension-heading">{dimensionwiseHeading}</span>
          {is_negative ? (
            <span
              style={{
                fontSize: "12px",
                fontWeight: 500,
                fontStyle: "italic",
                color: "gray",
              }}
            >
              (Lower Score is better)
            </span>
          ) : (
            <span
              style={{
                fontSize: "12px",
                fontWeight: 500,
                fontStyle: "italic",
                color: "gray",
              }}
            >
              (Higher Score is better)
            </span>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="background-cell-green table-color-legend">Proficient</div>
          <div className="background-cell-grey table-color-legend">Sufficient</div>
          <div className="background-cell-red table-color-legend">Deficient</div>
        </div>
        {this.props.user_details && !this.state.is_paid && (
          <div>
            <form method="post" name="redirect" action="https://www.skillablers.com/BillingTesting/Billing.aspx">
              <input
                type="hidden"
                id="encb_name"
                name="encb_name"
                value={this.props.user_details.first_name + " " + this.props.user_details.last_name}
              />
              <input type="hidden" id="encb_email" name="encb_email" value={this.props.user_details.email} />
              <input type="hidden" id="encb_phone" name="encb_phone" value={this.props.user_details.phone_number} />
              <input type="hidden" id="enc_RegId" name="enc_RegId" value={this.props.user_details.employee_number} />
              <input type="hidden" id="enc_servicetype" name="enc_servicetype" value="9" />
              <input type="hidden" id="enc_urlpage" name="enc_urlpage" value={process.env.REACT_APP_BASE_URL + "confirm-payment/home"} />
              {/* <input type="submit" value="Pay" style={{ marginRight: "15px", float: "right" }} className="p-button-warning whatbtn" /> */}
              <Button
                type="submit"
                style={{ marginRight: "15px", float: "right", marginBottom: 9, padding: 5 }}
                className="p-button-warning whatbtn"
                label="Buy Full Version"
                // icon={this.state.loading ? "pi pi-spin pi-spinner" : "pi pi-download"}
                // onClick={this.save_biopref}
              />
              {/* <script language="javascript">document.redirect.submit();</script> */}
            </form>
          </div>
        )}{" "}
      </div>
    );
    const learn_details = {
      T22CON01: "Focusing on strengths after listing them out",
      T22CON02: "Preparing one's thoughts before meeting new people or situations",
      T22CON03: "Making a plan to overcome shortcomings and failures",
      T22CON04: "Getting guidance and analysing how others solve problems, :: Anticipating challenges and surprises",
    };
    const training_data = [
      { sno: "1", training_id: "T22CON01", module_name: "Confidence building" },
      { sno: "2", training_id: "T22CON02", module_name: "Empathy" },
      { sno: "3", training_id: "T22CON03", module_name: "Flexibility " },
      { sno: "4", training_id: "T22CON04", module_name: "Networking " },
    ];
    const empDetails = {
      T22CON01: [
        { name: "name1", empNo: "111" },
        { name: "name2", empNo: "111" },
        { name: "name3", empNo: "111" },
      ],
      T22CON02: [
        { name: "name4", empNo: "111" },
        { name: "name5", empNo: "111" },
        { name: "name6", empNo: "111" },
      ],
      T22CON03: [
        { name: "name7", empNo: "111" },
        { name: "name8", empNo: "111" },
        { name: "name9", empNo: "111" },
      ],
      T22CON04: [
        { name: "name10", empNo: "111" },
        { name: "name11", empNo: "111" },
        { name: "name12", empNo: "111" },
      ],
    };
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} />
        <Dialog
          className=""
          appendTo={document.body}
          header={this.dialoghead()}
          visible={this.state.visible}
          onHide={this.onHide}
          // style={{
          //   minWidth: hidd ? "80vw" : "60vw",
          //   marginLeft: "8vw",
          //   marginTop: "3vw",
          //   maxheight: "40vw"
          // }}
        >
          <span style={{ lineHeight: "2" }}>
            {this.state.hidd ? (
              <div>
                <div
                  className="row"
                  style={{
                    width: "98%",
                    marginLeft: ".5%",
                    lineHeight: "18px",
                  }}
                >
                  {this.state.griddescription.map((e, index) => {
                    let w = e.key.replace(" ", "_");
                    return (
                      <div key={index} className="col-md-4" style={{ padding: "5px" }}>
                        <Panel
                          header={
                            <div style={{ cursor: "pointer" }} onClick={() => this.toggle(e.key)}>
                              {e.key}
                            </div>
                          }
                          className={e.key === this.state.pot ? (performance > 0 ? "gridbox " : "gridbox gridpanel") : "gridbox gridpanel"}
                        >
                          <div style={{ padding: 10 }} hidden={this.state.gridval[w]}>
                            {e.value}
                          </div>
                        </Panel>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : this.state.modalpop ? (
              <>
                <div>
                  <div>{this.state.opiniondescription}</div>

                  <div className="row" style={{ height: "70px", paddingTop: "20px" }}>
                    <span className="col-md-1">
                      <FontAwesomeIcon
                        onClick={() => this.opinionclick("agree")}
                        className={"opinionemoji " + this.opinionemojistyle(agree, "agree")}
                        icon={["far", "smile"]}
                      />
                    </span>
                    <span className="col-md-1">
                      <FontAwesomeIcon
                        className={"opinionemoji2 " + this.opinionemojistyle(neutral, "neutral")}
                        onClick={() => this.opinionclick("neutral")}
                        icon={["far", "meh"]}
                      />
                    </span>
                    <span className="col-md-1">
                      <FontAwesomeIcon
                        className={"opinionemoji3 " + this.opinionemojistyle(disagree, "disagree")}
                        onClick={() => this.opinionclick("disagree")}
                        icon={["far", "frown"]}
                      />
                    </span>
                  </div>
                  <div>{this.btnname()} Tell us why? (optional)</div>
                  <InputTextarea
                    style={{ width: "100%" }}
                    value={this.state.textarea}
                    onChange={(e) => this.setState({ textarea: e.target.value })}
                    rows={3}
                    //cols={120}
                  />
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => this.opinionbtn(rowData)} style={{ minWidth: "100px" }} label="Submit" disabled={this.btnstate()} />
                    <span style={{ marginLeft: "20px" }}>
                      <Button onClick={this.onHide} style={{ minWidth: "100px" }} label="Cancel" className="p-button-danger" />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              this.state.popupmsg
            )}
          </span>
        </Dialog>
        <Dialog
          style={{ maxHeight: "90%", overflow: "scroll" }}
          appendTo={document.body}
          header={<span style={{ color: "#FF9800", fontSize: 18 }}>Summary</span>}
          visible={this.state.summary_dialog}
          onHide={() => this.setState({ summary_dialog: false })}
        >
          <div>
            <div>
              Based on your response to the test, ATMA has computed your score on{" "}
              {this.state.summary_data.red + this.state.summary_data.grey + this.state.summary_data.green}{" "}
              {this.state.summary_data.red + this.state.summary_data.grey + this.state.summary_data.green === 1 ? "competency" : "competencies"} in
              all your primal characteristics.
            </div>

            <div className="row" style={{ marginTop: 18 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
                className="col-md-2 col-sm-3"
              >
                <div className="summary_count green">{this.state.summary_data.green}</div>
              </div>
              <div className="col-md-10 col-sm-9">
                You have shown proficiency in {this.state.summary_data.green} {this.state.summary_data.green === 1 ? "competency" : "competencies"}.
                You continue to nurture these characteristics, and put attention, if there is any change in your role / position or any dramatic
                change in your environment.
              </div>
            </div>
            <div className="row" style={{ marginTop: 18 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
                className="col-md-2 col-sm-3"
              >
                <div className="summary_count grey">{this.state.summary_data.grey}</div>
              </div>
              <div className="col-md-10 col-sm-9">
                You have shown sufficiency in {this.state.summary_data.grey} {this.state.summary_data.grey === 1 ? "competency" : "competencies"}.
                Though these characteristics help you now, a constant watch, self learning and getting external training can tap your hidden potential
                to make you more effective and increase your gains.
              </div>
            </div>
            <div className="row" style={{ marginTop: 18 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
                className="col-md-2 col-sm-3"
              >
                <div className="summary_count red">{this.state.summary_data.red}</div>
              </div>
              <div className="col-md-10 col-sm-9">
                You have shown deficiency in {this.state.summary_data.red} {this.state.summary_data.red === 1 ? "competency" : "competencies"}. These
                may be impediment for tapping your hidden potential and you need to put focussed effort to develop them. An external training or
                coaching will help you to accelerate your development.
              </div>
            </div>
          </div>
        </Dialog>

        {loading === true ? (
          <div className="row">
            <div className="loading-spinner col-md-3">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <div>
            <div className="row" style={{ marginTop: this.state.userb ? 0 : 20, paddingBottom: 0 }}>
              {competencies.length > 0 ? (
                this.state.userb ? null : this.props.user_details.organisation.share_mng === "share" ||
                  this.props.user_details.organisation.share_hr ? (
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{
                        float: "right",
                        marginRight: 20,
                        paddingBottom: 0,
                      }}
                    >
                      <span>
                        <span>Share with:</span>
                      </span>
                      {this.props.user_details.organisation.share_mng === "share" ? (
                        <>
                          <span style={{ marginLeft: 5 }}>
                            {user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV"
                              ? "Admin"
                              : "Manager"}
                          </span>
                          <span style={{ marginLeft: 5 }}>
                            <InputSwitch checked={this.state.sharedwithmng} onChange={(e) => this.shareScoremng(e)} />
                          </span>
                        </>
                      ) : null}
                      {this.props.user_details.organisation.share_hr ? (
                        <>
                          <span style={{ marginLeft: 5 }}>HR </span>
                          <span style={{ marginLeft: 5 }}>
                            <InputSwitch checked={this.state.sharedwithhr} onChange={(e) => this.shareScorehr(e)} />
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null
              ) : (
                <div></div>
              )}
            </div>
            <>
              {competencies.length > 0 ? (
                <div>
                  <div className="row">
                    {/* {this.state.pvsp_graph ? null : (
                    <div className="col-md-3"></div>
                  )} */}
                    {this.state.pvsp_graph || dashboardClip ? null : <div className="col-md-3"></div>}
                    <div className="col-md-6">
                      <span className="potentail-value-span">
                        <span className="potentail-value">{score_index.toFixed(0)}</span>
                      </span>
                      <Panel
                        style={{ height: "100%" }}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBottom: 9,
                            }}
                          >
                            <div>
                              ATMA Potential Index - The Composite Key Success Behaviour Score
                              <span>
                                <FontAwesomeIcon
                                  onClick={this.potentialindexinf}
                                  style={{
                                    cursor: "pointer",
                                    color: "#00a3b4",
                                    fontSize: "14px",
                                  }}
                                  icon={["far", "question-circle"]}
                                />
                              </span>
                            </div>
                            <div>
                              <Button
                                onClick={() => {
                                  this.setState({ summary_dialog: true });
                                }}
                                className="p-button-warning whatbtn"
                                label="Summary"
                              />
                            </div>
                          </div>
                        }
                      >
                        <div className="box-body">
                          <div className="row">
                            <EChartsRadar callBack={this.onDimensionClick} chartData={chartData} />
                          </div>
                        </div>
                      </Panel>
                    </div>
                    {this.state.pvsp_graph || dashboardClip ? (
                      this.state.pvsp_graph ? (
                        <div className="col-md-6">
                          <Panel
                            style={{ height: "100%" }}
                            header={
                              <>
                                9 Grid Potential vs Performance{" "}
                                <span>
                                  <FontAwesomeIcon
                                    onClick={this.gridinf}
                                    style={{
                                      cursor: "pointer",
                                      color: "#00a3b4",
                                      fontSize: "14px",
                                    }}
                                    icon={["far", "question-circle"]}
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="box-body">
                              <div className="row" style={{ cursor: "pointer" }} onClick={this.griddescription}>
                                <EChartsBox yaxis={performance} chartData={chartData2} />
                              </div>
                            </div>
                          </Panel>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <Panel header={<span style={{ display: "none" }}>Dasboard</span>} style={{ height: "100%" }}>
                            <div style={{ paddingTop: "15px" }} className="box-body">
                              <ReactPlayer
                                className="dashboard-clip-container"
                                loop={true}
                                muted={true}
                                playing={true}
                                controls={true}
                                width="100%"
                                url={dashboardClip}
                              />
                            </div>
                          </Panel>
                        </div>
                      )
                    ) : (
                      <div className="col-md-3"></div>
                    )}
                  </div>
                  <div className="row" ref="demo">
                    {dimensionwise.length > 0 ? (
                      <div className="col-md-12" style={{ paddingTop: 20 }}>
                        <div key={containerKey} className="box-body box-body-cust-250">
                          <br />
                          <Panel
                            style={{
                              borderTop: "5px solid " + this.state.color,
                            }}
                            header={header}
                            className="me-dashboard-tables dimensionwise"
                          >
                            <div className="box-body">
                              {uniqueGroupNames.map((group) => (
                                <DataTable
                                  key={group}
                                  className="metable-me-dashboard"
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                    backgroundColor: this.forcolor(),
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                  resizableColumns={true}
                                  responsive={true}
                                  value={dimensionwise.filter((f) => f.groupName === group)}
                                  emptyMessage={dimensionwise.length === 0 && !loadingDetails ? "No data" : "Loading..."}
                                  resizableColumns={true}
                                >
                                  <Column
                                    header={this.dimensionHeadTemplate(group)}
                                    rowSpan={2}
                                    body={this.dimensionNameTemplate}
                                    style={{
                                      width: "40%",
                                      backgroundColor: "white",
                                      borderTop: "0px",
                                    }}
                                  />
                                  <Column
                                    body={this.myScoreTemplate}
                                    header="My Score"
                                    style={{
                                      width: "10%",
                                      backgroundColor: "white",
                                      borderTop: "0px",
                                    }}
                                  />
                                  {user_details.organisation.org_ver_type in ["EV"] && (
                                    <Column
                                      body={(rowData) => this.topScoreTemplate(rowData, "grade_top_pc")}
                                      header={"My " + headers["grade"] + " top-3"}
                                      style={{
                                        width: "10%",
                                        backgroundColor: "white",
                                        borderTop: "0px",
                                      }}
                                    />
                                  )}
                                  {!(user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV") && (
                                    <Column
                                      header={"My " + headers["department"] + " top-3"}
                                      body={(rowData) => this.topScoreTemplate(rowData, "dept_top_pc")}
                                      style={{
                                        width: "10%",
                                        backgroundColor: "white",
                                        borderTop: "0px",
                                      }}
                                    />
                                  )}
                                  <Column
                                    header={
                                      user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV"
                                        ? "Peers Top-3"
                                        : "My Org top-3"
                                    }
                                    body={(rowData) => this.topScoreTemplate(rowData, "org_top_pc")}
                                    style={{
                                      width: "10%",
                                      backgroundColor: "white",
                                      borderTop: "0px",
                                    }}
                                  />
                                </DataTable>
                              ))}
                            </div>
                          </Panel>
                        </div>
                      </div>
                    ) : this.state.userb ? null : (
                      <div className="col-md-12">
                        <br />
                        {!(user_details.organisation.org_ver_type === "IV" || user_details.organisation.org_ver_type === "REV") && (
                          <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                            {this.state.scoredescription.map((e, index) => {
                              return (
                                <div
                                  className="medialog"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "800px",
                                    textAlign: "center",
                                  }}
                                  key={index}
                                >
                                  {e}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* <TrainingNeedsComp
                    empModel={true}
                    teamDash={false}
                    learn_details={learn_details}
                    training_data={training_data}
                    empDetails={{}}
                    empSel={{}}
                  /> */}
                </div>
              ) : (
                <div className="col-md-12">
                  <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                    <div
                      className="medialog"
                      style={{
                        fontSize: "20px",
                        fontWeight: "800px",
                        textAlign: "center",
                      }}
                    >
                      Test is not completed yet
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuration: state.auth.user_details.organisation.configuration,
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(MeDashboard);
