import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import api from "../../api";
import * as qs from "query-string";
import nav from "../../utils/Utils";

class PaymentCallback extends React.Component {
  componentDidMount() {
    const { enc_status, enc_RegId, enc_amount } = qs.parse(this.props.location.search);
    let path = this.props.location.pathname;
    if (enc_status === "Success") {
      api
        .post("/api/me/paymentCheck", {
          empno: enc_RegId,
          amount: enc_amount,
        })
        .then((res) => {
          nav("/" + path.substring(17));
        })
        .catch((err) => {
          nav("/" + path.substring(17));
        });
    } else {
      nav("/" + path.substring(17));
    }
  }

  render() {
    return (
      <div className="layout-main">
        <div className="loading-spinner-container">
          <ProgressSpinner></ProgressSpinner>
        </div>
      </div>
    );
  }
}

export default PaymentCallback;
