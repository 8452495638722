import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildOptionsArea } from "../Helper";

class EChartArea extends Component {
  render() {
    const { chartData = {} } = this.props;
    return (
      <ReactEcharts
        style={{ height: "45em" }}
        option={BuildOptionsArea(chartData)}
      />
    );
  }
}

export default EChartArea;
