import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildOptions } from "../Helper";

class EChart extends Component {
  onChartClick = (param) => {
    this.props.callBack(param);
  };
  render() {
    const { chartData = [] } = this.props;
    let onEvents = {
      click: this.onChartClick,
      legendselectchanged: this.onChartLegendselectchanged,
    };
    return (
      <>
        {this.props.type && this.props.type === "succession" ? (
          <ReactEcharts
            ref={(e) => {
              this.echarts_react = e;
            }}
            onEvents={onEvents}
            style={{ height: "350px" }}
            option={BuildOptions(chartData)}
          />
        ) : this.props.height ? (
          <div>
            <ReactEcharts style={{ height: this.props.height }} option={BuildOptions(chartData)} />
          </div>
        ) : (
          <div>
            <ReactEcharts style={{ height: "150px" }} option={BuildOptions(chartData)} />
          </div>
        )}
      </>
    );
  }
}

export default EChart;
