import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { BuildBox } from "../Helper";

class EChart extends Component {
  render() {
    const { chartData = [] } = this.props;
    return (
      <div style={{ width: "90%", height: "90%", marginLeft: "5%", marginTop: "5%" }}>
        <div className="">
          <div className="">
            <ReactEcharts
              option={BuildBox(chartData)}
            />
            <span className="low-value-span">low</span>
            <span className="high1-value-span">high</span>
            <span className="high2-value-span">high</span>
            <span className="yaxis-value-span">{this.props.yaxis === 0 ? " Performance data not available" : ""}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default EChart;
