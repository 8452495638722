import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import api from "../../api";
import { connect } from "react-redux";
import _ from "lodash";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";
import { setNotificationCount } from "../../store/actions/util";

class CommunicationPage extends Component {
  state = {
    subject: "",
    message: "",
    support_messages: [],
    communicationData: [],
    loading: true,
    types: [],
    type: {},
    key: 0,
    expandedRows: {},
    errorMessages: {},
    newCompose: false,
    recipients: ["Yeta consultant"],
    saveCommunicationLoading: false,
    activeIndex: 0,
    communicationDataMaster: [],
    filteredCommunicationData: [],
    globalFilter: "",
  };
  componentDidMount() {
    if (this.props.user_details.isSuperUser) {
      if (!_.isEmpty(this.props.organisation)) this.fetchInitData();
    } else this.fetchInitData();
  }
  fetchInitData = () => {
    api
      .get("api/cce/communication_types")
      .then((res) => {
        this.setState({ ...res.data }, () => {
          this.setType();
          this.fetchCommunicationData();
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  componentDidUpdate(prevProps, prevState) {
    const { user_details, organisation } = this.props;
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchInitData();
    }
    const { expandedRows, communicationData } = this.state;
    const expandedRowsPrev = prevState.expandedRows;
    if (expandedRows && expandedRowsPrev && Object.keys(expandedRows).filter((x) => !Object.keys(expandedRowsPrev).includes(x)).length > 0) {
      const key = Object.keys(expandedRows).filter((x) => !Object.keys(expandedRowsPrev).includes(x))[0];
      let data = communicationData.filter((c) => c.created_on === key)[0];
      this.toggleRead(data, user_details);
    }
  }
  toggleRead = (data, user_details) => {
    if (!data.read[user_details.id]) this.markRead(data.id);
  };
  fetchCommunicationData = () => {
    const { user_details, organisation } = this.props;
    let data = {};
    if (user_details.isSuperUser) {
      data = { org_id: organisation.id };
      api.post("api/cce/fetch_all_employees", data).then((res) => this.setState({ ...res.data }));
    }
    api
      .post("api/cce/fetch_all_communication_data", data)
      .then((res) => {
        const communicationData = res.data.communicationData.map((c) => {
          return { ...c, newMessage: "" };
        });
        this.setState({
          communicationData,
          communicationDataMaster: communicationData,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  setType = () => {
    const { types } = this.state;
    let typesObj = {};
    types.forEach((t) => {
      typesObj[t.id] = t;
    });
    this.setState({
      typesOption: types.map((t) => {
        return { label: t.name, value: t };
      }),
      typesObj,
    });
  };
  supportmessage = (data) => {
    const { type, subject, message, employee, key, recipients = [] } = this.state;
    let recipient_database = [];
    if (Object.keys(type).length > 0) {
      recipient_database = [...type.recipient];
    }
    if (data) {
      data = {
        id: data.id,
        subject: data.subject,
        text: data.newMessage,
        chat_type: data.type,
        created_on: data.created_on,
      };
    } else {
      data = {
        subject: subject,
        text: message,
        recipient: [...recipient_database, ...recipients],
        chat_type: type && type.id ? type.id : null,
      };
      if (this.props.user_details.isSuperUser) {
        data["employee"] = employee;
      }
    }
    // const fileData = new FormData()
    // fileData.append('file', this.state.selectedFile)
    // data["fileData"] = fileData
    let errorMessages = this.validateInputs(data);
    Object.keys(errorMessages).forEach((e) => {
      setTimeout(() => {
        this.growl.show(errorMessages[e]);
      }, 1);
    });
    if (Object.keys(errorMessages).length === 0) {
      api
        .post("api/cce/save_communication", data)
        .then((res) => {
          if (res.data.success) {
            this.setState(
              {
                saveCommunicationLoading: false,
                key: key + 1,
                type: {},
                subject: "",
                message: "",
                newCompose: false,
              },
              () => {
                this.growl.show({
                  severity: "success",
                  summary: "Successful",
                });
                this.fetchCommunicationData();
              }
            );
          } else {
            this.setState({ saveCommunicationLoading: false });
            this.growl.show({
              severity: "error",
              summary: "Something went wrong",
            });
          }
        })
        .catch((e) => {
          this.setState({ saveCommunicationLoading: false });
          console.log(e);
        });
    }
  };
  validateInputs = (data) => {
    const { subject, text, chat_type, employee, recipient } = data;
    const { type, recipients } = this.state;
    const { user_details } = this.props;
    let errorMessages = {};
    if (subject.trim().length === 0) {
      errorMessages["subject"] = {
        severity: "error",
        summary: "Please enter subject",
      };
    }
    if (!data.id && (typeof employee !== "object" || Object.keys(employee).length === 0) && user_details.isSuperUser) {
      errorMessages["employee"] = {
        severity: "error",
        summary: "Please select employee",
      };
    }
    if (text.trim().length === 0) {
      errorMessages["message" + (data.created_on ? data.created_on : "")] = {
        severity: "error",
        summary: "Please enter message",
      };
    }
    if (chat_type === null) {
      errorMessages["type"] = {
        severity: "error",
        summary: "Please select chat type",
      };
    }
    if (
      !data.id &&
      type &&
      Object.keys(type).length > 0 &&
      !user_details.isSuperUser &&
      type.name.trim().replace(/ /g, "").toLowerCase() === "ineedhelp" &&
      recipients.length === 0
    ) {
      errorMessages["recipient"] = {
        severity: "error",
        summary: "Please select share with",
      };
    }
    this.setState({
      errorMessages,
      saveCommunicationLoading: errorMessages.length === 0,
    });
    return errorMessages;
  };
  renderFromTo = (chatJson, user_details, data) => {
    let from = "YETA";
    let to = "YETA";
    if (chatJson.sender.id === user_details.id) {
      from = "me";
      if (user_details.id === data.from_user) {
        to = data.to_user__first_name;
      } else {
        to = data.from_user__first_name;
      }
    } else {
      from = chatJson.sender.name;
      if (chatJson.sender.id === data.from_user) {
        to = user_details.id === data.to_user ? "me" : data.to_user__first_name;
      } else {
        to = user_details.id === data.from_user ? "me" : data.from_user__first_name;
      }
    }
    if (!from || (from && from.length === 0) || from === " ") {
      from = "YETA";
    }
    if (!to || (to && to.length === 0) || to === " ") {
      to = "YETA";
    }
    return "from: " + from + ", to: " + to;
  };
  renderChat = (chatJson, data) => {
    const { user_details } = this.props;
    return (
      <div>
        {this.renderFromTo(chatJson, user_details, data)}
        <span>
          {", "}
          <Moment format="D MMM YYYY hh:mm a" withTitle>
            {chatJson.sender.time}
          </Moment>
        </span>
        <div style={{ overflowWrap: "anywhere" }}>
          Message: <span style={{ color: chatJson.sender.id === user_details.id ? "black" : "blue" }}>{chatJson.text}</span>
        </div>
        <div className="split-line-chat"></div>
      </div>
    );
  };
  updateExpandedRows = (expandedRows, key) => {
    if (expandedRows === null) {
      expandedRows = {};
    }
    if (Object.keys(expandedRows).includes(key)) {
      delete expandedRows[key];
    } else {
      expandedRows[key] = true;
    }
    return expandedRows;
  };
  markRead = (id) => {
    const { communicationData } = this.state;
    const { user_details, notificationCount } = this.props;
    api.post("api/cce/mark_read_communication", { id }).then((res) => {
      if (res.data.success) {
        let communicationDataUpdated = communicationData.map((c) => {
          if (c.id === id) {
            let read = c.read;
            read[user_details.id] = true;
            c["read"] = read;
            return c;
          } else {
            return c;
          }
        });
        this.setState({ communicationData: communicationDataUpdated });
        this.props.setNotificationCount(parseInt(notificationCount) - 1);
      }
    });
  };
  updateRead = (expandedRows, data) => {
    const { user_details } = this.props;
    this.setState({
      expandedRows: this.updateExpandedRows(expandedRows, data.created_on),
    });
    this.toggleRead(data, user_details);
  };
  renderSubject = (data) => {
    const { expandedRows } = this.state;
    const { user_details } = this.props;
    let read = false;
    read = data.read[user_details.id];
    const latestTextObj = data.texts[Object.keys(data.texts)[Object.keys(data.texts).length - 1]];
    const latestText = latestTextObj.text;
    const limitText = 100;
    return (
      <div className="pointer" onClick={() => this.updateRead(expandedRows, data)}>
        <span className="icon-container">
          {read ? <FontAwesomeIcon icon={["far", "envelope-open"]} /> : <FontAwesomeIcon icon={["far", "envelope"]} />}
        </span>
        <b>{data.subject + " "}</b>
        {latestText.substring(0, limitText) + (latestText.length > limitText ? "..." : "")}
        <span className="float-right">
          <Moment format="D MMM YYYY hh:mm a" withTitle>
            {latestTextObj.sender.time}
          </Moment>
        </span>
      </div>
    );
  };
  updateMessage = (id, message) => {
    let { communicationData } = this.state;
    communicationData = communicationData.map((c) => {
      if (c.id === id) {
        return { ...c, newMessage: message };
      } else {
        return c;
      }
    });
    this.setState({ communicationData });
  };
  renderTexts = (data) => {
    const { typesObj, errorMessages } = this.state;
    let sortKeys = Object.keys(data.texts).sort((a, b) => a - b);
    return (
      <div className="expanded-row-container">
        {sortKeys.map((k, i) => (
          <div key={k}>{this.renderChat(data.texts[k], data, i)}</div>
        ))}
        <div className="p-col-12">
          <InputTextarea
            className={Object.keys(errorMessages).includes("message" + data.created_on) ? " p-error" : ""}
            rows={10}
            style={{ width: "70%" }}
            value={data.newMessage}
            autoResize={true}
            onChange={(e) => this.updateMessage(data.id, e.target.value)}
            placeholder="Enter Message"
          />
        </div>
        <div>
          <Button
            className="p-button-rounded p-button-warning"
            label="Send"
            onClick={() => this.setState({ saveCommunicationLoading: true }, this.supportmessage(data))}
          ></Button>
        </div>
      </div>
    );
  };
  employeeTemplate = (employee) => {
    return <div>{employee.first_name + ", " + employee.employee_number}</div>;
  };
  suggestEmployees = (event) => {
    let results = this.state.employees.filter((employee) => {
      return (
        employee.first_name.toLowerCase().includes(event.query.toLowerCase()) ||
        employee.employee_number.toLowerCase().startsWith(event.query.toLowerCase())
      );
    });
    this.setState({ employeeSuggestions: results });
  };
  renderRecipients = (recipients_array) => {
    const { errorMessages } = this.state;
    if (recipients_array.length > 0) {
      return (
        <div className="p-col-12">
          Share with:{" "}
          {recipients_array.map((r) => {
            return (
              <span key={r.trim().toUpperCase()} className="p-col-4">
                <Checkbox
                  className={Object.keys(errorMessages).includes("recipient") ? " p-error" : ""}
                  inputId={r.trim().toUpperCase()}
                  value={r}
                  onChange={(e) => this.onRecipientChange(e)}
                  checked={this.state.recipients.includes(r)}
                ></Checkbox>
                <label htmlFor={r.trim().toUpperCase()} className="p-checkbox-label">
                  {r}
                </label>
              </span>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };
  renderComposeDialog = () => {
    const { type, typesOption, types, value, errorMessages, newCompose, saveCommunicationLoading } = this.state;
    const { user_details } = this.props;
    let recipients_array = [];
    if (type.name && !user_details.isSuperUser && type.name.trim().replace(/ /g, "").toLowerCase() === "ineedhelp")
      recipients_array = ["HR", "Manager", "Yeta consultant"];
    return (
      <Dialog header="New message" visible={newCompose} style={{ width: "50vw" }} modal={true} onHide={() => this.setState({ newCompose: false })}>
        <div className="p-col-12">
          <span className="p-float-label">
            <Dropdown
              className={"p-col-12 chat-input-dialog" + (Object.keys(errorMessages).includes("type") ? " p-error" : "")}
              value={type}
              options={typesOption}
              onChange={(e) => {
                this.setState({ type: e.value });
              }}
              placeholder="Select a Type"
            />
          </span>
        </div>
        {/* {this.renderRecipients(recipients_array)} */}
        {user_details.isSuperUser ? (
          <div className="p-col-12">
            <AutoComplete
              className={"chat-input-dialog" + (Object.keys(errorMessages).includes("employee") ? " p-error" : "")}
              itemTemplate={(employee) => this.employeeTemplate(employee)}
              value={this.state.employee ? (typeof this.state.employee === "string" ? this.state.employee : this.state.employee.first_name) : ""}
              onChange={(e) => {
                this.setState({ employee: e.value });
              }}
              suggestions={this.state.employeeSuggestions}
              completeMethod={(event) => this.suggestEmployees(event)}
              placeholder="Enter employee name or employee number"
            />
          </div>
        ) : null}
        <div className="p-col-12">
          <span className="p-float-label">
            <InputText
              style={{ width: "100%" }}
              className={"p-col-12 chat-input-dialog" + (Object.keys(errorMessages).includes("subject") ? " p-error" : "")}
              id="in"
              placeholder="Enter Subject"
              value={value}
              onChange={(e) => this.setState({ subject: e.target.value })}
            />
          </span>
        </div>
        <div className="p-col-12">
          <InputTextarea
            style={{ width: "100%" }}
            className={"p-col-12 overflow-scroll vheight-max-30" + (Object.keys(errorMessages).includes("message") ? " p-error" : "")}
            rows={5}
            cols={30}
            value={value}
            onChange={(e) => this.setState({ message: e.target.value })}
            autoResize={true}
            placeholder="Enter Message"
          />
        </div>
        <div className="p-col-12">
          <div className="p-col-2 p-offset-10">
            <Button
              className="p-button-rounded p-button-warning"
              label="Send"
              disabled={saveCommunicationLoading}
              icon={saveCommunicationLoading ? "pi pi-spin pi-spinner" : ""}
              onClick={() => this.setState({ saveCommunicationLoading: true }, () => this.supportmessage())}
            ></Button>
          </div>
        </div>
        {/* <div className="p-col-12">
          <input type="file" name="file" onChange={this.onChangeHandler} />
        </div> */}
      </Dialog>
    );
  };
  // onChangeHandler=event=> {
  //   this.setState({
  //     selectedFile: event.target.files[0],
  //     loaded: 0,
  //   })
  // }
  chatRowClassName = (rowData) => {
    const { user_details } = this.props;
    if (rowData.read[user_details.id]) {
      return { "p-highlight-read": true };
    } else {
      return { "p-highlight-unread": true };
    }
  };
  updateCommunicationData = () => {
    const { globalFilter, communicationDataMaster } = this.state;
    const filteredCommunicationData = communicationDataMaster.filter(
      (c) =>
        c.from_user__first_name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        JSON.stringify(c.texts).replace(/\s/g, "").toLowerCase().includes(globalFilter.replace(/\s/g, "").toLowerCase())
    );
    this.setState({
      filteredCommunicationData: filteredCommunicationData.map((f) => f.id),
    });
  };
  renderChatDataTable = (type) => {
    var header = (
      <div style={{ textAlign: "center" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value }, () => this.updateCommunicationData())}
          onChange={() => null}
          value={this.state.globalFilter}
          placeholder="Search"
          size="50"
        />
      </div>
    );
    const { communicationData, expandedRows, filteredCommunicationData, globalFilter = "" } = this.state;
    return (
      <div className="p-col-12 chat-container">
        <DataTable
          value={communicationData.filter((c) => c.type === type && (globalFilter.length > 0 ? filteredCommunicationData.includes(c.id) : true))}
          expandedRows={expandedRows}
          onRowToggle={(e) => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={this.renderTexts}
          dataKey="created_on"
          emptyMessage="No messages found"
          paginator={true}
          rows={10}
          rowsPerPageOptions={[5, 10, 20]}
          rowClassName={this.chatRowClassName}
          header={header}
        >
          <Column expander={true} style={{ width: "3em" }} />
          <Column body={this.renderSubject} field="type" />
        </DataTable>
      </div>
    );
  };
  onRecipientChange = (e) => {
    let selectedRecipients = [...this.state.recipients];
    if (e.checked) selectedRecipients.push(e.value);
    else selectedRecipients.splice(selectedRecipients.indexOf(e.value), 1);
    this.setState({ recipients: selectedRecipients });
  };
  render() {
    const { key, types, loading, typesObj, communicationData } = this.state;
    const { user_details } = this.props;
    return (
      <div key={key} className="layout-main communication-container">
        <Growl ref={(el) => (this.growl = el)}></Growl>
        {loading ? (
          <div className="loading-spinner-container">
            <ProgressSpinner></ProgressSpinner>
          </div>
        ) : (
          <>
            {this.renderComposeDialog()}
            <div className="p-col-12">
              <div className="p-panel p-component">
                <div className="p-panel-titlebar">
                  <span className="p-panel-title">{this.props.menuProps.label}</span>
                </div>
                <div className="chat-compose-button-container">
                  <Button
                    className="p-button-rounded p-button-primary"
                    icon="pi pi-plus"
                    label="Compose"
                    onClick={() =>
                      this.setState(
                        {
                          errorMessages: {},
                          type: {},
                          subject: "",
                          message: "",
                          key: key + 1,
                          employee: {},
                          recipients: ["Yeta consultant"],
                        },
                        () =>
                          this.setState({ newCompose: true }, () => {
                            const { types, activeIndex } = this.state;
                            if (types.length > 0) {
                              this.setState({ type: types[activeIndex] });
                            }
                          })
                      )
                    }
                  ></Button>
                </div>
                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                  {types.map((t) => (
                    <TabPanel
                      key={typesObj[t.id].id}
                      header={
                        typesObj[t.id].name +
                        (communicationData.filter((c) => c.type === typesObj[t.id].id && !c.read[user_details.id]).length === 0
                          ? ""
                          : " - " + communicationData.filter((c) => c.type === typesObj[t.id].id && !c.read[user_details.id]).length)
                      }
                    >
                      {this.renderChatDataTable(typesObj[t.id].id)}
                    </TabPanel>
                  ))}
                </TabView>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    organisation: state.util.organisation,
    notificationCount: state.util.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationCount: (count) => dispatch(setNotificationCount(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPage);
