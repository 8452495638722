import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./containers/Login";
import PasswordReset from "./containers/PasswordReset";
import ForgotPassword from "./containers/ForgotPassword";
import NotFoundPage from "./containers/404Page";
import UserLogin from "./common/UserLogin";

const PublicRouter = () => (
  <Switch>
    <Route path="/login/:key" exact={true} component={Login} />
    <Route path="/login/:key/:loginkey" exact={true} component={Login} />
    <Route path="/(login|)/" exact={true} component={Login} />
    <Route path="/passwordResetConfirm/:uid/:token" component={PasswordReset} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/userlogin/:token" component={UserLogin} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default PublicRouter;
