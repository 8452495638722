import options from "./ECharts/EchartOptions";
import cloneDeep from "lodash/cloneDeep";
import XLSX from "xlsx";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nav from "../utils/Utils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const excelexport = ({ fname, expfname, headers, data }) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fname);
  XLSX.writeFile(wb, expfname);
};

export const trainexcelexport = ({ fname, expfname, headers, data, dims, currdate, reportname }) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data], { origin: "A6" });
  const wb = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [["Date:"]], {
    origin: "A1",
  });
  XLSX.utils.sheet_add_aoa(ws, [[currdate]], {
    origin: "B1",
  });
  XLSX.utils.sheet_add_aoa(ws, [["Report Type:"]], {
    origin: "A2",
  });
  XLSX.utils.sheet_add_aoa(ws, [[reportname]], {
    origin: "B2",
  });
  XLSX.utils.sheet_add_aoa(ws, [["KPT:"]], {
    origin: "A3",
  });
  XLSX.utils.sheet_add_aoa(ws, [[dims.join()]], { origin: "B3" });
  XLSX.utils.book_append_sheet(wb, ws, fname);
  XLSX.writeFile(wb, expfname);
};

export const BuildOptionsArea = (chartData) => {
  let { type = "area", title, date, happydata, stressdata, empcount } = chartData;
  const option = cloneDeep({ ...options[type] });
  option["title"]["text"] = empcount === undefined ? "\n \n \n \n \n \n \n \n \n \n LOW DATA" : title;
  option["xAxis"][0]["data"] = date;
  option["xAxis"][1]["data"] = date;
  option["series"][0]["data"] = empcount;
  option["series"][1]["data"] = happydata;
  option["series"][2]["data"] = stressdata;
  return option;
};
export const BuildOptionsScatter = (chartData) => {
  let { type = "scatter", data, lowd, highd, graph, yaxis_label } = chartData;
  const option = cloneDeep({ ...options[type] });
  console.log(data);
  option["title"]["text"] = data.length === 0 ? " \n \n \n \n No Data" : "";
  option["yAxis"]["name"] = yaxis_label;
  if (graph === "pvspa") {
    option["grid"] = {
      left: 50,
      right: 10,
    };
    option["xAxis"] = {
      name: "Head Count",
      show: true,
      splitLine: { show: false },
      nameLocation: "middle",
      nameTextStyle: {
        padding: [10, 0, 0, 0],
      },
      minInterval: 1,
    };
    // option["series"][0] = {
    //   silent: true,
    //   markArea: {
    //     label: {
    //       color: "black",
    //       position: "inside"
    //     },
    //     itemStyle: {
    //       color: "#fdc66740"
    //     },
    //     data: [
    //       [
    //         {
    //           xAxis: "0",
    //           yAxis: "40"
    //         },
    //         {
    //           yAxis: "70"
    //         }
    //       ]
    //     ]
    //   },
    //   type: "custom"
    // };
    // option["series"][1] = {
    //   silent: true,
    //   markArea: {
    //     label: {
    //       color: "black",
    //       position: "inside"
    //     },
    //     itemStyle: {
    //       color: "#a1d97c70"
    //     },
    //     data: [
    //       [
    //         {
    //           xAxis: "0",
    //           yAxis: "70"
    //         },
    //         {}
    //       ]
    //     ]
    //   },
    //   type: "custom"
    // };

    // option["series"][2] = {
    //   silent: true,
    //   markArea: {
    //     label: {
    //       color: "black",
    //       position: "inside"
    //     },
    //     itemStyle: {
    //       color: "#fe876f50"
    //     },
    //     data: [
    //       [
    //         {
    //           xAxis: "0",
    //           yAxis: "0"
    //         },
    //         {
    //           yAxis: "40"
    //         }
    //       ]
    //     ]
    //   },
    //   type: "custom"
    // };
    data.map((data, i) => {
      return (option["series"][i + 3] = {
        name: data.name,
        type: "line",
        data: data.rating,
        color: data.color,
        symbolSize: 0,
        smooth: true,
      });
    });
    data.map((data1, i) => {
      return (option["series"][i + 3 + data.length] = {
        name: data1.name,
        type: "scatter",
        data: data1.rating,
        color: data1.color,
        symbolSize: data1.rating.length === 1 ? 6 : 2,
        smooth: true,
      });
    });
  } else {
    if (graph === "toppot") {
      //option["series"][3]["data"] = data;
      option["series"][4]["symbolSize"] = data.length === 1 ? 6 : 5;
      option["series"][4]["data"] = data;
    } else {
      //option["series"][3]["data"] = data;
      option["series"][4]["symbolSize"] = data.length === 1 ? 6 : 5;
      option["series"][4]["data"] = data;
    }
  }
  if (lowd && highd) {
    option["series"][0]["markArea"]["data"] = [
      [
        {
          yAxis: lowd.toString(),
        },
        {
          yAxis: highd.toString(),
        },
      ],
    ];
  }
  if (highd) {
    option["series"][1]["markArea"]["data"] = [
      [
        {
          yAxis: highd.toString(),
        },
        {},
      ],
    ];
  }
  if (lowd) {
    option["series"][2]["markArea"]["data"] = [
      [
        {
          yAxis: "0",
        },
        {
          yAxis: lowd.toString(),
        },
      ],
    ];
  }
  return option;
};
export const BuildOptions = (chartData) => {
  let { type = "bar", data, title, width, yAxisLabel = "", employeesCount, rotateLabel = false } = chartData;
  let labelOption = {
    show: true,
    position: "top",
    color: "black",
    formatter: "{c}%",
  };
  const option = cloneDeep({ ...options[type] });
  if (rotateLabel) {
    labelOption["rotate"] = 70;
    labelOption["textStyle"] = {
      align: "top",
      verticalAlign: "middle",
    };
    option["grid"] = { ...option.grid, top: 35 };
  }
  if (type === "bar") {
    //option["xAxis"]["data"] = [...data.map(e => e.name.replace(" ", "\n"))];
    option["yAxis"]["name"] = yAxisLabel;
    option["legend"]["data"] = [...data.map((e) => e.name)];
    option["title"]["text"] = data.length === 0 ? " \n \n \n \n \n  No Data" : "";
    option["grid"]["width"] = width;
    let scoreValue = 0;
    option["series"] = data.map((each) => {
      scoreValue += each.value;
      return {
        data: [each.value],
        type: "bar",
        barMinWidth: 20,
        barMaxWidth: 60,
        name: each.name,
        label: {
          normal: labelOption,
        },
      };
    });
  }
  option["title"]["id"] = title;
  let scoreValue = 0;
  if (type === "MindFramepie") {
    option["series"][0]["data"] = data.map((each_data) => {
      scoreValue += each_data.value;
      return {
        name: each_data.name,
        value: each_data.value,
        itemStyle: {
          normal: { color: each_data.color },
        },
      };
    });
    option["title"]["text"] =
      employeesCount === undefined
        ? title + (scoreValue === 0 ? " \n \n \n \n \n \n \n LOW DATA" : "")
        : title + "\n N = " + employeesCount + (employeesCount < 5 || scoreValue === 0 ? "\n \n \n \n \n \n \n LOW DATA" : "");
  }
  if (type === "doughnut") {
    option["series"][0]["data"] = data;
    option["title"]["text"] = title;
  }
  return option;
};

export const BuildSummaryOption = (reportData, dimension) => {
  let option = cloneDeep({ ...options["bar_stacked"] });
  let employeesCount = 0;
  const highArray = [];
  const moderateArray = [];
  const lowArray = [];
  option["yAxis"]["data"] = reportData.map((r) => r.mainHeading + " (" + r.charts[0]["employeesCount"] + ")");
  reportData.forEach((r) => {
    r.charts.forEach((c) => {
      if (c.id === dimension.id) {
        let total = c.data.reduce((a, b, i) => {
          if (i === 1) return a.value + b.value;
          else return a + b.value;
        });
        employeesCount += c.employeesCount;
        c.data.forEach((d) => {
          if (d.name === "High") {
            highArray.push(returnPercent(d.value, total));
          }
          if (d.name === "Moderate") {
            moderateArray.push(returnPercent(d.value, total));
          }
          if (d.name === "Low") {
            lowArray.push(returnPercent(d.value, total));
          }
        });
      }
    });
  });
  option["title"]["text"] = dimension.name + (employeesCount < 5 ? " \n \n \n \n \n \n LOW DATA" : " N = " + employeesCount);
  option["series"] = [
    {
      name: "Low",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#FE624C",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0) + "%"),
        },
      },
      data: lowArray,
    },
    {
      name: "Moderate",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#F0FF5A",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0) + "%"),
        },
      },
      data: moderateArray,
    },
    {
      name: "High",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#00E781",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0) + "%"),
        },
      },
      data: highArray,
    },
  ];
  return option;
};

export const BuildOptionBarStacked = (reportData) => {
  console.log(reportData);
  let option = cloneDeep({ ...options["bar_stacked"] });
  const { highArray, moderateArray, lowArray } = reportData["series"];
  option["title"]["text"] = reportData["title"];
  option["xAxis"]["data"] = reportData["xAxisLabel"];
  option["yAxis"]["max"] = reportData["totalEmployees"];
  option["series"] = [
    {
      silent: true,
      name: "Area of concern",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#FE624C",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0)),
        },
      },
      data: lowArray,
    },
    {
      silent: true,
      name: "Moderate",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#F0FF5A",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0)),
        },
      },
      data: moderateArray,
    },
    {
      silent: true,
      name: "Good",
      type: "bar",
      stack: "summary",
      itemStyle: {
        color: "#00E781",
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          color: "black",
          formatter: (e) => (parseInt(e.value).toFixed(0) + "" === "0" ? "" : parseFloat(e.value).toFixed(0)),
        },
      },
      data: highArray,
    },
  ];
  return option;
};

export const BuildSplitOptions = (chartData, isChild) => {
  let { type = "bar", data, title, yAxisLabel = "% of employees", employeesCount } = chartData;
  const option = cloneDeep({ ...options[type] });
  if (type === "bar") {
    option["xAxis"]["data"] = [...data.map((e) => e.name.replace(" ", "\n"))];
    option["yAxis"]["name"] = yAxisLabel;
  }
  option["title"]["id"] = title;
  let scoreValue = 0;
  option["series"][0]["data"] = data.map((each_data) => {
    scoreValue += each_data.value;
    return {
      name: each_data.name,
      value: each_data.value,
      itemStyle: {
        normal: { color: each_data.color },
        // emphasis: { color: each_data.color }
      },
    };
  });
  if (!isChild) {
    option["title"]["text"] = title + "\n N = " + employeesCount + (employeesCount < 5 || scoreValue === 0 ? "\n \n \n \n \n \n \n LOW DATA" : "");
  } else {
    option["title"]["text"] = title;
  }
  return option;
};

export const returnPercent = (value, total) => {
  return ((value * 100) / total).toFixed(4);
};

export const BuildRadar = (chartData) => {
  console.log(chartData.data.sort((a, b) => (a.id > b.id ? -1 : 1)));
  const { type } = chartData;
  const option = cloneDeep({ ...options[type] });
  option["title"]["text"] = chartData.title;
  option["radar"]["triggerEvent"] = chartData.trigger;
  option["series"][0]["data"] = [chartData.data.sort((a, b) => (a.id > b.id ? -1 : 1)).map((d) => d.value)];
  option["radar"]["indicator"] = chartData.data
    .sort((a, b) => (a.id > b.id ? -1 : 1))
    .map((d) => {
      return { name: d.name.replace(" ", "\n"), max: 100, color: d.color };
    });
  return option;
};

export const BuildBox = (chartData) => {
  const option = cloneDeep({ ...options["boxChart"] });
  option["xAxis"]["name"] = chartData.xlabel;
  option["series"][0]["data"] = [[chartData.data.performance, chartData.data.potential]];
  return option;
};

export const renderNotificationIcon = (notificationCount, user_details) => {
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>Notification</Tooltip>}>
      <span onClick={() => (user_details.isSuperUser ? nav("/cce/support") : nav("/support"))} className="cursor-pointer icon-container-topbar">
        {notificationCount > 0 ? <span className="container-badge">{notificationCount < 100 ? notificationCount : "99+"}</span> : null}
        <FontAwesomeIcon className="font-awesome-icons-topbar topbar-icon" icon={["far", "bell"]} />
      </span>
    </OverlayTrigger>
  );
};
