import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  loading: true,
  answer: {},
  question_count: -1,
};

const questionload = (state, action) => {
  return updateObject(state, {
    loading: true,
    allselectedranking: false,
  });
};

const question = (state, action) => {
  return updateObject(state, {
    data: action.new_ques,
    loading: false,
  });
};

const questioncompleted = (state, action) => {
  return updateObject(state, {
    text: action.complete_text,
    status: action.status,
    loading: false,
    data: action.data,
  });
};

const group_answers = (state, action) => {
  return updateObject(state, {
    answer: action.answer,
    loading: false,
  });
};

const incrementQuestionCount = (state, action) => {
  return updateObject(state, {
    question_count: state.question_count + 1,
  });
};
const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.loading,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUESTIONS_LOADING:
      return questionload(state, action);
    case actionTypes.QUESTION_NEXT:
      return question(state, action);
    case actionTypes.QUESTIONS_COMPLETED:
      return questioncompleted(state, action);
    case actionTypes.GROUP_ANSWERS:
      return group_answers(state, action);
    case actionTypes.INCREMENT_QUESTION_COUNT:
      return incrementQuestionCount(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
