import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import authReducer from "./reducers/auth";
import utilReducer from "./reducers/util";
import questnReducer from "./reducers/question";

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  util: utilReducer,
  question: questnReducer
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

export default store;
