import React, { Component } from "react";
import api from "../../api";
import EchartsArea from "../../common/ECharts/EChartsArea";
import Echarts from "../../common/ECharts/ECharts";
import { ProgressSpinner } from "primereact/progressspinner";

class MindFrame extends Component {
  state = {
    mfStats: [],
    happydata: [],
    stressdata: [],
    loading_handle: true
  };

  componentDidMount() {
    api
      .get("/hr/mindframe/")
      .then(res => {
        this.setState({
          mfStats: res.data.data,
          happydata: res.data.data.mood.happy_f_data,
          stressdata: res.data.data.mood.stress_f_data,
          loading_handle: false
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { mfStats, happydata, stressdata, loading_handle } = this.state;
    const chartData = {
      type: "area",
      title: "",
      date: mfStats.h_date,
      happydata: mfStats.happy_val,
      stressdata: mfStats.stress_val,
      empcount: mfStats.emp_vol
    };
    const chartData1 = {
      type: "MindFramepie",
      title: "Happiness factors chart",
      data: happydata
    };
    const chartData2 = {
      type: "MindFramepie",
      title: "stress factors chart",
      data: stressdata
    };
    return (
      <div className="content">
        {loading_handle === true ? (
          <>
            <div className="row">
              <div className="loading-spinner col-md-3">
                <ProgressSpinner />
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="box box-no-topborder">
                <div className="box-header with-border">
                  <h3 className="box-title">MindFrame</h3>
                </div>
                <div className="box-body">
                  <div>
                    <EchartsArea chartData={chartData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="box box-no-topborder">
                <div className="box-header with-border"></div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <Echarts chartData={chartData1} />
                        <Echarts chartData={chartData2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MindFrame;
