import React, { Component } from "react";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";
import { Panel } from "primereact/panel";
import { MultiSelect } from "primereact/multiselect";
import Echarts from "../../common/ECharts/ECharts";

class ReadyNext extends Component {
  state = {
    loading_handle: false,
    dim_Emp: [],
    text: null,
    text2: "",
    globalFilter: null,
    loading_handle: false,
    tabled: null,
    dims: [],
    max1: "",
    max2: "",
    tableshow: false,
    name: [],
    dept: "",
    sec: "",
    dataprogress: false,
    load: true,
    load2: true,
    tablelen: [],
    filterData: {},
    seldep: [],
    sellev: [],
    selloc: [],
    selsec: [],
    vul_data: {},
    vul: [],
    showTable: false,
    vul_table: [],
    first: 0,
  };
  componentDidMount() {
    api.get("api/cce/get-dashboard-filters").then((res) => {
      this.setState({ ...res.data });
    });
    this.fetch();
  }

  fetch = () => {
    const { seldep, sellev, selloc, selsec } = this.state;
    const data = { user: this.props.user, dep: seldep, loc: selloc, lev: sellev, sec: selsec };
    api
      .post("/api/assessments/readynext-get", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({ dim_Emp: res.data.data, dataprogress: true });
        }
        this.setState({ load: false });
      })
      .catch((err) => console.log(err));
    api
      .post("/api/assessments/velnerbilityplanningemployees", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({ vul_data: res.data.res_data, vul: res.data.result });
        }
        this.setState({ load2: false });
      })
      .catch((err) => console.log(err));
  };
  empSearch = () => {
    if (this.state.text2 === "") {
      this.growl.show({
        severity: "warn",
        summary: "Warn Message",
        detail: "Select an Employee",
      });
    } else {
      this.setState({
        loading_handle: true,
        tableshow: true,
      });
      const fData = { managernumber: this.state.text2, user: this.props.user };
      api
        .post("/api/assessments/readynext-post", JSON.stringify(fData))
        .then((res) => {
          if (res.data.results) {
            console.log(res.data);
            this.setState({
              tablelen: res.data.empdata.length,
              tabled: res.data.empdata,
              dims: res.data.dimnames,
              max1: res.data.max1,
              max2: res.data.max2,
              loading_handle: false,
              globalFilter: null,
            });
          } else {
            this.setState({
              loading_handle: false,
              tableshow: false,
            });
            this.growl.show({
              severity: "warn",
              detail: "Data collection is in progress",
            });
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  rowClassName = (rowData) => {
    if (rowData.overall === this.state.max1 || rowData.overall === this.state.max2) {
      if (rowData.overall >= 70) {
        return { "p-highlight-green": true };
      } else if (rowData.overall >= 50 && rowData.overall < 70) {
        return { "p-highlight-yellow": true };
      } else if (rowData.overall < 50) {
        return { "p-highlight-red": true };
      }
    } else {
      return { "p-highlight-white": true };
    }
  };
  getdetails = (e) => {
    this.setState({ text2: e.value });

    this.setState({
      name: this.state.dim_Emp.filter((m) => {
        return m.value === e.value;
      }),
    });
  };

  render() {
    const rowsdt = this.state.dims.map((level, index) => <Column key={index} field={level} header={level} sortable={true} />);

    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column header="" colSpan={5} />
          <Column header="Matching Percentage" colSpan={this.state.dims.length + 1} />
        </Row>
        <Row>
          <Column field="empno" header="Employee Number" sortable={true} />
          <Column field="empname" header="Employee Name" sortable={true} />
          <Column field="grade" header="Grade" sortable={true} />
          <Column field="dept" header="Department" sortable={true} />
          <Column field="performance" header="Latest Performance Rating" sortable={true} />
          <Column field="overall" header="Overall Match" sortable={true} body={(rowData) => rowData.overall + "%"} />
          {rowsdt}
        </Row>
      </ColumnGroup>
    );
    var header = (
      <div className="p-grid">
        <div className="p-col-6">
          {/* <InputText
              type="search"
              style={{ marginRight: "50px" }}
              onInput={e => this.setState({ globalFilter: e.target.value })}
              placeholder="Search..."
              size="50"
            /> */}
        </div>
        <div className="p-col-6" style={{ float: "right" }}>
          <div className="total-employees-container">N - {this.state.tablelen}</div>
        </div>
      </div>
    );
    const { dim_Emp, text2, loading_handle, filterData, vul_data, vul, showTable } = this.state;
    const columnsdt = this.state.dims.map((level, index) => <Column key={index} field={level} body={(rowData) => rowData[level] + "%"} />);
    const chartData1 = {
      type: "MindFramepie",
      title: "",
      data: vul,
    };
    return (
      <div className="layout-main">
        <Panel header={<div style={{ fontSize: "18px" }}>Succession Analysis</div>}>
          {/* <div style={{ fontSize: "18px" }}>{this.props.menuProps.label}</div>}> */}
          {/* <div className="box-header with-border">
                <h3 className="box-title">ReadyNext Analysis</h3>
              </div> */}
          {this.state.load ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : this.state.dataprogress ? (
            <div>
              <Growl ref={(el) => (this.growl = el)} style={{ marginTop: 20 }} position="bottomright" />
              <Panel header="Filters">
                <div className="box-body box-body-cust-250">
                  <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", marginBottom: 9, flexWrap: "wrap" }}>
                    <MultiSelect
                      value={this.state.selloc}
                      options={filterData.locations ? filterData.locations : []}
                      onChange={(e) => this.setState({ selloc: e.value }, this.fetch)}
                      filter={true}
                      placeholder="Location..."
                      scrollHeight="200px"
                    />

                    <MultiSelect
                      value={this.state.seldep}
                      options={filterData.departments ? filterData.departments : []}
                      onChange={(e) => this.setState({ seldep: e.value }, this.fetch)}
                      filter={true}
                      placeholder="Department..."
                      scrollHeight="200px"
                    />

                    <MultiSelect
                      value={this.state.selsec}
                      options={filterData.sections ? filterData.sections : []}
                      onChange={(e) => this.setState({ selsec: e.value }, this.fetch)}
                      filter={true}
                      placeholder="Section..."
                      scrollHeight="200px"
                    />

                    <MultiSelect
                      value={this.state.sellev}
                      options={filterData.levels ? filterData.levels : []}
                      onChange={(e) => this.setState({ sellev: e.value }, this.fetch)}
                      filter={true}
                      placeholder="Grade..."
                      scrollHeight="200px"
                    />
                    <Button
                      type="button"
                      label="Reset"
                      onClick={() =>
                        this.setState(
                          {
                            seldep: [],
                            selloc: [],
                            sellev: [],
                            selsec: [],
                          },
                          () => this.fetch()
                        )
                      }
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  Select Employee:
                  <Dropdown
                    className="readynextdd"
                    style={{ width: "22em", marginLeft: "1em" }}
                    //size={70}
                    value={text2}
                    options={dim_Emp}
                    placeholder="Click here..."
                    onChange={(e) => this.getdetails(e)}
                    filter={true}
                    filterPlaceholder="Select Employee"
                    filterBy="label,value"
                  />
                  <Button style={{ marginLeft: "1em" }} onClick={() => this.empSearch()} label="Search" />
                  <div style={{ color: "gray", marginTop: ".5em" }}>
                    {this.state.name.map(
                      (e, index) => "Id: " + e.value + ", Name: " + e.name + ", Department: " + e.dept + ", Section: " + e.section
                    )}
                  </div>
                </div>
              </Panel>

              <div className="p-grid">
                <div>
                  {this.state.tableshow === false ? null : loading_handle === true ? (
                    <div style={{ textAlign: "center", paddingLeft: "50%" }}>
                      <div className="loading-spinner-container" style={{ textAlign: "center", paddingLeft: "50%" }}>
                        <ProgressSpinner></ProgressSpinner>
                      </div>
                    </div>
                  ) : (
                    <>
                      <br />
                      <DataTable
                        className="oranget"
                        header={header}
                        sortField="overall"
                        sortOrder={-1}
                        globalFilter={this.state.globalFilter}
                        paginator={true}
                        rows={20}
                        style={{ textAlign: "center" }}
                        rowsPerPageOptions={[10, 20, 50]}
                        responsive={true}
                        rowClassName={this.rowClassName}
                        headerColumnGroup={headerGroup}
                        resizableColumns={true}
                        value={this.state.tabled}
                      >
                        <Column field="empno" />
                        <Column field="empname" />
                        <Column field="grade" />
                        <Column field="dept" />
                        <Column field="performance" />
                        <Column field="overall" body={(rowData) => rowData.overall + "%"} />

                        {columnsdt}
                      </DataTable>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="p-col-12">
              <div style={{ color: "#6a6b6cba" }} className=" box-body box-body-cust-250">
                <div
                  className="medialog"
                  style={{
                    fontSize: "20px",
                    fontWeight: "800px",
                    textAlign: "center",
                  }}
                >
                  Data Collection is in Progress
                </div>
              </div>
            </div>
          )}
        </Panel>
        <Panel header={<div style={{ fontSize: "18px" }}>Vulnerability Analysis</div>}>
          {this.state.load2 ? (
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : (
            <Echarts
              callBack={(param) => {
                if (param.name === "With Green") {
                  this.setState({ vul_table: vul_data["green"], showTable: true, first: 0 });
                } else if (param.name === "With Red") {
                  this.setState({ vul_table: vul_data["red"], showTable: true, first: 0 });
                } else if (param.name === "With Yellow") {
                  this.setState({ vul_table: vul_data["yellow"], showTable: true, first: 0 });
                }
              }}
              type="succession"
              chartData={chartData1}
            />
          )}
          {showTable && (
            <DataTable
              className="oranget"
              header={"Vulnerabilty Data"}
              paginator={true}
              rows={10}
              style={{ textAlign: "center" }}
              rowsPerPageOptions={[10, 20, 50]}
              responsive={true}
              resizableColumns={true}
              value={this.state.vul_table}
              emptyMessage="No Data"
              first={this.state.first}
              onPage={(e) => this.setState({ first: e.first })}
            >
              <Column field="number" header="Manager Number" />
              <Column field="name" header="Manager Name" />
            </DataTable>
          )}
        </Panel>
      </div>
    );
  }
}

export default ReadyNext;
