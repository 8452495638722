import React, { Component } from "react";
import awake_logo from "../assets/images/atma-brand-icon.png";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { authLogout } from "../store/actions/auth";
import { setOrgSelected, fetchNotificationCount } from "../store/actions/util";
import { connect } from "react-redux";
import api from "../api";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { ProgressSpinner } from "primereact/progressspinner";
import { renderNotificationIcon } from "../common/Helper";

export class AdminTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null,
  };
  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };
  state = {
    orglist: [],
    orgSelectionVisible: true,
    loading: true,
  };
  componentDidMount() {
    api
      .get("api/cce/get_org_list")
      .then((res) => {
        this.setState({
          orglist: res.data.orgnames,
          loading: false,
          orgSelectionVisible: res.data.orgnames.length > 1,
        });
        if (res.data.orgnames.length === 1) this.props.setOrgSelected(res.data.orgnames[0].value);
        this.props.fetchNotificationCount();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    if (_.isEmpty(this.props.organisation)) {
      this.setState({ orgSelectionVisible: true });
    }
  }
  handleLogout = () => {
    this.props.logout();
  };
  render() {
    const { orglist, orgSelectionVisible, loading } = this.state;
    const { organisation, notificationCount, user_details } = this.props;
    return (
      <div className="layout-topbar clearfix">
        <Dialog
          className="language-container"
          appendTo={document.body}
          header="Select Organisation"
          visible={orgSelectionVisible}
          closable={false}
          onHide={() => this.setState({ orgSelectionVisible: false })}
        >
          {loading ? (
            <div className="center">
              <ProgressSpinner></ProgressSpinner>
            </div>
          ) : (
            <Dropdown
              value={organisation}
              options={orglist}
              className="dialoag-dropdown"
              onChange={(e) => {
                this.props.setOrgSelected(e.value);
                this.setState({ orgSelectionVisible: false });
              }}
              placeholder="Select a Organisation"
            />
          )}
        </Dialog>
        <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
          <span className="pi pi-bars" />
        </button>
        <span className="topbar-text">
          <img style={{ width: "164px", height: "35px", resizeMode: "contain" }} src={awake_logo}></img>
        </span>
        <span className="organisation-dropdown-adminbar relative top-10">
          <Dropdown
            value={organisation}
            options={orglist}
            onChange={(e) => {
              this.props.setOrgSelected(e.value);
              this.setState({ organisation: e.value, organisationId: e });
            }}
            placeholder="Organisation dropdown"
          />
        </span>
        <div className="layout-topbar-icons admin">
          {renderNotificationIcon(notificationCount, user_details)}
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Logout</Tooltip>}>
            <button onClick={() => this.handleLogout()} className="p-link">
              <span className="layout-topbar-icon pi pi-sign-out logout-icon" />
            </button>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organisation: state.util.organisation,
    notificationCount: state.util.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
    setOrgSelected: (org) => dispatch(setOrgSelected(org)),
    fetchNotificationCount: () => dispatch(fetchNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTopbar);
