import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import nav from "../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AppSubmenu extends Component {
  static defaultProps = {
    className: null,
    items: null,
    onMenuItemClick: null,
    root: false
  };

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onMenuItemClick: PropTypes.func,
    root: PropTypes.bool
  };

  state = {
    activeIndex: 0
  };

  componentDidMount() {
    let { items = [] } = this.props;
    if (items === null) items = [];
    const { pathname } = window.location;
    const pathArray = pathname.split("/");
    items.forEach((item, i) => {
      if (
        pathArray.includes(item.link) ||
        pathname.replace(/\//g, "") === item.link.replace(/\//g, "")
      ) {
        this.setState({ activeIndex: i });
      }
    });
    // this.setState({activeIndex: 0})
  }

  onMenuItemClick(event, item, index) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  renderLinkContent(item) {
    let submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    let badge = item.badge && (
      <span className="menuitem-badge">{item.badge}</span>
    );
    return (
      <React.Fragment>
        <FontAwesomeIcon
          icon={[item.icon.split(",")[0], item.icon.split(",")[1]]}
        />
        <span className="menu-label-container">{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  }

  renderLink(item, i) {
    let content = this.renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-route"
          to={item.to}
          onClick={e => this.onMenuItemClick(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          href={item.url}
          onClick={e => this.onMenuItemClick(e, item, i)}
          target={item.target}
        >
          {content}
        </a>
      );
    }
  }

  render() {
    const { pathname } = window.location;
    let items =
      this.props.items &&
      this.props.items.map((item, i) => {
        let active = this.state.activeIndex === i;
        let styleClass = classNames(
          item.badgeStyleClass,
          {
            "active-menuitem": active
          },
          {
            " active-menuitem-highlight":
              pathname.replace(/\//g, "") === item.link.replace(/\//g, "")
          }
        );
        return (
          <li className={"side-menu-container "+styleClass} key={i}>
            {item.items && this.props.root === true && (
              <div className="arrow"></div>
            )}
            {this.renderLink(item, i)}
            <AppSubmenu
              items={item.items}
              onMenuItemClick={this.props.onMenuItemClick}
            />
          </li>
        );
      });

    return items ? <ul className={this.props.className}>{items}</ul> : null;
  }
}

class AppMenu extends Component {
  static defaultProps = {
    onMenuItemClick: null
  };

  static propTypes = {
    model: PropTypes.array,
    onMenuItemClick: PropTypes.func
  };

  componentDidMount() {
    setTimeout(() => {
      const { menu = [] } = this.props;
      const { pathname } = window.location;
      if (pathname === "/") {
        if (menu[0] && !menu[0].items) {
          nav("/" + menu[0].link);
        } else if (menu[0] && menu[0].items && menu[0].items[0]) {
          nav("/" + menu[0].items[0].link);
        }
      }
    }, 500);
  }

  render() {
    const { menu = [] } = this.props;
    return (
      <div key={menu.length} className="layout-menu-container">
        {menu.length > 0 ? (
          <AppSubmenu
            items={menu}
            className="layout-menu"
            onMenuItemClick={this.props.onMenuItemClick}
            root={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    menu: state.auth.user_details.sideMenu
  };
};

export default connect(mapStateToProps, null)(AppMenu);
