import React, { Component } from "react";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import api from "../../api";
import { Growl } from "primereact/growl";
import { connect } from "react-redux";
import _ from "lodash";
import { InputSwitch } from "primereact/inputswitch";
import XLSX from "xlsx";
import { isEmptyRow } from "../../utils/Utils";

class EmployeeDimension extends Component {
  state = {
    value: [],
    org: null,
    levels: [],
    orglevels: [],
    group: [],
    selorg: null,
    sellevel: [],
    loading_handle: true,
    groupname: null,
    groupSuggestions: [],
    selectedNodeKeys: {},
    allkeys: [],
    pushqchecked: [],
    dispchecked: [],
    postdata: {},
    gen: null,
    freq: null,
    text: null,
    load_flag: "True",
    data: [],
    tablekey: 0,
    Load_button: true,
    Delete_button: true,
    Update_button: true,
    maritalstatus: true,
    displaystatus: true,
    savebtn: false,
    getbtn: false,
    updatebtn: false,
    resetbtn: false,
    loadbtn: false,
    pushbtn: false,
    delbtn: false,
    organisation: this.props.organisation,
    custom_dim: false,
    create_group: false,
    selected_dims: 0,
    indirect_dims: 0,
    quesions_len: 0,
    caldimbtn: false,
    exportbtn: false,
    file: null,
  };
  reset = () => {
    this.setState({ tablekey: this.state.tablekey + 1, resetbtn: true });
    const token = localStorage.getItem("token");

    api
      .post("/api/cce/reset", "", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.results["status"] === true) {
          this.setState({
            groupname: res.data.results["gname"],
            org: res.data.results["organisation"],
            levels: res.data.results["levels"],
            data: res.data.results["dims"],
            groupSuggestions: res.data.results["groupnames"],
            selectedNodeKeys: res.data.results["selectedkeys"],
            postdata: res.data.results["data"],
            selorg: null,
            sellevel: null,
            resetbtn: false,
            maritalstatus: true,
            displaystatus: true,
          });
        } else {
          this.growl.show({
            severity: "info",
            summary: "info Message",
            detail: "The groupname is not available.",
          });
        }
      });
  };
  get = () => {
    this.setState({ tablekey: this.state.tablekey + 1 });
    const { organisation } = this.props;
    if (this.state.groupname === null) {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Please select group name.",
      });
    } else {
      const getdata = {
        groupname: this.state.groupname,
        org: organisation,
      };
      const token = localStorage.getItem("token");
      this.setState({ getbtn: true });
      api
        .post("/api/cce/getgroup", getdata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({ getbtn: false });
          if (res.data.results["status"] === true) {
            this.setState({
              org: res.data.results["organisation"],
              levels: res.data.results["levels"],
              orglevels: res.data.results["levels"],
              data: res.data.results["dims"],
              groupSuggestions: res.data.results["groupnames"],
              selectedNodeKeys: res.data.results["selectedkeys"],
              postdata: res.data.results["data"],
              selorg: res.data.results["selorg"][0].value,
              sellevel: res.data.results["sellevel"].levels,
            });
          } else {
            this.growl.show({
              severity: "info",
              summary: "info Message",
              detail: "The groupname is not available.",
            });
          }
        });
    }
  };
  deletequestions = () => {
    this.setState({ tablekey: this.state.tablekey + 1, delbtn: true });
    const { organisation } = this.props;
    const getdata = {
      org: organisation,
    };
    const token = localStorage.getItem("token");
    api
      .post("/api/cce/deletequestions", getdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ delbtn: false });
        if (res.data.results["status"] === true) {
          this.setState({
            Load_button: res.data.results["Load_button"],
            Delete_button: res.data.results["Delete_button"],
            Update_button: res.data.results["Update_button"],
          });
          this.growl.show({
            severity: "info",
            summary: "Message",
            detail: "Question deleted from the queue.",
          });
        } else {
          this.growl.show({
            severity: "info",
            summary: "info Message",
            detail: "Please select Organisation, If selected the employees started answering",
          });
        }
      });
  };
  updatequestions = () => {
    this.setState({ tablekey: this.state.tablekey + 1 });
    const token = localStorage.getItem("token");
    const { organisation } = this.props;
    const getdata = {
      groupname: this.state.groupname,
      org: organisation,
    };
    api
      .post("/api/cce/updatequestions", getdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.results["status"] === true) {
          this.setState({
            Load_button: res.data.results["Load_button"],
            Delete_button: res.data.results["Delete_button"],
            Update_button: res.data.results["Update_button"],
          });
          this.growl.show({
            severity: "info",
            summary: "Message",
            detail: "Question deleted from the queue.",
          });
        } else {
          this.growl.show({
            severity: "info",
            summary: "info Message",
            detail: "The questions is not available.",
          });
        }
      });
  };
  loadquestions = () => {
    this.setState({ tablekey: this.state.tablekey + 1 });
    const { organisation } = this.props;
    if (this.state.groupname === null) {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Please select group name.",
      });
    } else {
      const getdata = {
        groupname: this.state.groupname,
        org: organisation,
      };
      const token = localStorage.getItem("token");
      this.setState({ pushbtn: true });
      api
        .post("/api/cce/loadquestions", getdata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({ pushbtn: false });
          if (res.data.results["status"] === true) {
            this.setState({
              Load_button: res.data.results["Load_button"],
              Delete_button: res.data.results["Delete_button"],
              Update_button: res.data.results["Update_button"],
            });
            this.growl.show({
              severity: "info",
              summary: "Message",
              detail: "Question pushed for the employees in this group.",
            });
          } else {
            this.growl.show({
              severity: "info",
              summary: "info Message",
              detail: "The groupname is not available.",
            });
          }
        });
    }
  };
  loaditems = () => {
    this.setState({ tablekey: this.state.tablekey + 1 });
    const { organisation } = this.props;
    if (this.state.groupname === null) {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Please select group name.",
      });
    } else {
      const getdata = {
        groupname: this.state.groupname,
        org: organisation,
      };
      const token = localStorage.getItem("token");
      this.setState({ loadbtn: true });
      api
        .post("/api/cce/loaditems", getdata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({ loadbtn: false });
          if (res.data.results["status"] === true) {
            this.setState({
              Load_button: res.data.results["Load_button"],
              Delete_button: res.data.results["Delete_button"],
              Update_button: res.data.results["Update_button"],
            });
            this.growl.show({
              severity: "info",
              summary: "Message",
              detail: "Items loaded to the table.",
            });
          } else {
            this.growl.show({
              severity: "info",
              summary: "info Message",
              detail: "The groupname or organisation is not selected.",
            });
          }
        });
    }
  };
  update = () => {
    this.setState({ tablekey: this.state.tablekey + 1 });
    if (this.state.groupname === null) {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Please select group name.",
      });
    } else {
      const updatedata = {
        org: this.state.selorg,
        levels: this.state.sellevel,
        dims: this.state.selectedNodeKeys,
        postdata: this.state.postdata,
        groupname: this.state.groupname,
      };
      const token = localStorage.getItem("token");
      this.setState({ updatebtn: true });
      api
        .post("/api/cce/updategroup", updatedata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({ updatebtn: false });
          if (res.data.results["status"] !== true) {
            this.growl.show({
              severity: "error",
              summary: "Error Message",
              detail: res.data.results["status"],
            });
          } else {
            this.setState({ updatebtn: false });
            this.growl.show({
              severity: "success",
              summary: "Group Updated Successfully",
            });
          }
        })
        .catch((err) => {
          this.setState({ updatebtn: false });
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
          console.log(err);
        });
    }
  };

  creategroup = () => {
    this.setState({ create_group: true }, () => {
      this.save();
    });
  };
  save = () => {
    console.log("group_save");
    this.setState({ tablekey: this.state.tablekey + 1 });
    if (this.state.selorg === null || this.state.sellevel === [] || this.state.groupname === null || this.state.selectedNodeKeys.length === 0) {
      this.growl.show({
        severity: "info",
        summary: "info Message",
        detail: "Please fill all mandatory fields or select atleast one dimension.",
      });
    } else {
      const data = {
        org: this.state.selorg,
        levels: this.state.sellevel,
        dims: this.state.selectedNodeKeys,
        postdata: this.state.postdata,
        groupname: this.state.groupname,
        create_group: this.state.create_group,
      };
      const token = localStorage.getItem("token");
      this.setState({ savebtn: true });
      api
        .post("/api/cce/empgroups", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          this.setState({ savebtn: false });
          const status = res.data.results["status"];
          console.log("data", res.data);
          if (res.data.results["status"] !== true) {
            if (res.data.results["CD"] == false) {
              this.setState({ custom_dim: true });
            } else {
              this.growl.show({
                severity: "error",
                summary: "Error Message",
                detail: status,
              });
            }
          } else {
            this.get();

            // this.setState({
            //   selectedNodeKeys: this.state.allkeys,
            //   groupSuggestions: res.data.results["groupnames"],
            //   selorg: null,
            //   sellevel: [],
            //   groupname: null,
            //   postdata: res.data.results["data"],
            //   custom_dim: false,
            // });
            this.growl.show({
              severity: "success",
              summary: "Group Created Successfully",
            });
          }
        })
        .catch((err) => {
          this.setState({ updatebtn: false });
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
          console.log(err);
        });
    }
  };

  fetchData = () => {
    const { organisation } = this.props;
    api
      .post("/api/cce/employeedimension", organisation)
      .then((res) => {
        this.setState({
          auto_push_que: res.data.results["auto_push_que"],
          org: res.data.results["organisation"],
          levels: res.data.results["levels"],
          orglevels: res.data.results["levels"],
          data: res.data.results["dims"],
          groupSuggestions: res.data.results["groupnames"],
          selectedNodeKeys: res.data.results["selectedkeys"],
          allkeys: res.data.results["selectedkeys"],
          postdata: res.data.results["data"],
          load_flag: "False",
          loading_handle: res.data.results["loading_handle"],
          Load_button: res.data.results["Load_button"],
          Delete_button: res.data.results["Delete_button"],
          Update_button: res.data.results["Update_button"],
        });
      })
      .catch((err) => console.log(err));
  };

  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.setState({ loading_handle: true });
      this.fetchData();
    }
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.organisation)) {
      this.fetchData();
    }
    // else (
    //   this.setState({ load_flag: "True" })
    // )
  }

  // componentDidMount() {
  //   const { organisation } = this.props;
  //   api
  //     .post("/api/cce/employeedimension", organisation)
  //     .then(res => {
  //       this.setState({
  //         org: res.data.results["organisation"],
  //         levels: res.data.results["levels"],
  //         orglevels: res.data.results["levels"],
  //         data: res.data.results["dims"],
  //         groupSuggestions: res.data.results["groupnames"],
  //         selectedNodeKeys: res.data.results["selectedkeys"],
  //         allkeys: res.data.results["selectedkeys"],
  //         postdata: res.data.results["data"],
  //         load_flag: "False",
  //         Load_button: res.data.results["Load_button"],
  //         Delete_button: res.data.results["Delete_button"],
  //         Update_button: res.data.results["Update_button"]
  //       });
  //     })
  //     .catch(err => console.log(err));
  // }
  family = (rowData, column) => {
    const postdata = this.state.postdata;
    return (
      <div style={{ textAlign: "center" }}>
        <Checkbox checked={postdata[rowData["key"]].family} onChange={(e) => this.familycheck(e.checked, rowData["key"], e)} />
      </div>
    );
  };
  familycheck = (checked, key1) => {
    const postdata = this.state.postdata;
    postdata[key1] = { ...postdata[key1], family: checked };
    this.setState({ postdata });
  };

  display = (rowData, column) => {
    const postdata = this.state.postdata;
    return (
      <div style={{ textAlign: "center" }}>
        <Checkbox checked={postdata[rowData["key"]].display} onChange={(e) => this.displaycheck(e.checked, rowData["key"])} />
      </div>
    );
  };

  displaycheck = (checked, key) => {
    const postdata = this.state.postdata;
    postdata[key] = { ...postdata[key], display: checked };
    this.setState({ postdata });
  };
  frequency = (rowData, column) => {
    const postdata = this.state.postdata;
    return (
      <div>
        <InputText value={postdata[rowData["key"]].frequency} onChange={(e) => this.frequencycheck(e.target.value, rowData["key"])} />
      </div>
    );
  };
  frequencycheck = (value, key) => {
    const postdata = this.state.postdata;
    postdata[key] = { ...postdata[key], frequency: value };
    this.setState({ postdata });
  };
  selectkeys = (checkedkeys) => {
    console.log("checkedkeys:", checkedkeys);
    this.setState({ selectedNodeKeys: checkedkeys });
  };

  calculate_dimensions = () => {
    this.setState({ caldimbtn: true });
    api
      .post("/api/cce/calculate_dimensions", { dims: this.state.selectedNodeKeys })
      .then((res) => {
        this.setState({ caldimbtn: false });
        if (res.data.result && res.data.data) {
          const data = res.data.data;
          this.setState({ selected_dims: data.direct_dims, indirect_dims: data.indirect_dims, quesions_len: data.que });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.setState({ caldimbtn: false });
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };

  gender = (rowData, column) => {
    const postdata = this.state.postdata;
    const val = postdata[rowData["key"]].gender["gender"];
    const options = [
      { label: "Female", value: "Female" },
      { label: "Male", value: "Male" },
      { label: "Others", value: "Others" },
    ];
    return (
      <div>
        <SelectButton multiple={true} value={val} options={options} onChange={(e) => this.gendercheck(e.value, rowData["key"])} />
      </div>
    );
  };
  gendercheck = (value, key) => {
    const postdata = this.state.postdata;
    postdata[key] = { ...postdata[key], gender: { gender: value } };
    this.setState({ postdata });
  };
  filterMsg = (event) => {
    let results = this.state.groupSuggestions.filter((grp) => {
      return grp.toLowerCase().startsWith(event.query.toLowerCase());
    });
    this.setState({ text: results });
  };
  onHide = () => {
    this.setState({
      custom_dim: false,
    });
  };
  filterlevel = (orgval) => {
    console.log("filterlevel", orgval);
    this.setState((prevState, props) => ({
      orglevels: prevState.levels.filter((l) => l.org === orgval),
    }));
  };
  maritalstatus = (checked) => {
    const postdata = this.state.postdata;
    Object.keys(postdata).map((key) => (postdata[key] = { ...postdata[key], family: checked }));
    this.setState({ maritalstatus: checked, postdata });
  };
  maritalheader() {
    return (
      <div>
        <span>MaritalStatus</span>
        <span style={{ paddingLeft: "10px" }}>
          <Checkbox checked={this.state.maritalstatus} onChange={(e) => this.maritalstatus(e.checked)} />
        </span>
      </div>
    );
  }
  displaystatus = (checked) => {
    const postdata = this.state.postdata;
    Object.keys(postdata).map((key) => (postdata[key] = { ...postdata[key], display: checked }));
    this.setState({ displaystatus: checked, postdata });
  };
  displayheader() {
    return (
      <div>
        <span>Display</span>
        <span style={{ paddingLeft: "10px" }}>
          <Checkbox checked={this.state.displaystatus} onChange={(e) => this.displaystatus(e.checked)} />
        </span>
      </div>
    );
  }
  export = () => {
    this.setState({ exportbtn: true });
    api
      .post("/api/cce/export_selected_dim", { dims: this.state.selectedNodeKeys })
      .then((res) => {
        this.setState({ exportbtn: false });
        if (res.data.result && res.data.link) {
          this.filedownload(res.data.link);
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.setState({ caldimbtn: false });
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  filedownload = (url) => {
    // console.log(url);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  handleFile = () => {
    let { file } = this.state;
    if (!file) {
      this.growl.show({
        severity: "error",
        detail: "Choose File",
      });
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
      });

      let cols = [];
      let rows = [];
      let emptyRowCount = 0;
      data.some((r, index) => {
        if (index == 0) {
          r.forEach((r1) => {
            cols.push({
              field: r1,
            });
          });
        }
        if (index > 0) {
          let obj = {};
          r.some((r1, index2) => {
            obj[cols[index2].field] = r1;
            return emptyRowCount === 10;
          });
          if (isEmptyRow(obj, "")) emptyRowCount += 1;
          else rows.push(obj);
        }
        return emptyRowCount === 10;
      });
      let finaldata = {};
      rows.map((r) => (finaldata[parseInt(r["dimension"])] = { checked: r["checked"], partialChecked: r["partially checked"] }));
      this.setState({ selectedNodeKeys: finaldata });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  auto_push_que = (e) => {
    let { organisation } = [];
    {
      this.props.user_details.isSuperUser
        ? (organisation = this.props.organisation)
        : (organisation = {
            id: this.props.user_details.organisation_id,
            name: this.props.user_details.organisation["account_name"],
          });
    }
    const data = { auto_push_que: e.value, org: organisation };
    api
      .post("api/cce/auto_push_que_flag", data)
      .then((res) => {
        if (res.data.results) {
          this.setState({
            auto_push_que: e.value,
          });
        } else {
          this.growl.show({
            severity: "error",
            summary: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        this.growl.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(err);
      });
  };
  render() {
    // console.log(this.state.postdata);
    const btn = {
      marginLeft: "2%",
      width: "23%",
      minWidth: "70px",
    };
    return (
      <div className="layout-main">
        {this.state.loading_handle === true ? (
          <>
            <div className="loading-spinner-container">
              <ProgressSpinner></ProgressSpinner>
            </div>
          </>
        ) : (
          <Panel
            header={
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>{this.props.menuProps.label}</div>
                <div>
                  <Button
                    label="Export"
                    onClick={this.export}
                    style={btn}
                    icon={this.state.exportbtn ? "pi pi-spin pi-spinner" : ""}
                    iconPos="right"
                  />
                </div>
              </div>
            }
          >
            <div>
              <Growl ref={(el) => (this.growl = el)} />
              {this.state.custom_dim ? (
                <Dialog
                  header="Custom Dimension"
                  visible={this.state.custom_dim}
                  style={{ width: "50vw" }}
                  onHide={() => this.onHide("displayBasic")}
                >
                  <p>Do you want to create a group without selecting custom dimension?</p>
                  <Button
                    style={{ marginLeft: "1%" }}
                    label="Yes Create Group"
                    onClick={this.creategroup}
                    // disabled={this.state.Delete_button}
                    // icon={this.state.delbtn ? "pi pi-spin pi-spinner" : ""}
                    iconPos="right"
                  />
                </Dialog>
              ) : null}
              <div className="p-grid">
                <div className="p-col-3">
                  <Dropdown
                    style={{ width: "100%", minWidth: "0px" }}
                    value={this.state.selorg}
                    options={this.state.org}
                    onChange={(e) => {
                      this.setState({ selorg: e.value });
                      this.filterlevel(e.value);
                    }}
                    placeholder="Select Organisation *"
                    showClear={true}
                  />
                </div>
                <div className="p-col-3">
                  <MultiSelect
                    style={{ width: "100%" }}
                    value={this.state.sellevel}
                    options={this.state.orglevels}
                    onChange={(e) => this.setState({ sellevel: e.value })}
                    placeholder="Select Level *"
                  />
                </div>
                <div className="p-col-3">
                  <AutoComplete
                    style={{ width: "100%" }}
                    dropdown={true}
                    value={this.state.groupname}
                    onChange={(e) => this.setState({ groupname: e.value })}
                    suggestions={this.state.text}
                    completeMethod={(e) => this.filterMsg(e)}
                    placeholder="Q Group Name *"
                  />
                </div>

                <div className="p-col-3">
                  <Button style={btn} label="Save" onClick={this.save} icon={this.state.savebtn ? "pi pi-spin pi-spinner" : ""} iconPos="right" />
                  <Button label="Get" onClick={this.get} style={btn} icon={this.state.getbtn ? "pi pi-spin pi-spinner" : ""} iconPos="right" />
                  <Button
                    label="Update"
                    onClick={this.update}
                    style={btn}
                    icon={this.state.updatebtn ? "pi pi-spin pi-spinner" : ""}
                    iconPos="right"
                  />
                  <Button label="Reset" onClick={this.reset} style={btn} icon={this.state.resetbtn ? "pi pi-spin pi-spinner" : ""} iconPos="right" />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                {/* <Button
                  style={{ marginLeft: "1%" }}
                  label="Load Items"
                  onClick={this.loaditems}
                  icon={this.state.loadbtn ? "pi pi-spin pi-spinner" : ""}
                  iconPos="right"
                />
                <Button
                  style={{ marginLeft: "1%" }}
                  label="Push Questions"
                  onClick={this.loadquestions}
                  icon={this.state.pushbtn ? "pi pi-spin pi-spinner" : ""}
                  iconPos="right"
                />
                <Button
                  style={{ marginLeft: "1%" }}
                  label="Delete Questions"
                  onClick={this.deletequestions}
                  disabled={this.state.Delete_button}
                  icon={this.state.delbtn ? "pi pi-spin pi-spinner" : ""}
                  iconPos="right"
                /> */}
                <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
                  <span style={{ marginRight: 9 }}> Enable auto push questions:</span>
                  No
                  <InputSwitch style={{ marginLeft: 5, marginRight: 5 }} checked={this.state.auto_push_que} onChange={this.auto_push_que} /> Yes
                </div>

                <div className="dim_dash" style={{ margin: 9, marginTop: 18 }}>
                  <div>
                    Stats for below selected dimensions:{" "}
                    <Button
                      style={{ width: 100 }}
                      label="Refresh"
                      onClick={this.calculate_dimensions}
                      icon={this.state.caldimbtn ? "pi pi-spin pi-spinner" : ""}
                      iconPos="right"
                    />
                  </div>
                  <div>No of selected dimensions : {this.state.selected_dims}</div>
                  <div>No of indirect dimensions : {this.state.indirect_dims}</div>
                  <div>No of questions : {this.state.quesions_len}</div>
                </div>
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-6 p-lg-3"></div>
            </div>
            <div className="p-grid container-upload">
              <div className="p-col-7">
                <input
                  type="file"
                  accept=".xlsx,image/*"
                  onChange={(event) => {
                    console.log("this is file selected:", event.target.files[0]);
                    this.setState({
                      file: event.target.files[0],
                    });
                  }}
                  style={{ marginLeft: "20px" }}
                />
                <label>(The file format should be of type .xls or .xlsx or image format)</label>
              </div>
              <div className="p-col">
                <Button type="submit" onClick={this.handleFile} className="btn btn-primary" label="Upload"></Button>
              </div>
            </div>
            <div className="p-grid">
              <div key={this.state.tablekey} className="p-col-12">
                <TreeTable
                  emptyMessage={
                    this.state.data.length > 0 && this.state.load_flag === "False"
                      ? "Loading data"
                      : this.state.data.length === 0 && this.state.load_flag === "True"
                      ? "Loading Data"
                      : "No data"
                  }
                  value={this.state.data}
                  selectionMode="checkbox"
                  selectionKeys={this.state.selectedNodeKeys}
                  onSelectionChange={(e) => this.selectkeys(e.value)}
                  style={{ textalign: "center" }}
                >
                  <Column field="name" header="Dimension" expander style={{ textAlign: "left" }}></Column>
                  <Column field="no_of_que" header="No of questions" style={{ textAlign: "left" }}></Column>
                  <Column field="dependencies" header="Dependency Dimensions" style={{ textAlign: "left" }}></Column>
                  {/* <Column body={this.display} header={this.displayheader()} style={{ textAlign: "center" }}></Column> */}
                  {/* <Column body={this.frequency} header="Frequency" style={{ textAlign: "center" }}></Column> */}
                  <Column body={this.gender} header="Gender" className="center-align"></Column>
                  {/* <Column body={this.family} header={this.maritalheader()} style={{ textAlign: "center" }}></Column> */}
                </TreeTable>
              </div>
            </div>
          </Panel>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organisation: state.util.organisation,
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(EmployeeDimension);
