import React from "react";
import Moment from "react-moment";
import api from "../api";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";

const ImgUpload = ({ onChange, src, loading }) => {
  return (
    <label htmlFor="photo-upload" className="custom-file-upload fas p-col-12">
      <div className="p-col-12 img-contianer">
        {loading ? (
          <div className="text-align-center height-200">
            <ProgressSpinner className="progress-spinner-profile-pic"></ProgressSpinner>
          </div>
        ) : (
          <div className="img-wrap img-upload">
            <img htmlFor="photo-upload" src={src} alt="profile-picture" />
          </div>
        )}
      </div>
      <input style={{ display: "none" }} id="photo-upload" type="file" onChange={onChange} accept=".jpg,.jpeg,.png" />
    </label>
  );
};
const DisplayDetails = ({ label, value }) => {
  return (
    <div className="p-col-6 display-profile-details">
      <div className="p-col-12 profile-label">{label}</div>
      <div className="p-col-12 profile-value">
        {label === "Date of birth" ? (
          <Moment format="D MMM YYYY" withTitle>
            {value}
          </Moment>
        ) : (
          value
        )}
      </div>
    </div>
  );
};
class ProfileCard extends React.Component {
  state = {
    file: "",
    imagePreviewUrl: "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true",
    name: "",
    status: "",
    active: "edit",
    profile_pic_url: "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true",
    loading: true,
    profile_url: "",
    thisdp: false,
  };

  photoUpload(e) {
    e.preventDefault();
    this.setState({
      loading: true,
      thisdp: true,
      profile_url: URL.createObjectURL(e.target.files[0]),
    });
    this.props.thisdp_change(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    console.log(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    formData.append("type", e.target.files[0]["type"]);
    formData.append("size", e.target.files[0]["size"]);
    formData.append("name", e.target.files[0]["name"]);

    api
      .post("api/me/upload-profile-picture", formData)
      .then((res) => this.setState({ ...res.data, loading: false }))
      .catch((error) => console.log(error));
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  render() {
    const { profile_pic_url, loading } = this.state;

    const {
      first_name = "NA",
      last_name,
      gender = "NA",
      phone_number = "NA",
      email = "NA",
      date_of_birth = "NA",
      employee_number = "NA",
      headers,
      userConfig,
    } = this.props.user_details;
    return (
      <div>
        <h3 style={{ textAlign: "center" }}>Profile</h3>
        <ImgUpload
          onChange={(e) => this.photoUpload(e)}
          src={this.state.thisdp ? this.state.profile_url : this.props.profile_pic_url}
          loading={false}
        />
        <div className="p-grid profile-data-container">
          <DisplayDetails label="Name" value={first_name + " " + last_name} />
          {userConfig && userConfig["gender"] && userConfig["gender"]["field_include"] && <DisplayDetails label={headers["gender"]} value={gender} />}
          {userConfig && userConfig["phone"] && userConfig["phone"]["field_include"] && (
            <DisplayDetails label={headers["phone"]} value={phone_number} />
          )}
          {userConfig && userConfig["email"] && userConfig["email"]["field_include"] && <DisplayDetails label={headers["email"]} value={email} />}
          {userConfig && userConfig["dateOfBirth"] && userConfig["dateOfBirth"]["field_include"] && (
            <DisplayDetails label={headers["dateOfBirth"]} value={date_of_birth} />
          )}
          {userConfig && userConfig["employeeNumber"] && userConfig["employeeNumber"]["field_include"] && (
            <DisplayDetails label={headers["employeeNumber"]} value={employee_number} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile_pic_url: state.util.profile_pic_url,
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(ProfileCard);
