import React, { Component } from 'react'
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import api from "../../api";
import { Growl } from "primereact/growl";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyRow } from "../../utils/Utils";

export const filetypes = [
    "Organisation",
    "Yeta Tools Update",
    "Yeta Items",
    "Dashboard parameters",
    "Emotional Report"
];

export const mandatoryFields = {
    "HR Master Update": ["employeeNumber", "firstName"]
}

class GroupConfiguration extends Component {
    state = {
        radioValue: "",
        cols: [],
        rows: [],
        errors: {},
        file: null,
        progress: false,
        result: null,
        sel_orgnames: [],
        loading: false
    };

    handleFile = () => {
        let { radioValue, file } = this.state
        const { organisation } = this.props;
        if (radioValue === "") {
            this.growl.show({
                severity: "error",
                detail: "Select atleast one option"
            });
        } else {
            const files = file;
            if (!files) {
                this.growl.show({
                    severity: "error",
                    detail: "Choose File"
                });
                return;
            }
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = reader.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, {
                    header: 1,
                    defval: ""
                });

                let cols = [];
                let rows = [];
                let emptyRowCount = 0;
                data.some((r, index) => {
                    if (index == 0) {
                        r.forEach(r1 => {
                            cols.push({
                                field: r1
                            });
                        });
                    }
                    if (index > 0) {
                        let obj = {};
                        r.some((r1, index2) => {
                            obj[cols[index2].field] = r1;
                            return emptyRowCount === 10;
                        });
                        if (isEmptyRow(obj, mandatoryFields[radioValue]))
                            emptyRowCount += 1;
                        else rows.push(obj);
                    }
                    return emptyRowCount === 10;
                });
                this.setState({ rows, progress: false });
                let datasending = rows;
                if (
                    radioValue === "Group Dimension",
                    radioValue === "Group Name"
                ) {
                    datasending = { data: rows, org: organisation.id };
                }
                this.setState({ loading: true });
                console.log("ds", datasending, radioValue, [organisation])
                api.post(this.urlMap(), datasending).then(res => {
                    this.setState({
                        radioValue: "",
                        result: res.data.result,
                        loading: false
                    });
                });
            };

            if (rABS) reader.readAsBinaryString(files);
            else reader.readAsArrayBuffer(files);
        }
    };

    urlMap = () => {
        let url = "";
        this.state.radioValue === "Group Name"
            ? (url = "api/cce/groupname")
            : this.state.radioValue === "Group Dimension"
                ? (url = "api/cce/groupdimension")
                : (url = "");
        return url;
    };

    render() {
        return (
            <div className="layout-main">
                <div className="p-grid">
                    <div className="p-col-12">
                        <Panel header={this.props.menuProps.label}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="row">
                                        <div className="p-col-4">
                                            <RadioButton
                                                name="filename_type"
                                                value="Group Name"
                                                onChange={event =>
                                                    this.setState({ radioValue: event.value })
                                                }
                                                checked={this.state.radioValue === "Group Name"}
                                            >
                                                Group Name
                                        </RadioButton>
                                            <label
                                                htmlFor="Group Name"
                                                className="p-radiobutton-label"
                                            >
                                                Group Name
                                        </label>
                                        </div>
                                        <div className="p-col-4">
                                            <RadioButton
                                                name="filename_type"
                                                value="Group Dimension"
                                                onChange={event =>
                                                    this.setState({ radioValue: event.value })
                                                }
                                                checked={this.state.radioValue === "Group Dimension"}
                                            >
                                                Group Dimension
                                            </RadioButton>
                                            <label
                                                htmlFor="Group Dimension"
                                                className="p-radiobutton-label"
                                            >
                                                Group Dimension
                                            </label>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="row">
                                                <div className="p-col-6">
                                                    <div className="p-grid container-upload">
                                                        <div className="p-col">
                                                            <input
                                                                type="file"
                                                                accept=".xlsx"
                                                                onChange={event => {
                                                                    this.setState({
                                                                        file: event.target.files[0],
                                                                        rows: [],
                                                                        cols: []
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "20px" }}
                                                            />
                                                            <label>
                                                                (The file format should be of type .xls or .xlsx)
                                                    </label>
                                                        </div>
                                                        <div className="p-col">
                                                            <Button
                                                                type="submit"
                                                                onClick={this.handleFile}
                                                                className="btn btn-primary"
                                                                label="Upload"
                                                            ></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        organisation: state.util.organisation
    };
};

export default connect(mapStateToProps, null)(GroupConfiguration);