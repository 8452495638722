import React, { Component } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { HelpModal } from "../utils/Utils";
import { authLogout, update_language, markReadHelp } from "../store/actions/auth";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import api from "../../src/api";
import { Growl } from "primereact/growl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderNotificationIcon } from "../common/Helper";
import { fetchNotificationCount, updateProfilePic } from "../store/actions/util";
import { OverlayPanel } from "primereact/overlaypanel";
import ProfileCard from "../utils/ProfileCard";
import atma_brand from "../assets/images/atma-brand.png";

export class AppTopbar extends Component {
  state = {
    helpModal: false,
    languageDialogVisible: false,
    pageId: 0,
    instructionsRead: false,
    firstTimeLoggedIn: false,
    languages: [],
    profile_url: "",
    thisdp: false,
    logoutdialog: false,
  };

  componentDidMount() {
    this.props.fetchNotificationCount();
    this.props.updateProfilePic();
    api
      .get("api/organisation/languages")
      .then((res) => {
        const { success, languages = [], show_language = false } = res.data;
        if (success && languages.length > 0) {
          this.setState({ languages: languages }, () => {
            const { user_details = { language_preference: "" } } = this.props;
            const { languages = [] } = this.state;
            const { language_preference, organisation } = user_details;
            this.setState({ language: language_preference });
            if (organisation && organisation.enableLanguage) {
              this.setState({ languageDialogVisible: show_language });
            }
            // if (!user_details.language_preference) {
            //   const language = languages.filter(l => l.is_default === true)[0][
            //     "code"
            //   ];
            //   this.setState({
            //     language: language,
            //     languageDialogVisible: true,
            //     firstTimeLoggedIn: true
            //   });
            // }
          });
        }
      })
      .catch((e) => console.log(e));
    this.setState({ helpModal: this.props.user_details.show_help });
  }

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  handleLogout = () => {
    this.setState({ logoutdialog: true });
  };
  handleLogoutconfirm = () => {
    this.props.logout();
  };
  thisdp_change = (url) => {
    this.setState({ thisdp: true, profile_url: url });
  };
  setLanguage = () => {
    const { language, firstTimeLoggedIn } = this.state;
    this.setState({ languageDialogVisible: false });
    api
      .post("api/organisation/languages", {
        language_preference: language,
      })
      .then((res) => {
        if (res.data.success) {
          const { user_details = { language_preference: "" } } = this.props;
          localStorage.setItem(
            "user_details",
            JSON.stringify({
              ...user_details,
              language_preference: language,
            })
          );
          this.props.update_language(language);
          this.showGrowl([
            {
              severity: "success",
              detail: "Language preference saved",
              closable: false,
            },
          ]);
        }
      })
      .catch((e) => console.log(e));
  };

  showGrowl = (messageArr) => {
    messageArr.forEach((m) => {
      setTimeout(() => {
        this.growl.show(m);
      }, 1);
    });
  };

  hideHelpModal = () => {
    this.setState({ helpModal: false });
  };

  onNextClick = () => {
    this.props.markHelpRead(false);
    if (this.props.user_details.organisation_settings.val.length === this.state.pageId + 1) {
      this.setState({ helpModal: false, instructionsRead: true });
    } else {
      this.setState({ pageId: this.state.pageId + 1 });
    }
  };

  closeLanguage = () => {
    if (this.props.user_details.show_language) {
      api.get("api/organisation/show_language").then((res) => {});
    }
    this.setState({ languageDialogVisible: false });
  };

  render() {
    const { helpModal, languageDialogVisible, languages = [], pageId, firstTimeLoggedIn, profile_pic_url } = this.state;
    let { language = "" } = this.state;
    let { notificationCount, user_details = {} } = this.props;
    if (language === null && languages.length > 0) {
      language = languages.filter((l) => l.is_default === true)[0]["code"];
    }
    const { organisation } = user_details;
    return (
      <div className="layout-topbar clearfix">
        <Growl ref={(el) => (this.growl = el)} position="bottomright" />
        <OverlayPanel className="profile-overlay profile-card-container" ref={(el) => (this.op = el)} showCloseIcon={true} dismissable={true}>
          <ProfileCard user_details={{ ...user_details, profile_pic_url }} thisdp_change={this.thisdp_change} />
        </OverlayPanel>
        {organisation && organisation.enableLanguage ? (
          <Dialog
            className="language-container"
            appendTo={document.body}
            header="Select your Language"
            visible={languageDialogVisible}
            modal={true}
            closable={!firstTimeLoggedIn}
            onHide={this.closeLanguage}
          >
            <div style={{ padding: 9, margin: 0 }}>
              {languages.map((l) => {
                if ((organisation && organisation.lang_status[l.code]) || l.code === "english") {
                  return (
                    <div className="p-grid cursor-pointer" key={l.description} onClick={() => this.setState({ language: l.code })}>
                      <div className={"p-col-12 option-container" + (language === l.code ? "-selected" : "")}>
                        <label htmlFor={l.description} className="option-label">
                          {l.description}
                        </label>
                        <span>
                          {language === l.code ? <FontAwesomeIcon className="float-right option-icon relative top5" icon={["fas", "check"]} /> : null}
                        </span>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="dialog-footer-container">
                <Button
                  type="submit"
                  autoFocus
                  onClick={() => this.setLanguage()}
                  className="p-button-rounded p-button-warning"
                  label="Save"
                  icon="pi pi-check"
                  iconPos="right"
                ></Button>
              </div>
            </div>
          </Dialog>
        ) : null}
        <Dialog
          style={{ maxWidth: "35vw", width: "70%" }}
          appendTo={document.body}
          header="Logout"
          visible={this.state.logoutdialog}
          onHide={() => this.setState({ logoutdialog: false })}
        >
          <div>
            <div style={{ textAlign: "center", fontSize: "15px" }}>Are you sure you want to logout?</div>
            <div style={{ textAlign: "center", marginTop: 30, marginBottom: 10 }}>
              <Button label="Yes" onClick={() => this.handleLogoutconfirm()} style={{ marginRight: 30 }} />
              <Button label="No" onClick={() => this.setState({ logoutdialog: false })} style={{ marginLeft: 30 }} />
            </div>
          </div>
        </Dialog>
        <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
          <span className="pi pi-bars" />
        </button>
        {user_details.organisation_settings ? (
          <HelpModal
            show={helpModal}
            pageId={pageId}
            language={language}
            onNextClick={this.onNextClick}
            hideHelpModal={this.hideHelpModal}
            user_details={this.props.user_details}
            onHide={() => {
              this.props.markHelpRead(false);
              this.setState({ helpModal: false });
            }}
          />
        ) : null}
        <span className="brand-icon">
          <img src={atma_brand}></img>
          <span className="topbar-text-header">
            powered by{" "}
            <a href="http://www.yetasolutions.com/" target="_blank">
              <b>yeta</b>
            </a>
          </span>
        </span>
        <div className="layout-topbar-icons relative top-5">
          <span onClick={(e) => this.op.toggle(e)} className="cursor-pointer displayName relative top-5">
            <b>{user_details.first_name + " " + user_details.last_name}</b>
          </span>
          {organisation && organisation.enableLanguage ? (
            // <OverlayTrigger
            //   placement="bottom"
            //   overlay={<Tooltip>Language</Tooltip>}
            // >

            //   <button
            //     className="p-link"
            //     onClick={() => this.setState({ languageDialogVisible: true })}
            //   >
            //     <FontAwesomeIcon
            //       className="font-awesome-icons-topbar topbar-icon"
            //       icon={["fas", "language"]}
            //     />
            //   </button>
            //  </OverlayTrigger>
            <Button
              style={{ top: "-8px" }}
              className="p-button-rounded p-button-warning"
              label="Language"
              // icon="pi pi-arrow-right"
              // iconPos="right"
              onClick={() => this.setState({ languageDialogVisible: true })}
            />
          ) : null}
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Profile</Tooltip>}>
            <button onClick={(e) => this.op.toggle(e)} className="p-link">
              {this.props.profile_pic_loading ? (
                <FontAwesomeIcon className="font-awesome-icons-topbar topbar-icon" icon={["fas", "user"]} />
              ) : (
                <img className="profile-pic-icon" src={this.state.thisdp ? this.state.profile_url : this.props.profile_pic_url}></img>
              )}
            </button>
          </OverlayTrigger>
          {renderNotificationIcon(notificationCount, user_details)}
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Logout</Tooltip>}>
            <button onClick={() => this.handleLogout()} className="p-link">
              <FontAwesomeIcon color="yellow" className="font-awesome-icons-topbar topbar-icon logout-icon" icon={["fas", "sign-out-alt"]} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Help</Tooltip>}>
            <button onClick={() => this.setState({ helpModal: true, pageId: 0 })} className="p-link">
              <FontAwesomeIcon className="font-awesome-icons-topbar topbar-icon" icon={["fas", "info-circle"]} />
            </button>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
    update_language: (language) => dispatch(update_language(language)),
    fetchNotificationCount: () => dispatch(fetchNotificationCount()),
    updateProfilePic: () => dispatch(updateProfilePic()),
    markHelpRead: () => dispatch(markReadHelp()),
  };
};

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    notificationCount: state.util.notificationCount,
    profile_pic_url: state.util.profile_pic_url,
    profile_pic_loading: state.util.profile_pic_loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopbar);
