import React, { Component } from "react";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { updateEmpData } from "../../store/actions/auth";

class Softskill extends Component {
  state = {
    dim: {},
    exp: {},
    exp1: false,
    master_bio_dim_data: [],
    bio_dim_data: [],
    selecteddims: [],
    master_selecteddims: [],
    bio_visible: false,
    bio_dim_data: null,
    expandedKeys: [],
    masterexpandedKeys: [],
    empskill: false,
    spin: false,
    bio_url: null,
    loading: false,
    parentdims: [],
    parentdimnames: {},
    showseldim: true,
    table_key: 1,
    final_price: null,
    mrp: "",
    discount: "",
    is_paid: true,
  };
  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    api
      .get("/api/me/biocvpreference")
      .then((res) => {
        if (res.data.results) {
          this.setState({
            bio_visible: true,
            bio_dim_data: res.data.dimens,
            master_bio_dim_data: res.data.dimens,
            selecteddims: res.data.selecteddims,
            master_selecteddims: res.data.selecteddims,
            empskill: res.data.empskills,
            spin: true,
            expandedKeys: res.data.expandkeys,
            masterexpandedKeys: res.data.expandkeys,
            parentdims: res.data.parentdim,
            parentdimnames: res.data.parentdimnames,
            table_key: 1,
            is_paid: res.data.is_paid,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  collapse = () => {
    let expandedKeys = { ...this.state.expandedKeys };
    if (expandedKeys) expandedKeys = this.state.masterexpandedKeys;
    else expandedKeys = true;
    this.setState({ expandedKeys: expandedKeys });
  };

  reset_bio = () => {
    this.setState({
      selecteddims: this.state.master_selecteddims,
      table_key: this.state.table_key + 1,
      bio_dim_data: this.state.master_bio_dim_data,
      expandedKeys: this.state.masterexpandedKeys,
    });
    this.collapse();
    // this.dt.reset();
  };

  download_bio = () => {
    let errors = [];
    if (errors.length === 0) {
      api
        .get("/api/cce/downloadcv")
        .then((res) => {
          if (res.data.results === true) {
            this.setState(
              {
                bio_url: res.data.biocv_url,
                loading: false,
              },
              this.filedownload(res.data.biocv_url)
            );
            console.log("res", res.data.biocv_url);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  filedownload = (url) => {
    const pdf_url = url;
    const link = document.createElement("a");
    link.href = pdf_url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  save_biopref = () => {
    if (this.state.is_paid) {
      this.setState({ loading: true });
      const data = { selecteddims: this.state.selecteddims };
      if (this.state.master_selecteddims !== this.state.selecteddims) {
        console.log("data", data);
        api
          .post("/api/me/savebiopreference", data)
          .then((res) => {
            if (res.data.results === true) {
              this.setState(
                {
                  bio_visible: false,
                  selecteddims: res.data.selecteddims,
                  master_selecteddims: res.data.selecteddims,
                  loading: false,
                  parentdims: res.data.parentdim,
                  parentdimnames: res.data.parentdimnames,
                },
                this.download_bio()
              );
              this.growl.show({
                severity: "success",
                summary: "Success Message",
                detail: res.data.status,
              });
              this.collapse();
            } else {
              this.setState({ loading: false });
              this.growl.show({
                severity: "warn",
                summary: "Info Message",
                detail: res.data.exception,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        console.log("nochange");
        this.download_bio();
      }
    } else {
      this.growl.show({
        severity: "warn",
        detail: "Buy full version to download soft skill bio",
        sticky: true,
      });
    }
  };

  scorebody = (rowData) => {
    if (rowData["score"] === "#") {
      return <i className="fas fa-lock"></i>;
    } else {
      return <span>{rowData["score"]}</span>;
    }
  };
  onSelecteddims = (e) => {
    const val = e.value;
    let uniqueids = [];
    let ids = [];
    let success = true;
    val.map((v) => {
      if (!uniqueids.includes(v.pdim)) {
        uniqueids.push(v.pdim);
      }
      ids.push(v.pdim);
    });

    uniqueids.map((ui) => {
      let filterIds = ids.filter((i) => i === ui);
      if (filterIds.length > 5) {
        this.growl.show({
          severity: "warn",
          detail: "Please select only five choices in each category",
        });
        success = false;
      }
    });
    if (success) {
      this.setState({ selecteddims: e.value });
    }
  };
  render() {
    let data = this.state.parentdims.map((pdim) => (
      <div className="col-md-6 col-sm-12" key={pdim}>
        <DataTable
          key={pdim + this.state.table_key}
          className="dimdata"
          value={this.state.bio_dim_data[pdim]}
          //style={{ height: "310px", width: "450px" }}
          scrollable={true}
          scrollHeight="250px"
          selection={this.state.selecteddims}
          onSelectionChange={(e) => this.onSelecteddims(e)}
          ref={(el) => (this.dt = el)}
        >
          <Column
            id={this.state.bio_dim_data[pdim].id}
            key={this.state.bio_dim_data[pdim].id}
            selectionMode="multiple"
            style={{ width: "3em", textAlign: "center" }}
            // reset={true}
            headerStyle={{ width: "3em", visibility: "hidden" }}
          />
          <Column
            id={this.state.bio_dim_data[pdim].id}
            key={this.state.bio_dim_data[pdim].id}
            field={this.state.parentdimnames[pdim]}
            header={this.state.parentdimnames[pdim]}
            reset={true}
            style={{ textAlign: "center" }}
          />
          <Column
            id={this.state.bio_dim_data[pdim].id}
            key={this.state.bio_dim_data[pdim].id}
            body={(rowData) => this.scorebody(rowData)}
            header="score"
            style={{ textAlign: "center" }}
            reset={true}
          />
        </DataTable>
      </div>
    ));
    const panelhead = (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>Softskills bio</div>
        <div>
          {this.props.user_details && !this.state.is_paid && (
            <form method="post" name="redirect" action={this.props.user_details.organisation.payment_link}>
              {/* "https://www.skillablers.com/BillingTesting/Billing.aspx"> */}
              <input
                type="hidden"
                id="encb_name"
                name="encb_name"
                value={this.props.user_details.first_name + " " + this.props.user_details.last_name}
              />
              <input type="hidden" id="encb_email" name="encb_email" value={this.props.user_details.email} />
              <input type="hidden" id="encb_phone" name="encb_phone" value={this.props.user_details.phone_number} />
              <input type="hidden" id="enc_RegId" name="enc_RegId" value={this.props.user_details.employee_number} />
              <input type="hidden" id="enc_servicetype" name="enc_servicetype" value="9" />
              <input type="hidden" id="enc_urlpage" name="enc_urlpage" value={process.env.REACT_APP_BASE_URL + "confirm-payment/softskill"} />
              {/* <input type="submit" value="Pay" style={{ marginRight: "15px", float: "right" }} className="p-button-warning whatbtn" /> */}
              <Button
                type="submit"
                style={{ marginRight: "15px", float: "right", marginBottom: 9 }}
                className="p-button-warning whatbtn"
                label="Buy Full Version"
                // icon={this.state.loading ? "pi pi-spin pi-spinner" : "pi pi-download"}
                // onClick={this.save_biopref}
              />
              {/* <script language="javascript">document.redirect.submit();</script> */}
            </form>
          )}{" "}
        </div>
      </div>
    );

    const headdata = (
      <div className="row">
        {this.state.parentdims.map((j) => (
          <div key={j} className="col-md-6" style={{ width: "50%" }}>
            {this.state.selecteddims.map((i) =>
              i.pdim === j ? (
                <div
                  key={i.id}
                  className="cvanschip"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "4px",
                    marginRight: "2px",
                    fontSize: "15px",
                  }}
                >
                  <span>
                    {i.score === "#" ? (
                      <span>
                        {i.dimname}- <i className="fas fa-lock"></i>
                      </span>
                    ) : (
                      <span>
                        {i.dimname}-{i.score}
                      </span>
                    )}
                  </span>
                </div>
              ) : null
            )}
          </div>
        ))}
      </div>
    );
    const le = ["as", "ad", "af", "ag", "ah"];
    console.log(le.slice(2, 4));
    const { user_details } = this.props;
    return (
      <div className="layout-main item-wrapper">
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: 50 }} position="bottomright" />

        {this.state.spin ? (
          this.state.empskill ? (
            this.state.bio_dim_data ? (
              <Panel
                blockScroll
                header={panelhead}
                visible={this.state.bio_visible}
                style={{ width: "100%", height: "100%" }}
                modal={true}
                onHide={() => this.setState({ bio_visible: false })}
              >
                <div className="p-col-12">
                  <div className="row">
                    <div className="col-md-6" style={{ marginTop: "-12px" }}>
                      <p>
                        You can choose your strongest competencies from your score card to prepare your softskill resume. You can attach this to your
                        standard resume, which can increase your chance of getting hired.
                      </p>
                      <p>
                        Choose up to 5 choices in each category and download as pdf. You can prepare unlimited number of times with different
                        combinations of choices and keep generating pdf as per your need.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div
                          style={{ border: "2px solid red", textAlign: "center", width: "fit-content", padding: 9, fontWeight: 700, color: "red" }}
                        >
                          <div>Choose maximum</div>
                          <div>5 on each side</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-col-3" style={{ marginTop: "-15px", float: "right" }}>
                      <Button
                        style={{ marginRight: "15px", float: "right" }}
                        className="p-button-warning whatbtn"
                        label="Download softskills bio"
                        icon={this.state.loading ? "pi pi-spin pi-spinner" : "pi pi-download"}
                        onClick={this.save_biopref}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Panel
                      blockScroll
                      header={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Seleted Preferences</div>
                          <Button
                            style={{ marginBottom: 9 }}
                            className="p-button-warning whatbtn"
                            label="Restore"
                            icon="pi pi-undo"
                            onClick={this.reset_bio}
                            tooltip="To last saved settings"
                            tooltipOptions={{ position: "bottom" }}
                          />
                        </div>
                      }
                      visible={this.state.bio_visible}
                      style={{
                        width: "100%",
                        height: "100%",
                        marginTop: "-10px",
                        marginRight: "3px",
                        marginLeft: "3px",
                      }}
                      modal={true}
                      onHide={() => this.setState({ bio_visible: false })}
                    >
                      <div className="row">
                        <div className="col-md-12">{headdata}</div>
                      </div>
                      <div className="row" style={{ marginTop: "-18px" }}>
                        {data}
                      </div>
                    </Panel>
                  </div>
                </div>
              </Panel>
            ) : null
          ) : (
            <Panel
              blockScroll
              header="Softskills Bio"
              visible={this.state.bio_visible}
              style={{ width: "100%", height: "100%" }}
              modal={true}
              onHide={() => this.setState({ bio_visible: false })}
            >
              <p
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: 700,
                  color: "#ff6c6c",
                }}
              >
                Please complete the assessment.
              </p>
            </Panel>
          )
        ) : (
          <Panel
            blockScroll
            header="Softskills Bio"
            visible={this.state.bio_visible}
            style={{ width: "100%", height: "100%" }}
            modal={true}
            onHide={() => this.setState({ bio_visible: false })}
          >
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner></ProgressSpinner>
            </div>
          </Panel>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

export default connect(mapStateToProps, null)(Softskill);
