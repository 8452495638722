import React, { Component } from "react";
import EChartScatter from "../../common/ECharts/EChartScatter";
import api from "../../api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import ScoreDistribution from "./DimInfLayout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";

let key = 0;
class PervsPotA extends Component {
  state = {
    rating1: [],
    rating2: [],
    rating3: [],
    no_rating: [],
    data: [],
    tabled: [],
    loading_handle: true,
    overallemp: "",
    rating3max: [],
    rating3len: [],
    rating2len: [],
    rating2max: [],
    rating2lt60: [],
    rating2lt20: [],
    success: false,
    tabsel: "",
    leveldata: [],
    deptdata: [],
    agedata: [],
    workexpdata: [],
    distdata: [],
    key: 0,
    hidden: false,
    table: [],
    headtabsel: "",
    tabload: false,
    totaldis: {},
    filterData: {},
    seldep: [],
    selloc: [],
    sellev: [],
    dep: [],
    loc: [],
    lev: [],
    filterApplied: false,
    empo: "",
    high_val: "",
    low_val: "",
  };
  componentDidMount() {
    api.get("api/cce/get-dashboard-filters").then((res) => {
      this.setState({ ...res.data });
      this.fetch();
    });
  }
  applyf() {
    if (this.state.filterApplied) {
      this.fetch();
    } else {
      this.growl.show({
        severity: "error",
        detail: "No Filter Selected",
      });
    }
  }
  fetch() {
    const { seldep, sellev, selloc } = this.state;
    const data = {
      dep: seldep,
      loc: selloc,
      lev: sellev,
      user: this.props.user,
    };
    this.setState({
      tabsel: "",
      load2: true,
      dep: seldep,
      loc: selloc,
      lev: sellev,
      hidden: false,
      tabload: false,
    });
    api
      .post("/api/assessments/performancedashboard", data)
      .then((res) => {
        const { results } = res.data;
        if (results) {
          this.setState({
            high_val: res.data.high_val,
            low_val: res.data.low_val,
            data: res.data.result,
            overallemp: res.data.overallCount,
            rating3max:
              res.data.result.Rating3max > 40
                ? res.data.result.Rating3max + 10
                : "",
            rating3len:
              res.data.result.Rating3max > 40
                ? res.data.result.Rating3.length + 1
                : "",
            rating2len:
              res.data.result.Rating2max > 60
                ? res.data.result.Rating2.length + 1
                : "",
            rating2max:
              res.data.result.Rating2max > 60
                ? res.data.result.Rating2max + 10
                : "",
            rating2lt60:
              res.data.result.Rating2max > 60
                ? res.data.result.Rating2lt60 + 1
                : "",
            rating2lt20: res.data.result.Rating2lt20 + 1,
            tabled: res.data.tabled,
            success: true,
            loading_handle: false,
            table: res.data.table,
            totaldis: res.data.totaldis,
            load2: false,
            nodata: false,
          });
        } else {
          if (this.state.filterApplied) {
            this.setState({
              overallemp: 0,
              success: true,
              loading_handle: false,
              load2: false,
              nodata: true,
            });
          } else {
            this.setState({
              overallemp: 0,
              success: false,
              loading_handle: false,
              load2: false,
              nodata: true,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }
  fetchdata = () => {
    this.refs.demo.scrollIntoView({ behavior: "smooth" });
    const { dep, loc, lev } = this.state;
    key = key + 1;
    const data = {
      type: this.state.tabsel,
      dep: dep,
      loc: loc,
      lev: lev,
      user: this.props.user,
    };
    api
      .post("/api/cce/performancedistribution", data)
      .then((res) =>
        this.setState({
          empo: res.data.overallCount,
          distdata: res.data,
          leveldata: res.data.levelWise.map((grade) => ({
            name: grade.role,
            value: grade.percentage,
          })),
          deptdata: res.data.deptWise.map((grade) => ({
            name: grade.name,
            value: grade.percentage,
          })),
          agedata: res.data.ageWise.map((grade) => ({
            name: grade.range,
            value: grade.percentage,
          })),
          workexpdata: res.data.workexpWise.map((grade) => ({
            name: grade.range,
            value: grade.percentage,
          })),
          tabload: false,
        })
      )
      .catch((err) => console.log(err));
  };

  tabsel = (name) => {
    let head =
      name === "moderatepotential"
        ? "Moderate Potential - " + this.state.totaldis["moderate"]
        : name === "goodpotential"
        ? "Good Potential - " + this.state.totaldis["good"]
        : "High Potential - " + this.state.totaldis["high"];
    key = key + 1;
    if (this.state.tabsel !== name) {
      this.setState(
        { tabsel: name, hidden: true, headtabsel: head, tabload: true },
        () => this.fetchdata(name)
      );
    } else {
      this.refs.demo.scrollIntoView({ behavior: "smooth" });
    }
  };
  renderSelectedTab = () => {
    const {
      leveldata,
      data,
      deptdata,
      agedata,
      workexpdata,
      tabsel,
      key,
      distdata,
    } = this.state;
    const { menuProps } = this.props;
    console.log(leveldata);
    return (
      <div className={tabsel}>
        <ScoreDistribution
          menuProps={menuProps}
          data={distdata}
          potential={[]}
          leveldata={leveldata}
          deptdata={deptdata}
          agedata={agedata}
          workexpdata={workexpdata}
          //  name={this.props.type}
          //globalfilter={globalfilter}
          height={"380px"}
          dheight={"440px"}
          total={true}
          nCount={this.state.empo}
          // type={this.props.type}
        />
      </div>
    );
  };
  render() {
    const {
      filterApplied,
      filterData,
      seldep,
      sellev,
      selloc,
      high_val,
      low_val,
    } = this.state;
    const chartData = {
      type: "scatter",
      title: "",
      lowd: low_val,
      highd: high_val,
      graph: "pvspa",
      data: this.state.data,
      yaxis_label: "Potential",
    };
    const { loading_handle, success } = this.state;
    return (
      <div className="layout-main">
        <Growl
          ref={(el) => (this.growl = el)}
          style={{ marginTop: 20 }}
          position="bottomright"
        />
        <Panel
          header={
            <div style={{ marginBottom: 15 }}>
              <span style={{ fontSize: "18px" }}>
                {this.props.menuProps.label}
              </span>
              <span
                style={{ float: "right" }}
                className="total-employees-container"
              >
                N - {this.state.overallemp}
              </span>
            </div>
          }
        >
          {loading_handle === true ? (
            <>
              <div className="loading-spinner-container">
                <ProgressSpinner></ProgressSpinner>
              </div>
            </>
          ) : success ? (
            <>
              <Panel header="Filters">
                <div className="box-body box-body-cust-250">
                  <div className="row">
                    <div className="col-md-3">
                      <MultiSelect
                        value={this.state.selloc}
                        options={
                          filterData.locations ? filterData.locations : []
                        }
                        onChange={(e) => this.setState({ selloc: e.value })}
                        filter={true}
                        placeholder="Location..."
                        scrollHeight="200px"
                      />
                    </div>
                    <div className="col-md-3">
                      <MultiSelect
                        value={this.state.seldep}
                        options={
                          filterData.departments ? filterData.departments : []
                        }
                        onChange={(e) => this.setState({ seldep: e.value })}
                        filter={true}
                        placeholder="Department..."
                        scrollHeight="200px"
                      />
                    </div>
                    <div className="col-md-3">
                      <MultiSelect
                        value={this.state.sellev}
                        options={filterData.levels ? filterData.levels : []}
                        onChange={(e) => this.setState({ sellev: e.value })}
                        filter={true}
                        placeholder="Grade..."
                        scrollHeight="200px"
                      />
                    </div>
                    <div className="col-md-3">
                      <Button
                        type="button"
                        label="Apply"
                        onClick={() =>
                          this.setState(
                            {
                              filterApplied:
                                seldep.length === 0 &&
                                selloc.length === 0 &&
                                sellev.length === 0
                                  ? false
                                  : true,
                            },
                            () => this.applyf()
                          )
                        }
                        style={{ marginRight: "15px" }}
                      />
                      <Button
                        type="button"
                        label="Reset"
                        onClick={() =>
                          this.setState(
                            {
                              seldep: [],
                              selloc: [],
                              sellev: [],
                              filterApplied: false,
                              load2: true,
                            },
                            () => this.fetch()
                          )
                        }
                        style={{ marginRight: "15px" }}
                      />
                    </div>
                  </div>
                </div>
              </Panel>
              {this.state.load2 ? (
                <div className="loading-spinner-container">
                  <ProgressSpinner></ProgressSpinner>
                </div>
              ) : this.state.nodata ? (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  No Data
                </div>
              ) : (
                <Panel header=" ">
                  <div className="row">
                    <div className="col-md-4" style={{ textAlign: "center" }}>
                      <div
                        className="tab-button-dim-inf"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="p-button-rounded p-button-secondary"
                          style={
                            this.state.tabsel === "highpotential"
                              ? {
                                  background: "#cefad7",
                                  border: "3px solid #39cccc",
                                  color: "black",
                                }
                              : {
                                  background: "#cefad7",
                                  border: "3px solid white",
                                  color: "black",
                                }
                          }
                          onClick={() => this.tabsel("highpotential")}
                          label={
                            "High Potential - " + this.state.totaldis["high"]
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4" style={{ textAlign: "center" }}>
                      <div
                        className="tab-button-dim-inf"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="p-button-rounded p-button-secondary"
                          style={
                            this.state.tabsel === "goodpotential"
                              ? {
                                  background: "#feefc8",
                                  border: "3px solid #ffb800",
                                  color: "black",
                                }
                              : {
                                  background: "#feefc8",
                                  border: "3px solid white",
                                  color: "black",
                                }
                          }
                          onClick={() => this.tabsel("goodpotential")}
                          label={
                            "Good Potential - " + this.state.totaldis["good"]
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4" style={{ textAlign: "center" }}>
                      <div
                        className="tab-button-dim-inf"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="p-button-rounded p-button-secondary"
                          style={
                            this.state.tabsel === "moderatepotential"
                              ? {
                                  background: "#ffdbdc",
                                  border: "3px solid #ff5050",
                                  color: "black",
                                }
                              : {
                                  background: "#ffdbdc",
                                  border: "3px solid white",
                                  color: "black",
                                }
                          }
                          onClick={() => this.tabsel("moderatepotential")}
                          label={
                            "Moderate Potential - " +
                            this.state.totaldis["moderate"]
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <EChartScatter pvsp={true} chartData={chartData} />
                    </div>
                    <div className="col-md-5">
                      <DataTable
                        className=""
                        //header={header}
                        globalFilter={this.state.globalFilter}
                        responsive={true}
                        style={{ textAlign: "center", marginTop: 50 }}
                        resizableColumns={true}
                        value={this.state.table}
                      >
                        <Column
                          field="name"
                          header=""
                          style={{ backgroundColor: "white" }}
                          headerStyle={{ backgroundColor: "white" }}
                        />
                        <Column
                          field="high"
                          header="High Potential"
                          style={{ backgroundColor: "#8ff4a370" }}
                          headerStyle={{ backgroundColor: "#8ff4a370" }}
                        />
                        <Column
                          field="good"
                          header="Good Potential"
                          style={{ backgroundColor: "#fbbc0540" }}
                          headerStyle={{ backgroundColor: "#fbbc0540" }}
                        />
                        <Column
                          field="moderate"
                          header="Moderate Potential"
                          style={{ backgroundColor: "#ffacac70" }}
                          headerStyle={{ backgroundColor: "#ffacac70" }}
                        />
                        <Column
                          field="total"
                          header="Total"
                          style={{ backgroundColor: "white" }}
                          headerStyle={{ backgroundColor: "white" }}
                        />
                      </DataTable>
                      {/* <table className="table text-center table-bordered thead-dark">
                  <tbody>
                    <tr style={{ backgroundColor: "#ffacac70" }}>
                      <td>Area of Concern(Employess)</td>
                      <td>{this.state.tabled.concern}</td>
                    </tr>
                  </tbody>
                </table> */}
                    </div>
                  </div>
                </Panel>
              )}
            </>
          ) : (
            // <div className="row error-text-message">
            //   <span>Performance data not available</span>
            // </div>
            <div className="p-col-12">
              <div
                style={{ color: "#6a6b6cba" }}
                className=" box-body box-body-cust-250"
              >
                <div
                  className="medialog"
                  style={{
                    fontSize: "20px",
                    fontWeight: "800px",
                    textAlign: "center",
                  }}
                >
                  Data Collection is in Progress
                </div>
              </div>
            </div>
          )}
        </Panel>
        {this.state.hidden ? (
          <Panel
            key={key}
            className={this.state.tabsel + "1"}
            header={this.state.headtabsel}
          >
            <div ref="demo">
              {this.state.tabload === true ? (
                <>
                  <div className="loading-spinner-container">
                    <ProgressSpinner></ProgressSpinner>
                  </div>
                </>
              ) : (
                this.renderSelectedTab()
              )}
            </div>
          </Panel>
        ) : null}
      </div>
    );
  }
}

export default PervsPotA;
