import * as actionTypes from "./actionTypes";
import api from "../../api";

export const questionload = () => {
  return {
    type: actionTypes.QUESTIONS_LOADING,
    completed_status: false,
    loading: true,
  };
};

export const question = (new_question) => {
  return {
    type: actionTypes.QUESTION_NEXT,
    new_ques: new_question,
    completed_status: false,
    loading: false,
  };
};

export const group_answers = (answer) => {
  return {
    type: actionTypes.GROUP_ANSWERS,
    answer: answer,
    completed_status: false,
    loading: false,
  };
};

export const set_loading = () => {
  return {
    type: actionTypes.SET_LOADING,
    loading: false,
  };
};

export const questioncompleted = (completedtext, status_flag, data) => {
  return {
    type: actionTypes.QUESTIONS_COMPLETED,
    complete_text: completedtext,
    completed_status: status_flag,
    loading: false,
    data: data,
  };
};

export const incrementQuestionCount = () => {
  return {
    type: actionTypes.INCREMENT_QUESTION_COUNT,
  };
};

export const getquestion = (data, return_item) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return (dispatch) => {
    dispatch(questionload());
    dispatch(incrementQuestionCount());
    api
      .post(
        "api/assessments/item-response",
        {
          data_array: data,
          return_item: return_item,
        },
        axiosConfig
      )
      .then((res) => {
        console.log("check", res.data);
        if ((res.data.item || res.data.group_result) && res.data.progress < 100) {
          dispatch(question(res.data));
          dispatch(group_answers({}));
        }
        if (res.data.progress === 100) {
          dispatch(questioncompleted("Questions Completed", true, res.data));
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(set_loading());
      });
  };
};
