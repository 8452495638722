import React, { Component } from "react";
import MyTeam from "./MyTeam";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import api from "../../api";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import { Growl } from "primereact/growl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrainingNeedsComp from "../../common/TrainingNeedsComp";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

class MyTeamParent extends Component {
  state = {
    managers: [],
    loading: true,
    empDetails: [],
    selEmp: "",
    empData: [],
    display: false,
    emp_traindata: null,
    emp_learn_details: {},
    training_data: [],
    trainEmpData: {},
  };

  fetchData = () => {
    const { managers } = this.state;
    api
      .post(this.props.type === "My Team" ? "/api/me/myteam-competencies" : "/api/me/mygroup-competencies", { managers: managers })
      .then((res) => {
        this.setState({
          loading: false,
          mygroupPotentialArray: { ...res.data },
        });
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    api
      .get("/api/cce/get-dashboard-filters")
      .then((res) => {
        this.setState({ ...res.data }, this.fetchData());
      })
      .catch((err) => console.log(err));
    api
      .get("/api/assessments/get_employees/" + this.props.type + "/")
      .then((res) => {
        this.setState({ empData: res.data.data });
      })
      .catch((err) => console.log(err));
    api
      .get("/api/assessments/get_team_training/" + this.props.type + "/")
      .then((res) => {
        this.setState({
          training_data: res.data.training_data,
          trainEmpData: res.data.empData,
        });
      })
      .catch((err) => console.log(err));
  }

  validateManagerSelection = (managers) => {
    if (managers.length > 4) {
      this.growl.show({
        severity: "error",
        summary: "Maximum 4 manager's group comparision is allowed",
      });
      return false;
    } else {
      return true;
    }
  };

  unSelectManager = (managerValue) => {
    let { managers } = this.state;
    managers = managers.filter((m) => m !== managerValue);
    this.setState({ managers });
  };

  getdetails = (e) => {
    if (e) {
      this.setState({ selEmp: e.value });
      let empDetails = this.state.empData.filter((m) => {
        return m.value === e.value;
      });
      this.setState({
        empDetails,
      });
      api
        .post("/api/assessments/get_team_training_emp", { emp_id: empDetails[0].emp_id })
        .then((res) => {
          this.setState({ emp_traindata: res.data.training_data, emp_learn_details: res.data.learn_details });
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const {
      managers,
      filterData,
      loading,
      mygroupPotentialArray,
      empData,
      selEmp,
      display,
      empDetails = [],
      emp_traindata = [],
      emp_learn_details = {},
      training_data = [],
      trainEmpData = {},
    } = this.state;
    const { type, menuProps } = this.props;
    const { link = "" } = menuProps;

    // const learn_details = {
    //   T22CON01: "Focusing on strengths after listing them out",
    //   T22CON02: "Preparing one's thoughts before meeting new people or situations",
    //   T22CON03: "Making a plan to overcome shortcomings and failures",
    //   T22CON04: "Getting guidance and analysing how others solve problems, :: Anticipating challenges and surprises",
    // };
    // const training_data = [
    //   { sn0: "1", training_id: "T22CON01", module_name: "Confidence building" },
    //   { sn0: "2", training_id: "T22CON02", module_name: "Empathy" },
    //   { sn0: "3", training_id: "T22CON03", module_name: "Flexibility " },
    //   { sn0: "4", training_id: "T22CON04", module_name: "Networking " },
    // ];
    // const empDetails1 = {
    //   T22CON01: [
    //     { name: "name1", empNo: "111" },
    //     { name: "name2", empNo: "111" },
    //     { name: "name3", empNo: "111" },
    //   ],
    //   T22CON02: [
    //     { name: "name4", empNo: "111" },
    //     { name: "name5", empNo: "111" },
    //     { name: "name6", empNo: "111" },
    //   ],
    //   T22CON03: [
    //     { name: "name7", empNo: "111" },
    //     { name: "name8", empNo: "111" },
    //     { name: "name9", empNo: "111" },
    //   ],
    //   T22CON04: [
    //     { name: "name10", empNo: "111" },
    //     { name: "name11", empNo: "111" },
    //     { name: "name12", empNo: "111" },
    //   ],
    // };
    return (
      <div className="layout-main">
        <Growl ref={(el) => (this.growl = el)}></Growl>
        {loading ? (
          <div className="row">
            <div className="loading-spinner col-md-3">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <>
            {!link.includes("myteamdashboard") ? (
              <Panel header="Compare Manager Dashboard">
                <div className="box-body box-body-cust-250">
                  <div className="row manager-selection-dashboard">
                    <div className="col-md-3">
                      <MultiSelect
                        value={managers}
                        options={filterData.managers ? filterData.managers : []}
                        onChange={(e) => {
                          if (this.validateManagerSelection(e.value)) this.setState({ managers: e.value });
                        }}
                        filter={true}
                        maxSelectedLabels={1}
                        selectedItemsLabel={managers.length + " managers selected"}
                        placeholder="Select Managers"
                      />
                    </div>
                    <div className="col-md-3">
                      <Button
                        type="button"
                        label="Apply"
                        onClick={() => this.setState({ loading: true }, () => this.fetchData())}
                        style={{ marginRight: "15px" }}
                      />
                      <Button
                        type="button"
                        label="Reset"
                        onClick={() =>
                          this.setState(
                            {
                              managers: [],
                              loading: true,
                            },
                            () => this.fetchData()
                          )
                        }
                        style={{ marginRight: "15px" }}
                      />
                    </div>
                  </div>
                  {filterData.managers.map(
                    (m) =>
                      managers.includes(m.value) && (
                        <span className="selected-managers-container" key={m.value}>
                          <span className="selected-managers">{m.label}</span>
                          <span className="selected-managers-icon">
                            <FontAwesomeIcon className="" icon={["fas", "times"]} onClick={() => this.unSelectManager(m.value)} />
                          </span>
                        </span>
                      )
                  )}
                </div>
              </Panel>
            ) : null}
            {Object.keys(mygroupPotentialArray).map((key) => (
              <MyTeam
                managerName={filterData.managers.filter((f) => f.value.toString() === key.toString())}
                userId={key}
                type={type}
                key={key}
                groupData={mygroupPotentialArray[key]}
                menuProps={menuProps}
              />
            ))}
          </>
        )}
        {loading ? (
          <div className="row">
            <div className="loading-spinner col-md-3">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <Panel header="Team Development Needs">
            <div style={{ padding: 9 }}>
              <div>
                <ul>
                  <li>
                    The table suggests a list of important training topics, which are required for the team to strengthen their weak behavioural
                    competencies.
                  </li>
                  <li>It is organised Team member-wise and Training topic-wise.</li>
                </ul>
              </div>
              <div className="p-grid" style={{ margin: 9, marginTop: 18 }}>
                <span style={{ marginRight: 9, fontWeight: 700 }}>Select: </span>
                Team member-wise
                <InputSwitch
                  style={{ marginLeft: 5, marginRight: 5 }}
                  checked={this.state.display}
                  onChange={(e) => {
                    this.setState({ display: e.value });
                  }}
                />
                Training topic-wise
              </div>
              {!display ? (
                <div>
                  <div className="p-grid">
                    <div className="p-col-12">
                      Choose Team Member:
                      <Dropdown
                        className="readynextdd"
                        style={{ width: "22em", marginLeft: "1em" }}
                        //size={70}
                        value={selEmp}
                        options={empData}
                        placeholder="Click here..."
                        onChange={(e) => this.getdetails(e)}
                        filter={true}
                        filterPlaceholder="Search"
                        filterBy="label,value"
                      />
                      {/* <Button style={{ marginLeft: "1em" }} onClick={() => this.empSearch()} label="Search" /> */}
                    </div>
                    <br />
                  </div>
                  {emp_traindata ? (
                    emp_traindata.length > 0 ? (
                      <TrainingNeedsComp
                        empModel={true}
                        teamDash={true}
                        learn_details={emp_learn_details}
                        training_data={emp_traindata}
                        empDetails={{}}
                        empSel={empDetails[0]}
                      />
                    ) : (
                      <div style={{ textAlign: "center", fontSize: 20, fontWeight: 700 }}>No Data</div>
                    )
                  ) : null}
                </div>
              ) : training_data && training_data.length > 0 ? (
                <TrainingNeedsComp
                  empModel={false}
                  teamDash={true}
                  learn_details={{}}
                  training_data={training_data}
                  empDetails={trainEmpData}
                  empSel={{}}
                />
              ) : (
                <div style={{ textAlign: "center", fontSize: 20, fontWeight: 700 }}>No Data</div>
              )}
            </div>
          </Panel>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  };
};

export default connect(mapStateToProps, null)(MyTeamParent);
